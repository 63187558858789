import { AboutDocumentFamilyDto } from "@rhinestone/portal-web-api";
import { usePortalApi } from "../../api-access/use-portal-api";
import { useDocumentInfo } from "../document-context";

export function useDocumentFamilyProperties(): AboutDocumentFamilyDto {
  const { hiveId, fullName } = useDocumentInfo();

  return usePortalApi(
    client => client.getDocumentFamilyProperties(hiveId, fullName),
    ["getDocumentFamilyProperties", hiveId, fullName]
  ).data as AboutDocumentFamilyDto;
}
