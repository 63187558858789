import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { MenuItem } from "../../../ui-components/ContextMenu/ContextMenu";
import {
  addHighlightMenuItem,
  AddHighlightMenuItemCommonProps
} from "./addHighlightMenuItem";

export function addAssetCollectionHighlightMenuItem(
  props: AddHighlightMenuItemCommonProps
): MenuItem {
  const labelText = (
    <Translate
      id={translationKeys.annotations.add_asset_collection_highlight}
    />
  );

  return addHighlightMenuItem({ ...props, labelText });
}
