import React from "react";
import { Translate } from "react-localize-redux";
import { Watermark } from ".";
import useTheme from "@material-ui/core/styles/useTheme";
import {
  DanishLawStatus,
  danishLawStatusColors,
  danishLawStatusTranslationKeys
} from "../../../danish-law-status";
import { useDocumentLegalStatus } from "../use-document-legal-status";
import { useDocumentInfo } from "../../../document-context";

const DanishLawStatusWatermark: React.FC = () => {
  const { documentId } = useDocumentInfo();
  const { documentLegalStatus } = useDocumentLegalStatus(documentId);
  const theme = useTheme();
  const status = documentLegalStatus ?? DanishLawStatus.Unknown;
  const translationKey = danishLawStatusTranslationKeys[status];
  const color = danishLawStatusColors(theme)[status];
  const enabled =
    documentLegalStatus && documentLegalStatus !== DanishLawStatus.Effective;

  return enabled ? (
    <Translate>
      {({ translate }) => (
        <Watermark label={String(translate(translationKey))} color={color} />
      )}
    </Translate>
  ) : (
    <></>
  );
};
export default DanishLawStatusWatermark;
