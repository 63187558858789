import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import TaxonContentBaseTile from "./TaxonContentBaseTile";

interface DocumentTileProps {
  children: React.ReactNode;
  siblingCount?: number;
}
const DocumentTile: React.FC<DocumentTileProps> = ({
  children,
  siblingCount
}) => {
  return (
    <TaxonContentBaseTile
      title={<Translate id={translationKeys.taxon_page.document_tile_header} />}
      siblingCount={siblingCount}
    >
      {children}
    </TaxonContentBaseTile>
  );
};

export default DocumentTile;
