/* eslint-disable complexity */

import * as React from "react";
import { ApiFetchIndicator } from "../../ui-components/ApiFetchIndicator";
import { ParagraphZoomLayout } from "../../ui-components/layouts/ParagraphZoomLayout";
import { useUserHasFeature, Feature } from "../../user/features";
import processParagraphSectionHeaderSkip from "../content/rhinestone-xml/processing-instructions/process-paragraph-section-header-skip";
import { useParagraphZoomContext } from "./ParagraphZoomContext";
import { ParagraphZoomStateContainer } from "./consolidation-info/ParagraphZoomStateContainer";
import { OutlinedBox } from "./content/OutlinedBox";
import { ParagraphZoomTextContainer } from "./content/ParagraphZoomTextContainer";
import { ParagraphZoomDataError } from "./errors/ParagraphZoomDataError";
import { ParagraphZoomRightPane } from "./panes/ParagraphZoomRightPane";
import { ParagraphPreparatoryWorkTimelinesContainer } from "./preparatory-work/ParagraphPreparatoryWorkTimelinesContainer";
import { TabContentContainer } from "./tabs/TabContentContainer";
import { ParagraphTimelineContainer } from "./timeline/ParagraphTimelineContainer";
import { LargeTextSkeleton } from "../../ui-components/Skeleton/SmallSkeleton";

interface ParagraphZoomProps {
  paragraphId: string | undefined;
}
const ParagraphZoom: React.FunctionComponent<ParagraphZoomProps> = ({
  paragraphId
}) => {
  const hasParagraphZoomTabSectionFeature = useUserHasFeature(
    Feature.ParagraphZoomTabSection
  );
  const { currentParagraphInTime, status, error } = useParagraphZoomContext();

  const hiveId = currentParagraphInTime?.portalDocumentContext?.hiveId;
  const fullName = currentParagraphInTime?.portalDocumentContext?.fullName;
  return (
    <ParagraphZoomLayout>
      <ParagraphTimelineContainer paragraphId={paragraphId} />

      <OutlinedBox withScroll>
        <ApiFetchIndicator
          status={status}
          errorComponent={<ParagraphZoomDataError error={error} />}
          loadingIndicator={<LargeTextSkeleton />}
        >
          {hiveId && fullName && paragraphId && (
            <ParagraphZoomTextContainer
              hiveId={hiveId}
              fullName={fullName}
              sectionIds={[paragraphId]}
              additionalParsers={[processParagraphSectionHeaderSkip()]}
            />
          )}
        </ApiFetchIndicator>
      </OutlinedBox>
      {hasParagraphZoomTabSectionFeature && (
        <OutlinedBox>
          <ApiFetchIndicator
            status={status}
            errorComponent={<ParagraphZoomDataError error={error} />}
            loadingIndicator={<LargeTextSkeleton />}
          >
            {hiveId && fullName && paragraphId && (
              <TabContentContainer
                hiveId={hiveId}
                fullName={fullName}
                paragraphId={paragraphId}
              />
            )}
          </ApiFetchIndicator>
        </OutlinedBox>
      )}
      <ParagraphZoomRightPane>
        <ParagraphZoomStateContainer />
        <ParagraphPreparatoryWorkTimelinesContainer />
      </ParagraphZoomRightPane>
    </ParagraphZoomLayout>
  );
};

export default ParagraphZoom;
