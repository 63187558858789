import * as React from "react";
import MaterialUiTimelineContent from "@material-ui/lab/TimelineContent";
import clsx from "clsx";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flex: "0 1 auto",
    padding: "0 16px",
    alignItems: ({ align }: TimelineContentProps) => align,
    alignSelf: ({ align }: TimelineContentProps) => align,
    LineHeight: 0,
    marginTop: 0
  }
}));

interface TimelineContentProps {
  className?: string;
  align?: "start" | "center" | "end" | "normal";
}

export const TimelineContent: React.FC<TimelineContentProps> = ({
  children,
  className,
  align = "normal"
}) => {
  const classes = useStyles({ align });
  return (
    <MaterialUiTimelineContent className={clsx(classes.content, className)}>
      {children}
    </MaterialUiTimelineContent>
  );
};
