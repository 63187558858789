import React from "react";
import { ArticleRelationChips } from ".";
import { usePortalProperty } from "../../../../use-portal-property";
import { useDocumentRelationChipsState } from "../DocumentRelationChipsStateProvider";
import { ArticleAllRelationChips } from "./ArticleAllRelationChips";
import { ApiFetchIndicator } from "../../../../../ui-components/ApiFetchIndicator";

export const ArticleRelationChipsGroup: React.FC = () => {
  const { expanded } = useDocumentRelationChipsState();
  const { value: showAllRelationsProperty, status } =
    usePortalProperty<boolean>("ShowAllRelations");

  return expanded ? (
    <ApiFetchIndicator status={status}>
      {showAllRelationsProperty ? (
        <ArticleAllRelationChips />
      ) : (
        <ArticleRelationChips />
      )}
    </ApiFetchIndicator>
  ) : null;
};

export default ArticleRelationChipsGroup;
