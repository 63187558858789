import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UiRenderErrorBoundaryProps {}

interface UiRenderErrorBoundaryState {
  hasError: boolean;
  error: Error | undefined;
  errorInfo: React.ErrorInfo | undefined;
}

export class UiRenderErrorBoundary extends React.Component<
  UiRenderErrorBoundaryProps,
  UiRenderErrorBoundaryState
> {
  constructor(props: UiRenderErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorInfo: undefined, error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;

    const errorMessage =
      error && errorInfo && createErrorReport(error, errorInfo);

    return hasError ? (
      <>
        <h1>Der skete en uventet fejl i portalen.</h1>
        {process.env.NODE_ENV !== "production" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: errorMessage?.replace(/\n/g, "<br>") || "Unknown error"
            }}
          ></div>
        ) : (
          renderProductionMessage(errorMessage)
        )}
      </>
    ) : (
      this.props.children
    );
  }
}

function createErrorReport(error: Error, errorInfo: React.ErrorInfo) {
  return `Error message: ${error.message} \n\n Error stacktrace: \n ${error.stack} \n\n React stacktrace: \n ${errorInfo.componentStack}`;
}

function renderProductionMessage(errorMessage: string | undefined) {
  return (
    <>
      <div>
        Venligst kontakt support. For at lette fejlsøgning, kopier fejl
        beskrivelse til udklipsholder og send det med i support sagen.
      </div>
      <button
        onClick={() =>
          navigator.clipboard.writeText(
            errorMessage || "No error description provided"
          )
        }
      >
        Kopier fejl til udklipsholder
      </button>
    </>
  );
}
