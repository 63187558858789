import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { SidePaneSectionHeader } from "../../ui-components/SidePaneSectionHeader/SidePaneSectionHeader";

const TimelineAccordionPanel: React.FC = ({ children }) => {
  return (
    <Translate>
      {({ translate }) => (
        <>
          <SidePaneSectionHeader
            text={translate(
              translationKeys.document_page.preparatory_work
            ).toString()}
          />
          {children}
        </>
      )}
    </Translate>
  );
};

export default TimelineAccordionPanel;
