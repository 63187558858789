/* eslint-disable complexity */
import { RelationGroupDto } from "@rhinestone/portal-web-api";
import React, { useCallback } from "react";
import { QueryStatus } from "react-query";
import { useHistory } from "react-router-dom";
import { LoadingProgress } from "../../../../../ui-components/LoadingProgress";
import { RelationGroupTree } from "../RelationGroupTree";
import RelationsDialog from "./RelationsDialog";

interface RelationsDialogContainerProps {
  data: RelationGroupDto[] | undefined;
  status: QueryStatus;
  groupName?: string;
  openLinksInNewTab: boolean;
  searchUrl?: string | null | undefined;
}
const RelationsDialogContainer: React.FC<RelationsDialogContainerProps> = ({
  data,
  status,
  groupName,
  openLinksInNewTab,
  searchUrl
}) => {
  const history = useHistory();

  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  if (status === "loading") return <LoadingProgress />;
  const relationDataElement = data?.find(
    x => x.relationGroupName === groupName
  );
  return (
    <RelationsDialog
      onClose={onClose}
      relationGroupNameTranslationKey={
        relationDataElement?.relationGroupNameTranslationKey
      }
      relationGroupName={relationDataElement?.relationGroupName}
      uniqueRelatedDocumentsCount={
        relationDataElement?.uniqueRelatedDocumentsCount || 0
      }
      searchUrl={searchUrl}
      openLinksInNewTab={openLinksInNewTab}
    >
      {relationDataElement && (
        <RelationGroupTree
          relationGroup={relationDataElement}
          openLinksInNewTab={openLinksInNewTab}
        />
      )}
    </RelationsDialog>
  );
};

export default RelationsDialogContainer;
