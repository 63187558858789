import { useMediaQuery, useTheme } from "@material-ui/core";
import { Breakpoint } from "./taxon-tile-helper";

export function useGetBreakpoint(): Breakpoint {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  return xs ? "xs" : sm ? "sm" : md ? "md" : lg ? "lg" : "xl";
}
