import { List } from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  list: {
    maxHeight: "calc(100% - 8px)",
    display: "flex",
    flexWrap: "wrap"
  }
}));

interface FolderContentListProps {
  children: React.ReactNode;
}
const FolderContentList: React.FC<FolderContentListProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <List dense={true} className={classes.list}>
      {children}
    </List>
  );
};

export default FolderContentList;
