import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import PopOver from "../../../ui-components/PopOver/PopOver";
import { Annotation } from "../annotation-state";
import { AnnotationCommentEditorFields } from "./AnnotationCommentEditorFields";

interface AnnotationCommentEditorProps<T extends AnnotationModel> {
  annotations: Annotation<T>[];
  defaultActiveAnnotation: Annotation<T>;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onSave: (annotation: Annotation<T>, newComment: string) => void;
  onDelete?: (annotation: Annotation<T>) => void;
  onActiveAnnotationChange: (annotation: Annotation<T>) => void;
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: "0px 8px",
    width: "320px",
    "& div": {
      padding: "8px 0"
    }
  }
}));

// Unable (don't know how) to declare generic React.FC<Props<T>> so leaving it out here....
export const AnnotationCommentEditor = <T extends AnnotationModel>({
  annotations,
  defaultActiveAnnotation,
  anchorEl,
  open,
  onClose,
  onSave,
  onDelete,
  onActiveAnnotationChange
}: AnnotationCommentEditorProps<T>) => {
  const classes = useStyles();

  return (
    <PopOver
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      className={classes}
      placement={"right-start"}
    >
      <AnnotationCommentEditorFields
        annotations={annotations}
        defaultActiveAnnotation={defaultActiveAnnotation}
        onClose={onClose}
        onSave={onSave}
        onDelete={onDelete}
        onActiveAnnotationChange={onActiveAnnotationChange}
      />
    </PopOver>
  );
};
