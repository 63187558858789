import * as React from "react";
import { Translate } from "react-localize-redux";
import DateFormatter from ".";
import { translationKeys } from "../../../gen/translation-keys";

export type DatePossiblyUndecidedFormatterProps = {
  value?: Date | string;
};

export const DatePossiblyUndecidedFormatter = ({
  value
}: DatePossiblyUndecidedFormatterProps) => {
  // Dates after (9999, 11, 30, 0, 0, 0) is by convention used as "undecided/without a fixed date"
  const realDate = typeof value === "string" ? new Date(value) : value;
  const date =
    !realDate ||
    realDate?.getTime() > new Date(9999, 11, 30, 0, 0, 0).getTime() ? (
      <Translate id={translationKeys.document_page.date_undecided} />
    ) : (
      <DateFormatter value={value} />
    );

  return date;
};
