import { Card, CardContent, CardHeader } from "@material-ui/core";
import * as React from "react";

export interface TileCardProps {
  title?: string;
  heading?: string;
  children?: React.ReactNode;
}

/**
 * TileCard supports only the basics needed for a TextTile.
 * Extend this component to support optional props such as image link, actions etc. when more tiles are added
 */
export const TileCard = ({ title, heading, children }: TileCardProps) => (
  <Card>
    <CardHeader title={heading} subheader={title} />
    <CardContent>{children}</CardContent>
  </Card>
);
