import { Chip, Avatar, makeStyles } from "@material-ui/core";
import * as React from "react";

export interface NumberChipProps {
  label: React.ReactNode;
  value?: number;
  onClick?: () => void;
}

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: "5px",
    backgroundColor: theme.alternativeBackgroundColor,
    color: "black",
    borderRadius: "4px",
    "&:hover, &:focus": {
      backgroundColor: theme.alternativeBackgroundColor
    },
    "&:active": {
      backgroundColor: "white"
    }
  },
  avatar: {
    padding: "2px",
    minWidth: "24px",
    backgroundColor: "white"
  }
}));

export const NumberChip: React.FC<NumberChipProps> = ({
  label,
  value,
  onClick
}) => {
  const classes = useStyles();
  return (
    <Chip
      className={classes.chip}
      avatar={
        <Avatar
          className={classes.avatar}
          variant={"circular"}
          style={{ width: "inherit" }}
        >
          {value || 0}
        </Avatar>
      }
      label={label}
      onClick={onClick}
      clickable={onClick !== undefined}
    />
  );
};
