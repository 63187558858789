import * as React from "react";
import { ApiFetchIndicator } from "../../../ui-components/ApiFetchIndicator";
import { ParagraphZoomDataError } from "../errors/ParagraphZoomDataError";
import { useParagraphZoomContext } from "../ParagraphZoomContext";
import { ParagraphZoomState } from "./ParagraphZoomState";
import { SmallTextSkeleton } from "../../../ui-components/Skeleton/SmallSkeleton";

export const ParagraphZoomStateContainer: React.FC = () => {
  const { currentParagraphInTime, status, error } = useParagraphZoomContext();
  return (
    <ApiFetchIndicator
      status={status}
      errorComponent={<ParagraphZoomDataError error={error} />}
      loadingIndicator={<SmallTextSkeleton />}
    >
      <ParagraphZoomState
        paragraphState={currentParagraphInTime?.paragraphState}
        effectiveDate={currentParagraphInTime?.effectiveDate}
      />
    </ApiFetchIndicator>
  );
};
