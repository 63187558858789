import { useDocumentInfo } from "../document-context";
import { usePortalApi } from "../../api-access/use-portal-api";
import { findKey } from "lodash";
import { TocItemData } from "./TocTreeView";
import {
  SelectableTreeItemData,
  TreeItemData,
  mapToSelectableTreeItemData
} from "../../ui-components/TreeView";
import { TocEntry } from "@rhinestone/portal-web-api";

export function useTocData() {
  const { hiveId, fullName } = useDocumentInfo();
  return usePortalApi(
    client => client.getToc(hiveId, fullName),
    ["getToc", hiveId, fullName]
  );
}

export type NodeAncestorItem = {
  nodeId: string;
  ancestors: string[];
  hasChildren: boolean;
};

export interface NodeIdAncestorMap {
  [nodeId: string]: Omit<NodeAncestorItem, "nodeId">;
}

export function findNodesToExpandFromHash(
  map: NodeIdAncestorMap,
  tocTargetId?: string
) {
  if (!tocTargetId) {
    return;
  }
  // using lodash to find first toc item in object dictionary that matches hash
  // note that some tocs, for example pdf tocs, might have several toc entries that
  // target same page. The logic here is simple and just grabs the first item that matches the hash
  const targetNodeId = findKey(map, (propValue, propName) => {
    // the nodeid in the treeviewitem map are in format 'tocTargetId-tocLevel'
    // for example q1-1.1.2
    // so we split it and take first part and try to do exact match
    const [originalId] = propName.split(";");
    return originalId === tocTargetId;
  });
  return targetNodeId
    ? {
        nodeId: targetNodeId,
        // we should expand all ancestor nodes
        ...map[targetNodeId]
      }
    : undefined;
}

/**
 * builds a flat list of toc using nodeids as keys, and storing ancestor list of node ids
 */
export function buildNodeMap(treeViewData: TocItemData[]): NodeIdAncestorMap {
  return buildNodeMapForLevel(treeViewData, []);
}

function buildNodeMapForLevel(
  treeViewData: TocItemData[],
  ancestors: string[]
): NodeIdAncestorMap {
  return treeViewData.reduce(
    (previousItem, currentItem) => {
      return {
        // keep previous added values
        ...previousItem,
        // add current tree view item data to map
        [currentItem.nodeId]: {
          ancestors,
          hasChildren: Boolean(currentItem.children?.length)
        },
        // if any children for item recursively reduce and pass down ancestor array to next level plus current level
        ...(currentItem.children
          ? buildNodeMapForLevel(currentItem.children, [
              ...ancestors,
              String(currentItem.nodeId)
            ])
          : {})
      };
    },
    /* seed for reduce method */ {}
  );
}

export function calculateTargetElementId(
  hash?: string,
  currentElementInView?: Element
) {
  const [, tocTargetId] = hash?.split("#") || [];

  // if currentElementView is set set use that
  // else use the hash from url to use as target id
  return currentElementInView?.id || tocTargetId;
}

// scrollIntoViewIfNeeded is an unofficial, but wildly supported function.
// It lets us scroll only if the element is outside the viewport, a fallback
// for IE we fall back to leave the element always centered, which gives a
// more noisy experience.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function scrollElementIntoView(target: any) {
  if (!target) {
    return;
  }

  if (target.scrollIntoViewIfNeeded) {
    target.scrollIntoViewIfNeeded();
  } else {
    // fallback to "scrollIntoView": supported even in IE
    target.scrollIntoView({
      block: "center"
    });
  }
}

export function getExpandableAncestorsAndSelf(node?: NodeAncestorItem) {
  if (!node) {
    return [];
  }

  return [...node.ancestors, ...(node.hasChildren ? [node.nodeId] : [])];
}

export function mapToTreeItems(tocData: TocEntry[]): SelectableTreeItemData[] {
  const treeItemData = tocData.map(toTreeItemData);
  return mapToSelectableTreeItemData(treeItemData);
}

const toTreeItemData = (item: TocEntry): TreeItemData => ({
  value: item.target,
  label: item.heading,
  children: item.children.map(toTreeItemData)
});

export function flattenTocData(entries: TocEntry[]): TocEntry[] {
  return entries.reduce<TocEntry[]>(
    (p, c) => [...p, c, ...flattenTocData(c.children)],
    []
  );
}
