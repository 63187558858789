import { useDocumentInfo } from "../document-context";
import { RelationTreeData } from "./RelationTreeData";
import { parseRelationPath } from "./parse-relation-path";
import { useConsolidationAndPageConfig } from "./preparatory-work-helpers";
import { useGetCanFetchRelationTree } from "./use-get-can-fetch-relation-tree";
import { useGetRelationTree } from "./use-get-relation-tree";

export const useGetRelationPaths = (
  hiveId: string,
  fullName: string,
  getConsolidationAndPageConfig = useConsolidationAndPageConfig,
  getCanFetchRelationTree = useGetCanFetchRelationTree,
  getRelationTree = useGetRelationTree,
  getDocumentInfo = useDocumentInfo
): { relationTreeData: RelationTreeData; isLoading: boolean } => {
  const {
    pageConfiguration,
    pageConfigurationStatus,
    preparationRelationTypeCode: followsRelationTypeCode
  } = getConsolidationAndPageConfig();

  const portalApiEnabled = getCanFetchRelationTree(
    hiveId,
    fullName,
    followsRelationTypeCode,
    pageConfiguration,
    pageConfigurationStatus
  );

  const [paths, isFetching] = getRelationTree(
    hiveId,
    fullName,
    pageConfiguration,
    portalApiEnabled,
    followsRelationTypeCode
  );
  const { hiveId: currentDocHiveId, fullName: currentDocFullName } =
    getDocumentInfo();
  const sortedPaths = paths.sort((path, _) =>
    // if any element in path has hiveId === hiveId and fullName === fullName - either the current document or
    // the one defined in input props - then it is the path we want first
    path.some(
      ({ hiveId: pathHiveId, fullName: pathFullName }) =>
        (pathHiveId === currentDocHiveId &&
          pathFullName === currentDocFullName) ||
        (pathHiveId === hiveId && pathFullName === fullName)
    )
      ? -1
      : 1
  );

  const relationTreeData: RelationTreeData = parseRelationPath(
    sortedPaths,
    currentDocHiveId,
    currentDocFullName
  );

  return {
    relationTreeData,
    isLoading: isFetching || pageConfigurationStatus === "loading"
  };
};
