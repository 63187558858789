// A context that stores the current hiveId and fullName of the paragraph in time

import { ParagraphInTime } from "@rhinestone/portal-web-api";
import React, { createContext, useState } from "react";
import { QueryStatus } from "react-query";
import { PortalApiError } from "../../api-access/use-portal-api";

type ParagraphZoomContext = {
  status: QueryStatus;
  error: PortalApiError | null;
  currentParagraphInTime: ParagraphInTime | undefined;
  setError: (error: PortalApiError | null) => void;
  setStatus: (status: QueryStatus) => void;
  setCurrentParagraphInTime: (
    paragraphInTime: ParagraphInTime | undefined
  ) => void;
};

export const ParagraphZoomContext = createContext<ParagraphZoomContext | null>(
  null
);

export const useParagraphZoomContext = () => {
  const context = React.useContext(ParagraphZoomContext);
  if (!context) {
    throw new Error(
      "useParagraphZoomContext must be used within a ParagraphZoomContextProvider"
    );
  }
  return context;
};

export const ParagraphZoomContextProvider: React.FC = ({ children }) => {
  const [currentParagraphInTime, setCurrentParagraphInTime] = useState<
    ParagraphInTime | undefined
  >(undefined);

  const [status, setStatus] = useState<QueryStatus>("idle");
  const [error, setError] = useState<PortalApiError | null>(null);

  return (
    <ParagraphZoomContext.Provider
      value={{
        status,
        error,
        currentParagraphInTime,
        setStatus,
        setError,
        setCurrentParagraphInTime
      }}
    >
      {children}
    </ParagraphZoomContext.Provider>
  );
};
