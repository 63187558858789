import { ParagraphInTime } from "@rhinestone/portal-web-api";
import React from "react";
import { Scrollbar } from "../../../ui-components/Scrollbar";
import { Timeline } from "../../../ui-components/split-timeline";
import ParagraphTimelineItem from "./ParagraphTimelineItem";

interface ParagraphTimelineProps {
  items: ParagraphInTime[];
  currentDocumentId?: string;
  elementOnClick: (item: ParagraphInTime) => void;
  createToolTip: (
    paragraph: ParagraphInTime
  ) => React.ComponentType | undefined;
}

export const ParagraphTimeline: React.FC<ParagraphTimelineProps> = ({
  items,
  currentDocumentId,
  elementOnClick,
  createToolTip
}) => (
  <Scrollbar>
    <Timeline>
      {items.map((item, index) => (
        <ParagraphTimelineItem
          key={index}
          paragraph={item}
          active={item.portalDocumentContext.documentId === currentDocumentId}
          isLastItem={index === items.length - 1}
          selectParagraphInTime={elementOnClick}
          createToolTip={createToolTip}
        />
      ))}
    </Timeline>
  </Scrollbar>
);
