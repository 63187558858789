import { makeStyles } from "@material-ui/core";
import React from "react";

type Props = {
  children?: React.ReactNode;
  id?: string;
};

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: "24px",
    fontWeight: 500,
    padding: "4px 0",
    margin: 0,
    marginBottom: "4px",
    borderBottom: "1px solid rgb(0 0 0 / 8%)",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));
export const PaneTitle = ({ children, id }: Props) => {
  const classes = useStyles();

  return (
    <h1 className={classes.heading} id={id}>
      {children}
    </h1>
  );
};
