import { Dispatch, useContext } from "react";
import {
  GenericAutoRestResponseType,
  RestError
} from "@rhinestone/portal-web-api";
import {
  ApiErrorActions,
  PortalApiContext,
  PortalApiContextType
} from "./PortalApiProvider";
import {
  PortalApiAccess,
  buildDefaultPortalApiErrorMessage,
  getCorrelationIdentifier
} from "./portal-api-access";
import {
  QueryKey,
  QueryObserverResult,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from "react-query";
import { KnownHttpStatusCodes } from "./http-status-codes";

/**
 * This type is an attempt to expand on react-query QueryKey
 * forcing usage of an PortalApiAccess based query key
 *
 * Since QueryKey can be almost anything (even an array of QueryKeys)
 *
 * Using Tuple array, to force parameter to point to one of the methods of PortalApiAccess class
 * And then just enough of optional QueryKey elements that we realistically need (add more if needed)
 */
export type PortalApiQueryKey = [
  keyof PortalApiAccess,
  QueryKey?,
  QueryKey?,
  QueryKey?,
  QueryKey?,
  QueryKey?,
  QueryKey?,
  QueryKey?,
  QueryKey?,
  QueryKey?,
  QueryKey?
];

/**
 * Error object to be used to throw error when react-query should consider query to be in error state
 *
 * Used both when autorest client throws error internally, but also if expectedErrorCodes are configured
 */
export class PortalApiError extends Error {
  constructor(
    public message: string,
    public statusCode?: number,
    public innerError?: Error
  ) {
    super();
  }
}

/**
 * Type combined by a selected set of react-query options, plus our options that can be provided
 *
 * Expand with extra react-query options when needed
 */
export type QueryOptions<T> = Pick<
  UseQueryOptions<T, PortalApiError>,
  "enabled" | "keepPreviousData" | "staleTime" | "initialData"
> & {
  expectedErrorStatusCodes?: KnownHttpStatusCodes;
};

/**
 * Common way of using react-query to call portal api
 *
 * will dispatch information about error to root api error handler if api call fails
 * @param action PortalApiAccess based action
 * @param queryKey Query key for this specific request, needs to include all information that makes this request unique. Is used for caching as well
 * @param options A limited set of options based on react-query options plus our custom options. Expand this if we find more needs (Check: https://react-query.tanstack.com/docs/api#usequery)
 * @param expectedStatusErrorCodes Http status codes which is expected by Api but should be treated as errors
 */
export function usePortalApi<T>(
  action: (client: PortalApiAccess) => Promise<GenericAutoRestResponseType<T>>,
  queryKey: PortalApiQueryKey,
  { expectedErrorStatusCodes, ...reactQueryOptions }: QueryOptions<T> = {}
): QueryObserverResult<T, PortalApiError> {
  const portalApiContext = useContext(PortalApiContext);

  return useQuery(
    queryKey,
    async () => {
      const result = await getQueryResponse(
        portalApiContext,
        action,
        expectedErrorStatusCodes
      );

      return extractDataFromResponse<T>(result);
    },
    {
      ...reactQueryOptions
    }
  );
}

async function getQueryResponse<T>(
  [portalApi, dispatchApiError]: PortalApiContextType,
  action: (client: PortalApiAccess) => Promise<GenericAutoRestResponseType<T>>,
  expectedErrorStatusCodes: KnownHttpStatusCodes | undefined
) {
  try {
    return await action(portalApi);
  } catch (error) {
    const restError = error as RestError;

    const isExpectedStatusCode = checkIfExpectedStatusCode(
      expectedErrorStatusCodes,
      restError.statusCode
    );
    if (!isExpectedStatusCode) {
      dispatchGlobalApiError(dispatchApiError, restError);
    }

    // We catch lower level error here and wrap in error object that is more friendly to our react query wrapper implementation
    throw new PortalApiError(
      isExpectedStatusCode
        ? `Received expected status code (${restError.statusCode})`
        : restError.message,
      restError.statusCode,
      restError
    );
  }
}

type MutationOptions<T, TVariables> = {
  onSuccess?: (data: T, variables: TVariables) => void;
  onError?: (error: PortalApiError, variables: TVariables) => void;
  expectedStatusErrorCodes?: KnownHttpStatusCodes;
};

/**
 * Used to mutate server-side state, with options to easily handle cache invalidation
 * For more advanced use-cases we may have to extend this function or use the underlying lower-level library "react-query" directly in code.
 * 
 * NOTE: client api function has to be a function that takes a single argument, so if multiple values are to be sent to api, combine them in a single object as properties!
 * 
 * @param mutationFn A function that gets access to the portalApi client and updates. Expected to return a mutate-function that takes a single variable as argument.
 * @param invalidateFn (optional) An array or a function that returns an array of **PortalApiQueryKey**
 * 
 * @return A tuple with the first being a [mutate]-function to call the mutateFn
 * 
 *  @example
 * ```ts
 * // Consider wrapping this hook in a function to just use it as
 * // const [mutate] = useDeleteSubscriptionForDocument()
  const [mutate] = usePortalApiMutation(
    client => (variables: MyType) => client.deleteSubscriptionsForDocument(variables),
    variables => [["getSubscription", variables.hiveId, variables.fullName]]
  );
 * ```
 */
export function usePortalApiMutation<T, TVariables>(
  mutationFn: (
    client: PortalApiAccess
  ) => (variables: TVariables) => Promise<GenericAutoRestResponseType<T>>,
  invalidateFn?:
    | ((variables: TVariables) => PortalApiQueryKey[])
    | PortalApiQueryKey[],
  {
    onSuccess,
    onError,
    expectedStatusErrorCodes
  }: MutationOptions<T, TVariables> = {}
) {
  const [portalApi, dispatchApiError] = useContext(PortalApiContext);
  const queryClient = useQueryClient();

  // for some reason we need to use .bind trick here, otherwise parameters are not available when mutation function a executed by caller
  return useMutation(
    withStatusCodeCheck(
      mutationFn(portalApi).bind(portalApi),
      dispatchApiError,
      expectedStatusErrorCodes
    )
      .bind(expectedStatusErrorCodes)
      .bind(dispatchApiError),
    {
      // onSettled is called no matter if the promise resolves or rejects. We might change this to use onSuccess instead
      // if this causes an issue, but the idea is to avoid having to handle rollback etc. manually and instead just sync
      // server-state to make sure we're still Okay.
      onSettled: async (data, error, variables) => {
        // Since we allow both function and array of PortalApiQueryKey, we need to do a type-check
        const invalidateQueryKeys =
          invalidateFn instanceof Function
            ? invalidateFn(variables)
            : invalidateFn;

        if (invalidateQueryKeys) {
          await Promise.all(
            invalidateQueryKeys.map(key => queryClient.invalidateQueries(key))
          );
        }
      },
      onError,
      onSuccess
    }
  );
}

function withStatusCodeCheck<TVariables, T>(
  action: (variables: TVariables) => Promise<GenericAutoRestResponseType<T>>,
  dispatchApiError: Dispatch<ApiErrorActions>,
  expectedErrorStatusCodes: KnownHttpStatusCodes | undefined
): (variables: TVariables) => Promise<T> {
  return async (variables: TVariables) => {
    const result = await getMutationResponse(
      variables,
      action,
      dispatchApiError,
      expectedErrorStatusCodes
    );
    // Check that status code of result is not something we consider an "error"
    return extractDataFromResponse(result);
  };
}

async function getMutationResponse<T, TVariables>(
  variables: TVariables,
  action: (variables: TVariables) => Promise<GenericAutoRestResponseType<T>>,
  dispatchApiError: Dispatch<ApiErrorActions>,
  expectedErrorStatusCodes: KnownHttpStatusCodes | undefined
) {
  try {
    return await action(variables);
  } catch (error) {
    const restError = error as RestError;

    const isExpectedStatusCode = checkIfExpectedStatusCode(
      expectedErrorStatusCodes,
      restError.statusCode
    );
    if (!isExpectedStatusCode) {
      dispatchGlobalApiError(dispatchApiError, restError);
    }

    // We catch lower level error here and wrap in error object that is more friendly to our react query wrapper implementation
    throw new PortalApiError(
      isExpectedStatusCode
        ? `Received expected status code (${restError.statusCode})`
        : restError.message,
      restError.statusCode,
      restError
    );
  }
}

function extractDataFromResponse<T>(result: GenericAutoRestResponseType<T>): T {
  const content =
    result._response.parsedBody !== undefined
      ? result._response.parsedBody
      : result._response.bodyAsText;

  if (content === undefined && result._response.blobBody !== undefined) {
    return { blobBody: result._response.blobBody } as unknown as T;
  }
  return content;
}

export function dispatchGlobalApiError(
  dispatchApiError: Dispatch<ApiErrorActions>,
  error: RestError
) {
  dispatchApiError({
    action: "ERROR_OCCURRED",
    newErrors: [
      {
        statusCode: error.statusCode,
        url: error.request?.url,
        message: buildDefaultPortalApiErrorMessage(error),
        correlationIdentifier: getCorrelationIdentifier(error)
      }
    ]
  });
}

const checkIfExpectedStatusCode = (
  expectedStatusErrorCodes: KnownHttpStatusCodes | undefined,
  statusCode: number | undefined
) => statusCode && (expectedStatusErrorCodes as number[])?.includes(statusCode);
