import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  useCurrentDocumentPageParameters,
  useGetNewRelativeDocumentUrl
} from "../../../document-page/document-page-helpers";
import { ParagraphZoomContainer } from "../../../paragraph-zoom/ParagraphZoomContainer";
import { useParagraphZoomAllowed } from "../section-actions/ParagraphZoomSectionAction";

const ParagraphZoom: React.FC = () => {
  const paragraphZoomEnabled = useParagraphZoomAllowed();
  const { pzoom } = useCurrentDocumentPageParameters();
  const url = useGetNewRelativeDocumentUrl({ pzoom: undefined });
  const history = useHistory();

  const closeParagraphZoom = useCallback(() => {
    history.replace(url);
  }, [history, url]);

  return paragraphZoomEnabled && pzoom ? (
    <ParagraphZoomContainer onClose={closeParagraphZoom} />
  ) : null;
};

export default ParagraphZoom;
