import * as React from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps
} from "@material-ui/core";

type TreeViewItemWithCheckboxProps = {
  onChange: (checked: boolean) => void;
};

export function TreeViewItemWithCheckbox({
  onChange,
  checked,
  indeterminate,
  label
}: TreeViewItemWithCheckboxProps &
  Pick<FormControlLabelProps, "label"> &
  Pick<CheckboxProps, "checked" | "indeterminate">) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={e => onChange(e.target.checked)}
          indeterminate={indeterminate}
          size="small"
          tabIndex={-1}
        />
      }
      label={label}
      onClick={e => e.stopPropagation()}
    />
  );
}
