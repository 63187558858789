import * as React from "react";
import { AccordionDetails, AccordionSummary } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import MuiAccordion from "@material-ui/core/Accordion";
import { AccordionProps as MuiAccordionProps } from "@material-ui/core/Accordion";

type AccordionProps = {
  label: React.ReactNode;
  summaryClassName?: string;
  expandIcon?: React.ReactNode;
  children?: React.ReactNode;
  backgroundColor?: string;
  foldingTransitionTimeout?: number;
  unmountOnExit?: boolean;
  elevate?: boolean;
} & Pick<
  MuiAccordionProps,
  | "onChange"
  | "className"
  | "expanded"
  | "classes"
  | "square"
  | "defaultExpanded"
  | "disabled"
>;

const useStyles = makeStyles(theme => ({
  accordionDetailsDiv: {
    maxWidth: "100%"
  },
  // MUI accordions are wrapped in paper-like elevated element,
  // This removes box-shadows, and more
  noElevation: {
    boxShadow: "none",
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    "&:last-child": {
      borderBottom: "none"
    },
    "&:before": {
      backgroundColor: "transparent"
    }
  }
}));

export const Accordion = React.forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      label,
      summaryClassName,
      children,
      expandIcon,
      foldingTransitionTimeout,
      unmountOnExit,
      backgroundColor,
      elevate = true,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();
    return (
      <MuiAccordion
        ref={ref}
        TransitionProps={{
          unmountOnExit,
          timeout: foldingTransitionTimeout
        }}
        {...rest}
        className={!elevate ? classes.noElevation : ""}
        style={{ backgroundColor }}
      >
        <AccordionSummary className={summaryClassName} expandIcon={expandIcon}>
          {label}
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.accordionDetailsDiv}>{children}</div>
        </AccordionDetails>
      </MuiAccordion>
    );
  }
);
