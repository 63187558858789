import { HtmlNewAnnotationDTO } from "@rhinestone/portal-web-api";
import React from "react";
import { MenuItem } from "../../../ui-components/ContextMenu/ContextMenu";
import { LoadingProgress } from "../../../ui-components/LoadingProgress";
import {
  Annotation,
  AnnotationContext
} from "../../annotations/annotation-state";
import { HtmlAnnotationFactory } from "../html-annotation-factory";
import {
  handleRange,
  selectionStartsAtExcludedElement
} from "./html-highlight-helpers";

export interface AddHighlightMenuItemCommonProps {
  annotationFactory: HtmlAnnotationFactory;
  context: AnnotationContext | undefined;
  addAnnotation:
    | ((
        annotation: Annotation<HtmlNewAnnotationDTO>
      ) => Promise<unknown> | undefined)
    | undefined;
  documentRef: HTMLDivElement | null;
}

interface AddUserHighlightMenuItemProps
  extends AddHighlightMenuItemCommonProps {
  labelText: React.ReactNode;
}

export function addHighlightMenuItem(
  props: AddUserHighlightMenuItemProps
): MenuItem {
  return {
    onClick: () =>
      handleRange(range => {
        const htmlAnnotation =
          props.annotationFactory.annotationFromRange(range);
        const annotation = {
          annotation: htmlAnnotation,
          contextId: props.context?.contextId
        } as Annotation<HtmlNewAnnotationDTO>;
        props.addAnnotation && props.addAnnotation(annotation);
      }),
    label: (
      <span data-test-id="add-highlight">
        {props.labelText}
        {!props.documentRef && <LoadingProgress size={24} />}
      </span>
    ),
    disabled: !props.documentRef,
    hidden: () => !props.context || selectionStartsAtExcludedElement()
  };
}
