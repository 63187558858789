import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useState } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface ResponsiveTooltipProps {
  tooltip: React.ReactNode;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  iconSize: "small";
  leaveTouchDelay?: number;
  onOpen?: () => void;
  ariaLabel: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    height: 20,
    alignItems: "center",
    display: "flex"
  }
}));

export const ResponsiveTooltip: React.FC<ResponsiveTooltipProps> = ({
  tooltip,
  placement,
  iconSize,
  leaveTouchDelay,
  children,
  onOpen,
  ariaLabel
}) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Tooltip
      open={showTooltip}
      onOpen={() => {
        onOpen?.();
        setShowTooltip(true);
      }}
      onClose={() => setShowTooltip(false)}
      title={tooltip || ""}
      placement={placement || "right-start"}
      leaveDelay={1000}
      leaveTouchDelay={leaveTouchDelay || 5000}
    >
      <Box component="div" className={classes.container}>
        {children}
        <Box>
          <IconButton
            onClick={() => {
              setShowTooltip(!showTooltip);
              onOpen?.();
            }}
            size={iconSize}
            aria-label={ariaLabel}
          >
            <InfoOutlinedIcon fontSize={iconSize} />
          </IconButton>
        </Box>
      </Box>
    </Tooltip>
  );
};
