import { Collapse, Paper, makeStyles } from "@material-ui/core";
import Form from "@rjsf/material-ui";
import validator from "@rjsf/validator-ajv8";
import * as React from "react";
import { optionsSchema } from "./options-json-schema";
import { AiCompletionOptionsDto } from "@rhinestone/portal-web-api";
import MarkdownEditor from "./MarkdownEditor";

interface AiSearchSettingsProps {
  isVisible: boolean;
  settings?: AiCompletionOptionsDto;
  updateSettings: (settings: AiCompletionOptionsDto) => void;
}

const useStyles = makeStyles(theme => ({
  settings: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[100]
  }
}));

const AiSearchSettings: React.FunctionComponent<AiSearchSettingsProps> = ({
  isVisible,
  settings,
  updateSettings
}) => {
  const uiSchema = {
    promptProfile: {
      systemPrompt: {
        "ui:widget": MarkdownEditor
      }
    }
  };

  const classes = useStyles();
  return (
    <Collapse in={isVisible}>
      <Paper elevation={0} variant="outlined" className={classes.settings}>
        <Form
          schema={optionsSchema}
          formData={settings}
          onSubmit={e => updateSettings(e.formData)}
          validator={validator}
          uiSchema={uiSchema}
        />
      </Paper>
    </Collapse>
  );
};

export default AiSearchSettings;
