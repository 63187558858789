import * as React from "react";
import { Translate } from "react-localize-redux";
import { DocumentStatusIcon } from "../StatusIcons/DocumentStatusIcons";
import {
  DocumentStatus,
  documentStatusTranslationKeys
} from "../document-status";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { translationKeys } from "../../../gen/translation-keys";
import clsx from "clsx";

export interface StatusItemProps {
  status: DocumentStatus;
  hideIcon?: boolean;
}

const useStyles = makeStyles(theme => ({
  verticalAlign: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    paddingLeft: theme.spacing(1)
  },
  tooltipContainer: {
    display: "flex"
  },
  tooltipIcon: {
    height: "24px",
    width: "24px"
  }
}));

export const StatusItem: React.FC<StatusItemProps> = ({
  status,
  hideIcon = false
}) => {
  const classes = useStyles();
  return renderStatusItem(
    status,
    classes,
    <span className={classes.verticalAlign}>
      <Translate id={documentStatusTranslationKeys[status]} />
      {!hideIcon && (
        <DocumentStatusIcon
          status={status}
          active={true}
          className={classes.icon}
        />
      )}
    </span>
  );
};

const renderStatusItem = (
  status: DocumentStatus,
  classes: ReturnType<typeof useStyles>,
  statusItem: React.ReactElement
) => {
  switch (status) {
    case DocumentStatus.LatestLbk:
      return (
        <Translate>
          {({ translate }) => (
            <Tooltip
              title={translate(
                translationKeys.document_page.latest_lbk_tooltip
              ).toString()}
              placement="top-start"
            >
              <span className={clsx(classes.tooltipContainer)}>
                {statusItem}
                <InfoOutlinedIcon
                  className={clsx(classes.icon, classes.tooltipIcon)}
                />
              </span>
            </Tooltip>
          )}
        </Translate>
      );
    default:
      return statusItem;
  }
};
