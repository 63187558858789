import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { ListItemIcon } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../../gen/translation-keys";
import { RelationIcon } from "../../../../ui-components/icons";

export const RelationsSectionAction: React.FC = () => {
  return (
    <>
      <ListItemIcon>
        <RelationIcon fontSize="small" />
      </ListItemIcon>
      <Typography noWrap>
        <Translate id={translationKeys.document_page.relation} />
      </Typography>
    </>
  );
};
