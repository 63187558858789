import {
  HtmlToReactNode,
  HtmlToReactProcessingInstructions
} from "html-to-react";

import SectionWithLinkButton from "../SectionWithLinkButton";
import { buildSectionTitleString } from "./process-section-helpers";

const processParagraphSectionHeaderTabLink = (
  hiveId: string,
  fullName: string,
  linkText: JSX.Element
): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => {
      return !node.parent && node.name === "section";
    },
    processNode: (node, children, index) => {
      const sectionTitleAsString = buildSectionTitleString(node);
      return SectionWithLinkButton(
        node,
        sectionTitleAsString,
        index,
        children,
        hiveId,
        fullName,
        linkText
      );
    }
  };
};

export default processParagraphSectionHeaderTabLink;
