import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import { HtmlAnnotation } from "../document-components/HtmlAnnotation";
import { HtmlAnnotationState } from "../../../annotations/html/html-annotation-state";

const processAnnotations = (
  annotationState: HtmlAnnotationState | undefined
): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: true,
    shouldProcessNode: (node: HtmlToReactNode) => {
      return Boolean(node?.attribs?.["data-annotation-id"]);
    },
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      const annotationId = node.attribs["data-annotation-id"];
      return (
        <HtmlAnnotation
          key={annotationId}
          id={annotationId}
          annotationState={annotationState}
        >
          {children}
        </HtmlAnnotation>
      );
    }
  };
};

export default processAnnotations;
