import React from "react";
import TileGrid from "./tiles/TileGrid";
import TaxonPageTitle from "./TaxonPageTitle";
import TaxonPage from "../taxonomy-tile-page/TaxonPage";

interface TaxonsProps {
  children: React.ReactNode;
  pageTitle: string | React.ReactNode;
  breadcrumbElements: { title: React.ReactNode; link: string }[];
}
const Taxons: React.FC<TaxonsProps> = ({
  children,
  pageTitle,
  breadcrumbElements
}) => {
  return (
    <TaxonPage>
      <TaxonPageTitle
        title={pageTitle}
        breadcrumbElements={breadcrumbElements}
      />
      <TileGrid>{children}</TileGrid>
    </TaxonPage>
  );
};

export default Taxons;
