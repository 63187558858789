import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import { convertToReactAttribs } from "./process-attribute-helpers";

const processImages = (): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) =>
      node.name === "img" && node.attribs.height && node.attribs.width,
    processNode: (node: HtmlToReactNode) => {
      const attributes = convertToReactAttribs(node.attribs);
      // return the node with loading="lazy" attribute
      return <img {...attributes} loading="lazy" />;
    }
  };
};

export default processImages;
