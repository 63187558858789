import React from "react";
import SectionTextTabContents, {
  TabContentsProps
} from "./SectionTextTabContents";

const GeneralRemarksTabContents: React.FC<TabContentsProps> = props => {
  const getSectionIds = () => ["alm-bem"];

  return <SectionTextTabContents {...props} getSectionIds={getSectionIds} />;
};

export default GeneralRemarksTabContents;
