import React from "react";
import TextField from "@material-ui/core/TextField";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteProps as MuiAutocompleteProps } from "@material-ui/lab/Autocomplete";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { makeStyles } from "@material-ui/core";

/**
 * This component is a simplified wrapper of MUI's AutoComplete, which works
 * with flat list of string options. The MUI version as many  extension points
 * to use, if needed later.
 * https://material-ui.com/components/autocomplete/#autocomplete
 */
type AutoCompleteProps<T> = Pick<
  MuiAutocompleteProps<T, boolean, boolean, boolean>,
  "options" | "classes" | "getOptionLabel" | "filterOptions" | "id"
> & {
  label: React.ReactNode;
  onChange?: (newValue: T | null) => void;
};
const useStyles = makeStyles(theme => ({
  autocomplete: {
    "& label": {
      right: "50px",
      bottom: "0px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    '& label[data-shrink="true"]': {
      right: "unset"
    }
  }
}));
export function AutoComplete<T>({
  label,
  onChange,
  id,
  ...rest
}: AutoCompleteProps<T>) {
  const classes = useStyles();

  const handleChange = (_: unknown, newValue: T | null) => {
    onChange?.(newValue);
  };

  return (
    <MuiAutocomplete
      ListboxProps={{ "data-test-id": "autocomplete-listbox" }}
      className={classes.autocomplete}
      autoComplete
      id={id}
      autoHighlight // so to make clicking Enter goes to the first result immediately
      onChange={handleChange}
      noOptionsText={<Translate id={translationKeys.search.no_results} />}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          size="small"
          margin="normal"
          variant="outlined"
          data-test-id={"autocomplete-input"}
        />
      )}
      {...rest}
    />
  );
}
