import * as React from "react";
import { LegacyAngularJsScope } from "../shellConfigurationTypes";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

interface LegacyLocationServiceSupportProps {
  angularScope: LegacyAngularJsScope;
}

/**
 * This ensures angular scope gets reevaluated whenever location changes
 * If we don't do this, then angular location evaluation doesn't happen until some later event
 * for example clicking in user interface.
 */
export const LegacyLocationServiceSupport: React.FC<LegacyLocationServiceSupportProps> =
  ({ angularScope, children }) => {
    const history = useHistory();
    useEffect(() => {
      const unRegisterHistoryListener = history.listen(x => {
        // Ensure location monitoring get reevaluated when location changes
        // by executing apply on current angular scope
        angularScope.$apply();
      });
      return () => unRegisterHistoryListener();
    }, [history, angularScope]);
    return <>{children}</>;
  };
