import * as React from "react";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import { IconButton } from "../../../ui-components/IconButton";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import {
  EditorialNoteList,
  EditorialNote
} from "../../content/shared/EditorialNote";
import HtmlRenderer from "../../../ui-components/HtmlRenderer";
import { useEditorialNotes } from "../../content/shared/MergeDocument/merge-document-util";
import { processLinks } from "../../content/rhinestone-xml/processing-instructions/process-links";

export const EditorialNotesDocumentAction: React.FC = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const editorialNotes = useEditorialNotes();

  return (
    <Translate>
      {({ translate }) => (
        <>
          <IconButton
            onClick={e =>
              setAnchorEl(anchorEl ? null : (e.target as HTMLElement))
            }
            size="small"
            title={translate(translationKeys.document_page.notes).toString()}
            {...props}
          >
            <NoteOutlinedIcon />
          </IconButton>

          <EditorialNoteList
            title={<Translate id={translationKeys.document_page.notes} />}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            {editorialNotes
              .filter(x => x.locationType === "Article")
              .map(editorialNote => (
                <EditorialNote key={editorialNote.sectionId}>
                  <HtmlRenderer
                    html={editorialNote.text}
                    processingInstructions={[processLinks()]}
                  />
                </EditorialNote>
              ))}
          </EditorialNoteList>
        </>
      )}
    </Translate>
  );
};

export function useShouldShowEditorialNoteAction() {
  return useEditorialNotes().some(x => x.locationType === "Article");
}
