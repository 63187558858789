import * as React from "react";
import ShowMoreTextExt from "react-show-more-text";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

/*
This a simple wrapper around third part component react-show-more-text. 
Use it to clamp text to any number of lines. 
https://github.com/devzonetech/react-show-more-text

TODO: Consider using a more lightweight and better maintained component, based on CSS line-clamp property, if IE11 
support is not important (it shouldn't be in this case).
See e.g.: https://github.com/mikelpmc/react-multiline-clamping#readme
*/

export interface ShowMoreTextProps {
  lines?: number;
  more?: React.ReactNode;
  less?: React.ReactNode;
}

export const ShowMoreText: React.FC<ShowMoreTextProps> = ({
  more,
  less,
  lines = 3,
  children
}) => {
  const moreInt = more || (
    <Translate id={translationKeys.document_page.show_more} />
  );
  const lessInt = less || (
    <Translate id={translationKeys.document_page.show_less} />
  );
  return (
    <ShowMoreTextExt lines={lines} more={moreInt} less={lessInt}>
      {children}
    </ShowMoreTextExt>
  );
};
