/* eslint-disable complexity */
import { usePortalApi } from "../../api-access/use-portal-api";
import { HardCodedFieldSetName } from "../document-fieldsets";
import {
  ConsolidationConfiguration,
  documentIsLoaded,
  mapToRelationItemModel,
  pageConfigurationIsLoaded,
  relationTypesIsConfigured,
  useConsolidationAndPageConfig
} from "./preparatory-work-helpers";
import { RelationItemModel } from "./RelationItemModel";

export function useGetTargetRelations(props: {
  hiveId?: string;
  fullName?: string;
  relationTypeCode?: string;
  bookmark?: string | undefined;
  getRelationTypeCode?: (
    consolidationConfiguration: ConsolidationConfiguration | undefined
  ) => string;
}): [RelationItemModel[], boolean] {
  const pageConfig = useConsolidationAndPageConfig();
  const defaultRelationTypeCode = pageConfig.consolidationRelationTypeCode;
  const relationTypeCodeToUse =
    (props.getRelationTypeCode
      ? props.getRelationTypeCode(pageConfig)
      : props.relationTypeCode) || defaultRelationTypeCode;
  const {
    pageConfiguration,
    pageConfigurationStatus,
    consolidationRelationTypeCode,
    preparationRelationTypeCode
  } = pageConfig;

  const { data, isLoading } = usePortalApi(
    client =>
      client.getDocumentTargetRelationFieldsSets(
        props.hiveId || "",
        props.fullName || "",
        relationTypeCodeToUse,
        HardCodedFieldSetName,
        props.bookmark ?? ""
      ),
    [
      "getDocumentTargetRelationFieldsSets",
      props.hiveId,
      props.fullName,
      relationTypeCodeToUse,
      HardCodedFieldSetName,
      props.bookmark ?? ""
    ],
    {
      enabled:
        documentIsLoaded(props.hiveId, props.fullName) &&
        pageConfigurationIsLoaded(pageConfigurationStatus, pageConfiguration) &&
        relationTypesIsConfigured(
          consolidationRelationTypeCode,
          preparationRelationTypeCode
        )
    }
  );

  const mappedRelations = mapToRelationItemModel(data, pageConfiguration);
  return [mappedRelations, isLoading];
}
