import * as React from "react";
import { DocumentContext } from "../../document/document-context";
import { DocumentHighlightsProvider } from "../../document/highlights/DocumentHighlightsProvider";
import { DocumentDto } from "@rhinestone/portal-web-api";
import { DocumentLayout } from "./DocumentLayout";
import { AnnotationClientStateProvider } from "../annotations/AnnotationClientStateProvider";
import { DocumentActionsSettingsProvider } from "../DocumentActionMenu/document-actions/DocumentActionsSettingsProvider";
import ParagraphZoom from "../content/rhinestone-xml/document-components/ParagraphZoom";
import DocumentPageRelationsDialogsContainer from "../content/shared/DocumentPageRelationsDialogsContainer";
import { ParagraphZoomContextProvider } from "../paragraph-zoom/ParagraphZoomContext";
import {
  DocumentRelationChipsStateProvider,
  DocumentSectionRelationChipsStateProvider
} from "../content/shared/document-relation-groups/DocumentRelationChipsStateProvider";

interface DocumentPageProps {
  document: DocumentDto | undefined;
}

export const DocumentPage: React.FC<DocumentPageProps> = ({ document }) => {
  return document ? (
    <DocumentContext.Provider value={document}>
      <DocumentHighlightsProvider document={document}>
        <AnnotationClientStateProvider>
          <DocumentActionsSettingsProvider>
            <ParagraphZoomContextProvider>
              {/* A bit weird that relationsdialog is placed under ParagrapZoom context. 
              At some point investigate and write comment or refactor */}
              <DocumentPageRelationsDialogsContainer />
              <ParagraphZoom />
              <DocumentRelationChipsStateProvider>
                <DocumentSectionRelationChipsStateProvider>
                  <DocumentLayout />
                </DocumentSectionRelationChipsStateProvider>
              </DocumentRelationChipsStateProvider>
            </ParagraphZoomContextProvider>
          </DocumentActionsSettingsProvider>
        </AnnotationClientStateProvider>
      </DocumentHighlightsProvider>
    </DocumentContext.Provider>
  ) : null;
};
