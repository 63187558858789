import React from "react";
import { ShowProfileContainer } from "./ShowProfile/ShowProfileContainer";
import Page from "../../ui-components/layouts/Page";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

const MyProfilePage: React.FC = () => {
  return (
    <Page
      title={<Translate id={translationKeys.account.profile_page_header} />}
    >
      <ShowProfileContainer />
    </Page>
  );
};

export default MyProfilePage;
