/* 
This is just a simple wrapper around native URL API, to support relative URL's (which the native one doesn't)
    https://developer.mozilla.org/en-US/docs/Web/API/URL
    https://github.com/whatwg/url/issues/531

If requirements become more advanced consider using a package for this purpose
*/

export class RelativeUrl {
  private _url: URL;
  private _dummyBaseUrl = "http://dummy.to";

  constructor(path?: string) {
    this._url = new URL(path ?? "", this._dummyBaseUrl);
  }

  get path(): string {
    return this._url.pathname;
  }

  set path(newPath: string) {
    this._url.pathname = newPath;
  }

  get searchParams(): URLSearchParams {
    return this._url.searchParams;
  }

  get hash(): string {
    return this._url.hash;
  }

  set hash(newHash: string) {
    this._url.hash = newHash.replace("#", "");
  }

  toString() {
    return this._url.toString().replace(this._url.origin, "");
  }
}
