import { da, sv, nn, nb } from "date-fns/esm/locale";

export function getLocale(languageCode: string) {
  switch (languageCode) {
    case "sv":
      return sv;
    case "nn":
      return nn;
    case "nb":
      return nb;
    case "da":
      return da;
    default:
      console.warn(
        `Unknown language code ${languageCode}. Defaulting to locale 'da'`
      );
      return da;
  }
}

export function getDateFormat(languageCode: string) {
  switch (languageCode) {
    case "sv":
      return "yyyy-MM-dd";
    case "nn":
    case "nb":
      return "dd.MM.yyyy";
    case "da":
      return "dd/MM/yyyy";
    default:
      console.warn(
        `Unknown language code ${languageCode}. Defaulting to date format for 'da'`
      );
      return "dd/MM/yyyy";
  }
}
