import React, { createRef } from "react";
import MetadataRender from "../MetadataRender/MetadataRender";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../../gen/translation-keys";
import { useFieldSet } from "../../../use-fieldset";
import { LoadingProgress } from "../../../../ui-components/LoadingProgress";
import { useDocumentInfo } from "../../../document-context";
import {
  SEARCH_MARK_OPTIONS,
  useMarkJs
} from "../../../../../browser-utils/useMarkjs";

/**
 * This component has the fieldset property "abstract" hardcoded. As per August 2020, this is only used for Schultz Legal Research portals.
 * In the future we need to make this component configurable, so that it can be used by other customers and on other portals. Other portals
 * might have their own metadata-schema and therefore their own naming of fieldset, e.g "abstract" might not be named "abstract" in every portal,
 * thus making a need for making this configurable.
 */
export interface AbstractProps {
  highlights?: string[];
}

export const Abstract: React.FC<AbstractProps> = ({ highlights }) => {
  const abstractRef = createRef<HTMLDivElement>();
  const { hiveId, fullName } = useDocumentInfo();
  const documentFieldset = useFieldSet(hiveId, fullName);
  //highlight search results
  useMarkJs(abstractRef.current, highlights, SEARCH_MARK_OPTIONS);
  // Show spinner while fieldset is loading
  if (!documentFieldset) return <LoadingProgress />;
  if (!documentFieldset.abstract) return null;

  return (
    <div ref={abstractRef}>
      <MetadataRender
        label={<Translate id={translationKeys.document_metadata.abstract} />}
        text={documentFieldset.abstract as string}
        noBackground={true}
      />
    </div>
  );
};
