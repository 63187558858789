import * as React from "react";
import { ChangeEventHandler } from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";

import {
  CreateLinkFormNumberKeys,
  TypedCreateLinkNumberField
} from "./NumberField";

interface PeriodFormFieldProps {
  fieldName: CreateLinkFormNumberKeys;
  label?: React.ReactNode;
  max: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}
const PeriodFormField: React.FC<PeriodFormFieldProps> = ({
  fieldName,
  label,
  max,
  onChange
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <TypedCreateLinkNumberField
          fieldName={fieldName}
          label={label}
          onChange={onChange}
          fieldOptions={{
            required: translate(
              translationKeys.document_page.create_link_period_required
            ).toString(),
            min: {
              value: 0,
              message: translate(
                translationKeys.document_page.create_link_period_min
              ).toString()
            },
            max: {
              value: max,
              message: translate(
                translationKeys.document_page.create_link_period_max,
                { max }
              ).toString()
            }
          }}
        />
      )}
    </Translate>
  );
};

export default PeriodFormField;
