import React, { useContext } from "react";
import { DocumentDto } from "@rhinestone/portal-web-api";

export const DocumentContext = (() => {
  const newContext = React.createContext({} as DocumentDto);
  newContext.displayName = "DocumentInfoContext";
  return newContext;
})();

export function useDocumentInfo(): DocumentDto {
  return useContext(DocumentContext);
}
