import React from "react";
import { Timeline } from "../../../ui-components/split-timeline";
import DanishLawHistoryTimelineItem from "./DanishLawHistoryTimelineItem";
import { LegalTimelineNodeDto } from "@rhinestone/portal-web-api";

export type DanishLawTimelineNode = LegalTimelineNodeDto & {
  isLast?: boolean;
  isFirst?: boolean;
  isActive?: boolean;
  isLatestEffective?: boolean;
  isLatestLbk?: boolean;
  consolidationDocuments: string[] | undefined;
};

export const DanishLawHistoryTimeline: React.FC<{
  nodes: DanishLawTimelineNode[];
}> = ({ nodes }) => {
  return (
    <Timeline>
      {nodes.map(node => (
        <DanishLawHistoryTimelineItem key={node.documentId} node={node} />
      ))}
    </Timeline>
  );
};
