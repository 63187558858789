import * as React from "react";
import { makeStyles } from "@material-ui/core";
import ExcludeFromAnnotation from "../../annotations/ExcludeFromAnnotation";

const useStyle = makeStyles(theme => ({
  localNote: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "5px",
    // added margin to separate multiple notes from each other
    marginBottom: "2px"
  }
}));

export const LocalNote: React.FC = ({ children }) => {
  return (
    <ExcludeFromAnnotation>
      {/* preserving data-mergetype attribute to make it easier to inspect dom and see what section is local note in debugging scenarios */}
      <section className={`${useStyle().localNote}`} data-mergetype="localnote">
        {children}
      </section>
    </ExcludeFromAnnotation>
  );
};
