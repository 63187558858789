import {
  SnackbarProvider as NsSnackbarProvider,
  useSnackbar as useNsSnackbar
} from "notistack";
import React, { useMemo, useRef } from "react";
import { IconButton } from "../IconButton";
import CloseIcon from "@material-ui/icons/Close";
import UndoIcon from "@material-ui/icons/Undo";
import { makeStyles } from "@material-ui/core";
import { Translate } from "react-localize-redux";

export const SnackbarProvider: React.FC = ({ children }) => {
  const notistackRef = useRef<NsSnackbarProvider>(null);
  const onClickDismiss = (key: React.ReactText) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };
  return (
    <NsSnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      action={key => (
        <IconButton
          size="small"
          data-test-id="snackbar-close"
          aria-label="close"
          color="inherit"
          onClick={onClickDismiss(key)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    >
      {children}
    </NsSnackbarProvider>
  );
};

const useStyles = makeStyles({
  container: {
    "& [href]": {
      textDecoration: "underline",
      fontWeight: "bold"
    }
  }
});

/**
 * Provides customized functions to enqueue message for snackbar
 */
export function useSnackbar() {
  const { enqueueSnackbar } = useNsSnackbar();
  const classes = useStyles();
  return useMemo(
    () => ({
      enqueueSuccess: (message: React.ReactNode, undo?: () => void) =>
        enqueueSnackbar(
          <span className={classes.container}>
            {message} {undo && renderUndoButton(undo)}
          </span>,
          { variant: "success" }
        ),
      enqueueFailure: (message: React.ReactNode) =>
        enqueueSnackbar(<span className={classes.container}>{message}</span>, {
          variant: "error"
        }),
      enqueueWarning: (message: React.ReactNode) =>
        enqueueSnackbar(<span className={classes.container}>{message}</span>, {
          variant: "warning"
        }),
      enqueueInfo: (message: React.ReactNode, action?: React.ReactNode) =>
        enqueueSnackbar(<span className={classes.container}>{message}</span>, {
          variant: "info",
          action: action
        }),
      enqueueSuccessTranslation: (translationKey: string, undo?: () => void) =>
        enqueueSnackbar(
          <span className={classes.container}>
            <Translate id={translationKey} /> {undo && renderUndoButton(undo)}
          </span>,
          { variant: "success" }
        ),
      enqueueFailureTranslation: (translationKey: string) =>
        enqueueSnackbar(
          <span className={classes.container}>
            <Translate id={translationKey} />
          </span>,
          { variant: "error" }
        )
    }),
    [classes.container, enqueueSnackbar]
  );
}

function renderUndoButton(undo: () => void) {
  return (
    <IconButton onClick={undo}>
      <UndoIcon />
    </IconButton>
  );
}
