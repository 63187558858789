import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../../../gen/translation-keys";
import { ModalDialog } from "../../../../../ui-components/ModalDialog";
import { useCurrentDocumentPageParameters } from "../../../../document-page/document-page-helpers";
import ChipLabel from "../RelationChips/ChipLabel";
import RelationDialogTitleContainer from "./RelationDialogTitle";

export interface RelationsDialogProps {
  children?: React.ReactNode;
  onClose: () => void;
  relationGroupNameTranslationKey?: string;
  relationGroupName?: string;
  searchUrl?: string | null | undefined;
  uniqueRelatedDocumentsCount: number;
  openLinksInNewTab: boolean;
}

const RelationsDialog: React.FC<RelationsDialogProps> = ({
  children,
  onClose,
  relationGroupNameTranslationKey,
  relationGroupName,
  searchUrl,
  uniqueRelatedDocumentsCount,
  openLinksInNewTab
}) => {
  const { relgroup, relsection } = useCurrentDocumentPageParameters();
  return (
    <ModalDialog
      dataTestId="relations-dialog"
      open={true}
      onClose={onClose}
      preventEventOnMouseNavigation
      title={
        <RelationDialogTitleContainer
          label={
            <Translate>
              {({ translate }) => {
                return (
                  <ChipLabel
                    relationGroupName={
                      (relationGroupNameTranslationKey &&
                        String(translate(relationGroupNameTranslationKey))) ||
                      relationGroupName ||
                      String(
                        translate(
                          translationKeys.relation_groups
                            .relation_group_not_found,
                          {
                            relationGroup: relgroup,
                            section: relsection || "*"
                          }
                        )
                      )
                    }
                  />
                );
              }}
            </Translate>
          }
          searchUrl={searchUrl}
          relationCount={uniqueRelatedDocumentsCount}
          openLinksInNewTab={openLinksInNewTab}
        />
      }
    >
      {children}
    </ModalDialog>
  );
};

export default RelationsDialog;
