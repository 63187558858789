import * as React from "react";
import { StatusItem } from "./StatusItem";
import { DocumentStatus, getStatusFromFieldsetValue } from "../document-status";
import {
  FieldSet,
  FieldSetTaxonValue,
  FieldSetValue,
  useFieldSet
} from "../use-fieldset";
import { useDocumentInfo } from "../document-context";
import { ShowMoreText } from "../../ui-components/ShowMoreText";
import { useDocumentPageConfig } from "../use-document-page-config";
import { DatePossiblyUndecidedFormatter } from "../../localization/DateFormatter/DateFormatterPossiblyUndecided";
import { Classification } from "./Classification";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import {
  AboutDocumentModel,
  DocumentFieldType
} from "@rhinestone/portal-web-api";
import { Link } from "../../ui-components/Link";

export function getDisplayValue(
  fieldType: DocumentFieldType,
  fieldValue: FieldSetValue,
  fieldName: string,
  linkTo?: string
) {
  // fieldValue is undefined if the key could not be found in the fieldSet,
  // this is not allowed and is a problem with configuration
  if (fieldValue === undefined) {
    return (
      <span style={{ color: "red" }}>
        Configuration error: FieldSet "DefaultPortalFields" does not contain
        field "{fieldName}"
      </span>
    );
  }

  // Field is in fieldset, but is empty string, null or empty array. This is allowed, so we just hide the empty information.
  return shouldHideField(fieldValue)
    ? null
    : getType(fieldType, fieldValue, linkTo);
}

const shouldHideField = (fieldValue: FieldSetValue) =>
  fieldValue === null ||
  fieldValue === "" ||
  (fieldValue as FieldSetTaxonValue[]).length === 0;

// eslint-disable-next-line complexity
function getType(
  fieldType: DocumentFieldType,
  fieldValue: FieldSetValue,
  linkTo?: string
) {
  switch (fieldType) {
    case "Date":
      return dateItem(fieldValue);
    case "Status":
      return statusItem(fieldValue);
    case "FamilyStatus":
      return statusItem(fieldValue, true);
    case "Classification":
      return classificationItem(fieldValue);
    case "Boolean":
      return booleanItem(fieldValue);
    case "Link":
      return linkTo ? linkItem(fieldValue, linkTo) : textItem(fieldValue);
    default:
      return textItem(fieldValue);
  }
}

function dateItem(fieldValue: FieldSetValue) {
  return <DatePossiblyUndecidedFormatter value={fieldValue as string} />;
}

function classificationItem(value: FieldSetValue) {
  if (!isTaxon(value)) return null;
  return <Classification taxons={value} />;
}

function booleanItem(value: FieldSetValue) {
  return (
    <div>
      <Translate
        id={
          value ? translationKeys.boolean.true : translationKeys.boolean.false
        }
      ></Translate>
    </div>
  );
}

function isTaxon(value: FieldSetValue): value is FieldSetTaxonValue[] {
  return (value as FieldSetTaxonValue[]) !== undefined;
}

function statusItem(fieldValue: FieldSetValue, hideIcon?: boolean) {
  const status = getStatusFromFieldsetValue(fieldValue);
  // For unknown/none status do not display label or value.
  if (status === DocumentStatus.Unknown || status === DocumentStatus.None)
    return null;
  return <StatusItem status={status} hideIcon={hideIcon} />;
}

function textItem(fieldValue: FieldSetValue) {
  return <ShowMoreText lines={3}>{fieldValue}</ShowMoreText>;
}

function linkItem(fieldValue: FieldSetValue, linkTo: string) {
  return <Link to={linkTo}>{fieldValue}</Link>;
}

export function useGetAboutDocumentFields() {
  const { hiveId, fullName } = useDocumentInfo();
  const fieldset = useFieldSet(hiveId, fullName) || {};
  const { data } = useDocumentPageConfig();

  return getFirstMatchingFieldset(data?.aboutDocumentConfiguration, fieldset);
}

// Return first matching configuration's fields.
// Notice a configuration without a condition always match and thus making
// it act as the default/fallback configurations for documents
export function getFirstMatchingFieldset(
  configurations: AboutDocumentModel[] | undefined,
  fieldset: FieldSet
) {
  return configurations?.find(
    x =>
      !x.conditionFieldName ||
      fieldset[x.conditionFieldName] === x.conditionFieldValue
  )?.fields;
}
