import * as React from "react";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import { IconButton } from "../../ui-components/IconButton";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { usePortalApiMutation } from "../../api-access/use-portal-api";
import { useSnackbar } from "../../ui-components/Snackbar";

interface RemoveSubscriptionProps {
  hiveId: string;
  fullName: string;
}

export const RemoveSubscription: React.FC<RemoveSubscriptionProps> = ({
  hiveId,
  fullName
}) => {
  const { enqueueSuccess } = useSnackbar();

  const { mutate: deleteSubscription, status } =
    useDeleteSubscriptionsForDocument(() =>
      enqueueSuccess(
        <Translate
          id={
            translationKeys.document_page.document_version_subscription_removed
          }
        />
      )
    );

  const handleClick = () =>
    deleteSubscription({
      fullName,
      hiveId
    });

  return (
    <Translate>
      {({ translate }) => (
        <IconButton
          // Make sure to disable button while we are updating server-side data
          disabled={status !== "idle"}
          size="small"
          title={translate(
            translationKeys.document_page.subscription_remove
          ).toString()}
          onClick={handleClick}
        >
          <NotificationsActive color={"primary"} />
        </IconButton>
      )}
    </Translate>
  );
};

function useDeleteSubscriptionsForDocument(onSuccess: () => void) {
  return usePortalApiMutation(
    client => (variables: { hiveId: string; fullName: string }) =>
      client.deleteSubscriptionsForDocument(
        variables.hiveId,
        variables.fullName
      ),
    ({ hiveId, fullName }) => [
      ["getSubscriptionsForDocument", hiveId, fullName]
    ],
    { onSuccess }
  );
}
