import * as React from "react";
import { useGetInstanceRelationsData } from "./instance-relations-helpers";
import {
  DisplayType,
  DocumentFieldSet,
  FieldNames
} from "../document-fieldsets";
import {
  LinkTabs,
  Link,
  LinkTabsSkeleton,
  LinkTabsProps
} from "../../ui-components/LinkTabs";
import { useFieldSet } from "../use-fieldset";
import { useDocumentInfo } from "../document-context";
import { getDocumentUrl } from "../document-page/document-page-helpers";

export const InstanceRelationsContainer: React.FC<
  Pick<LinkTabsProps, "variant">
> = ({ variant }) => {
  const { hiveId, fullName } = useDocumentInfo();
  const documentFieldset = useFieldSet(hiveId, fullName);
  const { reverseRelations, forwardRelations, isFetching } =
    useGetInstanceRelationsData();

  const linkList = BuildLinkList(
    forwardRelations,
    documentFieldset,
    reverseRelations
  );

  const shouldDisplayInstanceRelations =
    documentFieldset &&
    documentFieldset[FieldNames.DisplayType] === DisplayType.Dom;

  if (!shouldDisplayInstanceRelations) {
    return <></>;
  }

  if (isFetching) {
    return (
      <LinkTabsSkeleton numberOfTabs={3} activeTab={0} variant={variant} />
    );
  }

  return <LinkTabs links={linkList} variant={variant} />;
};

function BuildLinkList(
  forwardRelations: DocumentFieldSet[],
  currentDocumentFieldSet: DocumentFieldSet | undefined,
  reverseRelations: DocumentFieldSet[]
) {
  return [
    ...forwardRelations.map(mapFieldSetToLink).reverse(),
    mapFieldSetToLink(currentDocumentFieldSet),
    ...reverseRelations.map(mapFieldSetToLink)
  ].filter(l => !!l) as Link[];
}

function mapFieldSetToLink(fieldSet: DocumentFieldSet) {
  if (!fieldSet) return undefined;
  return {
    // Court instance is called "instance" on some portals, and "court" on others.
    // Instead of adding additional configuration to handle this issue (in configuration)
    // we instead hard-code to pick which ever one is available
    label: fieldSet[FieldNames.Court] || fieldSet[FieldNames.Instance],
    to: getDocumentUrl({
      hiveId: fieldSet[FieldNames.HiveId],
      fullName: fieldSet[FieldNames.FullName]
    })
  };
}
