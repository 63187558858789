import * as React from "react";
import { SectionAction } from "../rhinestone-document-helper-types";
import { useLazyLoad } from "../../../../ui-components/LazyLoad/useLazyLoad";

export interface SectionProps {
  sectionTitleAsString?: string;
  id: string;
  className: string;
  index: number;
  renderSectionActions: SectionAction;
}

export const Section: React.FC<SectionProps> = ({
  sectionTitleAsString,
  id,
  className,
  renderSectionActions,
  children
}) => {
  const { ref, inView } = useLazyLoad();

  return (
    <span className="section-with-menu">
      <section ref={ref} id={id} className={className}>
        {/* Section actions should only be loaded if the section is in view because there are potential many thousands sections in a document, and sectio actions are an expensive component to mount */}
        {inView &&
          renderSectionActions({
            sectionId: id,
            sectionClassName: className,
            sectionTitle: sectionTitleAsString,
            sectionActionsClassName: "section-menu"
          })}
        {children}
      </section>
    </span>
  );
};
