import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { Translate } from "react-localize-redux";
import { Link } from "../../ui-components/Link";
import { translationKeys } from "./../../../gen/translation-keys";

interface AssetCollectionInfoProps {
  assetCollectionTitle: string;
  isTemplate: boolean;
  link: string;
}

const useStyles = makeStyles(theme => ({
  assetCollectionInfo: { marginTop: "8px", padding: "4px 16px" }
}));

export const AssetCollectionInfo: React.FC<AssetCollectionInfoProps> = ({
  assetCollectionTitle,
  isTemplate,
  link
}) => {
  const classes = useStyles();
  return (
    <div className={classes.assetCollectionInfo}>
      {!isTemplate && (
        <Translate
          id={
            translationKeys.document_page.document_in_asset_collection_context
          }
        />
      )}
      {isTemplate && (
        <Translate
          id={
            translationKeys.document_page
              .document_in_asset_collection_template_context
          }
        />
      )}{" "}
      <Link to={link}>{assetCollectionTitle}</Link>
    </div>
  );
};
