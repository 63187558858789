import { DocumentDto, DocumentModel } from "@rhinestone/portal-web-api";
import { usePortalApi } from "../../api-access/use-portal-api";

export function useEffectiveVersion(documentInfo: DocumentDto | undefined) {
  const hiveId = documentInfo?.hiveId ?? "";
  const fullName = documentInfo?.fullName ?? "";
  const {
    data: effectiveVersion,
    status,
    error
  } = usePortalApi<DocumentModel>(
    client => client.getEffectiveVersion(hiveId, fullName),
    ["getEffectiveVersion", hiveId, fullName],
    {
      enabled: !!documentInfo
    }
  );
  return { effectiveVersion, status, error };
}
