import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "../../ui-components/Snackbar";
import Box from "@material-ui/core/Box";
import { Translate } from "react-localize-redux";
import { Button } from "../../ui-components/Button";
import { useHistory } from "react-router-dom";

export function useProfileEmailRequiredSnackbar() {
  const history = useHistory();
  const { enqueueInfo } = useSnackbar();
  return () =>
    enqueueInfo(
      <>
        <Typography variant="body1" gutterBottom>
          <Box fontWeight={600}>
            <Translate id="account.email_required_header" />,
          </Box>
        </Typography>
        <Typography variant="body2" noWrap={false}>
          <Translate id="account.email_required_message" />
        </Typography>
      </>,
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => history.push("/Account/MyProfile")}
        size="small"
        style={{ whiteSpace: "nowrap" }}
      >
        <Translate id="account.profile_page_header" />
      </Button>
    );
}
