import * as React from "react";
import TimelineAccordionContainer from "../../preparatory-work/TimelineAccordionContainer";
import TimelineAccordionPanel from "../../preparatory-work/TimelineAccordionPanel";
import { useGetPrepatoryWorkTimelines } from "../../preparatory-work/use-get-prepatory-work-timelines";
import { useEnablePrepChain } from "../../preparatory-work/preparatory-work-helpers";
import { useCurrentDocumentPageParameters } from "../../document-page/document-page-helpers";
import { useParagraphZoomContext } from "../ParagraphZoomContext";
import { ApiFetchIndicator } from "../../../ui-components/ApiFetchIndicator";
import { ParagraphZoomDataError } from "../errors/ParagraphZoomDataError";
import { Skeleton } from "@material-ui/lab";

export const ParagraphPreparatoryWorkTimelinesContainer: React.FC = () => {
  const enablePrepChain = useEnablePrepChain();
  const { pzoom: paragraphId } = useCurrentDocumentPageParameters();
  const { currentParagraphInTime, status, error } = useParagraphZoomContext();

  const hiveId = currentParagraphInTime?.portalDocumentContext?.hiveId;
  const fullName = currentParagraphInTime?.portalDocumentContext?.fullName;

  const { timelineDocuments, isLoading } = useGetPrepatoryWorkTimelines(
    hiveId,
    fullName,
    config => config.paragraphConsolidationRelationTypeCode,
    paragraphId
  );
  if (!enablePrepChain) {
    return null;
  }

  return (
    <ApiFetchIndicator
      status={[status, isLoading ? "loading" : "success"]}
      errorComponent={<ParagraphZoomDataError error={error} />}
      loadingIndicator={<Skeleton variant="rect" height={500} />}
    >
      <TimelineAccordionPanel>
        {timelineDocuments.map(doc => (
          <TimelineAccordionContainer
            key={`${doc.hiveId}_${doc.fullName}`}
            hiveId={doc.hiveId}
            fullName={doc.fullName}
            openLinksInNewTab={true}
          />
        ))}
      </TimelineAccordionPanel>
    </ApiFetchIndicator>
  );
};
