import React from "react";
import { useDocumentInfo } from "../../../../document-context";
import { RelationQueryType } from "../RelationChips/RelationQueryType";
import { useRelationSearchUrl } from "../RelationChips/use-get-relation-search-url";
import { useDocumentRelationGroupsForTopOfDocument } from "../use-document-relation-groups";
import RelationsDialogContainer from "./RelationsDialogContainer";

interface ArticleRelationDialogProps {
  groupName: string;
}

export const ArticleRelationDialog: React.FC<ArticleRelationDialogProps> = ({
  groupName
}) => {
  const { hiveId, fullName } = useDocumentInfo();

  const { data, status } = useDocumentRelationGroupsForTopOfDocument(
    true,
    hiveId,
    fullName
  );
  const searchUrl = useRelationSearchUrl(
    hiveId,
    fullName,
    RelationQueryType.TopOfDocument,
    undefined,
    groupName
  );
  return (
    <RelationsDialogContainer
      data={data}
      status={status}
      groupName={groupName}
      searchUrl={searchUrl}
      openLinksInNewTab={true}
    />
  );
};
