import { usePortalApi } from "../../api-access/use-portal-api";
import { useDocumentInfo } from "../document-context";

export function useIsDanishLaw() {
  const { hiveId, fullName } = useDocumentInfo();
  const { data, isFetching, isError } = usePortalApi(
    client => client.IsDkLaw(hiveId, fullName),
    ["IsDkLaw", hiveId, fullName]
  );

  return {
    isDanishLaw: !!data,
    isFetching,
    isError
  };
}
