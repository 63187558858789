import { RelatedTargetDocumentFieldSetsDto } from "@rhinestone/portal-web-api";
import { usePortalApi } from "../../api-access/use-portal-api";
import { HardCodedFieldSetName } from "../document-fieldsets";
import {
  ConsolidationAndPageConfig,
  ConsolidationConfiguration,
  documentIsLoaded,
  pageConfigurationIsLoaded,
  relationTypesIsConfigured,
  useConsolidationAndPageConfig
} from "../preparatory-work/preparatory-work-helpers";
import { QueryStatus } from "react-query";

export function useGetTargetRelationsByFamily(
  hiveId: string,
  fullName: string,
  familyName?: string,
  fieldSetName?: string,
  sourceBookmark?: string,
  relationTypeCode?: string,
  getRelationTypeCode?: (
    consolidationConfiguration: ConsolidationConfiguration | undefined
  ) => string
): [RelatedTargetDocumentFieldSetsDto[] | undefined, QueryStatus] {
  const fieldSetNameToUse = fieldSetName || HardCodedFieldSetName;
  const pageConfig = useConsolidationAndPageConfig();
  const defaultRelationTypeCode = pageConfig.consolidationRelationTypeCode;
  const relationTypeCodeToUse = getRelationTypeCodeToUse(
    getRelationTypeCode,
    pageConfig,
    relationTypeCode,
    defaultRelationTypeCode
  );
  const {
    pageConfiguration,
    pageConfigurationStatus,
    consolidationRelationTypeCode,
    preparationRelationTypeCode
  } = pageConfig;

  const { data, status } = usePortalApi(
    client =>
      client.getDocumentTargetRelationFieldsSetsByFamily(
        hiveId || "",
        familyName || "",
        fullName,
        relationTypeCodeToUse,
        fieldSetNameToUse,
        sourceBookmark ?? ""
      ),
    [
      "getDocumentTargetRelationFieldsSetsByFamily",
      hiveId,
      familyName,
      relationTypeCodeToUse,
      fieldSetNameToUse,
      sourceBookmark ?? ""
    ],
    {
      enabled: shouldRetrievingConsolidationInformationBeEnabled(
        familyName,
        documentIsLoaded(hiveId, fullName),
        pageConfigurationStatus,
        pageConfiguration,
        consolidationRelationTypeCode,
        preparationRelationTypeCode
      )
    }
  );

  return [data, status];
}

function shouldRetrievingConsolidationInformationBeEnabled(
  familyName: string | undefined,
  documentIsLoaded: boolean,
  pageConfigurationStatus: string,
  pageConfiguration: any,
  consolidationRelationTypeCode: string,
  preparationRelationTypeCode: string
): boolean | undefined {
  return (
    Boolean(familyName) &&
    documentIsLoaded &&
    pageConfigurationIsLoaded(pageConfigurationStatus, pageConfiguration) &&
    relationTypesIsConfigured(
      consolidationRelationTypeCode,
      preparationRelationTypeCode
    )
  );
}

function getRelationTypeCodeToUse(
  getRelationTypeCode:
    | ((
        consolidationConfiguration: ConsolidationConfiguration | undefined
      ) => string)
    | undefined,
  pageConfig: ConsolidationAndPageConfig,
  relationTypeCode: string | undefined,
  defaultRelationTypeCode: string
) {
  return (
    (getRelationTypeCode
      ? getRelationTypeCode(pageConfig)
      : relationTypeCode) || defaultRelationTypeCode
  );
}
