import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import { ArticleAction } from "../../../DocumentActionMenu";
import DocumentHeader from "../document-components/DocumentHeader";

const processDocumentHeader = (
  renderAdditionalDocumentInformation?: () => React.ReactNode,
  renderArticleActions?: ArticleAction
): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => {
      return node.name === "header" && node.parent?.name === "article";
    },
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      /*
       * To allow position: sticky of underlying elements (document-toolbar), while subsequently
       * preserving the rhinestone XML in the DOM output, we have to set display: "inline" to
       * let the outer article-element be the logical parent of the inner elements.
       */
      const note = React.Children.toArray(children).filter(child => {
        return (
          (child as React.ReactElement<{ className?: string }>)?.props
            ?.className === "note-container"
        );
      });
      const notNote = React.Children.toArray(children).filter(child => {
        return (
          (child as React.ReactElement<{ className?: string }>)?.props
            ?.className !== "note-container"
        );
      });

      return (
        <DocumentHeader
          index={index}
          notNote={notNote}
          note={note}
          renderArticleActions={renderArticleActions}
          renderAdditionalDocumentInformation={
            renderAdditionalDocumentInformation
          }
        />
      );
    }
  };
};

export default processDocumentHeader;
