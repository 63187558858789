import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";

interface ParagrpaphTimelineLabelProps {
  repealed: boolean;
}
export const ParagraphTimelineLabel: React.FC<ParagrpaphTimelineLabelProps> = ({
  repealed
}) => {
  return repealed ? (
    <Translate id={translationKeys.paragraph_zoom.repealed} />
  ) : (
    <></>
  );
};
