import * as React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  icon: {
    strokeWidth: "2",
    stroke: theme.palette.grey[400],
    fill: "none"
  },
  latestLbk: {
    fill: theme.palette.status.latestLbk.main
  },
  latestLbkStroke: {
    stroke: theme.palette.status.latestLbk.main
  },
  effectiveStroke: {
    stroke: theme.palette.status.effective.main
  },
  effective: {
    fill: theme.palette.status.effective.main
  },
  coming: {
    stroke: theme.palette.status.coming.main,
    fill: theme.palette.status.coming.main
  },
  historic: {
    stroke: theme.palette.status.historic.main,
    fill: theme.palette.status.historic.main
  },
  unknown: {
    stroke: theme.palette.status.unknown.main,
    fill: theme.palette.status.unknown.main
  },
  previousVersion: {
    stroke: theme.palette.status.previousVersion.light,
    fill: theme.palette.status.previousVersion.light
  }
}));

export type StatusIconProps = {
  active?: boolean;
  alt?: React.ReactNode;
} & SvgIconProps;

export const ComingStatusIcon: React.FC<StatusIconProps> = ({
  active,
  alt,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <SvgIcon {...rest}>
      {/* wcag fix for when using svg and no text in Link (href) */}
      <title>{alt}</title>
      <polygon
        points="12,1 1,23 23,23"
        className={clsx(classes.icon, active && classes.coming)}
      />
    </SvgIcon>
  );
};

export const EffectiveStatusIcon: React.FC<StatusIconProps> = ({
  active,
  alt,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <SvgIcon {...rest}>
      {/* wcag fix for when using svg and no text in Link (href) */}
      <title>{alt}</title>
      <circle
        cx="50%"
        cy="50%"
        r="46%"
        className={clsx(
          classes.icon,
          classes.effectiveStroke,
          active && classes.effective
        )}
      />
    </SvgIcon>
  );
};
export const LatestLbkStatusIcon: React.FC<StatusIconProps> = ({
  active,
  alt,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <SvgIcon {...rest}>
      {/* wcag fix for when using svg and no text in Link (href) */}
      <title>{alt}</title>
      <circle
        cx="50%"
        cy="50%"
        r="46%"
        className={clsx(
          classes.icon,
          classes.latestLbkStroke,
          active && classes.latestLbk
        )}
      />
    </SvgIcon>
  );
};

export const HistoricStatusIcon: React.FC<StatusIconProps> = ({
  active,
  alt,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <SvgIcon {...rest}>
      {/* wcag fix for when using svg and no text in Link (href) */}
      <title>{alt}</title>
      <polygon
        points="12,1 23,12 12,23 1,12"
        className={clsx(classes.icon, active && classes.historic)}
      />
    </SvgIcon>
  );
};

export const PreviousVersionStatusIcon: React.FC<StatusIconProps> = ({
  active,
  alt,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <SvgIcon {...rest}>
      {/* wcag fix for when using svg and no text in Link (href) */}
      <title>{alt}</title>
      <polygon
        points="12,1 23,12 12,23 1,12"
        className={clsx(classes.icon, active && classes.previousVersion)}
      />
    </SvgIcon>
  );
};

export const UnknownStatusIcon: React.FC<StatusIconProps> = ({
  active,
  alt,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <SvgIcon {...rest}>
      {/* wcag fix for when using svg and no text in Link (href) */}
      <title>{alt}</title>
      <circle
        cx="50%"
        cy="50%"
        r="46%"
        className={clsx(classes.icon, active && classes.unknown)}
      />
    </SvgIcon>
  );
};
