/**
 * This is a list of hard-coded features that corresponds to the features found in
 * "Portal.Domain\Feature.cs"
 */

import { useUserContext } from "./use-user-context";

export enum Feature {
  AssetCollection = "d2f1726d-0b46-4a78-8d49-9a4b63c1b0d0",
  AssetCollectionTemplate = "7cba5718-2616-47f0-be4e-24b364826d82",
  MarkUpAndAnnotations = "77524b68-d7ab-4a0f-b1c2-31484c5930c7",
  ParagraphZoom = "2954088e-009d-4f76-9f00-34ccd4e55186",
  ParagraphZoomTabSection = "dea0b27f-353e-4324-a690-2864aa130753"
}

/**
 * @param features Any number of Features to check for.
 *
 * @returns True when user is authenticated and has given feature(s).
 */
export function useUserHasFeature(...features: Feature[]) {
  const { user } = useUserContext();
  const userFeatures = user?.features || [];

  return features.every(a => userFeatures.some(b => b.featureId === a));
}
