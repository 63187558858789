import * as React from "react";
import { Translate } from "react-localize-redux";
import { ListItem, ListItemText } from "../../ui-components/List";
import { DocumentFieldModel } from "@rhinestone/portal-web-api";
import { getDisplayValue } from "./about-document-helpers";
import { Box, Typography } from "@material-ui/core";
import { FieldSetValue } from "../use-fieldset";

export const FieldItem: React.FC<
  DocumentFieldModel & {
    fieldValue: FieldSetValue;
    linkTo?: string;
  }
> = ({ label, fieldType, fieldValue, fieldName, linkTo }) => {
  const displayValue = getDisplayValue(
    fieldType,
    fieldValue,
    fieldName,
    linkTo
  );

  if (!displayValue) return null;

  return (
    <ListItem>
      <ListItemText
        disableTypography
        primary={
          <Box fontWeight="fontWeightBold">
            <Translate id={label}></Translate>
          </Box>
        }
        secondary={
          <Typography component="div" variant="body2" color="textPrimary">
            {displayValue}
          </Typography>
        }
      />
    </ListItem>
  );
};
