import * as React from "react";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import { IconButton } from "../../ui-components/IconButton";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { usePortalApiMutation } from "../../api-access/use-portal-api";
import { useSnackbar } from "../../ui-components/Snackbar";
import { useIsProfileMissingEmail } from "../../account/profile-email-required/use-is-profile-missing-email";
import { useProfileEmailRequiredSnackbar } from "../../account/profile-email-required/use-profile-email-required-snackbar";

interface CreateSubscriptionProps {
  hiveId: string;
  fullName: string;
  title: string;
}

export const CreateSubscription: React.FC<CreateSubscriptionProps> = ({
  hiveId,
  fullName,
  title
}) => {
  const isProfileMissingEmail = useIsProfileMissingEmail();
  const profileEmailRequiredSnackbar = useProfileEmailRequiredSnackbar();
  const { createSubscription, createSubscriptionStatus } =
    useCreateSubscriptionForDocument();
  const subscriptionName = (title || fullName).substring(0, 256);

  const handleClicked = () => {
    if (isProfileMissingEmail) {
      profileEmailRequiredSnackbar();
    } else {
      createSubscription({ hiveId, fullName, name: subscriptionName });
    }
  };

  return (
    <Translate>
      {({ translate }) => (
        <IconButton
          // Make sure to disable button while we are updating server-side data
          disabled={createSubscriptionStatus === "loading"}
          onClick={handleClicked}
          size="small"
          title={String(
            translate(translationKeys.document_page.subscription_create)
          )}
        >
          <NotificationsOutlinedIcon />
        </IconButton>
      )}
    </Translate>
  );
};

function useCreateSubscriptionForDocument() {
  const { enqueueSuccess } = useSnackbar();
  const { mutate, status } = usePortalApiMutation(
    client => client.createSubscriptionForDocument,
    ({ hiveId, fullName }) => [
      ["getSubscriptionsForDocument", hiveId, fullName]
    ],
    {
      onSuccess: () =>
        enqueueSuccess(
          <Translate
            id={
              translationKeys.document_page
                .document_version_subscription_created
            }
          />
        )
    }
  );
  return { createSubscription: mutate, createSubscriptionStatus: status };
}
