import { DocumentPropertyDto } from "@rhinestone/portal-web-api";
import { useEffect } from "react";
import { useDocumentInfo } from "./document-context";

export function useSetPageTitle() {
  const { properties } = useDocumentInfo();
  const documentTitle = getTitleFromProperties(properties);
  useEffect(() => {
    const initialDocumentTitle = document.title;
    document.title = documentTitle;
    return () => {
      document.title = initialDocumentTitle;
    };
  }, [documentTitle]);
}

export function getTitleFromProperties(
  properties: DocumentPropertyDto[]
): string {
  const propertyNames = ["shorttitle", "nickname", "title"];
  for (const propertyName of propertyNames) {
    const property = properties?.find(x => x.name === propertyName);
    if (property !== undefined) {
      return property.value;
    }
  }
  return document.title;
}
