import * as React from "react";
import {
  VerticalAlignBottom,
  VerticalAlignBottomOutlined
} from "@material-ui/icons";
import Fade from "@material-ui/core/Fade";

import { IconButton } from "../../../ui-components/IconButton";
import { Translate } from "react-localize-redux";
import ExportDocumentDialog from "../../ExportDocument/ExportDocumentDialog";
import { translationKeys } from "../../../../gen/translation-keys";

export const ExportDocumentAction: React.FC = props => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  return (
    <Translate>
      {({ translate }) => (
        <>
          <IconButton
            {...props}
            size="small"
            title={translate(translationKeys.document_page.export).toString()}
            onClick={() => setIsVisible(true)}
          >
            <Fade in={true}>
              {isVisible ? (
                <VerticalAlignBottom color={"primary"} />
              ) : (
                <VerticalAlignBottomOutlined />
              )}
            </Fade>
          </IconButton>

          <ExportDocumentDialog
            open={isVisible}
            onClose={() => setIsVisible(false)}
          />
        </>
      )}
    </Translate>
  );
};
