import * as React from "react";
import { AnnotationActiveIndicator } from "../AnnotationActiveIndicator";
import { AnnotationCommentEditor } from "./AnnotationCommentEditor";
import { useState } from "react";
import { CommentMarkerList } from "./CommentMarkerList";
import { AnnotationLayoutState } from "../annotation-layout-state";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { Annotation } from "../annotation-state";

interface AnnotationCommentsProps<T extends AnnotationModel> {
  annotations: Annotation<T>[];
  layoutState: AnnotationLayoutState;
  onSaveAnnotation: (annotation: Annotation<T>) => Promise<T> | undefined;
  onDeleteAnnotationComment: (
    annotation: Annotation<T>
  ) => Promise<T> | undefined;
  markerRightPosition: string;
}

export const AnnotationComments = <T extends AnnotationModel>({
  annotations,
  layoutState,
  onSaveAnnotation,
  onDeleteAnnotationComment,
  markerRightPosition
}: AnnotationCommentsProps<T>) => {
  /* 
    TODO: consider gathering all state into state object and use useReducer to update state instead of myriad of state
    and write test for this 
  */
  const [editAnnotations, setEditAnnotations] = useState<{
    annotations: Annotation<T>[];
    anchorEl: HTMLElement;
  }>();

  const [annotationBeingEdited, setAnnotationBeingEdited] =
    useState<Annotation<T>>();

  const closeAnnotationComments = () => {
    setEditAnnotations(undefined);
    setAnnotationBeingEdited(undefined);
  };

  const saveComment = async (annotation: Annotation<T>, newComment: string) => {
    if (!newComment) return await deleteComment(annotation);
    else {
      closeAnnotationComments();
      await onSaveAnnotation({
        ...annotation,
        annotation: { ...annotation.annotation, comment: newComment }
      });
    }
  };

  const deleteComment = async (annotation: Annotation<T>) => {
    /* TODO: figure out what what we should do with "delete" button here
    For now i made simply  "delete" the comment on annotation.
    Since we want a concept where we want annotations without comments (on server this would just mean "no comment"), 
    i expect we will have an annotation edit tool bound to highlight part of annotation...... we will see */
    closeAnnotationComments();
    await onDeleteAnnotationComment({
      ...annotation,
      annotation: { ...annotation.annotation, comment: "" }
    });
  };

  const annotationToHighlight = annotationBeingEdited;

  return (
    <>
      {annotations && (
        <CommentMarkerList
          right={markerRightPosition}
          annotations={annotations}
          layoutState={layoutState}
          onShowComment={(annotations, anchorEl) => {
            setEditAnnotations({
              annotations,
              anchorEl
            });
            setAnnotationBeingEdited(annotations[0]);
          }}
        />
      )}
      {editAnnotations && annotationBeingEdited && (
        <AnnotationCommentEditor
          onClose={() => closeAnnotationComments()}
          onSave={saveComment}
          onDelete={deleteComment}
          onActiveAnnotationChange={a => setAnnotationBeingEdited(a)}
          annotations={editAnnotations.annotations}
          defaultActiveAnnotation={annotationBeingEdited}
          anchorEl={editAnnotations.anchorEl}
          open
        />
      )}
      {annotationToHighlight && (
        <AnnotationActiveIndicator
          visible
          annotation={annotationToHighlight.annotation}
          layoutState={layoutState}
        />
      )}
    </>
  );
};
