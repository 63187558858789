import Box from "@material-ui/core/Box";
import React from "react";

interface SidePaneSectionHeaderProps {
  text: string;
}

export const SidePaneSectionHeader: React.FC<SidePaneSectionHeaderProps> = ({
  text
}) => (
  <Box p={2} fontWeight={600} fontSize="h6.fontSize">
    {text}
  </Box>
);
