import { usePortalApi } from "../api-access/use-portal-api";

export type FieldSetTaxonValue = {
  Key: string;
  Title: string;
  LongTitle: string;
};
export type FieldSetValue = string | FieldSetTaxonValue[];

export type FieldSet = {
  [propertyName: string]: FieldSetValue;
};

export function useFieldSet(hiveId: string, fullName: string) {
  return usePortalApi(
    client =>
      // it seems DefaultPortalFields is always used on all portal,
      // though maybe in the future this should could change and be made configurable
      client.getDocumentFieldset(hiveId, fullName, "DefaultPortalFields"),
    ["getDocumentFieldset", hiveId, fullName],
    {
      enabled: Boolean(hiveId && fullName)
    }
  ).data;
}
