import * as React from "react";
import { Divider } from "../../../../ui-components/Divider";

export const EditorialNote: React.FC = ({ children }) => {
  return (
    <>
      {<Divider />}
      {children}
    </>
  );
};
