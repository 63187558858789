import MuiListItemIcon, {
  ListItemIconProps
} from "@material-ui/core/ListItemIcon";
import * as React from "react";

export const ListItemIcon: React.FC<
  Pick<ListItemIconProps, "children" | "color" | "className">
> = ({ children, ...rest }) => (
  <MuiListItemIcon {...rest}>{children}</MuiListItemIcon>
);
