import { Box } from "@material-ui/core";
import React from "react";
import { LoadingProgress } from "./LoadingProgress";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  loaderContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    top: 0
  }
}));

interface OverlayLoaderProps {
  isLoading: boolean;
}
const OverlayLoader: React.FC<OverlayLoaderProps> = ({
  isLoading,
  children
}) => {
  const classes = useStyles();
  return (
    <Box paddingBottom={2}>
      {isLoading && (
        <Box className={classes.loaderContainer}>
          <LoadingProgress />
        </Box>
      )}
      {children}
    </Box>
  );
};

export default OverlayLoader;
