import React from "react";
import RelationsActionAlwaysEnabled from "./RelationsActionAlwaysEnabled";

import { useShouldAlwaysShowRelationsAction } from "./use-should-always-show-relations-action";
import { useDocumentInfo } from "../../../document-context";
import RelationsAction from "./RelationsAction";

const RelationsActionContainer: React.FC = () => {
  const { taxons } = useDocumentInfo();
  const { shouldAlwaysShowRelationsAction, status } =
    useShouldAlwaysShowRelationsAction(taxons);
  return !shouldAlwaysShowRelationsAction ? (
    <RelationsAction status={status} />
  ) : (
    <RelationsActionAlwaysEnabled />
  );
};

export default RelationsActionContainer;
