import * as React from "react";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";

interface TocExpandIconProps {
  expanded: boolean;
}

export const TocExpandIcon: React.FC<TocExpandIconProps> = ({ expanded }) => {
  return (
    <>
      {!expanded && <ChevronRight onClick={e => e.preventDefault()} />}
      {expanded && <ExpandMore onClick={e => e.preventDefault()} />}
    </>
  );
};
