import { DanishLawStatus } from "@rhinestone/portal-web-api";
import { usePortalApi } from "../../../api-access/use-portal-api";
import { QueryStatus } from "react-query";

export function useDocumentLegalStatus(documentId: string): {
  documentLegalStatus: DanishLawStatus | undefined;
  status: QueryStatus;
} {
  const { data, status } = usePortalApi(
    client => client.getDocumentLegalStatus(documentId),
    ["getDocumentLegalStatus", documentId]
  );

  return {
    documentLegalStatus: data,
    status
  };
}
