import { BaseFormFieldProps } from "./FieldOptions";
import { connectToForm } from "./FormFieldWrapper";
import { Select } from "../../Select";

export type FormSelectFieldProps<TFieldName extends string> = {
  children: React.ReactNode;
  label?: React.ReactNode;
  fullWidth?: boolean;
} & BaseFormFieldProps<TFieldName, string>;

const FormSelectField: <TFieldName extends string>(
  props: FormSelectFieldProps<TFieldName>
) => JSX.Element = connectToForm(Select);

export default FormSelectField;
