import * as React from "react";
import { noop } from "lodash";
import { useContext } from "react";

type SectionRelationChipsState = {
  [sectionId: string]: boolean | undefined;
};

type DocumentRelationChipsStateContextType = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];

type DocumentSectionRelationChipsStateContextType = [
  SectionRelationChipsState,
  React.Dispatch<React.SetStateAction<SectionRelationChipsState>>
];

const DocumentRelationChipsStateContext = (() => {
  const newContext = React.createContext<DocumentRelationChipsStateContextType>(
    [false, noop]
  );
  newContext.displayName = "DocumentRelationChipsStateContext";
  return newContext;
})();

const DocumentSectionRelationChipsStateContext = (() => {
  const newContext =
    React.createContext<DocumentSectionRelationChipsStateContextType>([
      {},
      noop
    ]);
  newContext.displayName = "DocumentSectionRelationChipsStateContext";
  return newContext;
})();

/**
 * This provider holds collapsed state of relation chips container for the document
 * (below document actions). There may be only one such container for a document.
 */
export const DocumentRelationChipsStateProvider: React.FC = ({ children }) => {
  const [documentState, setDocumentState] = React.useState<boolean>(false);

  return (
    <DocumentRelationChipsStateContext.Provider
      value={[documentState, setDocumentState]}
    >
      {children}
    </DocumentRelationChipsStateContext.Provider>
  );
};

/**
 * This provider holds collapsed state of relation chips containers of each document section
 * (below section title). Multiple sections may have relations, so this provider keeps an array of states
 * (for each relations container section).
 */
export const DocumentSectionRelationChipsStateProvider: React.FC = ({
  children
}) => {
  const [sectionsState, setSectionsState] =
    React.useState<SectionRelationChipsState>({});

  return (
    <DocumentSectionRelationChipsStateContext.Provider
      value={[sectionsState, setSectionsState]}
    >
      {children}
    </DocumentSectionRelationChipsStateContext.Provider>
  );
};

export function useDocumentRelationChipsState() {
  const [expanded, setExpanded] = useContext(DocumentRelationChipsStateContext);

  return { expanded, setExpanded };
}

export function useDocumentSectionRelationChipsState(sectionId: string) {
  const [sectionsState, setSectionsState] = useContext(
    DocumentSectionRelationChipsStateContext
  );

  const setExpanded = (expanded: boolean | undefined) => {
    setSectionsState(prev => ({ ...prev, [sectionId]: expanded }));
  };

  // return state of document section relation chips state if it already exist, otherwise use false
  const expanded =
    sectionsState[sectionId] !== undefined ? sectionsState[sectionId] : false;
  return { expanded, setExpanded };
}
