import { getNameFromLink } from "./link-helper-functions";
import { Taxon, Taxonomy } from "@rhinestone/portal-web-api";
import { useSearchParams as importedUseSearchParams } from "../../../../browser-utils/useSearchParams";

export function getTaxon(
  taxon: Taxonomy | Taxon | undefined,
  subPath: string[]
): Taxonomy | Taxon | undefined {
  if (subPath.length === 0) {
    return taxon;
  }

  const subTaxon = taxon?.children?.find(
    (f: Taxon) => f.title === getNameFromLink(subPath[0])
  );

  if (subTaxon) {
    return getTaxon(subTaxon, subPath.slice(1));
  }
  return undefined;
}

export function useGetPathList(
  useSearchParams: <T>() => T = importedUseSearchParams
) {
  const { path } = useSearchParams() as {
    path: string;
  };

  const pathList = path ? path.split("/").filter(x => x.length > 0) : [];
  return pathList;
}
