import React from "react";
import { usePortalApi } from "../../api-access/use-portal-api";

import TaxonsContainer from "../components/TaxonsContainer";

export const TaxonomyTilePageContainer: React.FC = () => {
  const productCode = window.location.pathname.split("/")[2];

  const product = usePortalApi(
    client => client.getProduct(productCode),
    ["getProduct", productCode],
    {
      enabled: Boolean(productCode)
    }
  ).data;

  return <>{product && <TaxonsContainer product={product} />}</>;
};
