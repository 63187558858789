import * as H from "history";

const searchPreviewQueryParameter = "searchPreview";
const searchPreviewSessionStorageKey = "searchPreviewName";

export const getPreviewNameFromUrl = (location: H.Location) => {
  return new URLSearchParams(location.search).get(searchPreviewQueryParameter);
};

export const clearPreview = (location: H.Location, history: H.History) => {
  sessionStorage.removeItem(searchPreviewSessionStorageKey);

  // Get the current query parameters
  const queryParams = new URLSearchParams(location.search);

  // Remove a query parameter
  queryParams.delete(searchPreviewQueryParameter);

  // Update the URL with the new query parameters
  history.push({
    pathname: location.pathname,
    search: queryParams.toString()
  });

  window.location.reload();
};

export const updateSessionStorage = (searchPreviewFromUrl: string) => {
  // Update session storage if valid...
  const activePreview = sessionStorage.getItem(searchPreviewSessionStorageKey);

  if (searchPreviewFromUrl !== activePreview) {
    sessionStorage.setItem(
      searchPreviewSessionStorageKey,
      searchPreviewFromUrl
    );

    // Reload the page if updating the search preview as configuration
    // needs to be reloaded to reflect the new search preview
    window.location.reload();
  }
};
