import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { Icon, ListItemIcon, useMediaQuery, useTheme } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../../gen/translation-keys";
import { usePortalApi } from "../../../../api-access/use-portal-api";
import { useDocumentInfo } from "../../../document-context";
import { useUserHasFeature, Feature } from "../../../../user/features";

export const ParagraphZoomSectionAction: React.FC = () => {
  return (
    <>
      <ListItemIcon>
        <Icon className="fa fa-binoculars" fontSize="small" />
      </ListItemIcon>
      <Typography noWrap>
        <Translate id={translationKeys.document_page.paragraph_zoom} />
      </Typography>
    </>
  );
};

// currently, paragraph-zoom feature is available only on sections with "paragraf" class
// as hardcoded assumption such sections are eligible for paragraph-zooming
// there is no configuration (no XPath or any other means yet) to identify paragraph-zoom sections
// also, this feature is available only on "medium" or larger screens
function useSectionEligibleForParagraphZoom(sectionClassName: string) {
  const theme = useTheme();
  const wideScreen = useMediaQuery(theme.breakpoints.up("sm"));

  return wideScreen && sectionClassName === "paragraf";
}

export function useShouldShowParagraphZoomAction(
  sectionClassName: string
): boolean {
  const isSectionEligible =
    useSectionEligibleForParagraphZoom(sectionClassName);

  const paragraphZoomAllowed = useParagraphZoomAllowed(isSectionEligible);

  return isSectionEligible && paragraphZoomAllowed;
}
export function useParagraphZoomAllowed(enabled = true) {
  const { documentId } = useDocumentInfo();

  const hasParagraphZoomFeature = useUserHasFeature(Feature.ParagraphZoom);

  const documentHasParagraphZoom = Boolean(
    usePortalApi(
      client => client.documentHasParagraphZoom(documentId),
      ["documentHasParagraphZoom", documentId],
      { enabled }
    ).data
  );
  return documentHasParagraphZoom && hasParagraphZoomFeature;
}
