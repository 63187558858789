import * as React from "react";
import { Translate } from "react-localize-redux";
import { useSearchParams } from "../../browser-utils/useSearchParams";
import { translationKeys } from "../../gen/translation-keys";
import { Link } from "../ui-components/Link";

export const LoginErrorPage: React.FC = () => {
  // the actual error key send by server is key underneath "account" translation
  // this is kept when migrating view to client side from server side, to not risk breaking any portals
  const { ci, errorKey: accountErrorKey } = useSearchParams<{
    ci?: string;
    errorKey?: string;
  }>();
  return (
    <div>
      <h2>
        <Translate
          id={
            accountErrorKey
              ? `account.${accountErrorKey}`
              : translationKeys.account.error_could_not_log_in
          }
        ></Translate>
      </h2>
      <span>
        <Translate
          id={translationKeys.account.contact_administrator}
        ></Translate>
      </span>
      {ci && (
        <div>
          <Translate id={translationKeys.account.enter_code}></Translate>:{" "}
          <strong>{ci}</strong>
        </div>
      )}
      <div>
        <Link to={"/"}>
          <Translate id={translationKeys.ribbon.go_to_main_page}></Translate>
        </Link>
      </div>
    </div>
  );
};
