import { Box, Container, Typography } from "@material-ui/core";
import * as React from "react";
import { Grid } from "../../../ui-components/layouts/Grid";
import EmailFormField from "./EmailFormField";
import NameFormField from "./NameFormField";
import { SendLinkFormProps, TypedForm } from "./TypedForm";
import FormActions from "../FormActions";
import MessageField from "./MessageField";
import * as labels from "./FormLabels";
import { useEffect } from "react";
import LinkField from "./LinkField";
import { ProfileEmailRequired } from "../../../account/profile-email-required/ProfileEmailRequired";
import EmailSelectFormField from "./EmailSelectFormField";

export interface SendLinkDefaultValues {
  fromName: string | undefined;
  fromEmail: string | undefined;
}

interface SendLinkProps {
  onLoad: () => void;
  onCancel: () => void;
  onSubmit: (formData: SendLinkFormProps) => void;
  link: string | null;
  userDisplayName?: string;
  userEmails?: string[];
  isProfileMissingEmail: boolean;
}

export const SendLinkForm: React.FC<SendLinkProps> = ({
  link,
  onLoad,
  onCancel,
  onSubmit,
  userDisplayName,
  userEmails,
  isProfileMissingEmail
}) => {
  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      {isProfileMissingEmail && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box marginTop={3}>
              <ProfileEmailRequired />
            </Box>
          </Grid>
        </Grid>
      )}
      <TypedForm
        defaultValues={{
          fromName: userDisplayName,
          fromEmail: userEmails ? userEmails[0] : ""
        }}
        onSubmit={onSubmit}
      >
        <>
          <Typography variant="h5" gutterBottom>
            {labels.fromHeader}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <NameFormField
                fieldName="fromName"
                label={labels.fromNameLabel}
                disabled={!!userDisplayName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <EmailSelectFormField
                fieldName="fromEmail"
                label={labels.fromEmailLabel}
                disabled={!userEmails || userEmails.length <= 1}
                options={userEmails}
              />
            </Grid>
          </Grid>
          <Box marginTop={5}>
            <Typography variant="h5" gutterBottom>
              {labels.toHeader}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <NameFormField fieldName="toName" label={labels.toNameLabel} />
            </Grid>
            <Grid item xs={12} md={12}>
              <EmailFormField fieldName="toEmail" label={labels.toEmailLabel} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <MessageField textLabel={labels.messageLabel} />
            </Grid>
            <Grid item xs={12} md={12}>
              <LinkField
                link={link}
                label={labels.linkLabel}
                copyLabel={labels.linkCopyLabel}
                copySuccess={labels.linkCopySuccess}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid
              item
              container
              xs={12}
              md={12}
              justify="flex-end"
              direction="row"
            >
              <FormActions onCancel={onCancel} />
            </Grid>
          </Grid>
        </>
      </TypedForm>
    </Container>
  );
};

export default SendLinkForm;
