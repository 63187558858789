import * as React from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import {
  isAnyDescendantsSelected,
  TreeViewItemWithCheckbox,
  SelectableTreeItemData
} from ".";

export type TreeViewMultiSelectItemProps = {
  item: SelectableTreeItemData;
  selected: string[];
  onChange: (checked: boolean, treeItemId: string) => void;
};

export const TreeViewMultiSelectItem: React.FC<TreeViewMultiSelectItemProps> =
  ({ item, selected, onChange }) => {
    const { treeItemId, children, label, value, title } = item;
    const isSelected = selected.includes(treeItemId);
    const isAnyDescendantSelected = isAnyDescendantsSelected(selected, item);
    return (
      <TreeItem
        key={treeItemId}
        nodeId={treeItemId}
        label={
          <TreeViewItemWithCheckbox
            onChange={checked => onChange(checked, treeItemId)}
            checked={isSelected}
            indeterminate={!isSelected && isAnyDescendantSelected}
            label={label}
          />
        }
        title={title}
        data-target={value}
      >
        {children.map(c => (
          <TreeViewMultiSelectItem
            key={c.treeItemId}
            item={c}
            selected={selected}
            onChange={onChange}
          />
        ))}
      </TreeItem>
    );
  };
