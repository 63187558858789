import React from "react";
import { DocumentLinkInfo, TicketInfo } from "@rhinestone/portal-web-api";
import { usePortalApiMutation } from "../../api-access/use-portal-api";
import { useSnackbar } from "../../ui-components/Snackbar";
import { useDocumentInfo } from "../document-context";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

export enum DocumentVersionOption {
  None = 0,
  Exact = 1,
  Effective = 2
}

export function useCreateDocumentLink() {
  const { hiveId, fullName } = useDocumentInfo();

  return usePortalApiMutation(
    client => (variables: { ticketInfo: TicketInfo }) =>
      client.createDocumentLink({
        ticketInfo: variables.ticketInfo,
        hiveId,
        fullName
      }),
    [["createDocumentLink"]],
    {
      onSuccess: data => {
        return data;
      }
    }
  );
}

export function useSendDocumentLink() {
  const { enqueueSuccess } = useSnackbar();
  const { hiveId, fullName } = useDocumentInfo();
  return usePortalApiMutation(
    client => (variables: { documentLinkInfo: DocumentLinkInfo }) =>
      client.sendDocumentLink({
        documentLinkInfo: variables.documentLinkInfo,
        hiveId,
        fullName
      }),
    [["sendDocumentLink"]],
    {
      onSuccess: () => {
        enqueueSuccess(
          <span>
            <Translate
              id={translationKeys.document_page.send_document_link_success}
            />
          </span>
        );
      }
    }
  );
}
