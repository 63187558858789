import { serializeSearchRequest, UrlTokens } from "../../index.modules";
import { SearchRequestClient } from "./search-request";
export function buildSearchPageDeepLinkFromTileData(
  taxonKey: string,
  taxonomyName: string,
  productCode: string
) {
  const searchRequest: SearchRequestClient = {
    fieldSetName: "SearchResultFields",
    criteria: [
      {
        providerKey: "products",
        data: productCode
      },
      {
        providerKey: taxonomyName,
        data: taxonKey
      }
    ],
    page: 1
  };
  return buildSearchPageDeepLinkFromRequest(searchRequest);
}
export function buildSearchPageDeepLinkFromRequest(
  request: SearchRequestClient
): string {
  return `${window.location.origin}/Soeg?${
    UrlTokens.search
  }=${serializeSearchRequest(request)}`;
}
