import DocumentIcon from "@material-ui/icons/Description";
import React from "react";
import ListItem from "./ListItem";

interface DocumentListItemProps {
  title: string;
  link: string;
}
const DocumentListItem: React.FC<DocumentListItemProps> = ({ title, link }) => {
  const icon = <DocumentIcon fontSize="small" />;
  return <ListItem title={title} link={link} icon={icon} />;
};

export default DocumentListItem;
