import * as React from "react";
import { WidgetProps } from "@rjsf/utils";
import Editor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { Marked } from "@ts-stack/markdown";

const MarkdownEditor: React.FC<WidgetProps> = ({
  id,
  value,
  readonly,
  onChange
}) => {
  const handleEditorChange = ({
    html,
    text
  }: {
    html: string;
    text: string;
  }) => {
    const newValue = text.replace(/\d/g, "");
    onChange(newValue);
  };

  return (
    <Editor
      value={value}
      style={{
        height: "500px"
      }}
      onChange={handleEditorChange}
      renderHTML={text => Marked.parse(text)}
      plugins={[
        "header",
        "font-bold",
        "font-italic",
        "list-unordered",
        "list-ordered",
        "mode-toggle",
        "full-screen",
        "auto-resize"
      ]}
    />
  );
};

export default MarkdownEditor;
