import React from "react";
import { useGetTileProps } from "../helpers/taxon-tile-helper";
import FolderContentList from "./FolderContentList";
import Tile from "./Tile";

interface TaxonContentBaseTileProps {
  children: React.ReactNode;
  title?: React.ReactNode | undefined;
  actions?: React.ReactNode | undefined;
  siblingCount?: number;
}
const TaxonContentBaseTile: React.FC<TaxonContentBaseTileProps> = ({
  children,
  title,
  actions,
  siblingCount
}) => {
  const childCount = React.Children.count(children);
  const { showExpand, size } = useGetTileProps(childCount, siblingCount);
  return (
    <Tile
      title={title}
      actions={actions}
      showExpandAction={showExpand}
      size={size}
    >
      <FolderContentList>{children}</FolderContentList>
    </Tile>
  );
};

export default TaxonContentBaseTile;
