import { Button } from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

interface SearchButtonProps {
  href: string;
}
const SearchButton: React.FC<SearchButtonProps> = ({ href }) => {
  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      startIcon={<SearchIcon />}
      href={href}
    >
      <Translate id={translationKeys.taxon_page.search_button_text} />
    </Button>
  );
};

export default SearchButton;
