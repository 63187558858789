import FormRadioButtonGroupField, {
  FormRadioButtonGroupFieldProps
} from "../../../ui-components/forms/controls/FormRadioButtonGroupField";

export type VersionValues = "Actual" | "Newest";

export type CreateLinkFormRadioButtonProps = {
  version: VersionValues;
};
export type CreateLinkFormRadioButtonKeys =
  keyof CreateLinkFormRadioButtonProps;

export const CreateLinkFormRadioButtonField: (
  props: FormRadioButtonGroupFieldProps<CreateLinkFormRadioButtonKeys>
) => JSX.Element = FormRadioButtonGroupField;
