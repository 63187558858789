import * as React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * we manage style for all the nested rhinestone document component here because of performance reasons we experienced
 * when styling many thousand similar components instances with useStyles
 */
const useStyles = makeStyles(theme => ({
  root: {
    // padding right to give a bit of air to position section menus a bit to the right of section text, without overflowing main container
    paddingRight: "15px",
    "& .section-with-menu": {
      // the section styling is moved here instead of calling useStyles in Section component for performance reasons (sometimes we can have thousands of section)
      "& section": {
        position: "relative",
        "& .section-menu": {
          position: "absolute",
          top: 5,
          right: -40,
          zIndex: 1
        }
      }
    },
    "& .foldable-section-with-menu": {
      // the section styling is moved here instead of calling useStyles in Section component for performance reasons (sometimes we can have thousands of section)
      "& section": {
        position: "relative",
        "&.foldable-section": {
          "& .foldable-section-header": {
            marginTop: 0,
            marginBottom: 0
          },
          "& .foldable-section-menu": {
            alignSelf: "center",
            justifyContent: "space-between",
            position: "initial",
            top: 0,
            right: 0
          }
        }
      }
    },
    "& .inline-note": {
      backgroundColor: theme.highlightColor,
      paddingLeft: 3,
      paddingRight: 3,
      fontSize: "1rem",
      display: "inline",
      lineHeight: "normal",
      "&:has(.exclude-from-annotation>*)": {
        display: "inline-block",
        width: "100%"
      },
      "&:has(.exclude-from-annotation>span)": {
        "&:not(:has(.exclude-from-annotation>span>*))": {
          display: "inline"
        }
      },
      "& *": {
        width: "fit-content",
        "&:first-child": {
          marginTop: 0
        },
        "&:last-child": {
          marginBottom: 0
        }
      }
    },
    "& .inline-note-icon": {
      cursor: "pointer",
      verticalAlign: -6,
      marginLeft: 3,
      marginBottom: "2px"
    },
    "& .inline-note-number": {
      fontSize: "0.8rem",
      verticalAlign: "super",
      color: theme.palette.text.secondary,
      lineHeight: "1",
      "&::after": {
        content: " "
      }
    },
    // Bug#91366: Table-cells baseline should align with the row's baseline
    "& td": {
      verticalAlign: "baseline"
    },
    "& ol, ul": {
      width: 750
    },
    "& table": {
      wordBreak: "initial"
    },
    "& table ol, ul": {
      width: "unset !important"
    },
    "& mark": {
      padding: 0,
      backgroundColor: "yellow"
    },
    "& mark.selected": {
      backgroundColor: "orange"
    },
    "& h2": {
      paddingRight: 48,
      display: "block"
    },
    "& img": {
      maxWidth: "100%",
      height: "auto"
    },
    "& blockquote": {
      fontSize: "1rem",
      padding: 0,
      margin: 0,
      border: 0
    },
    "& blockquote > :first-child:before, q:before": {
      content: "open-quote"
    },
    "& blockquote > :last-child:after, q:after": {
      content: "close-quote"
    },
    "& blockquote, q": {
      quotes: '"»" "«" "›" "‹"'
    },
    "& .rh-list": {
      display: "block !important",
      marginBlockStart: "unset !important",
      marginBlockEnd: "unset !important",
      paddingLeft: "48px"
    },
    "& .rh-list-item": {
      display: "flex !important",
      marginBlockStart: "unset !important",
      marginBlockEnd: "unset !important",
      marginLeft: "-20px"
    },
    "& .rh-list-id": {
      flex: "0 0 auto",
      marginLeft: "-12px",
      minHeight: "1px",
      float: "left",
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "8px",
      width: "2em"
    },
    "& p.Quote": {
      marginLeft: "32px",
      marginBottom: "0px"
    }
  }
}));

const useLawDocumentStyles = makeStyles(theme => ({
  root: {
    // only certain law documents needs this styling. (PBI: 95637)
    // this can be removed when class name = paragraf has been removed from "not" law documents
    "& .paragraf h2,h3,h4,h5,h6,h7,h8": {
      fontWeight: "bold"
    },
    "& .paragrafrange h2,h3,h4,h5,h6,h7,h8": {
      fontWeight: "bold"
    },
    // only certain law documents needs this styling. (PBI: 95637)
    // this can be removed when class name = stk has been removed from "not" law documents
    "& .stk h2,h3,h4,h5,h6,h7,h8": {
      fontWeight: "normal",
      fontStyle: "italic"
    },
    "& *:is(.paragraf,.paragrafrange,.stk) h2,h3,h4,h5,h6,h7,h8": {
      fontSize: "1em",
      display: "inline-block",
      padding: "1em 0 0 0",
      margin: "0"
    },
    "& section section.paragraf, & section section.paragrafrange": {
      padding: "0.5em 0 0 0"
    },
    "& section section.stk": {
      padding: "0.5em 0 0 0"
    },
    "& section.paragraf": {
      "& div:first-of-type,& p:first-of-type": {
        display: "inline"
      },
      "& section.stk": {
        display: "inline-block",
        "&:first-of-type": {
          display: "inline"
        }
      }
    },
    "& section.ikraftcentreretparagraf,section.ikraft": {
      "& .ikrafcenpgfexplicatus": {
        textAlign: "center",
        display: "block",
        marginTop: "16px"
      },
      "& p:has(.ikrafcenpgfexplicatus)": {
        margin: 0
      },
      "& section.stk": {
        "& p:first-of-type,*:first-child": {
          display: "inline"
        }
      }
    },
    "& hr.ikraft": {
      width: "40%",
      border: "1px solid rgba(0, 0, 0, 0.2)",
      borderRadius: "1px"
    },
    "& blockquote div": {
      display: "block !important",
      marginBlockStart: "unset !important",
      marginBlockEnd: "unset !important"
    },
    // Relation chips should not have margin, and displayed inline to have correct height
    "& section section .relation-chips div:first-of-type": {
      marginBlockStart: "0em",
      marginBlockEnd: "0em",
      display: "inline-flex"
    },
    // popup notes should not have margin, and displayed inline to have correct height
    "& section section .editorial-notes-list-container div:first-of-type": {
      marginBlockStart: "0em",
      marginBlockEnd: "0em",
      display: "block"
    },
    "& .paragraf .section-with-menu, & .paragrafrange .section-with-menu": {
      display: "inline-block",
      width: "100%"
    },
    "& .paragraf h2+.section-with-menu, & .paragrafrange h2+.section-with-menu":
      {
        display: "inline"
      },
    "& .paragraf .section-with-menu ~ .section-with-menu, & .paragrafrange .section-with-menu ~ .section-with-menu":
      {
        display: "block"
      },
    "& .paragraf .stk ~ .stk, & .paragrafrange .stk ~ .stk": {
      display: "block"
    }
  }
}));

const useNonLawDocumentStyles = makeStyles(theme => ({
  root: {
    "& section section h2,h3,h4,h5,h6,h7,h8": {
      ...theme.typography.h2,
      fontFamily: "inherit"
    },
    "& section section section h2,h3,h4,h5,h6,h7,h8": {
      ...theme.typography.h3,
      fontFamily: "inherit"
    },
    "& section section section section h2,h3,h4,h5,h6,h7,h8": {
      ...theme.typography.h4,
      fontFamily: "inherit"
    },
    "& section section section section section h2,h3,h4,h5,h6,h7,h8": {
      ...theme.typography.h5,
      fontFamily: "inherit"
    },
    "& section section section section section section h2,h3,h4,h5,h6,h7,h8": {
      ...theme.typography.h6,
      fontFamily: "inherit",
      fontWeight: "bold"
    }
  }
}));

interface DocumentTypographyProps {
  isLawDocument?: boolean;
}

export const DocumentTypography: React.FC<DocumentTypographyProps> = ({
  isLawDocument,
  children
}) => {
  const classes = useStyles();
  const lawDocumentClasses = useLawDocumentStyles();
  const nonLawDocumentClasses = useNonLawDocumentStyles();

  return (
    <Typography
      variant="body1"
      display="block"
      component="main"
      classes={{
        root:
          classes.root +
          (isLawDocument
            ? " " + lawDocumentClasses.root
            : " " + nonLawDocumentClasses.root)
      }}
    >
      {children}
    </Typography>
  );
};
