import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Alert } from "../../ui-components/Alert";
import { Button } from "@material-ui/core";
import { AlertTitle } from "@material-ui/lab";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { usePortalApi } from "../../api-access/use-portal-api";
import {
  getPreviewNameFromUrl,
  clearPreview,
  updateSessionStorage
} from "./searchPreviewUtils";

/**
 * This component is intended as a self contained UI for displaying and removing a search preview.
 *
 * The interface to the AngularJs based application is through the sessionStorage value,
 * which is how search previews were originally implemented in the AngularJs application.
 *
 * When the search page is migrated to react we can change the way this state is managed.
 */
const SearchPreview: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const searchPreviewFromUrl = getPreviewNameFromUrl(location);

  const portalSearchPreviews = usePortalApi(
    client => client.getSearchPreviews(),
    ["getSearchPreviews"]
  );

  // If url parameter is not set we don't need to do anything
  if (!searchPreviewFromUrl) {
    return null;
  }

  // Validate if the preview exists
  const previewInfo = portalSearchPreviews.data?.find(
    x => x.name === searchPreviewFromUrl
  );

  if (previewInfo === undefined) {
    return (
      <>
        <br />
        <Alert severity="error">
          <Translate
            id={translationKeys.search.search_preview_validation_error}
          />{" "}
          ({searchPreviewFromUrl})
        </Alert>
      </>
    );
  }

  updateSessionStorage(previewInfo.name);

  return (
    <>
      <br />
      <Alert
        severity="warning"
        action={
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => clearPreview(location, history)}
          >
            <Translate id={translationKeys.search.search_preview_close} />
          </Button>
        }
      >
        <AlertTitle>
          <Translate id={translationKeys.search.search_preview_title} />
        </AlertTitle>
        <Translate id={translationKeys.search.search_preview_text} /> (
        {previewInfo.title ?? previewInfo.name})
      </Alert>
    </>
  );
};

export default SearchPreview;
