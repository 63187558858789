import { usePortalApi } from "../../api-access/use-portal-api";

// Should match the FeatureFlag constants in Portal.Domain/FeatureFlags.cs
export type FeatureFlag = "AiAssistant" | "AiAssistantSettings";

/*
  Going forward we should consider getting all feature flags at once, and rely on ReactQuery for caching
*/

export function useFeatureFlag(name: FeatureFlag) {
  const { data, isError, isLoading } = usePortalApi(
    client => client.getFeatureFlag(name),
    ["getFeatureFlag", name]
  );
  return { isEnabled: !!data, isLoading, isError };
}
