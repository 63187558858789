import { DocumentToolBarHeightInPixels } from "../../../shared/DocumentToolbar";
import { LegacyNavBarHeightInPixels } from "../../../../../legacy-app-support/LegacyGlobalCssOverrides";
import {
  PDFViewer,
  PdfViewerScrollDestinationOptions
} from "pdfjs-dist/legacy/web/pdf_viewer";

export function handleScrollToDestination(
  pdfViewer: PDFViewer | undefined,
  goToDestination: PdfPageDestination | undefined
) {
  if (!pdfViewer || !goToDestination) {
    return;
  }

  if (
    pdfViewer.currentPageNumber === goToDestination ||
    !destinationIsWithinRange(pdfViewer, goToDestination)
  ) {
    return;
  }

  const [pageNumber, pagePosition] =
    transformToPdfJsDestination(goToDestination);
  pdfViewer.scrollPageIntoView({
    pageNumber,
    destArray: pagePosition
  });
  window.scrollBy(
    0,
    -(LegacyNavBarHeightInPixels + DocumentToolBarHeightInPixels)
  );
}

function destinationIsWithinRange(
  pdfViewer: PDFViewer,
  goToDestination: PdfPageDestination
): boolean {
  const pagNumberToGoTo =
    typeof goToDestination === "number"
      ? goToDestination
      : goToDestination.pageNumber;
  return pagNumberToGoTo <= pdfViewer.pagesCount;
}

function isParametersInitialized(
  pdfViewer: PDFViewer | undefined,
  goToDestination: PdfPageDestination | undefined
) {
  return !pdfViewer || !goToDestination || pdfViewer.pagesCount < 1;
}

function transformToPdfJsDestination(
  destination: PdfPageDestination
): [number, PdfViewerScrollDestinationOptions?] {
  // if destination is just number we treat as just go to page
  if (typeof destination === "number") return [Number(destination)];

  const pdfJsDestination = transformToPagePositionPdfjsArgument(
    destination.destination
  );

  return [destination.pageNumber, pdfJsDestination];
}

// eslint-disable-next-line complexity
function transformToPagePositionPdfjsArgument(
  destination: PdfDestination
): PdfViewerScrollDestinationOptions | undefined {
  /**
   * This weird mapping code is to generate pdf page destinations array that Pdfjs is
   * expecting. Check PdfViewerVersion11534ScrollDestinationOptions jsdoc for further explanation
   *
   * Also read about pdf fittypes here
   * https://www.pdftron.com/api/PDFTronSDK/dotnet/pdftron.PDF.Destination.FitType.html
   */
  const { fitType, x, y, zoom } = destination;
  switch (fitType) {
    case "XYZ":
    case "Fit":
    case "FitB":
      return [undefined, { name: fitType }, x, y, zoom];
    case "FitH":
    case "FitBH":
    case "FitV":
    case "FitBV":
      return [undefined, { name: fitType }, y];
    case "FitR":
      // this is for fitting to rectangle bookmark on page
      // hard coded width and height to zero for now
      // because we need to expand api, to extract this information
      return [undefined, { name: fitType }, x, y, 0, 0];
  }
}

export enum FitTypes {
  XYZ = "XYZ",
  Fit = "Fit",
  FitB = "FitB",
  FitH = "FitH",
  FitBH = "FitBH",
  FitV = "FitV",
  FitBV = "FitBV",
  FitR = "FitR"
}

interface PdfDestination {
  fitType: FitTypes;
  x: number;
  y: number;
  zoom: number;
}

export type PdfPageDestination =
  | number
  | {
      pageNumber: number;
      destination: PdfDestination;
    };
