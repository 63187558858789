import * as React from "react";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import { useState } from "react";

interface Props {
  noteId: string;
  noteIndex: string;
  noteClass?: string;
}

export const InlineNote: React.FC<Props> = ({
  noteId,
  noteIndex,
  noteClass,
  children
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <span key={`inline-note-${noteId}`} id={`inline-note-${noteId}`}>
      <NoteOutlinedIcon
        className="inline-note-icon"
        fontSize="small"
        onClick={() => {
          setVisible(!visible);
        }}
      />
      {noteClass !== "eu" && (
        <span className="inline-note-number">{noteIndex}</span>
      )}
      {visible && (
        <span
          id={noteId}
          footnote-index={noteIndex}
          aria-expanded="false"
          className="inline-note"
        >
          {children}
        </span>
      )}
    </span>
  );
};
