import { useShouldAlwaysShowRelationsAction } from "../../../../../DocumentActionMenu/document-actions/relations-action/use-should-always-show-relations-action";
import { useDocumentInfo } from "../../../../../document-context";
import { useSectionHasRelations } from "../../../../shared/document-relation-groups/use-document-relation-groups";

// This list might need to be tweaked.
const eligebleSectionTypes = ["kapitel", "paragraf"];

// Relations actions (button) should be shown on a given section in the document if either;
//
// A) The section has relations
// B) The portal is configured to always show relation buttons, with portal property, because of performance reasons where it takes to long to determine were in document there are relations
//
// Option B only applies for specific document types though. Because a lot of different sections
// e.g. stk. might have a section action, but relations are never relevant for these.
export const useShouldShowRelationSectionAction = (
  sectionId: string,
  sectionClassName: string
) => {
  const { hiveId, fullName, taxons: documentTaxons } = useDocumentInfo();
  const { shouldAlwaysShowRelationsAction: shouldShowRelationsActionFast } =
    useShouldAlwaysShowRelationsAction(documentTaxons);
  const hasRelations = useSectionHasRelations(sectionId, hiveId, fullName);
  const isEligibleSection = eligebleSectionTypes.includes(sectionClassName);

  return (shouldShowRelationsActionFast && isEligibleSection) || hasRelations;
};
