import * as React from "react";
import { useEffect, useState } from "react";
import {
  useTrackElementInView,
  useRhinestoneXmlToReactParser,
  scrollToElement
} from "./rhinestone-document-util";
import { FoldableSectionProvider } from "./document-components/FoldableSection";
import { DocumentToolBarHeightInPixels } from "../shared/DocumentToolbar";
import { LegacyNavBarHeightInPixels } from "../../../legacy-app-support/LegacyGlobalCssOverrides";
import { ArticleAction } from "../../DocumentActionMenu";
import { useFindInDocumentContext } from "../../FindInDocumentStateProvider";
import { RhinestoneFindBar } from "../FindBar/RhinestoneFindBar";
import {
  SEARCH_MARK_OPTIONS,
  useMarkJs
} from "../../../../browser-utils/useMarkjs";
import { DocumentTypography } from "../shared/DocumentTypography";
import {
  RhinestoneTargetLocation,
  SectionAction
} from "./rhinestone-document-helper-types";
import { HtmlAnnotationState } from "../../annotations/html/html-annotation-state";
import { ContentBox } from "./ContentBox";
import RhinestoneDocumentContextMenu from "../../context-menu/RhinestoneDocumentContextMenu";
import DocumentHeader from "./document-components/DocumentHeader";

export interface DocumentProps {
  rhinestoneXml: string;
  highlights?: string[];

  /**
   * Inject components into the action-area of each section with [render props](https://reactjs.org/docs/render-props.html)
   */
  renderSectionActions?: SectionAction;

  /**
   * Inject components into top of document with [render props](https://reactjs.org/docs/render-props.html)
   */
  renderArticleActions?: ArticleAction;
  renderAdditionalDocumentInformation?: () => React.ReactNode;

  targetLocation?: RhinestoneTargetLocation;

  onTargetElementInView?: (element: Element) => void;
  isLawDocument?: boolean;

  annotationState?: HtmlAnnotationState;
  renderAnnotationsComments?: (
    documentRef: HTMLDivElement | null
  ) => React.ReactNode;
}

export const RhinestoneDocument = ({
  rhinestoneXml,
  highlights,
  renderArticleActions,
  renderSectionActions,
  renderAdditionalDocumentInformation,
  targetLocation,
  onTargetElementInView,
  isLawDocument,
  annotationState,
  renderAnnotationsComments
}: DocumentProps) => {
  const { rhinestoneDocumentContent, sectionAncestorMap } =
    useRhinestoneXmlToReactParser(
      rhinestoneXml,
      renderAdditionalDocumentInformation,
      renderArticleActions,
      renderSectionActions,
      annotationState
    );
  const [documentElement, setDocumentElement] = useState<HTMLDivElement | null>(
    null
  );

  const elementInViewPort = useTrackElementInView(
    documentElement,
    OffSetToUseForTrackingElementInView
  );

  const handleFoldingSectionScrollRequest = React.useCallback(
    (elementId: string) => {
      scrollToElement(documentElement, elementId);
    },
    [documentElement]
  );

  useEffect(() => {
    if (!elementInViewPort || !onTargetElementInView) return;
    onTargetElementInView(elementInViewPort);
  }, [elementInViewPort, onTargetElementInView]);

  useEffect(() => {
    // this ensure we scroll if location target is updated without url changing (target location contains unique key prop)
    // it handles scrolling on initial document load (which is not handled by browser native scroll)
    scrollToElement(documentElement, targetLocation?.hash);
  }, [documentElement, targetLocation]);

  useMarkJs(documentElement, highlights, SEARCH_MARK_OPTIONS);

  const hasHeader = (rhinestoneXml: string) => {
    const regex = /<header>.*?<\/header>/s;
    return regex.test(rhinestoneXml);
  };

  const [findBarState, setFindBarState] = useFindInDocumentContext();
  const userContext =
    annotationState?.context?.type === "user"
      ? annotationState.context
      : undefined;
  const assetCollectionContext =
    annotationState?.context?.type === "asset-collection"
      ? annotationState.context
      : undefined;

  const addAnnotation = annotationState?.addAnnotation;
  const isShown = annotationState?.isShown;
  return (
    <>
      {findBarState.findBarVisible && (
        <div>
          <RhinestoneFindBar
            onClose={() => {
              setFindBarState({ findBarVisible: false });
            }}
          />
        </div>
      )}
      <FoldableSectionProvider
        targetLocation={targetLocation}
        sectionIdMap={sectionAncestorMap}
        onScrollElementIntoView={handleFoldingSectionScrollRequest}
      >
        <ContentBox ref={setDocumentElement}>
          {React.useMemo(() => {
            return (
              <RhinestoneDocumentContextMenu
                userContext={userContext}
                assetCollectionContext={assetCollectionContext}
                addAnnotation={addAnnotation}
                isShown={!!isShown}
                documentRef={documentElement}
              >
                <DocumentTypography isLawDocument={isLawDocument}>
                  {!hasHeader(rhinestoneXml) && (
                    <DocumentHeader
                      index={1}
                      notNote={[]}
                      note={[]}
                      renderArticleActions={renderArticleActions}
                      renderAdditionalDocumentInformation={
                        renderAdditionalDocumentInformation
                      }
                    />
                  )}
                  {rhinestoneDocumentContent}
                </DocumentTypography>
                {renderAnnotationsComments?.(documentElement)}
              </RhinestoneDocumentContextMenu>
            );
          }, [
            documentElement,
            isLawDocument,
            rhinestoneDocumentContent,
            renderAnnotationsComments,
            userContext,
            assetCollectionContext,
            addAnnotation,
            isShown,
            rhinestoneXml,
            renderAdditionalDocumentInformation,
            renderArticleActions
          ])}
        </ContentBox>
      </FoldableSectionProvider>
    </>
  );
};

/**
 * This number is used to offset when we decide an element in document "is in view"
 *
 * It it set to something similar as scrollMargin/scrollPaddings on html tag to account for the fixed navbar and document toolbar
 * and then plus something so it "feels" nice.
 *
 * It should be something that fits with scrollMargin/scrollpadding which is during deeplinking/toc navigation scenarios
 * so it behaves nicely with built in browser anchor scrolling
 */
const OffSetToUseForTrackingElementInView =
  LegacyNavBarHeightInPixels + DocumentToolBarHeightInPixels + 20;
