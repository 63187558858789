import * as React from "react";
import { FC } from "react";
import AiSearchInputField from "./AiSearchInputField";
import AiResponseStream from "./AiResponseStream";
import {
  AiCompletionNamedConfigurationDto,
  AiCompletionOptionsDto,
  ChatMessageDto,
  ChatResponseSourceDto
} from "@rhinestone/portal-web-api";
import { makeStyles } from "@material-ui/core";
import PreviousPrompt from "./PreviousPrompt";
import AiSearchSettingsButton from "./ai-settings/AiSearchSettingsButton";
import AiSearchSettings from "./ai-settings/AiSearchSettings";
import ChatHistory from "./ChatHistory";
import AiResponseContainer from "./AiResponseContainer";
import AiConfigurationSelect from "./AiConfigurationSelect";
import TypingAnimation from "./TypingAnimation";
import FeatureFlag from "../feature-flags/feature-flag/FeatureFlag";
import ResponseFeedback from "./ResponseFeedback";
import AllSources from "./AllSources";

interface AiAssistantProps {
  configKey: string | undefined;
  configurations: AiCompletionNamedConfigurationDto[];
}

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: "flex"
  }
}));

const AiAssistant: FC<AiAssistantProps> = ({ configKey, configurations }) => {
  const classes = useStyles();

  const [prompt, setPrompt] = React.useState(
    "Hvor meget ferie optjener man på en måned?"
  );
  const [previousPrompt, setPreviousPrompt] = React.useState("");
  const [response, setResponse] = React.useState("");
  const [citations, setCitations] = React.useState<ChatResponseSourceDto[]>([]);
  const [chatHistory, setChatHistory] = React.useState<ChatMessageDto[]>([]);
  const [isStreaming, setIsStreaming] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState<boolean>(false);
  const [completionConfiguration, setCompletionConfiguration] =
    React.useState<AiCompletionNamedConfigurationDto>(
      configKey
        ? configurations.find(x => x.key === configKey) ?? configurations[0]
        : configurations[0]
    );
  const [usedCitationIndexes, setUsedCitationIndexes] = React.useState<
    number[]
  >([]);

  const doAiSearch = () => {
    if (prompt.length === 0) return;
    setIsStreaming(true);
    setResponse("");
    setPreviousPrompt(prompt);
    setCitations([]);
  };

  const handleResponseChanged = (response: string) => {
    setResponse(prev => prev + response);
  };

  const handleStreamingDone = () => {
    setIsStreaming(false);
    setPrompt("");
  };

  const setCompletionOptions = (options: AiCompletionOptionsDto) => {
    setCompletionConfiguration({
      ...completionConfiguration,
      options
    });
  };

  return (
    <>
      <FeatureFlag name={"AiAssistantSettings"}>
        <AiConfigurationSelect
          completionConfigurations={configurations}
          selectedConfig={completionConfiguration}
          onConfigurationChanged={setCompletionConfiguration}
        />
      </FeatureFlag>
      <div className={classes.searchBar}>
        <AiSearchInputField
          prompt={prompt}
          isStreaming={isStreaming}
          doAiSearch={doAiSearch}
          handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPrompt(event.target.value)
          }
        />
        <FeatureFlag name={"AiAssistantSettings"}>
          <AiSearchSettingsButton
            toggleShowSettings={() => setShowSettings(!showSettings)}
          />
        </FeatureFlag>
      </div>
      <AiSearchSettings
        isVisible={showSettings}
        settings={completionConfiguration.options}
        updateSettings={setCompletionOptions}
      />
      <TypingAnimation isStreaming={isStreaming} />
      {isStreaming && (
        <AiResponseStream
          query={prompt}
          onCitationsReceived={setCitations}
          onResponseChanged={handleResponseChanged}
          onStreamingDone={handleStreamingDone}
          settings={completionConfiguration}
          onChatHistoryReceived={setChatHistory}
        />
      )}
      <PreviousPrompt prompt={previousPrompt} />
      <AiResponseContainer
        response={response}
        isStreaming={isStreaming}
        registerUsedCitationIndexes={setUsedCitationIndexes}
        citations={citations}
        usedCitationIndexes={usedCitationIndexes}
      />
      <ResponseFeedback response={response} isStreaming={isStreaming} />
      <FeatureFlag name={"AiAssistantSettings"}>
        <br />
        <AllSources citations={citations} isStreaming={isStreaming} />
        <ChatHistory chatHistory={chatHistory} isStreaming={isStreaming} />
      </FeatureFlag>
    </>
  );
};

export default AiAssistant;
