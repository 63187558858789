import * as React from "react";
import {
  FormControlLabel,
  FormGroup,
  FormControl,
  Checkbox,
  Tooltip
} from "@material-ui/core";

export interface CheckboxListProps {
  items: {
    key: string;
    label: string;
    checked: boolean;
    disabled?: boolean;
    disabledTooltip?: React.ReactNode;
  }[];
  onChange: (key: string, checked: boolean) => void;
}

export const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  onChange
}) => {
  if (items.length === 0) return null;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.name, event.target.checked);
  };

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {items.map((item, index) => (
          <Tooltip
            key={index}
            title={
              item.disabled && item.disabledTooltip ? item.disabledTooltip : ""
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.checked}
                  name={item.key}
                  onChange={handleChange}
                  disabled={item.disabled}
                />
              }
              label={item.label}
            />
          </Tooltip>
        ))}
      </FormGroup>
    </FormControl>
  );
};
