import React, { useCallback, useRef, useState } from "react";
import { DocumentTitle } from "../shared/DocumentTitle";
import { Fade, makeStyles } from "@material-ui/core";
import { MergeSectionViewModel } from "@rhinestone/portal-web-api";
import HtmlRenderer from "../../../ui-components/HtmlRenderer";
import { ArticleAction } from "../../DocumentActionMenu";
import { RelativeMergeLocation } from "@rhinestone/portal-web-api";
import { PdfFindBar } from "../FindBar/PdfFindBar";
import { useFindInDocumentContext } from "../../FindInDocumentStateProvider";
import { LocalNote } from "../shared/LocalNote";
import {
  SEARCH_MARK_OPTIONS,
  useMarkJs
} from "../../../../browser-utils/useMarkjs";
import { processLinks } from "../rhinestone-xml/processing-instructions/process-links";

export interface PdfContentProps {
  title: React.ReactNode;
  additionalDocumentInformation?: JSX.Element;
  renderArticleActions?: ArticleAction;
  localNotes?: MergeSectionViewModel[];
  highlights?: string[];
  children: (findBarElement: HTMLElement | undefined) => React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  pdfContent: {
    // make room for pdf annotations comments in margin so outer content doesn't overflow
    // TODO: Review if this should be changed when upgrading pdfjs. Depends on chosen scroll solution
    [theme.breakpoints.up("sm")]: {
      marginRight: "45px"
    },
    display: "flex",
    flexDirection: "column",
    "& mark": {
      padding: 0,
      backgroundColor: "yellow"
    },
    "& mark.selected": {
      backgroundColor: "orange"
    }
  }
}));

export const PdfContent: React.FC<PdfContentProps> = ({
  title,
  additionalDocumentInformation,
  renderArticleActions,
  highlights,
  localNotes = [],
  children
}) => {
  // Not sure if this is the correct way, but we need to update props on PdfViewer when FindBar is rendered
  // and it seemed like the way to do it, to pass a callback to ref prop. It will be called when DOM element changes for example is rendered
  const [findBarElement, setFindBarElement] = useState<HTMLElement>();
  const findBarRefCallback = useCallback(node => {
    setFindBarElement(node);
    return node;
  }, []);

  const classes = useStyles();
  const [findBarState, setFindBarState] = useFindInDocumentContext();
  const documentTitleRef = useRef<HTMLElement>(null);

  // This is used to highlight in the title as the title is rendered independent of the content
  useMarkJs(documentTitleRef.current, highlights, SEARCH_MARK_OPTIONS);

  return (
    <div className={classes.pdfContent}>
      <Fade in={findBarState.findBarVisible}>
        <div>
          <PdfFindBar
            ref={findBarRefCallback}
            onClose={() => {
              setFindBarState({ findBarVisible: false });
            }}
          />
        </div>
      </Fade>

      <DocumentTitle ref={documentTitleRef}>{title}</DocumentTitle>
      {renderArticleActions?.({})}
      {additionalDocumentInformation}
      {renderLocalNotes(localNotes, ["Before", "AfterBegin", "AfterHeading"])}
      {children(findBarElement)}
      {renderLocalNotes(localNotes, ["After", "BeforeEnd"])}
    </div>
  );
};

function renderLocalNotes(
  notes: MergeSectionViewModel[],
  location: RelativeMergeLocation[]
) {
  return notes
    .filter(x => location.includes(x.relativeMergeLocation))
    .map(x => (
      <LocalNote key={x.sectionId}>
        <HtmlRenderer html={x.text} processingInstructions={[processLinks()]} />
      </LocalNote>
    ));
}
