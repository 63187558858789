import * as React from "react";
import { ChatBubbleIcon } from "../../ui-components/icons";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { AnnotationLayoutState } from "./annotation-layout-state";

export interface AnnotationIndicatorProps<T extends AnnotationModel> {
  visible: boolean;
  annotation: T;
  layoutState: AnnotationLayoutState;
}

export const AnnotationActiveIndicator = <T extends AnnotationModel>({
  visible,
  annotation,
  layoutState
}: AnnotationIndicatorProps<T>) => {
  const layout = layoutState[annotation.id];
  return visible && layout ? (
    <ChatBubbleIcon
      color="secondary"
      opacity={0.6}
      style={{
        position: "absolute",
        left: layout.x,
        top: layout.y - 24
      }}
    />
  ) : null;
};
