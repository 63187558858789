import * as React from "react";
import { Accordion } from "../../ui-components/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MenuItem, Select } from "@material-ui/core";
import { useState } from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface PreparatoryWorkTimelineAccordionProps {
  labels: string[];
  defaultExpanded: boolean;
  timelineKey: string;
  handlePathSelection: (key: string, value: string) => void;
}

const useStyles = makeStyles(theme => ({
  accordion: {
    margin: "0 !important"
  }
}));

export const PreparatoryWorkTimelineAccordion: React.FC<PreparatoryWorkTimelineAccordionProps> =
  ({ labels, defaultExpanded, timelineKey, handlePathSelection, children }) => {
    const classes = useStyles();
    const handleChange = (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      handlePathSelection(timelineKey, "" + event.target.value);
    };
    const DropDown = () => {
      return (
        <Select
          fullWidth
          labelId="select"
          id="select"
          value={timelineKey}
          displayEmpty
          onChange={handleChange}
          onClick={(event: any) => event.stopPropagation()} // To stop the accordion from expanding then selecting
        >
          <MenuItem disabled value={""}>
            <Translate
              id={translationKeys.document_page.select_timeline_path}
            ></Translate>
          </MenuItem>
          {labels.map(x => (
            <MenuItem key={x} value={x}>
              {x}
            </MenuItem>
          ))}
        </Select>
      );
    };
    return (
      <Accordion
        className={classes.accordion}
        square
        label={labels.length > 1 ? <DropDown /> : labels[0]}
        expandIcon={<ExpandMoreIcon />}
        defaultExpanded={defaultExpanded}
        backgroundColor={"transparent"}
        elevate={false}
      >
        {children}
      </Accordion>
    );
  };
