import * as React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import TocElementList from "./TocElementList";

export interface TocItemData {
  target: string;
  heading: string;
  nodeId: string;
  children: TocItemData[];
}

interface TocTreeViewProps {
  items: TocItemData[];
  expandedNodeIds?: string[];
  selectedNodeIds?: string[];
  onSelectedNodeIdChange?: (nodeId: string) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0px 8px 16px 0",
    marginLeft: -4,
    overflowX: "hidden"
  }
}));

/**
 * TocTreeView is a lightweight component made specifically for showing dynamic a table of content for
 * documents with up to 1000s of entries.
 *
 * The interface structure matches that the TreeView ui-component, so can be combined if performance
 * is ever improved for the underlying material-ui lab-component TreeView.
 */
export const TocTreeView = React.forwardRef<HTMLDivElement, TocTreeViewProps>(
  (
    {
      items,
      expandedNodeIds,
      selectedNodeIds,
      onSelectedNodeIdChange = () => null
    },
    ref
  ) => {
    const classes = useStyles();

    const tree = React.useMemo(
      () => (
        <TocElementList
          children={items}
          expandedNodeIds={expandedNodeIds}
          selectedNodeIds={selectedNodeIds}
          onSelectedNodeIdChange={onSelectedNodeIdChange}
        />
      ),
      [items, expandedNodeIds, selectedNodeIds, onSelectedNodeIdChange]
    );

    return (
      <div ref={ref} className={clsx(classes.root)} data-test-id="document-toc">
        {tree}
      </div>
    );
  }
);
