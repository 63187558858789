import * as React from "react";
import ExistingAnnotationContextMenu from "../../../context-menu/ExistingAnnotationContextMenu";
import getAnnotationHighlightClassName from "../../../annotations/annotation-highlight";
import { HtmlAnnotationState } from "../../../annotations/html/html-annotation-state";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { AnnotationState } from "../../../annotations/annotation-state";

interface HtmlAnnotationProps {
  id: string;
  annotationState: HtmlAnnotationState | undefined;
}
export const HtmlAnnotation: React.FC<HtmlAnnotationProps> = ({
  id,
  annotationState,
  children
}) => {
  const { isShown } = annotationState || {};
  return (
    <ExistingAnnotationContextMenu
      annotationState={
        annotationState as unknown as AnnotationState<AnnotationModel, never>
      }
      annotationId={id}
    >
      <span
        className={
          isShown && annotationState
            ? getAnnotationTypeClassName(id, annotationState)
            : undefined
        }
      >
        {children}
      </span>
    </ExistingAnnotationContextMenu>
  );
};

function getAnnotationTypeClassName(
  id: string,
  { annotations }: HtmlAnnotationState
) {
  const annotation = annotations?.find(a => a.annotation.id === id);
  return getAnnotationHighlightClassName(annotation);
}
