import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { useEffect, useState } from "react";
import {
  LoadedTranslationItem,
  useAvailableTranslationsOnPortal,
  useInitializeReactLocalizeRedux,
  useLoadingOfSingleTranslationFile,
  updateLoadedTranslationAndNotLoadedState,
  useMemoizedReactLocalizeReduxCallbacks
} from "./translation-helpers";
import { LegacyAngularJsTranslationService } from "../shellConfigurationTypes";
import { PortalLocalizationModel } from "@rhinestone/portal-web-api";
interface TranslationContainerProps {
  legacyTranslationService: LegacyAngularJsTranslationService;
}
type Props = TranslationContainerProps & LocalizeContextProps;

/**
 * This component handles loading and setting currentlocale in transition period
 *
 * Its unfortunately way complex and there could be found a better way, maybe initialize this thing in another way
 *
 * It would be greatly simplified if we added an endpoint to fetch all translations add once
 *
 * However, existing angularjs is lazy loading the translation files, and we are mimicking this here.
 *
 * couldn't find a way to pass down translation files fetched in angularjs code to shell as props, so that is why we are re-fetching here
 *
 */
const TranslationContainer: React.FC<Props> = ({
  legacyTranslationService,
  initialize,
  activeLanguage,
  setActiveLanguage,
  addTranslationForLanguage,
  children
}) => {
  // This is an ugly way to read a value directly from Legacy Angular solution.
  // This may or may not be initialized and does not re-render when locale changes.
  // TODO: Setup some kind of observer here that can subscribe to language changes/load.
  const currentLocale = legacyTranslationService.use();

  const [
    memoizedInitialize,
    memoizedAddTranslationForLanguage,
    memoizedSetActiveLanguage
  ] = useMemoizedReactLocalizeReduxCallbacks(
    initialize,
    addTranslationForLanguage,
    setActiveLanguage
  );

  // track state of loaded languages from server
  const [loadedLanguages, setLoadedLanguages] = useState<
    LoadedTranslationItem[]
  >([]);

  // track state of translation files not loaded yet
  const [notLoadedLanguageCodes, setNotLoadedLanguageCodes] = useState<
    string[]
  >([]);

  // loading available translations
  const translationsAvailableOnPortal = useAvailableTranslationsOnPortal();

  // initializing react-localize-redux and start fetching translations
  useInitializeReactLocalizeRedux(
    translationsAvailableOnPortal,
    memoizedInitialize,
    setNotLoadedLanguageCodes
  );

  // fetching translation files one by one
  const fetchedTranslationFile = useLoadingOfSingleTranslationFile(
    notLoadedLanguageCodes
  );

  // when a translation file is fetched we update state and react-localize-redux
  useEffect(() => {
    if (!fetchedTranslationFile) return;
    updateLoadedTranslationAndNotLoadedState(
      fetchedTranslationFile,
      setNotLoadedLanguageCodes,
      setLoadedLanguages
    );
  }, [fetchedTranslationFile]);

  // handle current locale changes and translations loaded from server
  useEffect(() => {
    // we don't even know which translations exists, what current locale is or languages are loaded yet
    if (!translationsAvailableOnPortal || !loadedLanguages || !currentLocale)
      return;

    // validate if currentLocale matches available locales at all
    validateCurrentLocale(translationsAvailableOnPortal, currentLocale);

    const loadedTranslation = getLoadedLanguageFromCurrentLocale(
      loadedLanguages,
      currentLocale
    );

    if (loadedTranslation) {
      // add translation item to react-localize-redux
      memoizedAddTranslationForLanguage(
        loadedTranslation.translation,
        loadedTranslation.lang
      );
    }

    // ensure active language is set in case currentLocale changes
    console.debug("Setting active language in React shell: ", currentLocale);
    memoizedSetActiveLanguage(currentLocale);
  }, [
    translationsAvailableOnPortal,
    currentLocale,
    loadedLanguages,
    memoizedAddTranslationForLanguage,
    memoizedSetActiveLanguage
  ]);

  // We opt to show blank screen (or blank area if just rendering a single component tree) if currentLocale doesn't match any currently loaded languages
  // This is to prevent component hierarchy to render, and show ugly translations keys.
  // A warning is logged in useEffect to report any misconfiguration or local storage problems (angularjs saves last selected language in local storage)
  // in cases where currentLocale is set to something not even available on the portal
  return !getLoadedLanguageFromCurrentLocale(
    loadedLanguages,
    currentLocale
  ) ? null : (
    <>{children}</>
  );
};

function validateCurrentLocale(
  translationsAvailableOnPortal: PortalLocalizationModel[],
  currentLocale: string
) {
  if (!translationsAvailableOnPortal.find(t => t.locale === currentLocale))
    console.warn(
      `Selected current locale '${currentLocale}' doesn't match any available locales on portal:`,
      translationsAvailableOnPortal
    );
}

function getLoadedLanguageFromCurrentLocale(
  loadedLanguages: LoadedTranslationItem[],
  currentLocale: string
) {
  return loadedLanguages.find(l => l.lang === currentLocale);
}

export default withLocalize(TranslationContainer);
