import * as React from "react";
import { makeStyles, Popper, Grow } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "../../../../ui-components/IconButton";
import { CardHeader, Card, CardContent } from "../../../../ui-components/Card";
import { Scrollbar } from "../../../../ui-components/Scrollbar";

interface EditorialNoteListProps {
  title?: React.ReactNode;
  subheader?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  anchorEl: HTMLElement | null;
  children?: React.ReactElement | React.ReactElement[];
}

const useStyles = makeStyles(theme => ({
  scrollbarDiv: {
    height: "70vh",
    overflowY: "hidden",
    backgroundColor: "transparent",
    "& .scrollbar-container": {
      "& .ps__rail-y": {
        margin: "16px"
      }
    }
  },
  card: {
    width: 280,
    overflowY: "hidden",
    background: theme.highlightColor,
    margin: "16px",

    "& hr": {
      margin: "10px -16px"
    },
    " & .MuiCardContent-root": {
      paddingTop: "0"
    }
  },
  cardHeader: {
    "& .MuiCardHeader-content": {
      width: "100%"
    }
  }
}));

export const editorialNoteListClassName = "editorial-notes-list-container";
export const EditorialNoteList = React.forwardRef<
  HTMLDivElement,
  EditorialNoteListProps
>(({ title, subheader, onClose, className, anchorEl, children }, ref) => {
  const classes = useStyles();

  return (
    <Popper
      open={Boolean(anchorEl)}
      disablePortal
      anchorEl={anchorEl}
      placement="right-start"
      style={{ zIndex: 25 }}
      transition
      className={editorialNoteListClassName}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "0 0 0" }}>
          <div className={classes.scrollbarDiv}>
            <Scrollbar suppressScrollX>
              <Card ref={ref} className={`${classes.card} ${className}`}>
                <CardHeader
                  action={
                    <IconButton onClick={onClose} size="small">
                      <CloseIcon />
                    </IconButton>
                  }
                  title={title}
                  className={classes.cardHeader}
                  subheader={subheader}
                ></CardHeader>
                <CardContent>{children}</CardContent>
              </Card>
            </Scrollbar>
          </div>
        </Grow>
      )}
    </Popper>
  );
});
