import React, { useEffect } from "react";
import NoDataWarningTabContent from "./NoDataWarningTabContent";
import { sectionDataReducer } from "./helper-functions/section-data-reducer";

import { TabContentsProps } from "./SectionTextTabContents";
import { SpecialRemarksContents } from "./SpecialRemarksContents";
import { SpecialRemarksTabInnerContentsFetcher } from "./SpecialRemarksTabInnerContentsFetcher";
import { useReportSectionData } from "./helper-functions/use-report-section-data";
import { getInitialSections } from "./helper-functions/get-initial-sections";
import { SectionPayload } from "./helper-functions/SectionPayload";

const SpecialRemarksTabContents: React.FC<TabContentsProps> = props => {
  const { hiveId, fullName, targetBookmarks } =
    props.selectedChangeRelation || {};
  const initialSectionData: { [key: string]: SectionPayload } =
    getInitialSections(targetBookmarks);

  const [sectionData, setSectionData] = React.useReducer(
    sectionDataReducer,
    initialSectionData
  );

  useEffect(() => {
    setSectionData({
      type: "setUsedKeys",
      payload: targetBookmarks || []
    });
  }, [targetBookmarks]);

  const reportDataState = useReportSectionData(setSectionData);

  return (
    <>
      {hiveId && fullName && targetBookmarks ? (
        targetBookmarks.map((bookmark, index) => (
          <SpecialRemarksTabInnerContentsFetcher
            hiveId={hiveId}
            fullName={fullName}
            paragraphId={bookmark}
            key={index}
            onReportDataState={reportDataState}
          />
        ))
      ) : (
        <NoDataWarningTabContent />
      )}
      <SpecialRemarksContents
        sectionData={sectionData}
        headerLinkText={props.headerLinkText}
      />
    </>
  );
};

export default SpecialRemarksTabContents;
