import {
  ConsolidationAndPageConfig,
  useConsolidationAndPageConfig
} from "./preparatory-work-helpers";
import { useGetTargetRelations } from "./use-get-target-relations";

export function useGetPrepatoryWorkTimelines(
  hiveId: string | undefined = undefined,
  fullName: string | undefined = undefined,
  getRelationTypeCode: (config: ConsolidationAndPageConfig) => string,
  bookmarkId?: string,
  getTargetRelations = useGetTargetRelations,
  consolidationAndPageConfig = useConsolidationAndPageConfig
): {
  timelineDocuments: {
    hiveId: string;
    fullName: string;
  }[];
  isLoading: boolean;
} {
  const config = consolidationAndPageConfig();
  const { preparationRelationTypeCode } = config;

  const relationTypeCode = getRelationTypeCode(config);

  const [changeLaws, isLoadingChangeLaws] = getTargetRelations({
    hiveId,
    fullName,
    relationTypeCode,
    bookmark: bookmarkId
  });
  const [preparationRelations, isLoadingPreparationRelations] =
    getTargetRelations({
      hiveId,
      fullName,
      relationTypeCode: preparationRelationTypeCode
    });

  const consolidatedTimelineDocuments = [
    ...changeLaws,
    ...preparationRelations
  ].filter(
    (item, pos, self) =>
      self.findIndex(
        v => v.hiveId === item.hiveId && v.fullName === item.fullName
      ) === pos
  );
  const initialTimelineDocuments =
    consolidatedTimelineDocuments && consolidatedTimelineDocuments.length > 0
      ? consolidatedTimelineDocuments
      : hiveId && fullName
      ? [{ hiveId, fullName }]
      : [];
  return {
    timelineDocuments: initialTimelineDocuments,
    isLoading: isLoadingChangeLaws || isLoadingPreparationRelations
  };
}
