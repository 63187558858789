import { Icon, IconButton } from "@material-ui/core";
import * as React from "react";
import LawZoomModal from "./LawZoomModal";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

const LawZoomAction: React.FunctionComponent = props => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <>
      <Translate>
        {({ translate }) => (
          <IconButton
            {...props}
            size="small"
            title={translate(translationKeys.law_zoom.title).toString()}
            onClick={() => setShowModal(true)}
          >
            <Icon className="fa fa-binoculars" fontSize="small" />
          </IconButton>
        )}
      </Translate>

      <LawZoomModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default LawZoomAction;
