import { SectionPayload } from "./SectionPayload";

export const sectionDataReducer = (
  state: {
    [key: string]: SectionPayload;
  },
  action:
    | {
        type: "set";
        payload: {
          [key: string]: SectionPayload;
        };
      }
    | {
        type: "setUsedKeys";
        payload: string[];
      }
) => {
  switch (action.type) {
    case "setUsedKeys":
      return Object.keys(state).reduce((acc, key) => {
        if (action.payload.includes(key)) {
          acc[key] = state[key];
        }
        return acc;
      }, {} as { [key: string]: SectionPayload });
    case "set":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
