import * as React from "react";
import TocIcon from "@material-ui/icons/Toc";
import { TocContainer } from "../Toc";
import { SearchInTocContainer } from "../SearchInToc";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { useTocData } from "../Toc/toc-helpers";
import TocWrapper from "./TocWrapper";
import { PaneItem } from "../../ui-components/layouts/ThreePanesLayout";

export function useToc(
  currentDocumentElementInView: Element | undefined
): PaneItem | null {
  const { data, isLoading } = useTocData();
  return React.useMemo(() => {
    if (!isLoading && !data?.entries?.length) return null;
    const seachElement = <SearchInTocContainer />;
    const tocPane = {
      icon: <TocIcon />,
      key: "toc",
      title: <Translate id={translationKeys.document_page.table_of_contents} />,
      content: (
        // Table of content should leave some white-space on larger screen
        <TocWrapper seachElement={seachElement}>
          <TocContainer
            currentElementInView={currentDocumentElementInView}
            data={data}
            isLoading={isLoading}
          />
        </TocWrapper>
      )
    };
    return tocPane;
  }, [currentDocumentElementInView, data, isLoading]);
}
