import React from "react";
import { useDocumentRelationGroupsForBookmark } from "../use-document-relation-groups";
import { useDocumentInfo } from "../../../../document-context";
import RelationsDialogContainer from "./RelationsDialogContainer";
import { RelationQueryType } from "../RelationChips/RelationQueryType";
import { useRelationSearchUrl } from "../RelationChips/use-get-relation-search-url";

export interface SectionRelationDialogProps {
  sectionId: string;
  groupName: string;
}

export const SectionRelationDialog: React.FC<SectionRelationDialogProps> = ({
  sectionId,
  groupName
}) => {
  const { hiveId, fullName } = useDocumentInfo();

  const { data, status } = useDocumentRelationGroupsForBookmark(
    true,
    hiveId,
    fullName,
    sectionId
  );
  const searchUrl = useRelationSearchUrl(
    hiveId,
    fullName,
    RelationQueryType.Bookmark,
    sectionId,
    groupName
  );
  return (
    <RelationsDialogContainer
      data={data}
      status={status}
      groupName={groupName}
      openLinksInNewTab={true}
      searchUrl={searchUrl}
    />
  );
};
