import React from "react";
import { makeStyles, fade } from "@material-ui/core/styles";

interface WatermarkProps {
  label: string;
  color: string;
}

const useStyles = makeStyles(theme => {
  return {
    absolutePositionContainer: {
      position: "absolute",
      right: "360px",
      top: 0
    },
    fixedPositionInnerContainer: {
      position: "fixed",
      width: "360px",
      height: "360px",
      overflow: "hidden",
      userSelect: "none",
      pointerEvents: "none",
      zIndex: 50
    },
    statusText: {
      borderBottom: `2px ${fade(theme.palette.status.unknown.main, 0.6)} solid`,
      borderTop: `2px ${fade(theme.palette.status.unknown.main, 0.6)} solid`,
      borderColor: ({ color }: Pick<WatermarkProps, "color">) =>
        fade(color, 0.6),
      fill: ({ color }: Pick<WatermarkProps, "color">) => fade(color, 0.4),
      position: "relative",
      right: "-98px",
      fontSize: "10px",
      fontWeight: "bold",
      maxHeight: "60px",
      padding: "0 112px",
      textTransform: "uppercase",
      transform: "rotate(45deg) translate(0, 150px)",
      transformOrigin: "top center",
      width: "525px"
    }
  };
});

export const Watermark: React.FC<WatermarkProps> = ({ label, color }) => {
  const labelWidth = label.length * 6;
  const classes = useStyles({ color });

  return (
    <div className={classes.absolutePositionContainer}>
      <div className={classes.fixedPositionInnerContainer}>
        <svg
          className={`${classes.statusText}`}
          viewBox={`0 0 ${labelWidth} 13`}
        >
          <text x="0" y="10" textLength={labelWidth}>
            {label}
          </text>
        </svg>
      </div>
    </div>
  );
};
