import * as React from "react";
import { useEffect, useState } from "react";
import { useDocumentRelationGroupsForBookmark } from "../../content/shared/document-relation-groups/use-document-relation-groups";
import { RelationWrapper } from "../../content/shared/document-relation-groups/RelationChips/RelationWrapper";
import { RelationsButton } from "../../content/shared/document-relation-groups/RelationsButton";

interface ParagraphRelationsProps {
  hiveId: string;
  fullName: string;
  paragraphId: string;
}

export const ParagraphRelations: React.FC<ParagraphRelationsProps> = ({
  hiveId,
  fullName,
  paragraphId
}) => {
  const [expanded, setExpanded] = useState(false);

  // when paragraphInTime changed make sure to close relations chips
  useEffect(() => {
    setExpanded(false);
  }, [hiveId, fullName]);

  const { data, status } = useDocumentRelationGroupsForBookmark(
    true,
    hiveId,
    fullName,
    paragraphId
  );

  return (
    <>
      <RelationsButton
        expanded={expanded}
        setExpanded={setExpanded}
        status={status}
        hasRelations={data && data.length > 0}
      />
      {expanded && (
        <RelationWrapper
          expanded={expanded}
          setExpanded={setExpanded}
          relationData={data}
          sectionId={paragraphId}
        />
      )}
    </>
  );
};
