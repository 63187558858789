import { Rect } from "./rect";

export class PdfSelection {
  public StartX: number;
  public StartY: number;
  public EndX: number;
  public EndY: number;

  constructor(rect: Rect, public PageIndex: number) {
    this.StartX = rect.startX;
    this.StartY = rect.startY;
    this.EndX = rect.endX();
    this.EndY = rect.endY();
  }
}
