import { Link } from "@material-ui/core";
import React from "react";

interface TileTitleProps {
  link?: string;
  name: string;
}
const TileTitle: React.FC<TileTitleProps> = ({ link, name }) => {
  return link ? (
    <Link href={link} color="inherit">
      {name}
    </Link>
  ) : (
    <>{name}</>
  );
};

export default TileTitle;
