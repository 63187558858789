import { getDocumentUrl } from "../document-page/document-page-helpers";
import { RelationTreeData } from "./RelationTreeData";
import { RelationItemModel } from "./RelationItemModel";

export function parseRelationPath(
  paths: RelationItemModel[][],
  currentDocumentHiveId: string,
  currentDocumentFullName: string
) {
  const reversedPaths = paths.map(path => path.reverse());

  const relationTreeData: RelationTreeData = {
    title: "",
    paths: []
  };

  if (reversedPaths.length > 0) {
    relationTreeData.paths = reversedPaths.map(item => ({
      title: item?.[0]?.title,
      pathElements: item.map((pathElement, index) => {
        return (
          pathElement && {
            title: pathElement.label,
            hoverText: pathElement.fullName,
            link: getDocumentUrl({
              hiveId: pathElement.hiveId,
              fullName: pathElement.fullName
            }),
            selected:
              pathElement.hiveId === currentDocumentHiveId &&
              pathElement.fullName === currentDocumentFullName,
            splits:
              pathElement.split && (pathElement?.split.length || 0) > 1
                ? pathElement.split
                    .filter(
                      splitElement =>
                        splitElement.hiveId !== item[index - 1]?.hiveId ||
                        splitElement.fullName !== item[index - 1]?.fullName
                    )
                    .map(split => ({
                      title: split.label,
                      hoverText: split.fullName,
                      link: getDocumentUrl({
                        hiveId: split.hiveId,
                        fullName: split.fullName
                      }),
                      hasChildren: !!split.hasChildren
                    }))
                : []
          }
        );
      })
    }));
  }
  return relationTreeData;
}
