import { QueryStatus } from "react-query";
import { usePortalApi } from "../api-access/use-portal-api";

type PortalPropertyName =
  | "EnablePrepChain"
  | "UseAdvancedSendLinkForm"
  | "HideDocumentSubscriptionAlert"
  | "ShowAllRelations"
  | "HideRelationsActionButton"
  | "RelationHeavyTaxonKeys"
  | "DisableHttpToHttpsLinks";

export function usePortalProperty<T extends boolean | string>(
  name: PortalPropertyName
): {
  value: T | undefined;
  status: QueryStatus;
} {
  const result = usePortalApi(
    client => client.getPortalConfigurationPortalProperties(),
    ["getPortalConfigurationPortalProperties"]
  );

  const allProperties = result.data || [];
  const property = allProperties.find(p => p.propertyName === name)?.value;

  const isBoolString = (s: string | undefined) =>
    s?.toLowerCase() === "true" || s?.toLowerCase() === "false";
  const value = isBoolString(property)
    ? property?.toLowerCase() === "true"
    : property;

  return {
    value: value as T,
    status: result.status
  };
}
