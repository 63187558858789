export enum ParagraphStatus {
  expired = 0,
  Coming = 1,
  Effective = 2,
  Historic = 3,
  Undecided = 4
}
// We have circumstances where we need to get DocumentStatus from a string-key.
// Typescript enums cannot be used to lookup like this without changing some default-configs.
// So instead we just keep this object up-to-date with above enum.
export const ParagraphStatusKeys: { [key: string]: ParagraphStatus } = {
  Undecided: ParagraphStatus.Undecided,
  Coming: ParagraphStatus.Coming,
  Effective: ParagraphStatus.Effective,
  Historic: ParagraphStatus.Historic,
  Expired: ParagraphStatus.expired
};

export const getParagraphStatusTranslationKey = (status: ParagraphStatus) => {
  switch (status) {
    case ParagraphStatus.Coming:
      return "paragraph_zoom.status.coming";
    case ParagraphStatus.Effective:
      return "paragraph_zoom.status.effective";
    case ParagraphStatus.Historic:
      return "paragraph_zoom.status.historic";

    default:
      return "";
  }
};
