import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  Fab
} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles(theme => ({
  wrapper: {
    zIndex: 1,
    height: "100%",
    padding: "0px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      zIndex: 2
    }
  },
  expandedWrapper: {
    marginTop: -10,
    marginLeft: -10,
    marginRight: -10,
    height: "200%",
    maxHeight: "max-content",
    zIndex: 3
  },
  card: {
    height: "100%",
    position: "relative",
    overflow: "unset",
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[8]
    }
  },
  cardHeader: { height: 45 },

  expandedCard: {
    // border: "2px solid " + theme.palette.primary.main,
    boxShadow: theme.shadows[11],
    padding: 10
  },

  wideCard: {
    [theme.breakpoints.up("md")]: {
      gridColumn: "auto / span 2"
    }
  },
  highCard: {
    gridRow: "auto / span 2"
  },
  divider: {
    marginLeft: 0,
    marginRight: 0,
    transition: "all 0.2s ease-in-out"
  },
  dividerExpanded: {
    marginLeft: -10,
    marginRight: -10
  },
  content: {
    overflow: "auto",
    padding: "5px 0px"
  },
  actions: {
    justifyContent: "flex-end",
    bottom: 0,
    right: 0,
    zIndex: 3,
    position: "relative"
  },
  expandAction: {
    bottom: -28,
    justifyContent: "center",
    position: "absolute",
    width: "100%"
  }
}));

export type CardSize = "small" | "medium" | "large";

interface TileProps {
  title?: React.ReactNode | undefined;
  avatar?: React.ReactNode | undefined;
  actions?: React.ReactNode | undefined;
  showExpandAction?: boolean;
  size?: CardSize;
}
// eslint-disable-next-line complexity
const Tile: React.FC<TileProps> = ({
  title,
  actions,
  showExpandAction,
  size,
  children
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <Box
        className={clsx(
          classes.wrapper,
          (size === "medium" || size === "large") && classes.highCard,
          size === "large" && classes.wideCard,
          expanded && classes.expandedWrapper
        )}
      >
        <Card className={clsx(classes.card, expanded && classes.expandedCard)}>
          {title && (
            <>
              <CardHeader title={title} className={classes.cardHeader} />
              <Divider
                className={clsx(
                  classes.divider,
                  expanded ? classes.dividerExpanded : ""
                )}
              />
            </>
          )}

          <CardContent className={classes.content}>{children}</CardContent>
          {actions && (
            <CardActions className={classes.actions}>{actions}</CardActions>
          )}
          {showExpandAction && (
            <CardActions className={classes.expandAction}>
              <Fab
                color={expanded ? "default" : "primary"}
                size="small"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? <RemoveIcon /> : <AddIcon />}
              </Fab>
            </CardActions>
          )}
        </Card>
      </Box>
    </ClickAwayListener>
  );
};

export default Tile;
