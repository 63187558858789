import * as React from "react";
import MaterialUiInputLabel from "@material-ui/core/InputLabel";
import { InputLabelProps as MaterialInputLabelProps } from "@material-ui/core/InputLabel";

type InputLabelProps = Pick<MaterialInputLabelProps, "htmlFor" | "children">;

/**
 * Use in the context of a **FormControl** to render a label with state and target of the FormControl's input.
 */
export const InputLabel = ({ children, htmlFor }: InputLabelProps) => (
  <MaterialUiInputLabel htmlFor={htmlFor} variant="standard">
    {children}
  </MaterialUiInputLabel>
);
