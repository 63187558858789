import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { LegacyNavBarHeightInPixels } from "../../legacy-app-support/LegacyGlobalCssOverrides";
import HeaderExtension from "../../ui-components/layouts/HeaderExtension";

interface TaxonPageProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  container: {
    flexWrap: "nowrap",
    backgroundColor: theme.alternativeBackgroundColor,
    display: "flex",
    justifyContent: "center",
    paddingBottom: 40,
    minHeight: "calc(100vh - " + (LegacyNavBarHeightInPixels + 3) + "px)"
  }
}));

const TaxonPage: React.FC<TaxonPageProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <HeaderExtension />
      {children}
    </div>
  );
};

export default TaxonPage;
