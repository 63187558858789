import * as React from "react";
import {
  HtmlToReactPreprocessingInstructions,
  HtmlToReactProcessingInstructions,
  Parser as HtmlToReactParser
} from "html-to-react";
import processDefaultTags from "../../document/content/rhinestone-xml/processing-instructions/process-default-tags";

interface HtmlRendererProps {
  html: string;
  processingInstructions?: HtmlToReactProcessingInstructions[];
  preprocessingInstructions?: HtmlToReactPreprocessingInstructions[];
}

const htmlToReactParser = new HtmlToReactParser();

/**
 * A basic component to render generic html (for example coming from a rich text component)
 */
export const HtmlRenderer: React.FC<HtmlRendererProps> = ({
  html,
  processingInstructions = [],
  preprocessingInstructions = []
}) => {
  if (processingInstructions.length || preprocessingInstructions.length) {
    return (
      <>
        {htmlToReactParser.parseWithInstructions(
          html,
          () => true,
          [...processingInstructions, processDefaultTags],
          preprocessingInstructions
        )}
      </>
    );
  }

  return <>{htmlToReactParser.parse(html)}</>;
};
