import { useConsolidationAndPageConfig, useEnablePrepChain } from ".";
import { FieldSet, useFieldSet } from "../use-fieldset";
import { DocumentPropertyDto } from "@rhinestone/portal-web-api";

export function useShouldShowPreparatoryWork(
  hiveId: string,
  fullName: string,
  properties: DocumentPropertyDto[]
) {
  const documentFieldSet = useFieldSet(hiveId, fullName);
  const {
    preparatoryWorkConditionFieldName,
    preparatoryWorkConditionFieldValues
  } = useConsolidationAndPageConfig();

  const enablePrepChain = useEnablePrepChain();

  // This is to prevent preperatory work to be shown on the "legacy"/consolidated laws. When versioned laws
  // are fully transfered and ready, no consolidated laws will be left and this check can be removed.
  // The "consolidated_law" property are added to the consolidated laws by the migration pipeline from
  // infosys to Fundament
  const isConsolidatedLaw = properties.find(
    x => x.name === "consolidated_law" && x.value === "true"
  );

  // LBK's does never have preparatory documents, so it does not make sense to display the prep-chain.
  // Note that LBK's are part of the version chain of consolidated laws.
  const isLbk = properties.find(x => x.name === "lbk_nr");

  return (
    enablePrepChain &&
    !isLbk &&
    !isConsolidatedLaw &&
    shouldShowPreparatoryWork(
      documentFieldSet,
      preparatoryWorkConditionFieldName,
      preparatoryWorkConditionFieldValues
    )
  );
}

const shouldShowPreparatoryWork = (
  fieldSet: FieldSet | undefined,
  preparatoryWorkConditionFieldName: string,
  preparatoryWorkConditionFieldValues: string[] | undefined
) =>
  getFieldSetTaxonValueKeys(fieldSet, preparatoryWorkConditionFieldName).some(
    x => preparatoryWorkConditionFieldValues?.includes(x)
  );

const getFieldSetTaxonValueKeys = (
  fieldset: FieldSet | undefined,
  field: string
) => {
  const fieldSetValue = fieldset ? fieldset[field] : "";

  if (typeof fieldSetValue === "string") {
    return [fieldSetValue];
  }
  return fieldSetValue ? fieldSetValue?.map(x => x.Key) : [""];
};
