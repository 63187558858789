// Color tool: https://m2.material.io/inline-tools/color/

import { Color } from "@material-ui/core";

export const purple = {
  50: "#ede7f4",
  100: "#d1c4e4",
  200: "#b39dd2",
  300: "#9575c0",
  400: "#7f57b3",
  500: "#693ba6",
  600: "#6136a0",
  700: "#562e97",
  800: "#4b288e",
  900: "#3a1d7f"
} as unknown as Color;
