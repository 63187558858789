import * as React from "react";
import { AssetCollectionInfo } from "./AssetCollectionInfo";
import { useAssetCollectionContext } from "./asset-collection-context";
import { generateAssetCollectionLink } from "./asset-collection-utils";

export const AssetCollectionInfoContainer: React.FC = () => {
  const context = useAssetCollectionContext();
  if (!context || context === "loading" || context === "no-ac-context")
    return null;

  const link = generateAssetCollectionLink(
    context.isTemplate,
    context.isArchived,
    context.assetCollectionId,
    context.assetCollectionTemplateId
  );

  return (
    <AssetCollectionInfo
      assetCollectionTitle={context.assetCollectionTitle}
      isTemplate={context.isTemplate}
      link={link}
    />
  );
};
