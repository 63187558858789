import { throttle } from "lodash";
import { useEffect, useState } from "react";

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true },
  throttleTime: 300
};

export type MutationObserverObservable = {
  config: MutationObserverInit;
  throttleTime?: number;
};

/**
 * Wrapper for native browser MutationObserver to get notification of DOM subtree changes
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 *
 * Inspired from:
 * https://blog.logrocket.com/guide-to-custom-react-hooks-with-mutationobserver/
 */
export function useMutationObserver(
  targetEl: Node | null,
  cb: MutationCallback,
  options: MutationObserverObservable = DEFAULT_OPTIONS
) {
  const [observer, setObserver] = useState<MutationObserver>();

  useEffect(() => {
    const { throttleTime } = options;
    const obs = new MutationObserver(
      (throttleTime ?? 0) > 0 ? throttle(cb, throttleTime) : cb
    );
    setObserver(obs);
  }, [cb, options]);

  useEffect(() => {
    if (!observer || !targetEl) return;
    const { config } = options;
    console.debug("connecting mutation observer on", targetEl);

    observer.observe(targetEl, config);
    return () => {
      console.debug("disconnecting mutation observer on", targetEl);
      observer?.disconnect();
    };
  }, [observer, targetEl, options]);
}
