import * as React from "react";
import { Alert } from "../../ui-components/Alert";
import { Translate } from "react-localize-redux";
import { Button } from "../../ui-components/Button";
import { useHistory } from "react-router-dom";

export const ProfileEmailRequired: React.FC = () => {
  const history = useHistory();
  return (
    <Alert
      severity="info"
      variant="standard"
      title={<Translate id="account.email_required_header" />}
      action={
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => history.push("/Account/MyProfile")}
          size="small"
          style={{ whiteSpace: "nowrap" }}
        >
          <Translate id="account.profile_page_header" />
        </Button>
      }
    >
      <p>
        <Translate id="account.email_required_message" />
      </p>
    </Alert>
  );
};
