import * as React from "react";
import { useDocumentInfo } from "../document-context";
import RhinestoneDocumentContainer from "./rhinestone-xml/RhinestoneDocumentContainer";
import { PdfContentContainer } from "./pdf/PdfContentContainer";
import Content from "./Content";
import DocumentWatermarkContainer from "./shared/Watermark/DocumentWatermarkContainer";

interface ContentZoneProps {
  onTargetElementInView?: (element: Element) => void;
}

const ContentZone: React.FC<ContentZoneProps> = ({ onTargetElementInView }) => {
  const { primaryVariantTypeCode } = useDocumentInfo();

  return (
    <Content>
      <DocumentWatermarkContainer />
      {renderDocumentContent(primaryVariantTypeCode, onTargetElementInView)}
    </Content>
  );
};

function renderDocumentContent(
  primaryVariantTypeCode: string,
  onTargetElementInView?: (element: Element) => void
): JSX.Element {
  switch (primaryVariantTypeCode) {
    case "Schultz":
      return (
        <RhinestoneDocumentContainer
          onTargetElementInView={onTargetElementInView}
        ></RhinestoneDocumentContainer>
      );
    case "Pdf":
      return <PdfContentContainer />;
    default:
      return (
        <div>Unsupported primaryVariantTypeCode... Cant render content</div>
      );
  }
}

export default ContentZone;
