import React from "react";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import ChartIcon from "./MimeTypeIcons/ChartIcon";
import XmlIcon from "./MimeTypeIcons/XmlIcon";
import EmptyDocIcon from "./MimeTypeIcons/EmptyDocIcon";
import WordIcon from "./MimeTypeIcons/WordIcon";
import ExcelIcon from "./MimeTypeIcons/ExcelIcon";
import PowerPointIcon from "./MimeTypeIcons/PowerPointIcon";
import PdfIcon from "./MimeTypeIcons/PdfIcon";
import ImageIcon from "./MimeTypeIcons/ImageIcon";
import VideoIcon from "./MimeTypeIcons/VideoIcon";
import ZipIcon from "./MimeTypeIcons/ZipIcon";

interface MimeTypeIconProps {
  mimeType: string;
}
const MimeTypeIcon: React.FC<MimeTypeIconProps> = ({ mimeType }) => {
  const contentIcons: { [key: string]: React.ReactElement } = {
    "text/x.schultz.rhinestone.xml": <XmlIcon />,
    "text/x.schultz.infosys.xml": <XmlIcon />,
    "text/x.schultz.cxml": <XmlIcon />,
    "application/x.schultz.cxml.container": <XmlIcon />,
    "application/x.schultz.infosys.container": <EmptyDocIcon />,
    "application/x.schultz.rhinestone.container": <EmptyDocIcon />,
    "text/x.schultz.rhinestone.document": <EmptyDocIcon />,
    "text/x.schultz.rhinestone.worddocument": <WordIcon />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": (
      <WordIcon />
    ),
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
      <ExcelIcon />
    ),
    "application/vnd.ms-excel": <ExcelIcon />,
    "application/msword": <WordIcon />,
    "application/vnd.ms-powerpoint": <PowerPointIcon />,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      <PowerPointIcon />,
    "application/pdf": <PdfIcon />,
    "image/jpeg": <ImageIcon />,
    "video/avi": <VideoIcon />,
    "video/mp4": <VideoIcon />,
    "image/png": <ImageIcon />,
    "video/mpeg": <VideoIcon />,
    "application/zip": <ZipIcon />,
    "application/xml": <XmlIcon />,
    "text/plain": <EmptyDocIcon />,
    "image/gif": <ImageIcon />,
    "application/vnd.ms-visio.drawing.main+xml": <ChartIcon />,
    "application/vnd-ms-visio.drawing": <ChartIcon />,
    default: <InsertDriveFileOutlinedIcon />
  };

  return contentIcons[mimeType] || contentIcons["default"];
};

export default MimeTypeIcon;
