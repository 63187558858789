import { Menu, MenuItem as MuiMenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";

export interface ContextMenuPosition {
  mouseX: number;
  mouseY: number;
}

export interface MenuItem {
  label: JSX.Element | string;
  onClick: () => void;
  disabled?: boolean;
  hidden?: () => boolean;
}

interface ContextMenuProps {
  position?: ContextMenuPosition;
  menuItems?: MenuItem[];
}
const ContextMenu: React.FC<ContextMenuProps> = ({ position, menuItems }) => {
  const [open, setOpen] = React.useState(false);

  const [internalMenuItems, setInternalMenuItems] = useState<
    MenuItem[] | undefined | null
  >([]);

  const [savedRanges, setSavedRanges] = useState<Range[] | null>(null);

  useEffect(() => {
    setOpen(!!position);
  }, [position]);

  useEffect(() => {
    if (open)
      setInternalMenuItems(menuItems?.filter(i => !i.hidden?.() || false));
  }, [open, menuItems]);

  // This is a workaround to make sure text in the document is still selected, when the menu is displayed in firefox.
  useEffect(() => {
    const selObj = document.getSelection();
    if ((selObj?.rangeCount || 0) > 0 && selObj?.getRangeAt(0)) {
      const ranges = [];
      for (let i = 0; i < selObj.rangeCount; i++) {
        ranges.push(selObj.getRangeAt(i));
      }
      setSavedRanges(ranges);
      selObj.removeAllRanges();
    }
    return () => setSavedRanges(null);
  }, [open]);

  useEffect(() => {
    const selObj = document.getSelection();
    if (selObj && savedRanges) {
      savedRanges.forEach(savedRange => selObj.addRange(savedRange));
      setSavedRanges(null);
    }
  }, [savedRanges]);
  // end workaround

  return (
    <React.Fragment>
      <Menu
        open={open && !!position}
        onClose={() => setOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={
          position && { top: position.mouseY, left: position.mouseX }
        }
      >
        {internalMenuItems &&
          internalMenuItems.map((menuItem, index) => {
            return (
              <MuiMenuItem
                disabled={menuItem.disabled}
                key={index}
                onClick={() => {
                  setOpen(false);
                  menuItem.onClick();
                }}
              >
                {menuItem.label}
              </MuiMenuItem>
            );
          })}
      </Menu>
    </React.Fragment>
  );
};

export default ContextMenu;
