import React from "react";
import AnonymousImage, {
  AnonymousImageProps,
  imgAttributes
} from "./AnonymousImage";
import { useGetSupplementalPart } from "../../../../document-page/document-page-helpers";

interface AnonymousImageContainerProps {
  originalSrc: string;
  additionalAttributes?: imgAttributes | undefined;
}
const AnonymousImageContainer: React.FC<AnonymousImageContainerProps> = ({
  originalSrc,
  additionalAttributes
}) => {
  const { data, isLoading } = useGetSupplementalPart(originalSrc);
  return (
    <AnonymousImage
      data={data}
      isLoading={isLoading}
      additionalAttributes={additionalAttributes}
    />
  );
};

export default AnonymousImageContainer;
