import { Box } from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlipIcon from "@material-ui/icons/Flip";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  box: {
    position: "relative",
    width: 24,
    height: 24,
    left: -12
  },
  icon1: {
    position: "absolute"
  },
  icon2: {
    position: "absolute"
  },
  icon3: {
    transform: "scale(0.5,0.8)",
    left: 11
  },
  expand: {
    left: 8
  },
  collapse: {
    left: 16,
    transform: "rotate(180deg)"
  },
  rotate: {
    transform: "rotate(180deg)"
  }
}));

interface ExpandDocumentIconProps {
  expand: boolean;
}

const ExpandDocumentIcon: React.FC<ExpandDocumentIconProps> = ({ expand }) => {
  const classes = useStyles();
  return (
    <Box component="span" className={classes.box}>
      {expand ? (
        <FlipIcon className={classes.icon1} />
      ) : (
        <>
          <CheckBoxOutlineBlankIcon
            className={classes.icon2}
            color={"primary"}
          />
          <MoreVertIcon
            className={clsx(classes.icon2, classes.icon3)}
            color={"primary"}
          />
        </>
      )}
      <ArrowRightIcon
        className={clsx(
          classes.icon2,
          expand ? classes.expand : classes.collapse
        )}
        color={expand ? "inherit" : "primary"}
      />
    </Box>
  );
};

export default ExpandDocumentIcon;
