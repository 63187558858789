import React from "react";
import { ProductModel, Taxon } from "@rhinestone/portal-web-api";
import Taxons from "./Taxons";
import { useGetPathList, getTaxon } from "./helpers/taxon-helper-functions";
import DocumentBaseTileContainer from "./tiles/DocumentBaseTileContainer";
import FolderBaseTileContainer from "./tiles/FolderBaseTileContainer";
import { getPageTitle, getLink } from "./helpers/link-helper-functions";
import { usePortalApi } from "../../api-access/use-portal-api";
import NoTaxonomyErrorTile from "./tiles/NoTaxonomyErrorTile";

interface TaxonsContainerProps {
  product: ProductModel;
}
// eslint-disable-next-line complexity
const TaxonsContainer: React.FC<TaxonsContainerProps> = ({ product }) => {
  const productNavigationTaxonomyName = product.productNavigationTaxonomyName;
  const localTaxonomy = usePortalApi(
    client => client.getTaxonomy(productNavigationTaxonomyName),
    ["getTaxonomy", productNavigationTaxonomyName],
    {
      enabled: Boolean(productNavigationTaxonomyName)
    }
  ).data;

  const pathList = useGetPathList();
  const taxon = getTaxon(localTaxonomy, pathList);

  const folders: Taxon[] | undefined = taxon?.children;
  const isTaxon = taxon && "key" in taxon;
  const siblingCount = (folders?.length || 0) + (isTaxon ? 1 : 0);
  return (
    <Taxons
      pageTitle={getPageTitle(product.title, pathList)}
      breadcrumbElements={
        pathList.length > 0
          ? [
              { title: getPageTitle(product.title, []), link: getLink([]) },
              ...pathList.map((_, index) => ({
                title: getPageTitle(
                  product.title,
                  pathList.slice(0, index + 1)
                ),
                link: getLink(pathList.slice(0, index + 1))
              }))
            ]
          : []
      }
    >
      {folders &&
        folders.map(folder => {
          if (folder) {
            return (
              localTaxonomy && (
                <FolderBaseTileContainer
                  productCode={product.code}
                  taxonomyName={localTaxonomy.name}
                  key={folder.title}
                  taxonKey={folder.key}
                  folderName={folder.title}
                  subFolders={folder.children.map(x => ({
                    folderName: x.title
                  }))}
                  siblingCount={siblingCount}
                />
              )
            );
          }
          return <></>;
        })}
      {taxon && "key" in taxon && localTaxonomy && (
        <DocumentBaseTileContainer
          productCode={product.code}
          taxonomyName={localTaxonomy.name}
          taxonKey={taxon?.key}
          siblingCount={siblingCount}
        />
      )}
      {!localTaxonomy && <NoTaxonomyErrorTile />}
    </Taxons>
  );
};

export default TaxonsContainer;
