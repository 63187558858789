import { Box } from "@material-ui/core";
import React from "react";
import { Scrollbar } from "../../ui-components/Scrollbar";
import ExcludeFromAnnotation from "../annotations/ExcludeFromAnnotation";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  toc: { overflow: "hidden" }
}));

interface TocWrapperProps {
  seachElement: JSX.Element;
}
const TocWrapper: React.FC<TocWrapperProps> = ({ seachElement, children }) => {
  const classes = useStyles();
  return (
    <ExcludeFromAnnotation>
      <Box className={classes.wrapper}>
        {seachElement}
        <Box className={classes.toc}>
          <Scrollbar>{children}</Scrollbar>
        </Box>
      </Box>
    </ExcludeFromAnnotation>
  );
};

export default TocWrapper;
