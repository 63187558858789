import { Container, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import {
  CreateLinkFormRadioButtonField,
  VersionValues
} from "./RadioButtonField";
import { CreateLinkFormProps, CreateLinkTypedForm } from "./TypedForm";
import FormActions from "../FormActions";
import PeriodFormField from "./PeriodFormField";
import * as labels from "./FormLabels";
import { translationKeys } from "../../../../gen/translation-keys";
import { Translate } from "react-localize-redux";

export interface CreateLinkDefaultValues {
  defaultPeriodYear: number;
  defaultPeriodMonth: number;
  defaultPeriodDay: number;
  version: VersionValues;
}

export interface CreateLinkMaxValues {
  maxPeriodYear: number;
  maxPeriodMonth: number;
  maxPeriodDay: number;
}

interface CreateLinkProps extends CreateLinkDefaultValues, CreateLinkMaxValues {
  onCancel: () => void;
  onSubmit: (formData: CreateLinkFormProps) => void;
}

export const CreateLinkForm: React.FC<CreateLinkProps> = ({
  onCancel,
  onSubmit,
  defaultPeriodYear,
  defaultPeriodMonth,
  defaultPeriodDay,
  maxPeriodYear,
  maxPeriodMonth,
  maxPeriodDay,

  version
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <Container>
          <CreateLinkTypedForm
            defaultValues={{
              periodYear: defaultPeriodYear,
              periodMonth: defaultPeriodMonth,
              periodDay: defaultPeriodDay,
              version
            }}
            onSubmit={onSubmit}
          >
            <Typography variant="h5" gutterBottom>
              {labels.periodHeader}
            </Typography>

            <Grid container spacing={3}>
              <Grid item>
                <PeriodFormField
                  fieldName="periodYear"
                  label={translate(
                    translationKeys.document_page.create_link_period_year_label
                  ).toString()}
                  max={maxPeriodYear}
                />
              </Grid>
              <Grid item>
                <PeriodFormField
                  fieldName="periodMonth"
                  label={translate(
                    translationKeys.document_page.create_link_period_month_label
                  ).toString()}
                  max={maxPeriodMonth}
                />
              </Grid>
              <Grid item>
                <PeriodFormField
                  fieldName="periodDay"
                  label={translate(
                    translationKeys.document_page.create_link_period_day_label
                  ).toString()}
                  max={maxPeriodDay}
                />
              </Grid>
            </Grid>
            <Typography variant="h5" gutterBottom>
              {labels.versionHeader}
            </Typography>
            <CreateLinkFormRadioButtonField
              fieldName="version"
              items={[
                { label: labels.versionActual, value: "Actual" },
                { label: labels.versionNewest, value: "Newest" }
              ]}
              fieldOptions={{
                required: translate(
                  translationKeys.document_page.create_link_version_required
                ).toString()
              }}
            />
            <FormActions
              onCancel={onCancel}
              submitLabel={labels.createLinkSubmitLabel}
            />
          </CreateLinkTypedForm>
        </Container>
      )}
    </Translate>
  );
};

export default CreateLinkForm;
