import * as rison from "rison";
import _ from "lodash";
import {
  SearchRequestClient,
  UrlFriendlySearchRequest,
  UrlSearchPayload
} from "./search-request";
import { Criteria } from "./criterias";

/** (BUG#67830) (JMK)
 * Some characters are not supported in the query-area (everything between "?" & "#") of URIs in some browsers.
 * This is not currently understood by Angular router, so it saves the URL with some unencoded characters, but
 * when it's written to the URL, the characters get's encoded and the cache and actual URL gets out of sync.
 * This means using the history API no longer works (using back/forward buttons etc.)
 *
 * To fix it we manually specify some characters to always be encoded.
 * Adding characters here will always be backwards-compatible as unencoded will be treated as they were before.
 */
const RESERVED_QUERY_CHARACTERS: string[] = ["'"];
const LATEST_FORMAT = "o-rison";
const LATEST_VERSION = 2;

/*
    NOTE: Be very careful when changing the format of the url, since this is used in saved searches
    backwards compatibility can be solved by checking version number of search request and handle accordingly
  */

export interface UrlFriendlyCriteriaGroup {
  pk: string; //providerKey
  c: Array<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    da: any; //data
    go: number; //global order
  }>;
}

export function serializeSearchRequest(request: SearchRequestClient): string {
  const urlFriendlyRequest: UrlFriendlySearchRequest = {
    v: LATEST_VERSION,
    f: LATEST_FORMAT,
    p: doRisonObjectEncoding({
      fsn: request.fieldSetName,
      page: request.page,
      skip: request.skip,
      take: request.take,
      slices: request.slices,
      snippets: request.snippets,
      rvn: request.resultViewName,
      soo: request.ordering,
      c: condenseCriteria(request.criteria)
    })
  };

  return RESERVED_QUERY_CHARACTERS.reduce((result, character) => {
    return result.split(character).join(escape(character));
  }, JSON.stringify(urlFriendlyRequest));
}

export function deserializeSearchRequest(
  requestParamValue: string
): SearchRequestClient {
  requestParamValue = RESERVED_QUERY_CHARACTERS.reduce((result, character) => {
    return result.split(escape(character)).join(character);
  }, requestParamValue);

  const urlFriendlySearchRequest: UrlFriendlySearchRequest =
    JSON.parse(requestParamValue);

  if (urlFriendlySearchRequest.f !== LATEST_FORMAT) {
    throw Error(`Unknown search url format: ${urlFriendlySearchRequest.f}.`);
  }

  if (
    urlFriendlySearchRequest.v !== LATEST_VERSION &&
    urlFriendlySearchRequest.v !== 1
  ) {
    throw Error(`Unknown search url version: ${urlFriendlySearchRequest.v}.`);
  }

  // decode payload
  const decodedPayload = doRisonObjectDecoding(urlFriendlySearchRequest.p);

  const searchRequest: SearchRequestClient = {
    fieldSetName: decodedPayload.fsn,
    page: decodedPayload.page,
    skip: decodedPayload.skip,
    take: decodedPayload.take,
    resultViewName: decodedPayload.rvn,
    ordering: decodedPayload.soo,
    criteria: [],
    slices: false,
    snippets: false
  };

  let criteria = inflateCriteria(decodedPayload.c);
  if (urlFriendlySearchRequest.v === 1) {
    criteria = applyCriteriaPath(criteria);
  }
  searchRequest.criteria = criteria;

  return searchRequest;
}

function doRisonObjectEncoding(payload: UrlSearchPayload): string {
  // We use rison compact, uri friendly data format as described here:
  // https://github.com/w33ble/rison
  // This is data format chosen by the likes of Kibana to represent whole application state in URL

  return rison.encode_object(removeUndefinedValuesFromObject(payload));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function removeUndefinedValuesFromObject(obj: Record<string, any>) {
  const clone = { ...obj };
  Object.keys(clone).forEach(
    key => clone[key] === undefined && delete clone[key]
  );
  return clone;
}

function doRisonObjectDecoding(payload: string): UrlSearchPayload {
  return rison.decode_object<UrlSearchPayload>(payload);
}

function condenseCriteria(
  criteria: ReadonlyArray<Criteria>
): UrlFriendlyCriteriaGroup[] {
  const uniqueCriteria = _.uniqBy(
    criteria,
    (c: Criteria) => c.providerKey + JSON.stringify(c.data)
  );

  const groups = _(
    uniqueCriteria.map((crit, index) => ({
      providerKey: crit.providerKey,
      globalIndex: index,
      crit
    }))
  ).groupBy("providerKey");

  return groups.reduce(
    (combined, current) => [
      ...combined,
      {
        pk: current[0].providerKey,
        c: current.map(mapped => {
          return {
            go: mapped.globalIndex,
            da: mapped.crit.data
          };
        })
      }
    ],
    [] as UrlFriendlyCriteriaGroup[]
  );
}

function inflateCriteria(
  groupedCriteria: UrlFriendlyCriteriaGroup[]
): Criteria[] {
  const criteria: Array<{
    globalOrder: number;
    criteria: Criteria;
  }> = _.flatten(
    groupedCriteria.map(g =>
      g.c.map(urlFriendlyCriteria => {
        return {
          globalOrder: urlFriendlyCriteria.go,
          criteria: {
            providerKey: g.pk,
            data: urlFriendlyCriteria.da
          }
        };
      })
    )
  );
  return _.sortBy(criteria, "globalOrder").map(o => o.criteria);
}

// This is a fix for bug #68189
function applyCriteriaPath(criteria: Criteria[]): Criteria[] {
  return criteria.map(c => {
    return {
      data: c.data,
      providerKey: replaceProviderKey(c.providerKey)
    };
  });
}

function replaceProviderKey(providerKey: string) {
  const replaceDictionary: { [v1ProviderKey: string]: string } = {
    documentType: "DokumentType",
    emne: "Emne",
    kilde: "Kilde"
  };

  for (const v1ProviderKey in replaceDictionary) {
    if (providerKey === v1ProviderKey) {
      return replaceDictionary[v1ProviderKey];
    }
  }
  return providerKey;
}
