import * as React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import HeaderExtension from "./HeaderExtension";
import LeftPane from "./LeftPane";
import RightPane from "./RightPane";
import MobilePanes from "./MobilePanes";
import CenterPane from "./CenterPane";
import { useDocumentActionsSettings } from "../../document/DocumentActionMenu/document-actions/DocumentActionsSettingsProvider";

const useStyles = makeStyles(theme => ({
  mainGrid: {
    flexWrap: "nowrap",
    backgroundColor: theme.alternativeBackgroundColor,
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5)
    }
  }
}));

export interface PaneItem {
  key: string;
  icon?: React.ReactElement;
  title?: React.ReactNode;
  content: React.ReactNode;
  variant?: paneItemVariant;
}

export type paneItemVariant = "default" | "grey";

export interface PageLayoutProps {
  timeline?: PaneItem | null;
  toc?: PaneItem | null;
  center: React.ReactNode;
  right: PaneItem[];
}

export const ThreePanesLayout: React.FC<PageLayoutProps> = ({
  timeline,
  toc,
  center,
  right
}) => {
  const classes = useStyles();
  const isFullDocumentView =
    useDocumentActionsSettings()?.state.isFullDocumentView;
  return (
    <>
      <HeaderExtension />
      <Grid
        container
        justify="center"
        className={classes.mainGrid}
        direction="row"
      >
        {(timeline || toc) && <LeftPane timeline={timeline} toc={toc} />}
        <CenterPane center={center} hasTimeline={!!timeline} toc={toc} />
        {!isFullDocumentView && <RightPane right={right} />}
        <MobilePanes timeline={timeline} toc={toc} right={right} />
      </Grid>
    </>
  );
};
