import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "../Link";

export interface BreadcrumbItem {
  label: string;
  href: string;
}

interface BreadcrumbProps {
  entries: BreadcrumbItem[];
  className?: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ entries, ...rest }) => {
  return entries.length === 0 ? null : (
    <Breadcrumbs {...rest} aria-label="breadcrumb">
      {entries.map((entry, i) => {
        return (
          <Link key={i} color="inherit" to={entry.href}>
            {entry.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
