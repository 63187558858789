import * as React from "react";
import { Email, EmailOutlined } from "@material-ui/icons";
import Fade from "@material-ui/core/Fade";
import { IconButton } from "../../../ui-components/IconButton";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { SendDocumentLinkFormContainer } from "../../send-document-link/SendDocumentLinkFormContainer";
import { useState } from "react";

export const SendLinkAction: React.FC = props => {
  const [isModalShown, setIsModalShown] = useState(false);
  return (
    <>
      <Translate>
        {({ translate }) => (
          <IconButton
            {...props}
            size="small"
            title={translate(
              translationKeys.document_page.send_document_link
            ).toString()}
            onClick={() => setIsModalShown(true)}
          >
            <Fade in={true}>
              {isModalShown ? <Email color={"primary"} /> : <EmailOutlined />}
            </Fade>
          </IconButton>
        )}
      </Translate>

      <SendDocumentLinkFormContainer
        isModalShown={isModalShown}
        setIsModalShown={value => setIsModalShown(value)}
      />
    </>
  );
};
