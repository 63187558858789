import { Criteria } from "../criterias";
import { uniq } from "lodash";
import { RegexBasedTermsAnalyzer } from "../SearchTermAnalyzers/regex-based-terms-analyzer";
import { SearchTermsAnalyzerModel } from "@rhinestone/portal-web-api";

export function buildCriteriaFromTerms(
  searchText: string,
  termsProviderKey: string,
  termsAnalyzers?: SearchTermsAnalyzerModel[]
): Criteria[] {
  if (!searchText) {
    return [];
  }

  // we need to separate the quoted strings from the rest of the search text and
  // handle them as terms criteria. Thus bypassing any configured analyzer regexes.
  const { searchTextFiltered, quotedCriteria } = separateQuotedStrings(
    searchText,
    termsProviderKey
  );

  // The analyzers are then run on the remaining search text.
  const analyzersCriteria = buildCriteriaFromRegexAnalyzers(
    termsProviderKey,
    searchTextFiltered,
    termsAnalyzers
  );

  const analyzersAndTermsCriteria =
    analyzersCriteria.length > 0
      ? analyzersCriteria
      : [...buildTermsCriteria(searchTextFiltered, termsProviderKey)];

  return [...analyzersAndTermsCriteria, ...quotedCriteria];
}

function buildCriteriaFromRegexAnalyzers(
  termsProviderKey: string,
  searchText: string,
  termsAnalyzers?: SearchTermsAnalyzerModel[]
): Criteria[] {
  if (termsAnalyzers === undefined || termsAnalyzers.length === 0) {
    return [];
  }

  let filteredSearchText = searchText;
  const criteria: Criteria[] = [];
  for (const termsAnalyzer of termsAnalyzers) {
    const regexBasedTermAnalyzer = new RegexBasedTermsAnalyzer(termsAnalyzer);
    const analyzedTerms =
      regexBasedTermAnalyzer.analyzeTerms(filteredSearchText);
    if (analyzedTerms) {
      const regexCriteria = buildRegexCriteria(
        regexBasedTermAnalyzer.providerKey,
        analyzedTerms.analyzedTerms,
        analyzedTerms.analyzedTermsParts
      );

      criteria.push(regexCriteria);
      filteredSearchText = analyzedTerms.remainingTerms;
    }
  }

  return [
    ...buildTermsCriteria(filteredSearchText, termsProviderKey),
    ...criteria
  ];
}

export function buildTermsCriteria(
  searchText: string,
  termsProviderKey = "terms"
): Criteria[] {
  const terms = buildActualTermsToAdd(searchText);

  return terms.map(t => {
    return {
      providerKey: termsProviderKey,
      data: t
    };
  });
}

function buildRegexCriteria(
  providerKey: string,
  value: string,
  parts: string[]
): Criteria {
  return {
    providerKey: providerKey,
    data: {
      value: value,
      parts: parts
    }
  };
}

function buildActualTermsToAdd(
  searchText: string,
  quoteCharacter: string | RegExp = `"`
): string[] {
  // - trim leading and trailing whitespace
  // - trim leading and trailing ","
  // - remove "-"
  const regex = /^\s+|\s+$|^,+|,+$|^-$/g;
  const terms = searchText
    .split(quoteCharacter)
    .reduce((p: string[], c, i) => [...p, ...(i % 2 ? [c] : c.split(` `))], [])
    .map(s => s.replace(regex, ""))
    .filter(s => !/^\s*$/.test(s)); // filter out any unexpected empty strings after split

  return uniq(terms);
}

interface IFilteredSearchText {
  searchTextFiltered: string;
  quotedCriteria: Criteria[];
}

function separateQuotedStrings(
  searchText: string,
  termsProviderKey: string
): IFilteredSearchText {
  // get any quoted strings
  const extractQuotedStrings = /"([^"]*)"/g;
  const quotedStrings = searchText.match(extractQuotedStrings) || [];

  // build terms criteria for each quoted string
  const quotedCriteria = quotedStrings.flatMap(q =>
    buildTermsCriteria(q, termsProviderKey)
  );

  // remove quoted strings from searchText
  const searchTextFiltered = quotedStrings.reduce(
    (p, c) => p.replace(c, ""),
    searchText
  );

  return {
    searchTextFiltered: searchTextFiltered,
    quotedCriteria: quotedCriteria
  };
}
