import { serializeSearchRequest } from "../../search/search-request-url-serializer";
import { Criteria } from "../../search/criterias";
import { SearchRequestClient } from "../../search/search-request";

export function getProductCriteriaFromProductCode(
  productCode: string
): Criteria[] {
  if (!productCode) {
    return [];
  }
  return [{ providerKey: "products", data: productCode }];
}

export function encodeSearchRequest(searchRequestClient: SearchRequestClient) {
  const uri = serializeSearchRequest(searchRequestClient);
  return encodeURI(uri);
}
