import { PortalLocalizationModel } from "@rhinestone/portal-web-api";
import { useEffect, useCallback, useMemo } from "react";
import {
  InitializePayload,
  SingleLanguageTranslation
} from "react-localize-redux";
import { usePortalApi } from "../../api-access/use-portal-api";

export interface LoadedTranslationItem {
  lang: string;
  translation: any;
}

export function updateLoadedTranslationAndNotLoadedState(
  fetchedTranslationFile: SingleLanguageTranslation,
  setNotLoadedLanguageCodes: React.Dispatch<React.SetStateAction<string[]>>,
  setLoadedLanguages: React.Dispatch<
    React.SetStateAction<LoadedTranslationItem[]>
  >
) {
  // we have to update state by accessing previous state, since we have to mutate state while updating
  // this got very complicated but i couldn't figure out better way, to have multiple calls translation api for each translation
  setNotLoadedLanguageCodes(prevNotLoadedLanguageCodes => {
    // extract from state the language code just fetched
    const loadedTranslationCode = getTranslationBeingFetched(
      prevNotLoadedLanguageCodes
    );
    const [, ...missingTranslationToLoad] = prevNotLoadedLanguageCodes;
    setLoadedLanguages(previousLoadedLanguages => {
      return [
        ...previousLoadedLanguages,
        { lang: loadedTranslationCode, translation: fetchedTranslationFile }
      ];
    });
    // returning the missing translations to load initiating next fetching if anyone is left
    return missingTranslationToLoad;
  });
}

export function getTranslationBeingFetched(notLoadedLanguageCodes: string[]) {
  return notLoadedLanguageCodes[0];
}

export function useAvailableTranslationsOnPortal() {
  // get translations available on portal
  return usePortalApi(
    client => {
      return client.getLocalizations();
    },
    ["getLocalizations"]
  ).data;
}

export function useLoadingOfSingleTranslationFile(
  notLoadedLanguageCodes: string[]
) {
  const languageAsJsonObject = usePortalApi(
    client => {
      return client.getTranslations(
        getTranslationBeingFetched(notLoadedLanguageCodes)
      );
    },
    ["getTranslations", notLoadedLanguageCodes],
    { enabled: notLoadedLanguageCodes.length > 0 }
  ).data;

  return useMemo(
    () =>
      languageAsJsonObject && patchTranslationRecursively(languageAsJsonObject),
    [languageAsJsonObject]
  );
}

type TranslationObject = {
  [key: string]: string | TranslationObject;
};

/**
 * In existing translation values are parameterized translation uses a {{ data }} notation
 *
 * However, react-localize-redux library we use here, expect in the form ${ data } so here we patch the translation file recursively
 * @param translationObject
 */
function patchTranslationRecursively(
  translationObject: TranslationObject
): TranslationObject {
  return Object.keys(translationObject).reduce((state, nextKey) => {
    const next = translationObject[nextKey];
    return {
      ...state,
      [nextKey]:
        next instanceof Object
          ? patchTranslationRecursively(next)
          : next.replace(/{{/g, "${").replace(/}}/g, "}")
    };
  }, {});
}

export function useInitializeReactLocalizeRedux(
  translationsAvailable: PortalLocalizationModel[] | undefined,
  initialize: (payload: InitializePayload) => void,
  setNotLoadedLanguageCodes: React.Dispatch<React.SetStateAction<string[]>>
) {
  // initialize react-localize-redux and
  // start fetching translations one by one when we know what translations should be available
  useEffect(() => {
    if (!translationsAvailable) return;
    initialize({
      languages: translationsAvailable.map(language => ({
        name: language.locale,
        code: language.locale
      })),
      options: {
        renderToStaticMarkup: false
      }
    });
    // this will initiate fetching of translations files
    setNotLoadedLanguageCodes(translationsAvailable.map(l => l.locale));
  }, [initialize, setNotLoadedLanguageCodes, translationsAvailable]);
}

/**
 * We need to use memoized callbacks of react-localize-redux library prevent infinite re-renders
 * when using the callbacks in useEffects
 * @param initialize
 * @param addTranslationForLanguage
 * @param setActiveLanguage
 */
export function useMemoizedReactLocalizeReduxCallbacks(
  initialize: (payload: InitializePayload) => void,
  addTranslationForLanguage: (
    translation: SingleLanguageTranslation,
    language: string
  ) => void,
  setActiveLanguage: (languageCode: string) => void
) {
  return [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(initialize, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(addTranslationForLanguage, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(setActiveLanguage, [])
  ] as const;
}
