import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { TimelineTooltip } from "./TimelineTooltip";
import { TimelineConnector } from "./TimelineConnector";
import { TimelineContent } from "./TimelineContent";
import { TimelineSeparator } from "./TimelineSeparator";
import TimelineActiveLink from "./TimelineActiveLink";
import { TimelineItemWrapper } from "./TimelineItemWrapper";

interface TimelineItemProps {
  id?: string;
  active?: boolean;
  isLastItem?: boolean;
  sticky?: boolean;
  linkTo?: string;
  linkOnClick?: () => void;
  openLinksInNewTab?: boolean;
  tooltipComponent?: React.ComponentType | undefined;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  icon: React.ReactNode;
  "icon-aria-label"?: string;
  useLazyLoading?: boolean;
}
const useStyles = makeStyles({
  label: {
    fontStyle: "italic",
    lineHeight: 1,
    pointerEvents: "none"
  },
  itemSeparator: {
    "& a": {
      lineHeight: 0
    }
  }
});

// eslint-disable-next-line complexity
export const TimelineItem: React.FC<TimelineItemProps> = ({
  id,
  active,
  isLastItem,
  sticky,
  linkTo,
  linkOnClick,
  openLinksInNewTab,
  tooltipComponent,
  title,
  subTitle,
  icon,
  useLazyLoading,

  "icon-aria-label": ariaLabel
}) => {
  const classes = useStyles();
  const TooltipComponent = tooltipComponent ? tooltipComponent : () => null;
  return (
    <TimelineItemWrapper
      sticky={sticky}
      id={id}
      useLazyLoading={useLazyLoading}
    >
      <TimelineSeparator className={classes.itemSeparator}>
        <TimelineActiveLink
          active={active}
          linkTo={linkTo}
          linkOnClick={linkOnClick}
          openLinksInNewTab={openLinksInNewTab}
          aria-label={ariaLabel}
        >
          {icon}
        </TimelineActiveLink>
        {!isLastItem && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Box flexBasis={"auto"}>
          <TimelineActiveLink
            active={active}
            linkTo={linkTo}
            linkOnClick={linkOnClick}
            openLinksInNewTab={openLinksInNewTab}
          >
            {tooltipComponent ? (
              <TimelineTooltip tooltipContents={<TooltipComponent />}>
                {title}
              </TimelineTooltip>
            ) : (
              title
            )}
          </TimelineActiveLink>
          {subTitle && <Box className={classes.label}>{subTitle}</Box>}
        </Box>
      </TimelineContent>
    </TimelineItemWrapper>
  );
};
