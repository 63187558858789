import React from "react";
import {
  Tab as MuiTab,
  TabProps as MuiTabProps,
  makeStyles
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

export type LinkTabIndicatorColor = "primary" | "secondary" | undefined;

export type LinkTabProps = {
  to: string;
  indicatorColor: LinkTabIndicatorColor;
} & Pick<MuiTabProps, "label">;

export type LinkTabSkeletonProps = {
  active: boolean;
  indicatorColor: LinkTabIndicatorColor;
};

const useStyles = makeStyles(theme => ({
  active: {
    borderBottomStyle: "solid",
    borderBottomColor: ({
      indicatorColor
    }: {
      indicatorColor: LinkTabIndicatorColor;
    }) =>
      indicatorColor === "primary"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    borderBottomWidth: "medium"
  },
  skeleton: {
    width: "90%"
  }
}));

export const LinkTab: React.FC<LinkTabProps> = ({
  indicatorColor = "primary",
  ...rest
}) => {
  const classes = useStyles({ indicatorColor });
  return (
    <MuiTab
      component={NavLink}
      {...rest}
      activeClassName={clsx("Mui-selected", classes.active)}
    />
  );
};

export const LinkTabSkeleton: React.FC<LinkTabSkeletonProps> = ({
  active,
  indicatorColor,
  ...rest
}) => {
  const classes = useStyles({ indicatorColor });
  return (
    <MuiTab
      {...rest}
      label={<Skeleton className={classes.skeleton} />}
      className={clsx(active && classes.active)}
    />
  );
};
