import { Box } from "@material-ui/core";
import * as React from "react";
import { LegacyNavBarHeightInPixels } from "../../legacy-app-support/LegacyGlobalCssOverrides";
import clsx from "clsx";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: "calc(100vh - " + (LegacyNavBarHeightInPixels + 3) + "px)",
    [theme.breakpoints.only("xs")]: {
      width: "100vw",
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5)
    }
  }
}));

/**
 * This component constrains child tree to desired width ensuring a nice reading experience for article content
 *
 * This was defined by our UX guy and
 * its about getting a certain amount of characters per line but cant remember the number
 *
 */
export const ArticleContent: React.FC = ({ children }) => {
  const classes = useStyles();
  // The desired width i guess also depends on font-size and letter-spacing however
  // but for now this is set to some working width
  return (
    <Box className={clsx("article-content", classes.content)} maxWidth={855}>
      {children}
    </Box>
  );
};
