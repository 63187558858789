import React from "react";
import { TimelineLink } from "./TimelineLink";

interface TimelineLinkProps {
  active?: boolean;
  linkTo?: string;
  linkOnClick?: () => void;
  openLinksInNewTab?: boolean;
  "aria-label"?: string;
}
const TimelineActiveLink: React.FC<TimelineLinkProps> = ({
  active,
  linkTo,
  linkOnClick,
  openLinksInNewTab,
  "aria-label": ariaLabel,
  children
}) => {
  return (
    <>
      {!active && (!!linkTo || !!linkOnClick) ? (
        <TimelineLink
          linkTo={linkTo}
          linkOnClick={linkOnClick}
          openLinksInNewTab={openLinksInNewTab}
          aria-label={ariaLabel}
        >
          {children}
        </TimelineLink>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default TimelineActiveLink;
