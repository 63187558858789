import { DocumentPageConfigurationModel } from "@rhinestone/portal-web-api";
import { mapFieldsToRelationItem } from "./map-fields-to-prep-chain-item";
import { RelationItemModel } from "./RelationItemModel";
import { useGetRelationTreeData } from "./use-get-relation-tree-data";
import { getPaths } from "./utils";

export function useGetRelationTree(
  hiveId: string,
  fullName: string,
  pageConfiguration: DocumentPageConfigurationModel | undefined,
  portalApiEnabled: boolean,
  followsRelationTypeCode: string,
  getRelationTreeData = useGetRelationTreeData
): [RelationItemModel[][], boolean] {
  const { data, isFetching } = getRelationTreeData(
    hiveId,
    fullName,
    portalApiEnabled,
    followsRelationTypeCode
  );

  const paths = getPaths(data);

  const mappedPaths = paths.map(path =>
    path.map(
      fields =>
        fields.fieldSet &&
        mapFieldsToRelationItem(
          pageConfiguration,
          fields.fieldSet,
          [],
          fields.split
        )
    )
  );

  return [mappedPaths, isFetching];
}
