import React from "react";
import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  makeStyles
} from "@material-ui/core";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";

/**
 * The "to" property in the LinkProps is capable of handling more than a string. It can handle arrow functions and objects as well.
 * Therefore we might need to implement that feature in the future. See "https://reactrouter.com/web/api/Link"
 * for more information on the "to" property.
 */
export type LinkProps = Pick<RouterLinkProps, "to" | "target"> &
  Pick<
    MuiLinkProps,
    | "color"
    | "title"
    | "className"
    | "href"
    | "onClick"
    | "aria-label"
    | "style"
  > & {
    renderBlankTargetIcon?: boolean;
  };

const useStyles = makeStyles(theme => ({
  wrapIcon: {
    verticalAlign: "baseline",
    display: "inline-flex"
  },
  icon: {
    height: "unset"
  }
}));

/**
 * Component that can be used to render links "internal" to portal.
 *
 * If you need to render link out of Portal, use ExternalLink component instead
 *
 *
 * This Link component combines the Link component coming from Material UI together with the link component coming from "react-router-dom".
 * It does so to take advantage of the more clever way of making Links in a SPA that "react-router-dom" offers. The benefit of doing this opposed
 * to just using Material UI's Link component is to avoid causing full page reloads when the user follows a Link.
 */
export const Link: React.FC<LinkProps> = ({
  children,
  target,
  to,
  style,
  renderBlankTargetIcon,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <MuiLink
      style={style}
      component={RouterLink}
      to={to}
      target={target}
      {...rest}
      className={classes.wrapIcon}
    >
      {children}
      {renderBlankTargetIcon &&
        renderBlankTargetIcon === true &&
        target === "_blank" && (
          <LaunchIcon fontSize="small" className={classes.icon} />
        )}
    </MuiLink>
  );
};
