import * as React from "react";
import { Button } from "@material-ui/core";

interface IChangedSectionProps {
  sectionId: string;
  sectionTitle: string;
  isActive: boolean;
  onClick: (sectionId: string) => void;
}

const ChangedSection: React.FunctionComponent<IChangedSectionProps> = ({
  sectionId,
  sectionTitle,
  isActive,
  onClick
}) => {
  return (
    <Button
      variant={isActive ? "contained" : "outlined"}
      color={isActive ? "primary" : "default"}
      key={sectionId}
      onClick={() => onClick(sectionId)}
    >
      {sectionTitle}
    </Button>
  );
};

export default ChangedSection;
