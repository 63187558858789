const MATCHES_COUNT_LIMIT = 1000;

const FindState = {
  FOUND: 0,
  NOT_FOUND: 1,
  WRAPPED: 2,
  PENDING: 3
};

/**
 * This has been in-sourced from pdfjs-dist@2.16.105, since PDFFindBar is no longer part of the library build output
 *
 * We really could decide to remove this entirely since its just a thin integration layer between som DOM UI elements which we render using react
 * and pdfjs-dist library PDFFindController, so feel free to "dump" this in the future. And do a proper React way of integrating to the pdfjs-dist eventbus and PDFFindController
 *
 * Upgrading to version 2.x was a large process and to help better understand
 * how the PDFFindController integration works I kept it and left it almost untouched
 *
 * I have marked all places with **customized** where i made changes to the original code
 */
export class PDFFindBar {
  constructor(options, eventBus, l10n) {
    this.opened = false;
    this.bar = options.bar;
    this.toggleButton = options.toggleButton;
    this.findField = options.findField;
    this.highlightAll = options.highlightAllCheckbox;
    this.caseSensitive = options.caseSensitiveCheckbox;
    this.matchDiacritics = options.matchDiacriticsCheckbox;
    this.entireWord = options.entireWordCheckbox;
    this.findMsg = options.findMsg;
    this.findResultsCount = options.findResultsCount;
    this.findPreviousButton = options.findPreviousButton;
    this.findNextButton = options.findNextButton;
    this.eventBus = eventBus;
    this.l10n = l10n;
    this.toggleButton.addEventListener("click", () => {
      this.toggle();
    });
    // **customized**
    // commented out addEventlistener for find field since we don't want live search
    // this.findField.addEventListener("input", () => {
    //   this.dispatchEvent("");
    // });
    this.bar.addEventListener("keydown", e => {
      switch (e.keyCode) {
        case 13:
          if (e.target === this.findField) {
            this.dispatchEvent("again", e.shiftKey);
          }

          break;

        case 27:
          this.close();
          break;
      }
    });

    this.findPreviousButton.addEventListener("click", () => {
      this.dispatchEvent("again", true);
    });
    this.findNextButton.addEventListener("click", () => {
      this.dispatchEvent("again", false);
    });
    this.highlightAll.addEventListener("click", () => {
      this.dispatchEvent("highlightallchange");
    });
    this.caseSensitive.addEventListener("click", () => {
      this.dispatchEvent("casesensitivitychange");
    });
    this.entireWord.addEventListener("click", () => {
      this.dispatchEvent("entirewordchange");
    });
    this.matchDiacritics.addEventListener("click", () => {
      this.dispatchEvent("diacriticmatchingchange");
    });

    this.eventBus._on("resize", this.adjustWidth.bind(this));
  }

  reset() {
    this.updateUIState();
  }

  dispatchEvent(type, findPrev = false) {
    this.eventBus.dispatch("find", {
      source: this,
      type,
      query: this.findField.value,
      phraseSearch: true,
      caseSensitive: this.caseSensitive.checked,
      entireWord: this.entireWord.checked,
      highlightAll: this.highlightAll.checked,
      findPrevious: findPrev,
      matchDiacritics: this.matchDiacritics.checked
    });
  }

  updateUIState(state, previous, matchesCount) {
    let findMsg = Promise.resolve("");
    let status = "";

    switch (state) {
      case FindState.FOUND:
        break;

      case FindState.PENDING:
        status = "pending";
        break;

      case FindState.NOT_FOUND:
        findMsg = this.l10n.get("find_not_found");
        status = "notFound";
        break;

      case FindState.WRAPPED:
        findMsg = this.l10n.get(`find_reached_${previous ? "top" : "bottom"}`);
        break;
    }

    this.findField.setAttribute("data-status", status);
    this.findField.setAttribute("aria-invalid", state === FindState.NOT_FOUND);
    findMsg.then(msg => {
      this.findMsg.textContent = msg;
      this.adjustWidth();
    });
    this.updateResultsCount(matchesCount);
  }

  updateResultsCount({ current = 0, total = 0 } = {}) {
    const limit = MATCHES_COUNT_LIMIT;
    let matchCountMsg = Promise.resolve("");
    if (total > 0) {
      if (total > limit) {
        // **customized**
        // matchCountMsg = this.getMaxMatchCountMessage(matchCountMsg, limit);
        matchCountMsg = this.getMaxMatchCountMessage_Customized(
          matchCountMsg,
          limit
        );
      } else {
        // **customized**
        // matchCountMsg = this.getMatchCountMessage(current, total);
        matchCountMsg = this.getMatchCountMessage_Customized(current, total);
      }
    }

    matchCountMsg.then(msg => {
      this.findResultsCount.textContent = msg;
      this.adjustWidth();
    });
  }

  /**
   * Original logic as in sourced from pdfjs-dist@2.16.105
   */
  getMatchCountMessage(current, total) {
    const key = "find_match_count";
    return this.l10n.get(key, {
      current,
      total
    });
  }

  /**
   * Customized logic for getMatchCountMessage
   */
  getMatchCountMessage_Customized(current, total) {
    return new Promise(resolve =>
      resolve(this.opened ? `${current}/${total}` : "")
    );
  }

  /**
   * Original logic as in sourced from pdfjs-dist@2.16.105
   */
  getMaxMatchCountMessage(matchCountMsg, limit) {
    const key = "find_match_count_limit";
    matchCountMsg = this.l10n.get(key, {
      limit
    });
    return matchCountMsg;
  }

  /**
   * Customized logic for getMaxMatchCountMessage
   */
  getMaxMatchCountMessage_Customized(limit) {
    return new Promise(resolve => {
      resolve(this.opened ? `+${limit}` : "");
    });
  }

  open() {
    if (!this.opened) {
      this.opened = true;
      this.toggleButton.classList.add("toggled");
      this.toggleButton.setAttribute("aria-expanded", "true");
      this.bar.classList.remove("hidden");
    }

    this.findField.select();
    this.findField.focus();
    this.adjustWidth();
  }

  close() {
    if (!this.opened) {
      return;
    }

    this.opened = false;
    this.toggleButton.classList.remove("toggled");
    this.toggleButton.setAttribute("aria-expanded", "false");
    this.bar.classList.add("hidden");
    this.eventBus.dispatch("findbarclose", {
      source: this
    });
  }

  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  adjustWidth() {
    if (!this.opened) {
      return;
    }

    this.bar.classList.remove("wrapContainers");
    const findbarHeight = this.bar.clientHeight;
    const inputContainerHeight = this.bar.firstElementChild.clientHeight;

    if (findbarHeight > inputContainerHeight) {
      this.bar.classList.add("wrapContainers");
    }
  }
}
