import * as React from "react";
import { Box } from "@material-ui/core";
import { forwardRef } from "react";

export const documentContentId = "document-content";

const ContentBoxInternal = (
  { children }: { children?: React.ReactNode },
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  // using this funky {...{ ref }} spread syntax to set ref since there is error in Box typescript definition and they forgot to include ref property:
  // https://github.com/mui-org/material-ui/issues/19275
  return (
    <Box
      id={documentContentId}
      position={"relative"}
      marginRight={{ sm: "65px" }}
      {...{ ref }}
    >
      {children}
    </Box>
  );
};

// By declaring function we wrap in forwardRef as named const we get nice component name in dev tools

/**
 * ContentBox is wrapper for rhinestone content, it ensures positional and space styling is correct for RhinestoneDocument
 */
export const ContentBox = forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode }
>(ContentBoxInternal);
