import * as React from "react";
import { processLinks } from "../../document/content/rhinestone-xml/processing-instructions/process-links";
import HtmlRenderer from "../../ui-components/HtmlRenderer";
import { TileCard } from "../../ui-components/TileCard";
import { TileCardProps } from "../../ui-components/TileCard/TileCard";

export interface TextTileProps {
  tile: {
    richText: string;
  } & TileCardProps;
}

export const TextTile = ({ tile }: TextTileProps) => {
  const { richText, ...rest } = tile;
  return (
    <TileCard {...rest}>
      <HtmlRenderer html={richText} processingInstructions={[processLinks()]} />
    </TileCard>
  );
};
