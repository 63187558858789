import * as React from "react";
import {
  getParagraphStatusTranslationKey,
  ParagraphStatus
} from "../paragraph-status";
import { Translate } from "react-localize-redux";
import { ParagraphStatusIcon } from "../../StatusIcons/ParagraphStatusIcons";
import DateFormatter from "../../../localization/DateFormatter";
import Box from "@material-ui/core/Box";

export interface ParagraphZoomStateProps {
  loading?: boolean;
  paragraphState?: ParagraphStatus;
  effectiveDate?: Date;
}

export const ParagraphZoomState: React.FC<ParagraphZoomStateProps> = ({
  paragraphState,
  effectiveDate
}) => {
  return (
    <Box marginBottom={2}>
      <Box fontWeight="fontWeightBold">
        {paragraphState ? (
          <Translate
            id={getParagraphStatusTranslationKey(paragraphState)}
          ></Translate>
        ) : (
          <span>?</span>
        )}
      </Box>
      <Box display="flex" alignItems="center" mt={1}>
        <ParagraphStatusIcon
          status={paragraphState || ParagraphStatus.Undecided}
          active={true}
          fontSize={"inherit"}
        />
        <Box ml={1}>
          <DateFormatter value={effectiveDate} />
        </Box>
      </Box>
    </Box>
  );
};
