import * as React from "react";
import { FC } from "react";
import Page from "../ui-components/layouts/Page";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../gen/translation-keys";
import AiAssistant from "./AiAssistant";
import FeatureFlag from "../feature-flags/feature-flag/FeatureFlag";
import { useParams } from "react-router-dom";
import { useCompletionConfigurations } from "./ai-helpers";

interface AiAssistantPageProps {}

const AiAssistantPage: FC<AiAssistantPageProps> = () => {
  const { configKey } = useParams<{
    configKey: string | undefined;
  }>();

  const { data: completionConfigurations } = useCompletionConfigurations();

  return (
    <FeatureFlag name={"AiAssistant"}>
      <Page title={<Translate id={translationKeys.ribbon.ai_assistant} />}>
        {completionConfigurations ? (
          <AiAssistant
            configKey={configKey}
            configurations={completionConfigurations}
          />
        ) : (
          "Loading"
        )}
      </Page>
    </FeatureFlag>
  );
};

export default AiAssistantPage;
