export function getNameFromLink(link: string | undefined) {
  if (!link) {
    return "";
  }
  const result = link.split("___").join(" ");
  return decodeURIComponent(result.split("__").join("."));
}

export const getLink = (
  pathList: string[],
  origin: string = window.location.origin,
  pathname: string = window.location.pathname
) => {
  const path = pathList.map(x => encodeURIComponent(x)).join("/");

  return `${origin}${pathname}?path=/${path}`;
};
export const getPageTitle = (productTitle: string, pathList: string[]) => {
  if (pathList.length === 0) {
    return productTitle;
  }
  return pathList[pathList.length - 1];
};
