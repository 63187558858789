import * as React from "react";
import { Translate } from "react-localize-redux";

interface WarningTextProps {
  label: string;
  warningTranslationKey: string;
}

export const WarningText: React.FC<WarningTextProps> = ({
  label,
  warningTranslationKey
}) =>
  label ? (
    <span>{label}</span>
  ) : (
    <span style={{ color: "darkred" }}>
      <Translate id={warningTranslationKey} />
    </span>
  );
