/* eslint-disable complexity */
import React from "react";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { Translate } from "react-localize-redux";
import { translationKeys } from "./../../../gen/translation-keys";
import { Accordion } from "../../ui-components/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Annotation } from "./annotation-state";
import { useIsMobile } from "../../ui-components/media-queries";
import { OrphanedAnnotationsList } from "./OrphanedAnnotationsList";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "../../ui-components/Snackbar";

export const useOrphanedAnnotations = <T extends AnnotationModel>({
  isShown,
  annotations
}: {
  isShown: boolean;
  annotations: Annotation<T>[] | undefined;
}) => {
  const isMobile = useIsMobile();
  const orphanedAnnotations =
    annotations?.filter(a => a.annotation.isOrphan) || [];

  if (!isMobile && isShown && orphanedAnnotations.length > 0)
    return orphanedAnnotations;
  else return undefined;
};

export interface OrphanedAnnotationsContainerProps<T extends AnnotationModel> {
  orphanedAnnotations: Annotation<T>[];
  removeAnnotation: (annotation: Annotation<T>) => Promise<T> | undefined;
}

export const OrphanedAnnotationsContainer = <T extends AnnotationModel>({
  orphanedAnnotations,
  removeAnnotation
}: OrphanedAnnotationsContainerProps<T>) => {
  const { enqueueWarning } = useSnackbar();
  React.useEffect(() => {
    enqueueWarning(
      <Translate id={translationKeys.annotations.comments_not_placed_warning} />
    );
  }, [enqueueWarning]);

  const orphanedUserAnnotations = orphanedAnnotations.filter(
    a => a.contextType === "user"
  );
  const orphanedAssetCollectionAnnotations = orphanedAnnotations.filter(
    a => a.contextType === "asset-collection"
  );

  return (
    <Accordion
      label={
        <Typography noWrap variant={"h5"}>
          <Translate
            id={translationKeys.document_page.comments_not_allowed_in_document}
          />
        </Typography>
      }
      expandIcon={<ExpandMoreIcon />}
      defaultExpanded
    >
      {orphanedUserAnnotations.length > 0 && (
        <OrphanedAnnotationsList
          orphanedAnnotations={orphanedUserAnnotations}
          removeAnnotation={removeAnnotation}
        />
      )}
      {orphanedAssetCollectionAnnotations.length > 0 && (
        <OrphanedAnnotationsList
          orphanedAnnotations={orphanedAssetCollectionAnnotations}
          removeAnnotation={removeAnnotation}
        />
      )}
    </Accordion>
  );
};
