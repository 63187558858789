import * as React from "react";
import { useEffect } from "react";
import { usePdfJsPdfViewer } from "./use-pdfjs-pdf-viewer";
import {
  handleScrollToDestination,
  PdfPageDestination
} from "./pdfjs-react-bridge-helpers/scroll-helpers";
import { makeStyles } from "@material-ui/core";
import { PdfPagesLayoutState } from "./pdf-pages-layout-state";
import { PdfAnnotationState } from "../../../annotations/pdf/pdf-annotation-state";
import { PdfSelection } from "./annotations/pdf-selection";
import { PdfAnnotationModel } from "@rhinestone/portal-web-api";

export interface PdfViewerProps {
  url: string;
  pdfWorkerSrc?: string;
  goToDestination?: PdfPageDestination;
  // load progress is kept for "completeness" when migrating from angular code.
  // it doesnt seem like we actually show this progress anywhere in old solution but not sure
  loadProgressChanged?: (percentageProgress: number) => void;
  onContextMenu?: (
    mouseX: number,
    mouseY: number,
    selection?: PdfSelection,
    annotation?: PdfAnnotationModel
  ) => void;
  findBar?: HTMLElement | null;
  highlights?: string[];
  ticketId?: string;
  isAnnotationsVisible?: boolean;
  annotations?: PdfAnnotationState;
  renderAnnotations?: (layout: PdfPagesLayoutState) => React.ReactNode;
}

/**
 * Pdf viewer based on pdfjs-dist
 *
 * We use version 2.3.200 of pdfjs-dist since we couldn't get later versions to work with IE11
 * See: https://github.com/mozilla/pdf.js/wiki/Frequently-Asked-Questions#which-browsers-are-supported
 * and https://pdfjs.express/blog/what-browsers-does-pdf-js-support
 *
 * We could not find alternative solution in this point of time (like react-pdf for instance)
 * that has advanced features such as
 *  - Lazy rendering/virtualization of pages (big pdfs will crash browser if all pages are rendered)
 *  - Search and highlight like PDFFindcontroller
 *  - Active pdf links
 *
 * Note it is based on old customized version of PdfJs, so consider upgrading PdfJs
 *
 * Current ported functionality:
 *  - lazy loading
 *  - scroll to page
 *  - progress bar
 *  - scaling
 *  - Find in pdf
 *
 * Not yet ported functionality:
 *  - Pdf internal links (with anchors, for example #p23), external links work out of the box
 *  - Annotations (will come in later user story)
 * @param param0
 */
export const PdfViewer: React.FC<PdfViewerProps> = ({
  url,
  pdfWorkerSrc = `${process.env.PUBLIC_PATH}pdf.worker.js`,
  onContextMenu,
  goToDestination,
  findBar,
  highlights,
  ticketId = "",
  isAnnotationsVisible,
  annotations,
  renderAnnotations
}) => {
  const {
    pdfViewer,
    pdfViewContainerRef,
    pdfViewerElementRef,
    pdfViewHeight,
    pdfPagesLayoutState
  } = usePdfJsPdfViewer(
    url,
    pdfWorkerSrc,
    onContextMenu,
    findBar,
    highlights,
    goToDestination,
    annotations,
    ticketId
  );

  // handle go to page when page changes from props
  useEffect(() => {
    handleScrollToDestination(pdfViewer, goToDestination);
  }, [goToDestination, pdfViewer]);

  const styles = useStyle({ isAnnotationsVisible });
  return (
    <>
      {/* We set total height of internal PdfViewer div on outer div surrounding this whole LegacyPdfViewer Component
       ensuring that it takes up all space (and body element will be correct height of all content, ensuring background doesn't get clipped).
      The next level div "container" div ensures that we don't get infinite loading of pages by restricting div height to viewport*/}
      <div
        style={{ height: `${pdfViewHeight}px` }}
        className={styles.pdfWrapper}
      >
        {/* NOTE: 
          pdfjs-dist expects container div to be the scroll container, but in previous solution the library was changed to use window as scroll container.
          we have applied the same changes to pdf_viewer.js in node_modules folder (node_modules\pdfjs-dist\web\pdf_viewer.js) and used patch-package (https://www.npmjs.com/package/patch-package) to get changes applied
          We get change our design, so that the container div is positioned around entire document page mimicking a window scroll bar, but that will require a lot of effort and we
          had some trouble getting the rest of the new design to work perfectly with container being positioned around entire document page.
           */}
        <div ref={pdfViewContainerRef} className={styles.container}>
          <div ref={pdfViewerElementRef}>
            {/* This will be internal viewer div of PDFViewer. Notice that part of pdf_viewer.css has styling which requires this div to have class "pdfViewer".
            In old code this was never included, so we haven't added it here, but it might be useful and provide extra "features". */}
          </div>
          {renderAnnotations && renderAnnotations(pdfPagesLayoutState)}
        </div>
      </div>
    </>
  );
};

interface StyleProps {
  isAnnotationsVisible?: boolean;
}

const useStyle = makeStyles(theme => ({
  pdfWrapper: {
    // we need relative on our outer wrapper div because the next inner div is required to be absolute positioned by pdfjs
    position: "relative"
  },
  container: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    "& > div": {
      backgroundColor: "white",
      flexDirection: "column",
      alignItems: "center",
      display: "flex",
      "& hr": {
        width: "100%"
      }
    },
    "& .page": {
      position: "relative"
    },
    "& .annotationLayer": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      pointerEvents: ({ isAnnotationsVisible }: StyleProps) =>
        isAnnotationsVisible ? "all" : "none"
    },
    "& .linkAnnotation": {
      position: "absolute",
      "& a": {
        cursor: "pointer",
        display: "block",
        height: "100%",
        width: "100%"
      }
    },
    "& .textLayer .highlight.selected": {
      backgroundColor: "orange"
    },
    "& .textLayer .highlight": {
      backgroundColor: "yellow"
    }
  }
}));
