import * as React from "react";
import { IconButton } from "../../../ui-components/IconButton";
import { CommentIcon } from "../../../ui-components/icons";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { Badge } from "../../../ui-components/Badge";
import { Box } from "@material-ui/core";
import { Annotation } from "../annotation-state";

export const CommentMarkerHeight = 26;

export type CommentMarkerProps<T extends AnnotationModel> = {
  annotations: Annotation<T>[];
  onShowComment: (annotations: Annotation<T>[], target: HTMLElement) => void;
};

export const CommentMarker = <T extends AnnotationModel>({
  annotations,
  onShowComment
}: CommentMarkerProps<T>) => {
  const renderIcon = () => (
    <IconButton
      size="small"
      title={annotations.length === 1 ? annotations[0].annotation.comment : ""}
      onClick={e => onShowComment(annotations, e.currentTarget)}
      data-test-id="document-action-show-comment"
    >
      <CommentIcon />
    </IconButton>
  );

  const containsAnnotationWithComment =
    annotations.map(x => x.annotation.comment).findIndex(x => !!x) > -1;
  if (!containsAnnotationWithComment) {
    return null;
  }

  // We wrap in badge if CommentMarker represents more than one annotation
  return annotations.length > 1 ? (
    // offset position using negative margin because of added badge, so comment icons align
    <Box marginRight={"-21px"}>
      <Badge badgeContent={annotations.length} top={-7} left={-7}>
        {renderIcon()}
      </Badge>
    </Box>
  ) : (
    renderIcon()
  );
};
