import React, { useEffect } from "react";
import AttachmentElement from "./AttachmentElement";
import { useDownloadAttachment } from "./use-download-attachment";

interface AttachmentElementContainerProps {
  identifier: string;
  title: string;
  mimeType: string;
  displayFileName: string;
}
const AttachmentElementContainer: React.FC<AttachmentElementContainerProps> = ({
  identifier,
  title,
  mimeType,
  displayFileName
}) => {
  const [download, setDownload] = React.useState(false);
  const { isFetching } = useDownloadAttachment(
    identifier,
    displayFileName,
    download
  );
  useEffect(() => {
    if (!isFetching && download) {
      setDownload(false);
    }
  }, [isFetching, download]);
  const handleClick = () => {
    setDownload(true);
  };

  return (
    <AttachmentElement
      isFetching={isFetching}
      handleClick={handleClick}
      fileName={displayFileName}
      title={title}
      mimeType={mimeType}
    />
  );
};

export default AttachmentElementContainer;
