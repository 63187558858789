/* eslint-disable complexity */
import { DialogContent, useMediaQuery, useTheme } from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import * as React from "react";
import { ModalDialogTitle, ModalDialogActions, ModalDialogAction } from ".";
import { ModalDialogTitleTypography } from "./ModalDialogTitleTypography";

export type ModalDialogProps = {
  open: boolean;
  onClose: () => void;
  preventEventOnMouseNavigation?: boolean;
  title: React.ReactNode;
  confirm?: ModalDialogAction;
  cancel?: ModalDialogAction;
  dataTestId?: string;
} & Pick<DialogProps, "maxWidth" | "fullWidth" | "className">;

export const ModalDialog: React.FC<ModalDialogProps> = ({
  open,
  onClose,
  preventEventOnMouseNavigation,
  title,
  children,
  confirm,
  cancel,
  fullWidth = true,
  dataTestId,
  ...rest
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const onCloseInternal = React.useCallback(
    (event: unknown, reason: "backdropClick" | "escapeKeyDown") => {
      if (reason === "backdropClick") {
        const button = (event as MouseEvent)?.button || 0;
        // if preventEventOnMouseNavigation is true, then we don't want to call close the dialog when the user clicks button 3 or 4 (navigation buttons on the mouse)
        if (preventEventOnMouseNavigation && (button === 3 || button === 4)) {
          return;
        }
        if (button !== 3 && button !== 4) {
          onClose();
        }
      } else {
        onClose();
      }
    },
    [preventEventOnMouseNavigation, onClose]
  );

  return (
    <Dialog
      open={open}
      keepMounted={false}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      onClose={onCloseInternal}
      data-test-id={dataTestId}
      {...rest}
    >
      <ModalDialogTitle onClose={!confirm && !cancel ? onClose : undefined}>
        <ModalDialogTitleTypography>{title}</ModalDialogTitleTypography>
      </ModalDialogTitle>
      {fullWidth ? <DialogContent>{children}</DialogContent> : children}

      <ModalDialogActions cancel={cancel} confirm={confirm} />
    </Dialog>
  );
};
