import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string-for-all";

export const useApiTicket = () => {
  const location = useLocation();
  const [currentTicketId, setCurrentTicketId] = useState<string>();

  useEffect(() => {
    const { ticketId } = queryString.parse(location.search) as {
      ticketId: string | undefined;
    };
    if (currentTicketId !== ticketId) {
      setCurrentTicketId(ticketId);
    }
  }, [location.search, currentTicketId]);

  return currentTicketId;
};
