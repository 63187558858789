import { useMemo } from "react";
import { usePortalApi } from "../api-access/use-portal-api";

export function useSearchConfiguration() {
  const { data: searchViews } = usePortalApi(
    client => client.getSearchViews(),
    ["getSearchViews"]
  );
  return useMemo(
    () =>
      searchViews
        ? { defaultSearchView: searchViews && searchViews[0], searchViews }
        : undefined,
    [searchViews]
  );
}
