/* eslint-disable complexity */
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import {
  largeDesktopContentWidth,
  normalDesktopContentWidth,
  largeExtraWidth
} from "./CenterPane";
import { Paper } from "@material-ui/core";
import { useDocumentActionsSettings } from "../../document/DocumentActionMenu/document-actions/DocumentActionsSettingsProvider";
import {
  largeWidth as sidepaneLargeWidth,
  smallWidth as sidepaneSmallWidth
} from "./SidePane";

const useStyles = makeStyles(theme => ({
  paperFixedTopContainer: {
    position: "fixed",
    overflow: "hidden",
    height: "5px",
    width: normalDesktopContentWidth + theme.spacing(0.5) + "px",
    zIndex: 40,
    marginTop: "-3px",
    marginLeft: "-1px",
    backgroundColor: theme.palette.background.default
  },
  paperFixedTop: {
    border: "1px solid rgb(0 0 0 / 8%)",
    position: "absolute",
    zIndex: 40,
    height: "10px",
    width: normalDesktopContentWidth + theme.spacing(0.25) + "px"
  },
  paperFixedTopContainerLarge: {
    width: largeDesktopContentWidth + theme.spacing(0.5) + "px"
  },
  paperFixedTopLarge: {
    width: largeDesktopContentWidth + theme.spacing(0.25) + "px"
  },
  fullWidth: {
    [theme.breakpoints.up("sm")]: {
      width: normalDesktopContentWidth + largeExtraWidth + 2 + "px !important"
    }
  },
  fullWidthLeftPane: {
    maxWidth:
      "calc(100vw - " +
      (sidepaneSmallWidth - 2 + theme.spacing() * 5) +
      "px) !important"
  },
  fullWidthNoLeftPane: {
    maxWidth: "calc(100vw - " + (theme.spacing() * 4 - 1) + "px) !important"
  },
  fullWidthLarge: {
    width:
      normalDesktopContentWidth +
      largeExtraWidth +
      2 +
      sidepaneLargeWidth +
      "px !important"
  }
}));

interface PaperFixedTopProps {
  large?: boolean;
  noLeftPane?: boolean;
}
const PaperFixedTop: React.FC<PaperFixedTopProps> = ({ large, noLeftPane }) => {
  const classes = useStyles();
  const isFullDocumentView =
    useDocumentActionsSettings()?.state.isFullDocumentView;

  const fullViewStyle =
    isFullDocumentView && (large ? classes.fullWidthLarge : classes.fullWidth);
  const paneStyle =
    isFullDocumentView &&
    (noLeftPane ? classes.fullWidthNoLeftPane : classes.fullWidthLeftPane);
  return (
    <div
      className={clsx(
        classes.paperFixedTopContainer,
        large && classes.paperFixedTopContainerLarge,
        fullViewStyle,
        paneStyle
      )}
    >
      <Paper
        elevation={4}
        className={clsx(
          classes.paperFixedTop,
          large && classes.paperFixedTopLarge,
          fullViewStyle,
          paneStyle
        )}
      />
    </div>
  );
};

export default PaperFixedTop;
