import {
  FieldValues,
  useFormContext,
  UseFormMethods
} from "react-hook-form/dist/index.ie11";

import React from "react";

interface ConnectFormProps {
  children: (methods: UseFormMethods<FieldValues>) => React.ReactNode;
}

const ConnectForm: React.FC<ConnectFormProps> = ({ children }) => {
  const methods: UseFormMethods<FieldValues> = useFormContext();
  return <>{children({ ...methods }) || null}</>;
};

export default ConnectForm;
