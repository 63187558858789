import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { LoadingProgress } from "../../ui-components/LoadingProgress";
import { ModalDialog } from "../../ui-components/ModalDialog";
import { useDocumentInfo } from "../document-context";
import { AssetCollectionForm } from "./AssetCollectionForm";
import {
  useAddDocumentToExistingAssetCollection,
  useAddDocumentToNewAssetCollection,
  useAddPassageToNewAssetCollection,
  useAddPassageToExistingAssetCollection,
  useMyAssetCollection
} from "./asset-collection-helpers";
import {
  FlowQuoteModel,
  GeometricSelectionModel
} from "@rhinestone/portal-web-api";

interface AddToAssetCollectionProps {
  setOpen: (open: boolean) => void;
  passage?: FlowQuoteModel | GeometricSelectionModel;
  isPdf?: boolean;
}

export const AddToAssetCollectionContainer: React.FC<AddToAssetCollectionProps> =
  ({ setOpen, passage, isPdf }) => {
    const assetCollections = useMyAssetCollection();

    const { mutateAsync: addToNew, isLoading: isAddingToNew } =
      useAddDocumentToNewAssetCollection();
    const { mutateAsync: addToExisting, isLoading: isAddingToExisting } =
      useAddDocumentToExistingAssetCollection();
    const { mutateAsync: addPassageToNew, isLoading: isAddingPassageToNew } =
      useAddPassageToNewAssetCollection();
    const {
      mutateAsync: addPassageToExisting,
      isLoading: isAddingPassageToExisting
    } = useAddPassageToExistingAssetCollection();

    const documentInfo = useDocumentInfo();

    const handleSelectExisting = async (id: string, title: string) => {
      if (passage) {
        await addPassageToExisting(
          {
            assetCollectionId: id,
            assetCollectionTitle: title,
            documentId: documentInfo.documentId,
            revisionNumber: documentInfo.revisionNumber,
            passage,
            isPdf: !!isPdf
          },
          {
            onSuccess: () => setOpen(false)
          }
        );
      } else {
        await addToExisting(
          {
            assetCollectionId: id,
            assetCollectionTitle: title,
            documents: [documentInfo]
          },
          {
            onSuccess: () => setOpen(false)
          }
        );
      }
    };

    const handleCreate = async (name = "", referenceNumber = "") => {
      if (passage) {
        await addPassageToNew(
          {
            assetCollectionTitle: name,
            referenceNumber,
            documentId: documentInfo.documentId,
            revisionNumber: documentInfo.revisionNumber,
            passage,
            isPdf: !!isPdf
          },
          {
            onSuccess: () => setOpen(false)
          }
        );
      } else {
        await addToNew(
          {
            assetCollectionTitle: name,
            referenceNumber,
            documents: [documentInfo]
          },
          {
            onSuccess: () => setOpen(false)
          }
        );
      }
    };

    const isFetching =
      isAddingToNew ||
      isAddingToExisting ||
      isAddingPassageToNew ||
      isAddingPassageToExisting;
    return (
      <>
        <ModalDialog
          title={
            <Translate
              id={translationKeys.assets.existing_collection_dialog_title}
              data={{ documentCount: 1 }}
            />
          }
          open={true}
          onClose={() => setOpen(false)}
          fullWidth={false}
        >
          {isFetching && <LoadingProgress />}
          <AssetCollectionForm
            assetCollections={assetCollections?.map(assetCollection => ({
              id: assetCollection.assetCollectionId,
              title: assetCollection.title,
              lastModified: assetCollection.lastModified
            }))}
            handleSelectExisting={handleSelectExisting}
            onClose={() => setOpen(false)}
            onSubmit={handleCreate}
          />
        </ModalDialog>
      </>
    );
  };
