import { IconButton, makeStyles } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import * as React from "react";
import { FC } from "react";

interface AiSearchSettingsButtonProps {
  toggleShowSettings: () => void;
}

const useStyles = makeStyles(theme => ({
  settingsButton: {
    borderRadius: "4px",
    marginLeft: "4px",
    border: "1px solid #ccc"
  }
}));

const AiSearchSettingsButton: FC<AiSearchSettingsButtonProps> = ({
  toggleShowSettings
}) => {
  const classes = useStyles();
  return (
    <IconButton onClick={toggleShowSettings} className={classes.settingsButton}>
      <Settings />
    </IconButton>
  );
};

export default AiSearchSettingsButton;
