import { Theme } from "@material-ui/core/styles";

export enum DanishLawStatus {
  Unknown = 0,
  Coming = 1,
  Effective = 2,
  Historic = 3,
  EffectiveLbk = 4,
  PreviousVersion = 5
}

export const danishLawStatusTranslationKeys: Record<DanishLawStatus, string> = {
  [DanishLawStatus.Unknown]: "",
  [DanishLawStatus.Coming]: "status.coming",
  [DanishLawStatus.Effective]: "status.effective",
  [DanishLawStatus.Historic]: "status.historic",
  [DanishLawStatus.EffectiveLbk]: "status.latestlbk",
  [DanishLawStatus.PreviousVersion]: "status.previousversion"
};

export const danishLawStatusColors: (
  theme: Theme
) => Record<DanishLawStatus, string> = theme => ({
  [DanishLawStatus.Coming]: theme.palette.status.coming.main,
  [DanishLawStatus.Effective]: theme.palette.status.effective.main,
  [DanishLawStatus.Historic]: theme.palette.status.historic.main,
  [DanishLawStatus.EffectiveLbk]: theme.palette.status.latestLbk.main,
  [DanishLawStatus.PreviousVersion]: theme.palette.status.previousVersion.main,
  [DanishLawStatus.Unknown]: theme.palette.status.unknown.main
});
