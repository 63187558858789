import * as React from "react";
import { ParagraphZoomDialog } from "./dialog/ParagraphZoomDialog";
import { ParagraphZoomDialogTitle } from "./ParagraphZoomDialogTitle";
import ParagraphZoomTitle from "./content/ParagraphZoomTitle";
import ParagraphZoom from "./ParagraphZoom";
import { useCurrentDocumentPageParameters } from "../document-page/document-page-helpers";

export interface ParagraphZoomContainerProps {
  onClose: () => void;
}

export const ParagraphZoomContainer: React.FC<ParagraphZoomContainerProps> = ({
  onClose
}) => {
  const { pzoom: paragraphId } = useCurrentDocumentPageParameters();

  return (
    <ParagraphZoomDialog
      open
      onClose={onClose}
      modalTitle={<ParagraphZoomDialogTitle title={<ParagraphZoomTitle />} />}
    >
      <ParagraphZoom paragraphId={paragraphId} />
    </ParagraphZoomDialog>
  );
};
