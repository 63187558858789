import React from "react";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";

export const InfoIconPopOver: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onMouseOver={handlePopoverOpen} size="small">
        <InfoOutlinedIcon fontSize="small" />
      </IconButton>
      <Popover open={open} anchorEl={anchorEl} onClose={handlePopoverClose}>
        <Box m={2} display="flex">
          <Box>{children}</Box>
          <Box ml={2}>
            <IconButton onClick={handlePopoverClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
