import { AnnotationModel } from "@rhinestone/portal-web-api";
import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { MenuItem } from "../../../ui-components/ContextMenu/ContextMenu";

export function addCommentMenuItem(
  annotation: AnnotationModel | undefined,
  setOpenEditor: React.Dispatch<React.SetStateAction<boolean>>
): MenuItem {
  return {
    onClick: () => setOpenEditor(true),
    label: (
      <span data-test-id="add-annotation-comment">
        <Translate id={translationKeys.document_page.comment} />
      </span>
    ),
    hidden: () => !annotation || !!annotation.comment
  };
}
