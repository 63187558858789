import {
  ClickAwayListener,
  makeStyles,
  Paper,
  Popper,
  PopperProps
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  popper: {
    // The popper from Mui by default has a z-index of 0, but for current use cases we have, we would like to make sure it overlays
    // here i set it to something below Mui modal level z-index
    // see: https://mui.com/customization/z-index/
    zIndex: theme.zIndex.modal - 100
  }
}));

type PopOverProps = Pick<PopperProps, "placement" | "anchorEl" | "open"> & {
  onClose: () => void;
  className: { paper: string };
};
const PopOver: React.FC<PopOverProps> = ({
  anchorEl,
  open,
  onClose,
  className,
  placement = "left-start",
  children
}) => {
  const styles = useStyles();
  return (
    <Popper
      className={styles.popper}
      open={open}
      disablePortal
      anchorEl={anchorEl}
      placement={placement}
      transition
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper className={className.paper} elevation={2}>
          {children}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default PopOver;
