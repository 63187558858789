import React from "react";
import { LoadingProgress } from "../../ui-components/LoadingProgress";
import { AccordionWithTimeline } from "./AccordionWithTimeline";
import { useGetRelationPaths } from "./use-get-relation-paths";

interface TimelineAccordionContainerProps {
  hiveId: string;
  fullName: string;
  openLinksInNewTab?: boolean;
  getRelationPaths?: typeof useGetRelationPaths;
}
const TimelineAccordionContainer: React.FC<TimelineAccordionContainerProps> = ({
  hiveId,
  fullName,
  openLinksInNewTab,
  getRelationPaths = useGetRelationPaths
}) => {
  const relationTreeData = getRelationPaths(hiveId, fullName);
  if (relationTreeData.isLoading) {
    return <LoadingProgress />;
  }

  return (
    <AccordionWithTimeline
      key={relationTreeData.relationTreeData.title}
      relationTreeData={relationTreeData.relationTreeData}
      openLinksInNewTab={openLinksInNewTab}
    />
  );
};

export default TimelineAccordionContainer;
