import { useEffect, useState } from "react";
import { throttle } from "lodash";

export function useScrollEvents(throttleScrollEvents?: number) {
  const [scrollEvent, setScrollEvent] = useState<Event>();
  useEffect(() => {
    const onScroll = throttleScrollEvents
      ? throttle(setScrollEvent, throttleScrollEvents)
      : setScrollEvent;
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [throttleScrollEvents]);
  return scrollEvent;
}
