import MaterialUiCard from "@material-ui/core/Card";
import * as React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    // IE 11 fix
    wordWrap: "break-word",
    wordBreak: "break-word"
  }
});

export const Card = React.forwardRef<
  HTMLDivElement,
  {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactElement | React.ReactElement[];
    variant?: "elevation" | "outlined";
    elevation?: number;
  }
>(({ className, elevation, ...rest }, ref) => (
  <MaterialUiCard
    elevation={elevation || 6}
    ref={ref}
    className={`${useStyles().root} ${className}`}
    {...rest}
  />
));
