import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { MenuItem } from "../../../ui-components/ContextMenu/ContextMenu";
import {
  addPdfHighlightMenuItem,
  addPdfUserHighlightMenuItemCommonProps
} from "./AddPdfHighlightMenuItem";

export function addPdfAssetCollectionHighlightMenuItem(
  props: addPdfUserHighlightMenuItemCommonProps
): MenuItem {
  const labelText = (
    <Translate
      id={translationKeys.annotations.add_asset_collection_highlight}
    />
  );
  return addPdfHighlightMenuItem({ ...props, labelText });
}
