import { useState, useCallback, RefObject } from "react";
import "./pdfjs-links.css";
import { PdfPagesLayoutState } from "../pdf-pages-layout-state";
import {
  PDFPageView,
  PDFViewer,
  PageRenderedEvent,
  PDFJsRenderingStates
} from "pdfjs-dist/legacy/web/pdf_viewer";
import { PDFPageProxy, AnnotationLayer } from "pdfjs-dist/legacy/build/pdf";
import { PageViewport } from "pdfjs-dist/types/web/interfaces";

export function usePdfjsPageRenderedHandler(
  pdfViewer: PDFViewer | undefined,
  pdfViewContainerRef: RefObject<HTMLDivElement>
) {
  // Tracking total height of internal pdf viewer which will be the height of all pages
  const [pdfViewHeight, setPdfViewHeight] = useState(0);

  // Track page position and scale
  const [pdfPagesLayoutState, setPagesLayoutState] =
    useState<PdfPagesLayoutState>({});

  const pdfjsPageRenderedHandler = useCallback(
    (e: PageRenderedEvent) => {
      if (!pdfViewer || !pdfViewContainerRef.current) return;
      const pageNumber = e.pageNumber;
      setPdfViewHeight(pdfViewer.viewer?.getBoundingClientRect().height ?? 0);
      setPagesLayoutState(prev => {
        return {
          ...prev,
          [pageNumber]: {
            offsetTop: (e.source.div as HTMLElement)?.offsetTop ?? 0,
            viewPortScale: pdfViewer.getPageView(pageNumber - 1).viewport.scale
          }
        };
      });
      addLinkAnnotations(pdfViewer.getPageView(pageNumber - 1), pdfViewer);
    },
    [pdfViewContainerRef, pdfViewer]
  );
  return {
    pdfjsPageRenderedHandler,
    pdfViewHeight,
    pdfPagesLayoutState
  };
}

// TODO: not used but kept until we are sure we don't need it. builtin pdfViewer.currentScaleValue does similar things
function scalePdfPageView(pageNumber: number, pdfViewer: PDFViewer) {
  const pageView = pdfViewer.getPageView(pageNumber - 1);

  const newScale = calculatePdfPageScale(pdfViewer, pageView.pdfPage);
  // prevent infinite loop when rendering by checking that scale is already set within some meaningful precision
  const scaleDiff = Math.abs(pageView.scale - newScale);
  if (scaleDiff < 0.1) return;
  pageView.update({ scale: newScale, rotation: 0 });
}

// TODO: not used but kept until we are sure we don't need it. builtin pdfViewer.currentScaleValue does similar things
export function scaleAllRenderedPages(pdfViewer: PDFViewer | undefined) {
  if (!pdfViewer) return;
  pdfViewer._pages
    ?.filter(
      (page: PDFPageView) =>
        page.renderingState === PDFJsRenderingStates.FINISHED
    )
    .forEach(p => scalePdfPageView(p.id, pdfViewer));
}

function calculatePdfPageScale(pdfViewer: PDFViewer, page: PDFPageProxy) {
  // Pdf.js Viewer tries to convert css pixels to "screen pixels" (96 dpi rule).
  // In order to calculate the correct scale for displaying the pdf page we need to take this into account.
  const cssUnits = 96.0 / 72.0;
  const containerMargin = 2;

  return (
    (pdfViewer.container.getBoundingClientRect().width - containerMargin) /
    page.getViewport({ scale: 1 }).width /
    cssUnits
  );
}

function addLinkAnnotations(pageView: PDFPageView, pdfViewer: PDFViewer) {
  const page: PDFPageProxy = pageView.pdfPage;
  page.getAnnotations().then((annotationsData: unknown[]) => {
    AnnotationLayer.render({
      // Rotation must be set to 0 for the whole PDF to not be rotated.
      // This seems to be rotation of the annotation layer relative to the page rotation.
      // So rotated PDF's still work as expected, even with this set.
      viewport: { ...pageView.viewport, rotation: 0 } as PageViewport,
      div: pageView.div,
      annotations: annotationsData,
      page: pageView.pdfPage,
      linkService: pdfViewer.linkService,
      enableScripting: true,
      downloadManager: undefined,
      renderForms: true
    });
  });
}
