import * as React from "react";
import { List } from "../../ui-components/List";
import { DocumentFieldModel } from "@rhinestone/portal-web-api";
import { FieldItem } from "./FieldItem";
import { FieldSet } from "../use-fieldset";

interface AboutDocumentProps {
  fieldSet: FieldSet;
  fields: DocumentFieldModel[];
}

export const AboutDocument: React.FC<AboutDocumentProps> = ({
  fieldSet,
  fields
}) => {
  return (
    <>
      <List dense>
        {fields.map((field, index) => (
          <FieldItem
            {...field}
            fieldValue={fieldSet ? fieldSet[field.fieldName] : ""}
            key={index}
          />
        ))}
      </List>
    </>
  );
};
