import React from "react";
import RelationChips from "./RelationChips";
import { Chip, makeStyles } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../../../gen/translation-keys";
interface NoRelationsWarningProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}
const useStyles = makeStyles(theme => ({
  container: {
    display: "inline"
  },
  chip: {
    marginRight: "5px",
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    borderRadius: "4px"
  },
  avatar: {
    padding: "2px",
    minWidth: "24px",
    backgroundColor: "white"
  }
}));

const NoRelationsWarning: React.FC<NoRelationsWarningProps> = ({
  expanded,
  setExpanded
}) => {
  const classes = useStyles();

  return (
    <div data-test-id="no-relation-chip" className={classes.container}>
      <Chip
        className={classes.chip}
        icon={<Warning />}
        label={
          <Translate id={translationKeys.document_page.no_section_relations} />
        }
      />
    </div>
  );
};

export default NoRelationsWarning;
