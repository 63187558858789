import { RelationItemModel } from "../../../preparatory-work/RelationItemModel";
import { SectionPayload } from "./SectionPayload";

export function getRelationItems(sectionIds?: {
  [key: string]: SectionPayload;
}): RelationItemModel[] {
  if (!sectionIds) {
    return [];
  }
  return Object.values(sectionIds).reduce((acc, section) => {
    const existingGroup = acc.find(
      group =>
        group.hiveId === section.hiveId && group.fullName === section.fullName
    );
    if (existingGroup) {
      existingGroup.targetBookmarks = existingGroup.targetBookmarks
        .concat(section.sectionIds)
        .filter((value, index, self) => self.indexOf(value) === index);
    } else {
      acc.push({
        label: "",
        title: "",
        hiveId: section.hiveId,
        fullName: section.fullName,
        targetBookmarks: section.sectionIds
      });
    }
    return acc;
  }, [] as RelationItemModel[]);
}
