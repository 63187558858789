import React, { useEffect } from "react";
import { useConsolidationAndPageConfig } from "../../preparatory-work";
import { useGetSourceRelations } from "../../preparatory-work/use-get-source-relations";

interface SpecialRemarksTabInnerContentsFetcherProps {
  hiveId: string;
  fullName: string;
  paragraphId: string;
  onReportDataState: (
    targetBookmark: string,
    hiveId: string,
    fullName: string,
    sectionIds: string[],
    isFetching: boolean
  ) => void;
}
export const SpecialRemarksTabInnerContentsFetcher: React.FC<SpecialRemarksTabInnerContentsFetcherProps> =
  React.memo(
    ({ hiveId, fullName, paragraphId, onReportDataState }) => {
      const { remarksOnRelationTypeCode } = useConsolidationAndPageConfig();
      const [relations, isFetching] = useGetSourceRelations(
        hiveId,
        fullName,
        remarksOnRelationTypeCode,
        paragraphId
      );

      useEffect(() => {
        onReportDataState(
          paragraphId,
          relations?.[0]?.hiveId,
          relations?.[0]?.fullName,
          relations
            .map(x => x.targetBookmarks || [])
            .reduce((acc, val) => acc.concat(val), []),
          isFetching
        );
      }, [paragraphId, relations, isFetching, onReportDataState]);
      return <></>;
    },
    (prevProps, nextProps) =>
      prevProps.hiveId === nextProps.hiveId &&
      prevProps.fullName === nextProps.fullName &&
      prevProps.paragraphId === nextProps.paragraphId
  );
