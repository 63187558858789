import React from "react";
import {
  DotIcon,
  Timeline,
  TimelineItem
} from "../../ui-components/split-timeline";
import {
  RelationTreeData,
  MainPathElement,
  PathData
} from "./RelationTreeData";
import { PreparatoryWorkTimelineAccordion } from "./PreparatoryWorkTimelineAccordion";
import { PreparatoryWorkTimelineSplitItem } from "./PreparatoryWorkTimelineSplitItem";

export interface AccordionWithTimelineProps {
  relationTreeData: RelationTreeData;
  openLinksInNewTab?: boolean;
}

export const AccordionWithTimeline: React.FC<AccordionWithTimelineProps> = ({
  relationTreeData,
  openLinksInNewTab
}) => {
  const [selectedPathKey, setSelectedPathKey] = React.useState(
    relationTreeData.paths[0]?.title || ""
  );

  const selectedPath =
    relationTreeData.paths.find(x => x.title === selectedPathKey) ||
    relationTreeData.paths[0];

  return (
    <PreparatoryWorkTimelineAccordion
      labels={relationTreeData.paths.map(x => x.title)}
      defaultExpanded={true}
      timelineKey={selectedPathKey}
      handlePathSelection={(_, value) => setSelectedPathKey(value)}
    >
      {selectedPath && (
        <Timeline>
          {selectedPath.pathElements.map((pathElement, index) => (
            <SplitTimelineElement
              key={index}
              pathElement={pathElement}
              index={index}
              openLinksInNewTab={openLinksInNewTab}
              selectedPath={selectedPath}
            />
          ))}
        </Timeline>
      )}
    </PreparatoryWorkTimelineAccordion>
  );
};
interface SplitTimelineElementProps {
  pathElement: MainPathElement;
  index: number;
  openLinksInNewTab: boolean | undefined;
  selectedPath: PathData;
}
const SplitTimelineElement: React.FC<SplitTimelineElementProps> = ({
  pathElement,
  index,
  openLinksInNewTab,
  selectedPath
}) => {
  return (
    <>
      {
        <PreparatoryWorkTimelineSplitItem
          splits={pathElement.splits}
          firstItem={index === 0 ? pathElement.splits?.[0] : undefined}
          openLinksInNewTab={openLinksInNewTab}
        />
      }
      <TimelineItem
        key={index}
        icon={
          <DotIcon alt={pathElement.hoverText} active={pathElement.selected} />
        }
        title={pathElement.title}
        linkTo={pathElement.link}
        active={pathElement.selected}
        isLastItem={index === selectedPath.pathElements.length - 1}
        openLinksInNewTab={openLinksInNewTab}
      />
    </>
  );
};
