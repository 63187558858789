import React from "react";
import { useDocumentRelationGroupsForBookmark } from "../use-document-relation-groups";
import { useDocumentSectionRelationChipsState } from "../DocumentRelationChipsStateProvider";
import { LoadingProgress } from "../../../../../ui-components/LoadingProgress";
import { useDocumentInfo } from "../../../../document-context";
import { RelationWrapper } from "./RelationWrapper";

export interface SectionRelationChipsProps {
  sectionId: string;
}

export const SectionRelationChips: React.FC<SectionRelationChipsProps> = ({
  sectionId
}) => {
  const { expanded, setExpanded } =
    useDocumentSectionRelationChipsState(sectionId);

  const { hiveId, fullName } = useDocumentInfo();

  const { data, status } = useDocumentRelationGroupsForBookmark(
    !!expanded,
    hiveId,
    fullName,
    sectionId
  );

  if (status === "loading") return <LoadingProgress />;
  return expanded && status === "success" ? (
    <RelationWrapper
      relationData={data}
      expanded={expanded}
      setExpanded={() => setExpanded(false)}
      sectionId={sectionId}
    />
  ) : null;
};
