import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  header: {
    display: "inline"
  }
}));

const MainHeader: React.FC = ({ children }) => {
  const classes = useStyles();
  return <header className={classes.header}>{children}</header>;
};

export default MainHeader;
