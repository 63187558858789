import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingTop: "30px",
    // leave some room for scrolling to last elements and toc navigation
    // we can consider calculating this based on screen-sizes, or last section size
    // remember to test with last section folded on both desktop and mobile if changing these values
    marginBottom: "95vh",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "75vh"
    },
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    "& .user-annotation-highlight": {
      backgroundColor: theme.userAnnotationHighlightColor
    },
    "& .asset-annotation-highlight": {
      backgroundColor: theme.assetAnnotationHighlightColor
    }
  }
}));
const Content: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer} data-test-id="document-content">
      {children}
    </div>
  );
};

export default Content;
