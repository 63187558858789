import React from "react";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { Translate } from "react-localize-redux";
import { translationKeys } from "./../../../gen/translation-keys";
import { OrphanedAnnotation } from "./OrphanedAnnotation";
import { Typography } from "@material-ui/core";
import { Annotation } from "./annotation-state";
import getAnnotationHighlightClassName from "./annotation-highlight";

export interface OrphanedAnnotationsListProps<T extends AnnotationModel> {
  orphanedAnnotations: Array<Annotation<T>>;
  removeAnnotation: (annotation: Annotation<T>) => Promise<T> | undefined;
}

export const OrphanedAnnotationsList = <T extends AnnotationModel>({
  orphanedAnnotations,
  removeAnnotation
}: OrphanedAnnotationsListProps<T>) => {
  // assume all orphaned annotation are the same "type" (user/asset-collection)
  const firstOrphan = orphanedAnnotations[0];
  const annotationTypeTranslationKey =
    firstOrphan.contextType === "user"
      ? translationKeys.assets.my_comments
      : translationKeys.assets.asset_collection_comments;
  const highlightClassName = getAnnotationHighlightClassName(firstOrphan);

  return (
    <>
      <Typography noWrap variant={"h6"}>
        <Translate id={annotationTypeTranslationKey} />
      </Typography>
      {orphanedAnnotations.map(orphan => (
        <OrphanedAnnotation
          key={orphan.annotation.id}
          orphanedAnnotation={orphan}
          removeAnnotation={removeAnnotation}
          highlightClassName={highlightClassName}
        />
      ))}
    </>
  );
};
