import React from "react";
import { TimelineConsolidationInfo } from "../../consolidation-Information/TimelineConsolidationInfo/TimelineConsolidationInfo";
import { InfoIconPopOver } from "../../../ui-components/PopOver/InfoIconPopOver";

export const ConsolidationInfo: React.FC<{ consolidationDocuments: string[] }> =
  ({ consolidationDocuments: relatedDocuments }) => {
    return (
      <InfoIconPopOver>
        <TimelineConsolidationInfo data={relatedDocuments} />
      </InfoIconPopOver>
    );
  };
