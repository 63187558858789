import React from "react";
import { Link, LinkProps } from "../../ui-components/Link";
import {
  CompleteDocumentPageUrl,
  getDocumentUrl
} from "../document-page/document-page-helpers";

export type DocumentPageLinkProps = CompleteDocumentPageUrl &
  Omit<LinkProps, "to">;

export const DocumentPageLink: React.FC<DocumentPageLinkProps> = ({
  hiveId,
  fullName,
  bookmark = "",
  showExact = true,
  ...rest
}) => {
  const url = getDocumentUrl({
    hiveId,
    fullName,
    showExact,
    bookmark
  });
  return <Link to={url} {...rest} />;
};
