import React from "react";
import { makeStyles } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { Button } from "../../../../../ui-components/Button";
import { translationKeys } from "../../../../../../gen/translation-keys";
import { Badge } from "../../../../../ui-components/Badge";
import Typography from "@material-ui/core/Typography/Typography";
import { isNil } from "lodash";

interface RelationDialogTitleProps {
  relationCount: number;
  label: string | JSX.Element;
  openLinksInNewTab: boolean;
  searchUrl?: string | null | undefined;
}

const useStyles = makeStyles(theme => ({
  modalTitle: {
    display: "inline",
    fontWeight: "bold",
    fontSize: "1em"
  },
  modalButtons: {
    float: "right",
    // The button color is overridden by bootstrap when it is a link,
    // so we have to set it here
    "& a": {
      color: theme.palette.primary.contrastText + " !important"
    }
  }
}));

const RelationDialogTitle: React.FC<RelationDialogTitleProps> = ({
  relationCount,
  label,
  openLinksInNewTab,
  searchUrl
}) => {
  const classes = useStyles();
  const target = openLinksInNewTab ? "_blank" : "";

  return (
    <>
      <Badge badgeContent={relationCount} top={5} left={5}>
        <Typography className={classes.modalTitle}>{label}</Typography>
      </Badge>
      <div className={classes.modalButtons}>
        <Button
          href={decodeURI(`/Soeg?search=${searchUrl}`)}
          target={target}
          disabled={isNil(searchUrl)}
          color="primary"
          variant="contained"
        >
          <Translate
            id={translationKeys.document_page.document_search_related_documents}
          />
        </Button>
      </div>
    </>
  );
};

export default RelationDialogTitle;
