export function openAsPdf(data: any, name = "file"): void {
  const blob = new Blob([data], { type: "application/pdf" });

  const navigator = window.navigator as Navigator & {
    msSaveOrOpenBlob?: (blob: Blob, name: string) => void;
  };
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, name + ".pdf");
    return;
  }

  // download file using dynamic link with correct name when msSaveOrOpenBlob is not available
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = name;
  downloadLink.dispatchEvent(new MouseEvent(`click`));
}
