import { AnnotationModel } from "@rhinestone/portal-web-api";
import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { ModalDialog } from "../../../ui-components/ModalDialog";
import { Annotation } from "../annotation-state";
import { AnnotationCommentEditorFields } from "./AnnotationCommentEditorFields";

interface AnnotationCommentEditorModalProps<T extends AnnotationModel> {
  openEditor: boolean;
  setOpenEditor: React.Dispatch<React.SetStateAction<boolean>>;
  annotations: Annotation<T>[];
  saveComment: (annotation: Annotation<T>, newComment: string) => void;
}
const AnnotationCommentEditorModal = <T extends AnnotationModel>({
  openEditor,
  setOpenEditor,
  annotations,
  saveComment
}: AnnotationCommentEditorModalProps<T>) => {
  return annotations && annotations.length > 0 ? (
    <ModalDialog
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      title={<Translate id={translationKeys.document_page.comment} />}
    >
      <AnnotationCommentEditorFields
        annotations={annotations}
        defaultActiveAnnotation={annotations?.[0]}
        onActiveAnnotationChange={() => void 0}
        onSave={(annotation, newComment) => {
          saveComment(annotation, newComment);
          setOpenEditor(false);
        }}
        onClose={() => setOpenEditor(false)}
      />
    </ModalDialog>
  ) : null;
};

export default AnnotationCommentEditorModal;
