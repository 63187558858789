import { Box } from "@material-ui/core";
import * as React from "react";
import { CommentMarker, CommentMarkerProps } from "../CommentMarker";
import {
  annotationsWithKnownPageLayout,
  groupAnnotations
} from "./comment-marker-list-helper";
import { AnnotationLayoutState } from "../../annotation-layout-state";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { Annotation } from "../../annotation-state";

interface CommentMarkerListProps<T extends AnnotationModel> {
  annotations: Annotation<T>[];
  layoutState: AnnotationLayoutState;
  right: string | number;
}

export type CombinedProps<T extends AnnotationModel> =
  CommentMarkerListProps<T> & Pick<CommentMarkerProps<T>, "onShowComment">;

export const CommentMarkerList = <T extends AnnotationModel>({
  annotations,
  layoutState,
  right,
  ...restOfCommentMarkerProps
}: CombinedProps<T>) => {
  return (
    <>
      {groupAnnotations(
        annotationsWithKnownPageLayout(annotations, layoutState),
        layoutState
      ).map(a => (
        <Box
          position="absolute"
          right={right}
          key={a.map(a => a.annotation.id).join(",")}
          style={{
            top:
              layoutState[a[0].annotation.id].y +
              AdjustmentToAlignIconWithHighlight
          }}
        >
          <CommentMarker annotations={a} {...restOfCommentMarkerProps} />
        </Box>
      ))}
    </>
  );
};

const AdjustmentToAlignIconWithHighlight = -7;
