import * as React from "react";
import { calculateTargetElementId } from "./toc-helpers";
import { Toc as TocElement } from "./Toc";
import { useHistory } from "react-router-dom";
import { LoadingProgress } from "../../ui-components/LoadingProgress";
import { Toc } from "@rhinestone/portal-web-api";

interface TocContainerProps {
  currentElementInView?: Element;
  data: Toc | undefined;
  isLoading: boolean;
}

export const TocContainer: React.FC<TocContainerProps> = ({
  currentElementInView,
  data,
  isLoading
}) => {
  // use location to enable setting toc state from url (deep link support)
  const { replace, location } = useHistory();

  const [targetElementId, setTargetElementId] = React.useState(
    calculateTargetElementId(location.hash, currentElementInView)
  );

  React.useEffect(() => {
    if (currentElementInView) {
      setTargetElementId(currentElementInView.id);
    }
  }, [currentElementInView]);

  React.useEffect(
    () => setTargetElementId(calculateTargetElementId(location.hash)),
    [location.hash]
  );

  const handleTargetElementChanged = (elementId: string) => {
    // we replace history with new location object
    // this will result in new react-router history entry
    // with new key property, ensuring any listeners or users of useLocation()
    // gets notified even though actual url doesn't change at all
    // this solves issue were we want to be able to scroll to an element in document
    // but url hash already points to that element
    replace({
      ...location,
      hash: elementId
    });

    // Immediately set this to optimistically update UI in tree-view as we have to wait for:
    // hash to update -> page to scroll -> wait for throttle -> update targetElementId
    setTargetElementId(elementId);
  };
  if (isLoading) {
    return <LoadingProgress />;
  }
  return data && data.entries && data.entries.length > 0 ? (
    <TocElement
      tocData={data}
      targetElementId={targetElementId}
      onTargetElementChanged={handleTargetElementChanged}
    />
  ) : null;
};
