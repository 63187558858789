import MaterialUITab, { TabProps as MuiTabProps } from "@material-ui/core/Tab";
import * as React from "react";
import { getAriaKey } from "./tab-helpers";

export type TabProps = Pick<
  MuiTabProps,
  "label" | "tabIndex" | "classes" | "title"
> & { tabKey: string };

function a11yProps(key: string) {
  return {
    id: getAriaKey(key),
    "aria-controls": `full-width-tabpanel-${key}`
  };
}

export const Tab: React.FC<TabProps> = ({
  tabIndex,
  tabKey,
  classes,
  ...rest
}) => (
  <MaterialUITab {...a11yProps(tabKey || "0")} classes={classes} {...rest} />
);
