import * as React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import { TextField as MuiTextField } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { LoadingProgress } from "../../../ui-components/LoadingProgress";
import { useFindInDocumentContext } from "../../FindInDocumentStateProvider";
import { useRef } from "react";

/**
 * Created this const to encapsulate class names for FindBar subcomponents
 * because LegacyPdfViewer needs to query for elements from root FindBar element when setting up
 */
export const findBarClassNames = {
  findInput: "find-input",
  findPrevious: "find-previous",
  findNext: "find-next",
  findSpinner: "find-spinner",
  findResultsCount: "find-msg",
  findToggle: "find-toggle"
};

export interface FindBarProps {
  totalMatches: number | null;
  currentMatch: number | null;
  onClose: () => void;
  findNext: (term: string) => void;
  findPrevious: (term: string) => void;
}

/**
 * Note when used in PdfFindBar some of the elements are updated using direct dom manipulation pdf-find-bar.js
 *
 * pending rewrite to plain React
 */
export const FindBar = React.forwardRef<HTMLDivElement, FindBarProps>(
  (
    { totalMatches, currentMatch, onClose, findNext, findPrevious },
    forwardedRef
  ) => {
    const [input, setInput] = React.useState("");
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInput(event.target.value);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" && input !== "") {
        event.shiftKey ? findPrevious(input) : findNext(input);
      }
    };

    const componentKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    const [findBarState] = useFindInDocumentContext();

    const inputElement = useRef<HTMLInputElement>();
    React.useEffect(() => {
      const input = inputElement.current;
      setInput("");
      input?.setRangeText("");
      if (findBarState.findBarVisible) {
        if (input) {
          input.focus();
        }
      }
    }, [findBarState.findBarVisible]);

    return (
      <div
        ref={forwardedRef}
        className={useStyle().findBar}
        onKeyDown={componentKeyDown}
      >
        <div>
          <Translate>
            {({ translate }) => (
              <>
                <MuiTextField
                  inputRef={inputElement}
                  onChange={handleChange}
                  name="findInDocumentInput"
                  label={
                    <Translate
                      id={translationKeys.find_in_document.search_term}
                    />
                  }
                  inputProps={{
                    className: findBarClassNames.findInput,
                    title: translate(
                      translationKeys.find_in_document.search_term
                    ).toString()
                  }}
                  onKeyPress={onKeyDown}
                />

                <IconButton
                  className={findBarClassNames.findPrevious}
                  onClick={() => findPrevious(input)}
                >
                  <KeyboardArrowUpIcon
                    fontSize="small"
                    titleAccess={translate(
                      translationKeys.find_in_document.previous
                    ).toString()}
                  />
                </IconButton>
                <IconButton
                  className={findBarClassNames.findNext}
                  onClick={() => findNext(input)}
                >
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    titleAccess={translate(
                      translationKeys.find_in_document.next
                    ).toString()}
                  />
                </IconButton>
                <span className={findBarClassNames.findResultsCount}>
                  {totalMatches && (
                    <span>
                      {currentMatch}/{totalMatches}
                    </span>
                  )}
                </span>
                <IconButton
                  className={findBarClassNames.findToggle}
                  onClick={() => onClose()}
                >
                  <CloseIcon
                    fontSize="small"
                    titleAccess={translate(
                      translationKeys.find_in_document.close
                    ).toString()}
                  />
                </IconButton>
                <span className={`${findBarClassNames.findSpinner} hidden`}>
                  <LoadingProgress size="small" />
                </span>
              </>
            )}
          </Translate>
        </div>
      </div>
    );
  }
);

const useStyle = makeStyles(theme => ({
  findBar: {
    "& .hidden": {
      display: "none"
    },
    position: "fixed",
    top: "64px",
    zIndex: 26,
    padding: "8px",
    borderTop: 0,
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
    "& input": {
      width: "400px"
    },
    "& button": {
      userSelect: "none",
      cursor: "pointer"
    }
  }
}));
