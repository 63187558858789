import * as React from "react";
import {
  ListSelector,
  ListSelectorItem
} from "../../ui-components/ListSelector";
import DateFormatter from "../../localization/DateFormatter/DateFormatter";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

type SelectAssetCollectionProps = {
  assetCollections: { id: string; title: string; lastModified: Date }[];
  onSelectExisting: (id: string, title: string) => Promise<void>;
  onCreateNew: () => void;
};

export const SelectAssetCollection: React.FC<SelectAssetCollectionProps> = ({
  assetCollections,
  onSelectExisting,
  onCreateNew
}) => {
  return (
    <ListSelector
      actions={
        <ListSelectorItem
          data-test-id="create-new-asset-collection"
          onClick={onCreateNew}
          icon={<ArchiveOutlinedIcon />}
          primary={
            <Translate
              id={translationKeys.ribbon.add_to_new_asset_collection}
            />
          }
        />
      }
    >
      {assetCollections.map(assetCollection => (
        <ListSelectorItem
          variant="primary"
          key={assetCollection.id}
          onClick={() =>
            onSelectExisting(assetCollection.id, assetCollection.title)
          }
          icon={<ArchiveOutlinedIcon />}
          primary={assetCollection.title}
          secondary={
            <>
              <Translate id={translationKeys.assets.last_modified} />
              {": "}
              <DateFormatter value={assetCollection.lastModified} />
            </>
          }
        />
      ))}
    </ListSelector>
  );
};
