import React, { SetStateAction } from "react";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { ModalDialog } from "../../ui-components/ModalDialog";
import Box from "@material-ui/core/Box";
import { Annotation } from "./annotation-state";

export interface DeleteAnnotationConfirmationProps<T extends AnnotationModel> {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  annotation: Annotation<T>;
  removeAnnotation: (annotation: Annotation<T>) => void;
}

export const DeleteAnnotationConfirmation = <T extends AnnotationModel>({
  annotation,
  removeAnnotation,
  open,
  setOpen
}: DeleteAnnotationConfirmationProps<T>) => {
  return (
    <ModalDialog
      title={
        <Translate
          id={translationKeys.annotations.confirm_highlight_delete_title}
        />
      }
      open={open}
      onClose={() => setOpen(false)}
      confirm={{
        action: () => {
          removeAnnotation(annotation);
          setOpen(false);
        },
        caption: (
          <span data-test-id="annotation-delete-confirm">
            <Translate id={translationKeys.document_page.delete} />
          </span>
        )
      }}
      cancel={{
        action: () => setOpen(false),
        caption: <Translate id={translationKeys.document_page.cancel} />
      }}
      fullWidth={false}
    >
      <Box margin={"20px"}>
        <Translate
          id={translationKeys.annotations.confirm_highlight_delete_message}
        />
      </Box>
    </ModalDialog>
  );
};
