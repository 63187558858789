import React from "react";
import { ApiFetchIndicator } from "../../../ui-components/ApiFetchIndicator";
import { useDanishLawBasedTimeline } from "./use-danish-law-based-timeline";
import {
  DanishLawHistoryTimeline,
  DanishLawTimelineNode
} from "./DanishLawHistoryTimeline";
import { useGetTargetRelationsByFamily } from "../use-get-target-relations-by-family";
import {
  DocumentPageConfigurationModel,
  LegalTimelineNodeDto,
  RelatedTargetDocumentFieldSetsDto
} from "@rhinestone/portal-web-api";
import { DanishLawStatus } from "../../danish-law-status";
import { mapToRelationItemModel } from "../../preparatory-work/preparatory-work-helpers";
import { useDocumentPageConfig } from "../../use-document-page-config";

export const DanishLawHistoryTimelineContainer: React.FC<{
  hiveId: string;
  fullName: string;
  familyName: string;
}> = ({ hiveId, fullName, familyName }) => {
  const { status: timelineStatus, data: timelineItems } =
    useDanishLawBasedTimeline(hiveId, fullName, familyName);

  // TODO Could the related document/consolidation information be part of the response
  // from the timeline end-point? Seems strange to have this very business specific
  // knowledge and config in front-ends.
  // Gets documents with target relations to this, with a code of the consolidation  (as configured),
  const [relatedTargetDocuments, relatedStatus] = useGetTargetRelationsByFamily(
    hiveId,
    fullName,
    familyName
  );

  const { data: pageConfig, status: pageConfigStatus } =
    useDocumentPageConfig();

  const nodes = mapToTimelineNodes(
    fullName,
    timelineItems,
    relatedTargetDocuments,
    pageConfig
  );

  return (
    <ApiFetchIndicator
      status={[timelineStatus, relatedStatus, pageConfigStatus]}
    >
      {nodes && <DanishLawHistoryTimeline nodes={nodes} />}
    </ApiFetchIndicator>
  );
};

const mapToTimelineNodes = (
  fullName: string,
  items: LegalTimelineNodeDto[] | undefined,
  relatedTargetDocuments: RelatedTargetDocumentFieldSetsDto[] | undefined,
  pageConfig: DocumentPageConfigurationModel | undefined
): DanishLawTimelineNode[] | undefined => {
  return items?.map((value, index) => ({
    isFirst: index === 0,
    isLast: index === items.length - 1,
    isActive: value.fullName === fullName,
    isLatestEffective:
      items.findIndex(i => i.status === DanishLawStatus.Effective) === index,
    isLatestLbk:
      items.findIndex(i => i.status === DanishLawStatus.EffectiveLbk) === index,
    consolidationDocuments: findRelatedTargetDocuments(
      value.documentId,
      relatedTargetDocuments,
      pageConfig
    ),
    ...value
  }));
};

const findRelatedTargetDocuments = (
  sourceDocumentId: string,
  relatedTargetDocuments: RelatedTargetDocumentFieldSetsDto[] | undefined,
  pageConfig: DocumentPageConfigurationModel | undefined
) => {
  if (!relatedTargetDocuments || !pageConfig) return undefined;

  const relatedTargetDocument = relatedTargetDocuments.find(
    x => x.sourceDocumentId === sourceDocumentId
  );

  if (!relatedTargetDocument) return undefined;

  return mapToRelationItemModel(relatedTargetDocument?.targets, pageConfig).map(
    x => x.title
  );
};
