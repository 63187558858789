import { HtmlNewAnnotationDTO } from "@rhinestone/portal-web-api";
import { excludedFromAnnotationClassName } from "../annotations/ExcludeFromAnnotation";

export class HtmlAnnotationFactory {
  constructor(private contextElement?: HTMLDivElement | null) {}

  public annotationFromRange(range: Range): HtmlNewAnnotationDTO | null {
    if (!this.contextElement) {
      return null;
    }

    // Create range from the document element, which makes up the selection boundary.
    const documentRange = document.createRange();
    documentRange.selectNodeContents(this.contextElement);

    const selectionStartOffset = this.getStartOffset(range, documentRange);
    const innerText = this.cleanWhiteSpace(
      this.getTextFromRangeFast(range)
    ).trim();
    const selectionLength = innerText.length;
    return {
      startOffset: selectionStartOffset,
      endOffset: selectionStartOffset + selectionLength,
      length: selectionLength,
      comment: ""
    };
  }

  // Create range from start of document to start of selection
  private getStartOffset(selectionRange: Range, documentRange: Range) {
    // To prevent modifying the current range, we first make a safe clone that doesn't directly modify the DOM
    const preSelectionRange = selectionRange.cloneRange();

    preSelectionRange.collapse(true);

    // We don't want the current range, but instead the range of everything before the start of the current range
    preSelectionRange.setStart(
      documentRange.startContainer,
      documentRange.startOffset
    );
    const innerText = this.cleanWhiteSpace(
      this.getTextFromRangeFast(preSelectionRange)
    );

    return innerText.length;
  }

  private getTextFromRangeFast(range: Range) {
    const contents = range.cloneContents();

    // Do not include invisible elements
    contents
      .querySelectorAll(`.${excludedFromAnnotationClassName}`)
      .forEach(invisibleElement => {
        invisibleElement.remove();
      });

    // append line-break to td elements
    contents.querySelectorAll("td").forEach(element => {
      element.append("\n");
    });
    // include space before br
    contents.querySelectorAll("br").forEach(element => {
      element.prepend(" ");
    });

    return contents.textContent || "";
  }

  private cleanWhiteSpace(text: string) {
    // replace all line-breaks with one space
    text = text.replace(/\n/g, " ");
    // ensure only one whitespace between words
    text = text.replace(/\s\s+/g, " ");

    // remove white spaces from start of string if present
    return this.trimLeft(text);
  }

  // String.prototype.trimLeft is unsupported in IE11
  private trimLeft(str: string) {
    return str.replace(/^\s+/, "");
  }
}
