import React from "react";
import FolderIcon from "@material-ui/icons/Folder";
import ListItem from "./ListItem";

interface FolderListItemProps {
  title: string;
  link: string;
}
const FolderListItem: React.FC<FolderListItemProps> = ({ title, link }) => {
  const icon = <FolderIcon fontSize="small" />;

  return <ListItem title={title} link={link} icon={icon} />;
};

export default FolderListItem;
