import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import TableWrapper from "../document-components/TableWrapper";
import { convertToReactAttribs } from "./process-attribute-helpers";

const processTables = (): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => {
      return node.name === "table";
    },
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      return (
        <TableWrapper key={index}>
          <table {...convertToReactAttribs(node.attribs)}>{children}</table>
        </TableWrapper>
      );
    }
  };
};

export default processTables;
