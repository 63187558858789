import { PDFViewer } from "pdfjs-dist/legacy/web/pdf_viewer";
import { PDFPageProxy } from "pdfjs-dist/legacy/build/pdf";
import {
  IL10n,
  TextAccessibilityManager,
  AnnotationLayerBuilder
} from "pdfjs-dist/types/web/interfaces";

/**
 * We have subclassed PDFViewer to override some functionality
 */
export class CustomPDFViewer extends PDFViewer {
  constructor(options: any, private annotationLayerFactory: any) {
    super(options);
  }
  public override createAnnotationLayerBuilder(arg: {
    pageDiv: HTMLDivElement;
    pdfPage: PDFPageProxy;
    annotationStorage?: any;
    imageResourcesPath?: string | undefined;
    renderForms: boolean;
    l10n: IL10n;
    enableScripting?: boolean | undefined;
    hasJSActionsPromise?: Promise<boolean> | undefined;
    mouseState?: any | undefined;
    fieldObjectsPromise?: Promise<{ [x: string]: any[] } | null> | undefined;
    annotationCanvasMap?: Map<string, HTMLCanvasElement> | undefined;
    accessibilityManager?: TextAccessibilityManager | undefined;
  }): AnnotationLayerBuilder {
    return this.annotationLayerFactory.createAnnotationLayerBuilder(arg);
  }
}
