import React from "react";
import { RelationItemModel } from "../../preparatory-work/RelationItemModel";
import SectionTextTabContents, {
  TabContentsProps
} from "./SectionTextTabContents";

const BillTextTabContents: React.FC<TabContentsProps> = props => {
  const getSectionIds = (changeRelation?: RelationItemModel) =>
    changeRelation?.targetBookmarks || [];

  return <SectionTextTabContents {...props} getSectionIds={getSectionIds} />;
};

export default BillTextTabContents;
