import {
  SearchResultsExportInfo,
  SearchFilterPeriodModel
} from "@rhinestone/portal-web-api";
import { isValid, parseISO } from "date-fns";
import { format } from "date-fns/esm";
import * as React from "react";
import {
  Language,
  LocalizeContextProps,
  TranslateFunction,
  withLocalize
} from "react-localize-redux";
import { DateRangeValue } from "../../ui-components/DatePicker/DateRangePicker";
import { getDateFormat } from "../../localization/supported-date-locales";
import {
  Criteria,
  CriteriaChange,
  CriteriaChangeType,
  CriteriaProvider
} from "../criterias";
import PeriodSearchFilter from "./PeriodSearchFilter";

export interface Props {
  icon: string;
  color: string;
  filter: SearchFilterPeriodModel;
  registerCriteriaProvider: (provider: CriteriaProvider) => void;
  criteria: Criteria[];
  onCriteriaChanged: (action: CriteriaChange) => void;
  titleResourceFrom: string;
  titleResourceTo: string;
}

const PeriodSearchFilterProvider: React.FunctionComponent<
  Props & LocalizeContextProps
> = ({
  filter,
  registerCriteriaProvider,
  onCriteriaChanged,
  criteria,
  color,
  icon,
  titleResourceFrom,
  titleResourceTo,
  activeLanguage,
  translate
}) => {
  const criteriaDateFormat = "yyyy-MM-dd";

  const initialCriteriaProviderKey = getInitialCriteriaProvider(
    criteria,
    filter
  );

  const [selectedCriteriaProviderKey, setSelectedCriteriaProviderKey] =
    React.useState(initialCriteriaProviderKey);

  const [selectedDateRange, setSelectedDateRange] =
    React.useState<DateRangeValue>({ from: null, to: null });

  React.useEffect(() => {
    registerCriteriaProviders(
      filter,
      registerCriteriaProvider,
      color,
      icon,
      titleResourceFrom,
      titleResourceTo,
      activeLanguage,
      translate
    );
  }, [
    activeLanguage,
    color,
    icon,
    filter,
    registerCriteriaProvider,
    titleResourceFrom,
    titleResourceTo,
    translate
  ]);

  React.useEffect(() => {
    // Set SelectedDateRange from selected criteria provider (keep in mind they come in as URL-friendly strings)
    let dateRangeFromCriteria: DateRangeValue = { from: null, to: null };

    criteria
      .filter((x: Criteria) => x.providerKey === selectedCriteriaProviderKey)
      .forEach(x => {
        if (x.data.from) {
          dateRangeFromCriteria = {
            ...dateRangeFromCriteria,
            from: parseISO(x.data.from)
          };
        }
        if (x.data.to) {
          dateRangeFromCriteria = {
            ...dateRangeFromCriteria,
            to: parseISO(x.data.to)
          };
        }
      });

    setSelectedDateRange(dateRangeFromCriteria);
  }, [criteria, selectedCriteriaProviderKey]);

  const handleSelectedCriteriaProviderKeyChange = (providerKey: string) => {
    const previousCriteriaProviderKey = selectedCriteriaProviderKey;
    setSelectedCriteriaProviderKey(providerKey);

    // Remove previous providerKey dates
    let changes = criteria
      .filter(x => x.providerKey === previousCriteriaProviderKey)
      .map(x => ({
        type: CriteriaChangeType.Remove,
        criteria: x
      }));

    // Add/switch to new providerKey dates (Remove the following code to simply clear dates when dropdown value changes)
    changes = [
      ...changes,
      ...changes.map(x => ({
        type: CriteriaChangeType.Add,
        criteria: {
          ...x.criteria,
          providerKey
        }
      }))
    ];

    if (changes.length > 0) onCriteriaChanged({ changes });
  };

  // eslint-disable-next-line complexity
  const handleSelectedDateRangeChange = (dateRangeValue: DateRangeValue) => {
    setSelectedDateRange(dateRangeValue);

    if (dateRangeValue.from != null && !isValid(dateRangeValue.from)) {
      return;
    }

    if (dateRangeValue.to != null && !isValid(dateRangeValue.to)) {
      return;
    }

    let changes = criteria
      .filter(x => x.providerKey === selectedCriteriaProviderKey)
      .map(x => ({
        type: CriteriaChangeType.Remove,
        criteria: x
      }));

    if (dateRangeValue.from) {
      changes = [
        ...changes,
        {
          type: CriteriaChangeType.Add,
          criteria: {
            data: {
              from: format(dateRangeValue.from, criteriaDateFormat)
            },
            providerKey: selectedCriteriaProviderKey
          }
        }
      ];
    }

    if (dateRangeValue.to) {
      changes = [
        ...changes,
        {
          type: CriteriaChangeType.Add,
          criteria: {
            data: {
              to: format(dateRangeValue.to, criteriaDateFormat)
            },
            providerKey: selectedCriteriaProviderKey
          }
        }
      ];
    }

    onCriteriaChanged({ changes });
  };

  return (
    <PeriodSearchFilter
      filter={filter}
      providerKey={selectedCriteriaProviderKey}
      onProviderKeyChange={handleSelectedCriteriaProviderKeyChange}
      selectedDateRange={selectedDateRange}
      onSelectedDateRangeChange={handleSelectedDateRangeChange}
    />
  );
};

export default withLocalize(PeriodSearchFilterProvider);

function registerCriteriaProviders(
  filter: SearchFilterPeriodModel,
  registerCriteriaProvider: (provider: CriteriaProvider) => void,
  color: string,
  icon: string,
  titleResourceFrom: string,
  titleResourceTo: string,
  activeLanguage: Language,
  translate: TranslateFunction
) {
  // refrain from registering criteria providers if translations are NOT ready
  const translation = translate("document_metadata.title", undefined, {
    onMissingTranslation: () => "TRANSLATION_MISSING"
  });
  if (translation === "TRANSLATION_MISSING") return;

  const titleTo = translate(titleResourceTo).toString();
  const titleFrom = translate(titleResourceFrom).toString();

  // We have to register multiple criteria providers here, as each options is it's own provider
  (filter.options || []).forEach(option => {
    const optionTranslation = translate(option.titleResource || "").toString();

    // Remove this if statement when we have fixed swagger specification for api so that all properties on dtos per default will be non-optional.
    if (!option.flag) {
      throw Error(
        "Configured option.flag coming from the server is null or undefined. This is unexpected and should not happen."
      );
    }

    registerCriteriaProvider(
      getPeriodSearchCriteriaProvider(
        option.flag,
        optionTranslation,
        icon,
        color,
        titleFrom,
        titleTo,
        getDateFormat(activeLanguage.code)
      )
    );
  });
}

function getPeriodSearchCriteriaProvider(
  providerKey: string,
  optionTranslation: string,
  icon: string,
  color: string,
  titleFrom: string,
  titleTo: string,
  dateFormat = "yyyy-MM-dd"
): CriteriaProvider {
  return {
    key: providerKey,
    getCriteriaViewModel: (criteria: Criteria) => {
      if (criteria.providerKey !== providerKey) {
        return null;
      }

      return {
        longDisplayValue: undefined,
        displayValue: criteria.data.from
          ? `${optionTranslation} ${titleFrom} ${format(
              new Date(criteria.data.from),
              dateFormat
            )}`
          : `${optionTranslation} ${titleTo} ${format(
              new Date(criteria.data.to),
              dateFormat
            )}`,
        displayValueResource: undefined,
        criteria,
        icon,
        color
      };
    },
    buildCriteriaDescription: (request: SearchResultsExportInfo) => {
      return {
        ...request
      };
    }
  };
}

function getInitialCriteriaProvider(
  criteria: Criteria[],
  filter: SearchFilterPeriodModel
) {
  if (!filter.options || filter.options.length === 0) return "";

  const firstMatchingCriteriaProvider = criteria.find((c: Criteria) =>
    (filter.options || []).some(option => option.flag === c.providerKey)
  );

  if (firstMatchingCriteriaProvider)
    return firstMatchingCriteriaProvider.providerKey;

  return String(filter.options[0].flag);
}
