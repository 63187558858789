import { SearchViewType } from "@rhinestone/portal-web-api";
import { SearchRequestClient } from "../../../../search/search-request";
import { serializeSearchRequest } from "../../../../search/search-request-url-serializer";
import { RelationQueryType } from "./RelationChips/RelationQueryType";

// This string MUST match the the PropertyName on the configured LinkHubsQueryBuildingSearchDecorator
// for all portals. Even though it is configurable. See values in [RhinestonePortal].[SearchDecoratorConfigurations].[SearchDecorator]
// where serialized value is of type LinkHubsQueryBuildingSearchDecorator
const properNameForRelationGroupSearchDecorator = "related";

export function relationGroupSearchUrl(
  relationGroupName: string | null,
  hiveId: string,
  fullName: string,
  bookmark: string,
  relationQueryType: RelationQueryType | null | undefined,
  searchView?: SearchViewType
) {
  if (relationGroupName === null) {
    // Without a relation-group name we cannot look up relations on the search page
    return null;
  }

  if (searchView === undefined) {
    // can't build search url without default search view
    return null;
  }

  const criteria = [
    {
      providerKey: properNameForRelationGroupSearchDecorator,
      data: bookmark
        ? {
            relationGroup: relationGroupName,
            hiveId,
            fullName,
            bookmark,
            relationQueryType: relationQueryType ?? RelationQueryType.Bookmark
          }
        : {
            relationGroup: relationGroupName,
            hiveId,
            fullName,
            relationQueryType: relationQueryType ?? RelationQueryType.Bookmark
          }
    }
  ];

  const searchRequestClient: SearchRequestClient = {
    fieldSetName: searchView.documentFieldSetName,
    criteria,
    skip: 0,
    page: 1,
    take: searchView.defaultPageSize,
    resultViewName: searchView.name,
    ordering: undefined
  };

  const uri = serializeSearchRequest(searchRequestClient);
  return encodeURI(uri);
}
