import FormTextField, {
  FormTextFieldProps
} from "../../../ui-components/forms/controls/FormTextField";

export type TextProps = {
  fromName: string;
  fromEmail: string;
  toName: string;
  toEmail: string;
  message: string;
};
export type TextKeys = keyof TextProps;

export const TextField: (props: FormTextFieldProps<TextKeys>) => JSX.Element =
  FormTextField;
