import { usePortalProperty } from "../../use-portal-property";

export function useHideRelationsAction(
  getPortalPropertyValue = usePortalProperty
): boolean | undefined {
  const { value: hideRelationsActionButton } = getPortalPropertyValue<boolean>(
    "HideRelationsActionButton"
  );

  return hideRelationsActionButton;
}
