import * as React from "react";
import { useSnackbar } from "../../../ui-components/Snackbar";
import { ClipboardField } from "../../../ui-components/TextField/ClipboardField";

interface LinkFieldProps {
  label: JSX.Element;
  copyLabel: JSX.Element;
  copySuccess: JSX.Element;
  link: string | null;
}
const LinkField: React.FC<LinkFieldProps> = ({
  label,
  copyLabel,
  copySuccess,
  link
}) => {
  const { enqueueInfo } = useSnackbar();
  return (
    <ClipboardField
      value={link ?? ""}
      fullWidth={true}
      label={label}
      labelCopyButton={copyLabel}
      onValueCopied={() => enqueueInfo(copySuccess)}
    />
  );
};

export default LinkField;
