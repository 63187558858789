import * as React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "../IconButton/IconButton";
import Box from "@material-ui/core/Box/Box";
import clsx from "clsx";

// Use this component as child of elements with overflow: hidden and fixed height.
// it will display a pop-over tooltip on clicking the "clamped" text with the full text,
// but only when the parent element is "overflowing"

// The content display will usually be a little smaller than the scroll height, event though
// the element is not scrolling. So we use some slack
const contentRectThreshold = 5; //px.

const useStyles = makeStyles(() => ({
  overflow: {
    cursor: "pointer"
  }
}));

export const ShowMoreToolTip: React.FunctionComponent<{ text?: string }> = ({
  text,
  children
}) => {
  const classes = useStyles();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const displayText = text ? text : children;

  useEffect(() => {
    const currentRef = containerRef.current?.parentElement;
    const observer = new ResizeObserver(entries => {
      const [entry] = entries;
      if (
        entry.target.scrollHeight >
        entry.contentRect.height + contentRectThreshold
      )
        setIsOverflowing(true);
    });
    if (currentRef) observer.observe(currentRef);
    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [containerRef]);

  return (
    <div ref={containerRef}>
      <div
        className={clsx(isOverflowing && classes.overflow)}
        onClick={() => {
          if (isOverflowing) setToolTipOpen(true);
        }}
      >
        {displayText}
      </div>
      <Popover
        open={toolTipOpen}
        anchorEl={containerRef.current}
        onClose={() => setToolTipOpen(false)}
      >
        <Box m={2} display="flex" maxWidth={containerRef.current?.clientWidth}>
          <Box>{displayText}</Box>
          <Box ml={2}>
            <IconButton onClick={() => setToolTipOpen(false)} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};
