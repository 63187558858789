export function scrollToTop() {
  // using experimental smooth transition in attempt to make it more obvious to the user whats going on
  // since its not very intuitive that we scroll page to top
  // if it causes problems just revert to window.scrollTo(0,0)
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: "smooth"
  });
}
