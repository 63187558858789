import { Hidden } from "@material-ui/core";
import React from "react";
import { CollapsedPaneContent } from "./CollapsedPaneContent";
import { MobileDrawer } from "./MobileDrawer";
import { PaneItem } from "./ThreePanesLayout";

interface MobilePanesProps {
  timeline?: PaneItem | null;
  toc?: PaneItem | null;
  right: PaneItem[];
}
const MobilePanes: React.FC<MobilePanesProps> = ({ timeline, toc, right }) => {
  return (
    <Hidden mdUp>
      <MobileDrawer anchor="right">
        {/* render right pane in tablet */}
        <Hidden xsDown mdUp>
          <CollapsedPaneContent items={right} />
        </Hidden>
        {/* render left + right pane in tablet */}
        <Hidden smUp>
          <CollapsedPaneContent
            items={[toc, timeline, ...right].filter(Boolean) as PaneItem[]}
          />
        </Hidden>
      </MobileDrawer>
    </Hidden>
  );
};

export default MobilePanes;
