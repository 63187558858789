import React from "react";
import {
  NumberField,
  NumberFieldStylingProps
} from "../../TextField/NumberField";
import { BaseFormFieldProps } from "./FieldOptions";

import { connectToForm } from "./FormFieldWrapper";

export type FormNumberFieldProps<TFieldName extends string> =
  BaseFormFieldProps<TFieldName, number> & NumberFieldStylingProps;

const FormNumberField: <TFieldName extends string>(
  props: FormNumberFieldProps<TFieldName>
) => JSX.Element = connectToForm(NumberField);

export default FormNumberField;
