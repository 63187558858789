import MaterialUiIconButton, {
  IconButtonProps as MuiIconButtonProps
} from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import * as React from "react";

type IconButtonProps = Pick<
  MuiIconButtonProps,
  | "children"
  | "title"
  | "size"
  | "disabled"
  | "color"
  | "onClick"
  | "onMouseOver"
  | "onMouseOut"
  | "className"
>;

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ title, ...rest }, ref) => {
    // Work-around to display tool-tip on disabled buttons
    // https://mui.com/material-ui/react-tooltip/#disabled-elements
    return title ? (
      <Tooltip title={title}>
        <span>
          <MaterialUiIconButton ref={ref} {...rest} aria-label={title} />
        </span>
      </Tooltip>
    ) : (
      <MaterialUiIconButton ref={ref} {...rest} />
    );
  }
);
