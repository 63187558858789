/* eslint-disable complexity */
import * as React from "react";
import {
  EditorialNotesSectionAction,
  useShouldShowEditorialNoteAction
} from "../../section-actions/EditorialNotesSectionAction";
import { Menu } from "../../../../../ui-components/Menu";
import { useRef } from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../../../gen/translation-keys";
import {
  EditorialNoteList,
  EditorialNote
} from "../../../shared/EditorialNote";
import HtmlRenderer from "../../../../../ui-components/HtmlRenderer";
import { useEditorialNotes } from "../../../shared/MergeDocument/merge-document-util";
import { processLinks } from "../../processing-instructions/process-links";
import {
  ParagraphZoomSectionAction,
  useShouldShowParagraphZoomAction
} from "../../section-actions/ParagraphZoomSectionAction";
import { SectionActionProps } from "../../rhinestone-document-helper-types";
import { MenuItem } from "../../../../../ui-components/MenuItem";
import { useDocumentSectionRelationChipsState } from "../../../shared/document-relation-groups/DocumentRelationChipsStateProvider";
import { RelationsSectionAction } from "../../section-actions/RelationsSectionAction";
import { useGetNewRelativeDocumentUrl } from "../../../../document-page/document-page-helpers";
import { useHistory } from "react-router-dom";
import { useShouldShowRelationSectionAction } from "./relation-section-action/use-should-show-relation-section-action";

export const DocumentSectionActions: React.FC<Omit<SectionActionProps, "ref">> =
  ({ sectionId, sectionClassName, sectionTitle, sectionActionsClassName }) => {
    const menuContainerRef = useRef(null);
    const hasEditorialNotesAction = useShouldShowEditorialNoteAction(sectionId);
    const [showEditorialNotes, setShowEditorialNotes] = React.useState(false);
    const { setExpanded: setSectionRelationChipsExpanded } =
      useDocumentSectionRelationChipsState(sectionId);

    const url = useGetNewRelativeDocumentUrl({ pzoom: sectionId });
    const history = useHistory();

    const onParagraphZoomClick = () => {
      history.replace(url);
    };

    const actions: React.ReactNode[] = [];

    if (hasEditorialNotesAction) {
      actions.push(
        <MenuItem
          onClick={() => setShowEditorialNotes(!showEditorialNotes)}
          key={"EditorialNotesSectionAction"}
        >
          <EditorialNotesSectionAction />
        </MenuItem>
      );
    }

    const shouldShowRelationSectionAction = useShouldShowRelationSectionAction(
      sectionId,
      sectionClassName
    );

    shouldShowRelationSectionAction &&
      actions.push(
        <MenuItem
          onClick={() => setSectionRelationChipsExpanded(true)}
          key={"RelationsSectionAction"}
        >
          <RelationsSectionAction />
        </MenuItem>
      );

    const paragraphZoomEnabled =
      useShouldShowParagraphZoomAction(sectionClassName);

    if (paragraphZoomEnabled) {
      actions.push(
        <MenuItem
          data-test-id="section-menu-item-paragraph-zoom"
          onClick={onParagraphZoomClick}
          key={"ParagraphZoomSectionAction"}
        >
          <ParagraphZoomSectionAction />
        </MenuItem>
      );
    }

    const editorialNotes = useEditorialNotes().filter(
      x => x.targetElementId === sectionId
    );

    // Eventually all sections will have actions so this should rather be a quick hack than something
    // all the actions have to be thought around..
    if (actions.length === 0) return null;

    return (
      <>
        {hasEditorialNotesAction && (
          <EditorialNoteList
            title={<Translate id={translationKeys.document_page.notes} />}
            subheader={sectionTitle}
            anchorEl={showEditorialNotes ? menuContainerRef.current : null}
            onClose={() => setShowEditorialNotes(false)}
          >
            {editorialNotes.map(editorialNote => (
              <EditorialNote key={editorialNote.sectionId}>
                <HtmlRenderer
                  html={editorialNote.text}
                  processingInstructions={[processLinks()]}
                />
              </EditorialNote>
            ))}
          </EditorialNoteList>
        )}

        <div
          ref={menuContainerRef}
          data-test-id="section-menu"
          className={sectionActionsClassName}
        >
          <Menu>{actions}</Menu>
        </div>
      </>
    );
  };
