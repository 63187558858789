import * as React from "react";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import Typography from "@material-ui/core/Typography";
import { ListItemIcon } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../../gen/translation-keys";
import { useEditorialNotes } from "../../shared/MergeDocument/merge-document-util";

export const EditorialNotesSectionAction: React.FC = () => {
  return (
    <>
      <ListItemIcon>
        <NoteOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <Typography noWrap>
        <Translate id={translationKeys.document_page.notes} />
      </Typography>
    </>
  );
};

export function useShouldShowEditorialNoteAction(sectionId: string) {
  return useEditorialNotes().some(x => x.targetElementId === sectionId);
}
