import * as React from "react";
import { Avatar } from "../../../ui-components/Avatar/Avatar";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader
} from "../../../ui-components/Card";
import { IconButton } from "../../../ui-components/IconButton";
import { EditOutlined, EmailOutlined, Person } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "../../../ui-components/List";

export interface ShowProfileFormProps {
  name: string;
  organizationName?: string;
  emails: string[];
  canEdit: boolean;
  edit: () => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275
  },
  title: {
    marginBottom: 0
  },
  action: {
    marginLeft: "auto"
  },
  listIcon: {
    minWidth: 0,
    marginRight: theme.spacing(1)
  }
}));

export const ShowProfile: React.FC<ShowProfileFormProps> = ({
  emails,
  name,
  organizationName,
  canEdit,
  edit
}) => {
  const classes = useStyles();

  const initials = name
    .split(" ")
    .filter(name => name !== "")
    .map(name => name.charAt(0).toUpperCase())
    .slice(0, 2)
    .join("");

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        avatar={
          <Avatar>{initials.length === 2 ? initials : <Person />}</Avatar>
        }
        title={
          <Typography variant="h5" component="h2" className={classes.title}>
            {name}
          </Typography>
        }
        subheader={
          organizationName && (
            <Typography color="textSecondary">{organizationName}</Typography>
          )
        }
        action={
          canEdit && (
            <IconButton className={classes.action} onClick={edit}>
              <EditOutlined />
            </IconButton>
          )
        }
      ></CardHeader>
      <CardContent>
        <List>
          {emails.map(mail => (
            <ListItem key={mail}>
              <ListItemIcon className={classes.listIcon}>
                <EmailOutlined />
              </ListItemIcon>
              <ListItemText primary={mail} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default ShowProfile;
