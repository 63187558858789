import {
  Typography,
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import * as React from "react";
import { ChatResponseSourceDto } from "@rhinestone/portal-web-api";
import { ExpandMore } from "@material-ui/icons";

interface AllSourcesProps {
  citations: ChatResponseSourceDto[];
  isStreaming: boolean;
}

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  history: {
    display: "flex",
    flexDirection: "column"
  }
}));

const AllSources: React.FunctionComponent<AllSourcesProps> = ({
  citations,
  isStreaming
}) => {
  const classes = useStyles();

  if (citations.length === 0 || isStreaming) return null;

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Alle kilder</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.history}>
          {citations.map(citation => (
            <Typography key={citation.index}>
              <a href={citation.url} target="_blank" rel="noopener noreferrer">
                <b> {citation.index}</b> {citation.documentTitle}{" "}
                {citation.sliceBookmark}
              </a>
              <br />
              <code>(score: {citation.score})</code>
              <br />
              <code>(similarity: {citation.similarity})</code>
              <br />
              <code>(reranker: {citation.semanticRerankerScore})</code>
              <br />
            </Typography>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AllSources;
