import React from "react";
import { ParagraphZoomTextContainer } from "../content/ParagraphZoomTextContainer";
import NoDataWarningTabContent from "./NoDataWarningTabContent";
import ParagraphZoomTabContents from "./ParagraphZoomTabContents";
import processParagraphSectionHeaderTabLink from "../../content/rhinestone-xml/processing-instructions/process-paragraph-section-header-tab-link";
import { RelationItemModel } from "../../preparatory-work/RelationItemModel";

export interface TabContentsProps {
  selectedChangeRelation?: RelationItemModel;
  headerLinkText: JSX.Element;
}

interface SectionTextContentsProps extends TabContentsProps {
  getSectionIds: (changeRelation?: RelationItemModel) => string[];
}

const SectionTextTabContents: React.FC<SectionTextContentsProps> = ({
  selectedChangeRelation,
  getSectionIds,
  headerLinkText
}) => {
  const sectionIds = getSectionIds(selectedChangeRelation);
  const relationHiveId = selectedChangeRelation?.hiveId;
  const relationFullName = selectedChangeRelation?.fullName;

  const content = relationHiveId && relationFullName && sectionIds && (
    <ParagraphZoomTextContainer
      hiveId={relationHiveId}
      fullName={relationFullName}
      sectionIds={sectionIds}
      noDataWarning={<NoDataWarningTabContent />}
      additionalParsers={[
        processParagraphSectionHeaderTabLink(
          relationHiveId,
          relationFullName,
          headerLinkText
        )
      ]}
    />
  );

  return <ParagraphZoomTabContents>{content}</ParagraphZoomTabContents>;
};
export default SectionTextTabContents;
