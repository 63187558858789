//a material ui context menu component
import React from "react";
import ContextMenu, { ContextMenuPosition, MenuItem } from "./ContextMenu";

interface MouseContextMenuProps {
  menuItems?: MenuItem[];
  shouldDisplayMenu?: () => boolean;
}

const MouseContextMenu: React.FC<MouseContextMenuProps> = ({
  children,
  menuItems,
  shouldDisplayMenu
}) => {
  const [position, setPosition] = React.useState<ContextMenuPosition>();
  const shouldDisplayMenuInternal = shouldDisplayMenu
    ? shouldDisplayMenu
    : () => true;

  const handleContextMenu = (event: React.MouseEvent) => {
    if (menuItems && menuItems.some(i => !i.hidden?.() || false)) {
      if (position !== undefined) {
        event.preventDefault();
        setPosition(undefined);
      }
      if (shouldDisplayMenuInternal()) {
        event.preventDefault();
        setPosition(
          position === undefined
            ? { mouseX: event.clientX, mouseY: event.clientY }
            : undefined
        );
      }
    }
  };

  return (
    <span onContextMenu={handleContextMenu} style={{ cursor: "context-menu" }}>
      {menuItems && menuItems.some(i => !i.hidden?.() || false) && (
        <ContextMenu position={position} menuItems={menuItems} />
      )}
      {children}
    </span>
  );
};

export default MouseContextMenu;
