import * as React from "react";
import { FC } from "react";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import { IconButton } from "../ui-components/IconButton";
import { Button, TextField, makeStyles } from "@material-ui/core";

interface ResponseFeedbackProps {
  response: string;
  isStreaming: boolean;
}

type Feedback = "like" | "dislike";

const useStyles = makeStyles(theme => ({
  dislike: {
    color: theme.palette.error.main
  },
  like: {
    color: theme.palette.success.main
  },
  feedback: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  submitButton: {
    marginTop: theme.spacing(1)
  }
}));
const ResponseFeedback: FC<ResponseFeedbackProps> = ({
  response,
  isStreaming
}) => {
  const classes = useStyles();

  const [responseFeedback, setResponseFeedback] = React.useState<
    Feedback | undefined
  >();
  const [responseFeedbackText, setResponseFeedbackText] =
    React.useState<string>("");

  const handleFeedback = (feedback: Feedback) => {
    if (feedback === responseFeedback) {
      setResponseFeedback(undefined);
      return;
    }

    setResponseFeedback(feedback);
  };

  const handleFeedbackTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setResponseFeedbackText(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Send feedback to api
    console.log({ responseFeedback, responseFeedbackText, response });
  };

  if (isStreaming || !response) {
    return null;
  }

  return (
    <div>
      <div className={classes.feedback}>
        Kunne du lide dette svar?{" "}
        <IconButton onClick={() => handleFeedback("dislike")} size="small">
          <ThumbDownAltIcon
            className={responseFeedback === "dislike" ? classes.dislike : ""}
          />
        </IconButton>
        <IconButton onClick={() => handleFeedback("like")} size="small">
          <ThumbUpAltIcon
            className={responseFeedback === "like" ? classes.like : ""}
          />
        </IconButton>
      </div>
      <div>
        {responseFeedback && (
          <form onSubmit={handleSubmit}>
            <TextField
              id="standard-basic"
              onChange={handleFeedbackTextChange}
              fullWidth
              label="Giv din feedback her"
              multiline
              rows={4}
              variant="outlined"
            />
            <Button
              variant="outlined"
              type="submit"
              className={classes.submitButton}
            >
              Send feedback
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResponseFeedback;
