import * as React from "react";
import { ParagraphZoomText } from "./ParagraphZoomText";
import { useDocumentSections } from "../paragraph-zoom-helpers";
import { HtmlToReactPreprocessingInstructions } from "html-to-react";
import MissingSectionDataWarning from "../tabs/MissingSectionDataWarning";
import { getMissingIds } from "./get-missing-ids";
import { Divider, makeStyles } from "@material-ui/core";
import { SmallTextSkeleton } from "../../../ui-components/Skeleton/SmallSkeleton";

export interface ParagraphZoomTextContainerProps {
  hiveId: string;
  fullName: string;
  sectionIds: string[];
  noDataWarning?: JSX.Element;
  additionalParsers?: HtmlToReactPreprocessingInstructions[];
}

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export const ParagraphZoomTextContainer: React.FC<ParagraphZoomTextContainerProps> =
  ({ hiveId, fullName, sectionIds, noDataWarning, additionalParsers }) => {
    const classes = useStyles();
    // get data from API based on the currentParagraphInTime
    const { data: sections } = useDocumentSections(
      hiveId,
      fullName,
      sectionIds
    );

    const missingIds = getMissingIds(sections ?? [], sectionIds);

    return sections ? (
      sections.length > 0 || missingIds.length > 0 ? (
        <>
          {sections.map((section: string, index: number) => (
            <>
              <ParagraphZoomText
                key={index}
                paragraphXml={section || ""}
                additionalParsers={additionalParsers}
              />
              <Divider className={classes.divider} />
            </>
          ))}
          {missingIds.map(missingSectionId => (
            <MissingSectionDataWarning
              key={missingSectionId}
              sectionTitle={missingSectionId}
            />
          ))}
        </>
      ) : (
        noDataWarning || null
      )
    ) : (
      <SmallTextSkeleton />
    );
  };
