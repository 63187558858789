/* eslint-disable complexity */
import React from "react";
import DanishLawStatusWatermark from "./DanishLawStatusWatermark";
import DocumentStatusWatermark from "./DocumentStatusWatermark";
import { useIsDanishLaw } from "../../../AboutDocumentFamily/use-is-danish-law";

const DocumentWatermarkContainer: React.FC = () => {
  const { isDanishLaw, isFetching: isFetchingIsDanishLaw } = useIsDanishLaw();

  if (isFetchingIsDanishLaw) return <></>;

  return isDanishLaw ? (
    <DanishLawStatusWatermark />
  ) : (
    <DocumentStatusWatermark />
  );
};
export default DocumentWatermarkContainer;
