import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { LegacyNavBarHeightInPixels } from "../../legacy-app-support/LegacyGlobalCssOverrides";
import HeaderExtension from "../../ui-components/layouts/HeaderExtension";
import { Box } from "@material-ui/core";
import PageTitleTile from "./PageTitleTile";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.alternativeBackgroundColor,
    minHeight: "calc(100vh - " + (LegacyNavBarHeightInPixels + 3) + "px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
}));

export type PageProps = {
  children: React.ReactNode;
  title: React.ReactNode;
};

const Page: React.FC<PageProps> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <HeaderExtension />
      <PageTitleTile titleText={title}>
        <Box>{children}</Box>
      </PageTitleTile>
    </div>
  );
};

export default Page;
