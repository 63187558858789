import React from "react";
import { NumberChip } from "../../../../../ui-components/Chip";
import { makeStyles } from "@material-ui/core";

export interface RelationChipProps {
  relationCount: number;
  label: string | JSX.Element;
  name: string | undefined;
  onClick?: () => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: "inline"
  }
}));

const RelationChip: React.FC<RelationChipProps & { sectionId?: string }> = ({
  relationCount,
  label,
  name,
  onClick
}) => {
  const classes = useStyles();
  return (
    <div data-test-id={"relation-chip-" + name} className={classes.container}>
      <NumberChip
        value={relationCount}
        label={label}
        onClick={() => onClick && onClick()}
      />
    </div>
  );
};

export default RelationChip;
