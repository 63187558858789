import {
  SearchRequestClient,
  UrlTokens,
  deserializeSearchRequest
} from "../../../index.modules";
import { usePortalApi } from "../../api-access/use-portal-api";
import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

export function useDocumentSearchHighlights(
  documentId: string | undefined,
  searchRequest: SearchRequestClient
) {
  return usePortalApi(
    client => client.getHighlights(documentId as string, searchRequest),
    ["getHighlights", documentId, JSON.stringify(searchRequest.criteria)],
    {
      enabled:
        documentId !== undefined &&
        searchRequest.criteria.findIndex(c => c.providerKey === "terms") >= 0
    }
  ).data;
}

export function useGetAndRemoveSearchRequestFromUri() {
  const [searchRequest, setSearchRequest] = useState<SearchRequestClient>({
    criteria: []
  });
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (!queryParams.has(UrlTokens.search)) return;

    const searchQuery = queryParams.get(UrlTokens.search);
    if (searchQuery != null) {
      const request = deserializeSearchRequest(searchQuery);
      request.skip = 0; // Reset paging. This is a fix for bug #117755
      setSearchRequest(request);
    }

    queryParams.delete(UrlTokens.search);
    history.replace({
      ...location,
      search: queryParams.toString()
    });
  }, [history, location]);

  return searchRequest;
}
