import { AnnotationModel } from "@rhinestone/portal-web-api";
import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { MenuItem } from "../../../ui-components/ContextMenu/ContextMenu";

export function deleteAnnotationMenuItem(
  annotation: AnnotationModel | undefined,
  setOpenConfirmation: React.Dispatch<React.SetStateAction<boolean>>
): MenuItem {
  return {
    onClick: () => setOpenConfirmation(true),
    label: (
      <span data-test-id="delete-annotation">
        <Translate id={translationKeys.annotations.delete} />
      </span>
    ),
    hidden: () => annotation === undefined
  };
}
