import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { ShowMoreText } from "../../../ui-components/ShowMoreText";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  moreLessWrapper: {
    fontSize: "50%"
  },
  titleNotes: {
    marginTop: -24
  }
}));
type noteIdChild = {
  props: {
    noteId: string | undefined;
  };
};

export const DocumentTitle = React.forwardRef<HTMLElement, any>(
  (props, forwardedRef) => {
    const classes = useStyles();
    const more = (
      <Box component="span" className={classes.moreLessWrapper}>
        <Translate id={translationKeys.document_page.show_more} />
      </Box>
    );
    const less = (
      <Box component="span" className={classes.moreLessWrapper}>
        <Translate id={translationKeys.document_page.show_less} />
      </Box>
    );
    const noneNoteChildren = Array.isArray(props.children)
      ? props.children?.filter((child: noteIdChild) => {
          return !child.props?.noteId;
        })
      : props.children;

    const noteChildren = Array.isArray(props.children)
      ? props.children?.filter((child: noteIdChild) => {
          return !!child.props?.noteId;
        })
      : null;

    return (
      <>
        <Typography ref={forwardedRef} variant="h1">
          <ShowMoreText more={more} less={less} lines={4}>
            {noneNoteChildren}
          </ShowMoreText>
        </Typography>
        <Box className={classes.titleNotes}>{noteChildren}</Box>
      </>
    );
  }
);
