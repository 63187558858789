import React from "react";
import { ApiFetchIndicator } from "../../../ui-components/ApiFetchIndicator";
import { useCurrentDocumentPageParameters } from "../../document-page/document-page-helpers";
import { ParagraphZoomDataError } from "../errors/ParagraphZoomDataError";
import { getParagraphLawTitle } from "../paragraph-zoom-helpers";
import { useParagraphZoomContext } from "../ParagraphZoomContext";
import { ParagraphRelations } from "./ParagraphRelations";

const ParagraphZoomTitle: React.FC = () => {
  const { pzoom: paragraphId } = useCurrentDocumentPageParameters();
  const { currentParagraphInTime, status, error } = useParagraphZoomContext();

  const hiveId = currentParagraphInTime?.portalDocumentContext.hiveId;
  const fullName = currentParagraphInTime?.portalDocumentContext.fullName;

  return (
    <ApiFetchIndicator
      status={status}
      errorComponent={<ParagraphZoomDataError error={error} />}
    >
      {currentParagraphInTime && paragraphId && (
        <>
          {getParagraphLawTitle(currentParagraphInTime)}
          {hiveId && fullName && (
            <ParagraphRelations
              hiveId={hiveId}
              fullName={fullName}
              paragraphId={paragraphId}
            />
          )}
        </>
      )}
    </ApiFetchIndicator>
  );
};

export default ParagraphZoomTitle;
