import React from "react";
import { LoadingProgress } from "../../../../../ui-components/LoadingProgress";
import { useParagraphZoomContext } from "../../../../paragraph-zoom/ParagraphZoomContext";
import { RelationQueryType } from "../RelationChips/RelationQueryType";
import { useRelationSearchUrl } from "../RelationChips/use-get-relation-search-url";
import { useDocumentRelationGroupsForBookmark } from "../use-document-relation-groups";
import RelationsDialogContainer from "./RelationsDialogContainer";

interface PZoomRelationDialogProps {
  paragraphId: string;
  groupName?: string;
}
const PZoomRelationDialog: React.FC<PZoomRelationDialogProps> = ({
  paragraphId,
  groupName
}) => {
  const { currentParagraphInTime } = useParagraphZoomContext();

  return currentParagraphInTime ? (
    <PZoomRelationDialogInner
      paragraphId={paragraphId}
      groupName={groupName}
      hiveId={currentParagraphInTime?.portalDocumentContext.hiveId}
      fullName={currentParagraphInTime?.portalDocumentContext.fullName}
    />
  ) : (
    <LoadingProgress />
  );
};

interface PZoomRelationDialogInnerProps {
  paragraphId: string;
  groupName?: string;
  hiveId: string;
  fullName: string;
}
const PZoomRelationDialogInner: React.FC<PZoomRelationDialogInnerProps> = ({
  paragraphId,
  groupName,
  hiveId,
  fullName
}) => {
  const { data, status } = useDocumentRelationGroupsForBookmark(
    true,
    hiveId,
    fullName,
    paragraphId
  );
  const searchUrl = useRelationSearchUrl(
    hiveId,
    fullName,
    RelationQueryType.Bookmark,
    paragraphId,
    groupName
  );

  return (
    <RelationsDialogContainer
      data={data}
      status={status}
      groupName={groupName}
      openLinksInNewTab={true}
      searchUrl={searchUrl}
    />
  );
};

export default PZoomRelationDialog;
