import React from "react";
import { Translate } from "react-localize-redux";
import { IconRenderProps } from "../../../ui-components/split-timeline";
import { ParagraphStatusIcon } from "../../StatusIcons/ParagraphStatusIcons";
import {
  getParagraphStatusTranslationKey,
  ParagraphStatus
} from "../paragraph-status";

interface ParagraphTimelineIconProps extends IconRenderProps {
  paragraphState: ParagraphStatus;
}
export const ParagraphTimelineIcon: React.FC<ParagraphTimelineIconProps> =
  props => (
    <Translate>
      {({ translate }) => (
        <ParagraphStatusIcon
          status={props.paragraphState}
          active={props.active}
          className={props.className}
          alt={translate(
            getParagraphStatusTranslationKey(props.paragraphState)
          ).toString()}
          fontSize={props.fontSize}
        />
      )}
    </Translate>
  );
