import React from "react";
import { LoadingProgress } from "../../ui-components/LoadingProgress";
import { Box } from "@material-ui/core";
import { TimelineConsolidationInfo } from "../consolidation-Information/TimelineConsolidationInfo/TimelineConsolidationInfo";
import { ConsolidationConfiguration } from "../preparatory-work";
import { useGetTargetRelations } from "../preparatory-work/use-get-target-relations";

interface ToolTipContentProps {
  hiveId: string;
  fullName: string;
  paragraphId?: string;
  getRelationTypeCode: (
    consolidationConfiguration: ConsolidationConfiguration | undefined
  ) => string;
}
export const ToolTipContent: React.FC<ToolTipContentProps> = ({
  hiveId,
  fullName,
  paragraphId,
  getRelationTypeCode
}) => {
  const [targetRelations, isLoading] = useGetTargetRelations({
    hiveId,
    fullName,
    bookmark: paragraphId,
    getRelationTypeCode
  });
  return isLoading ? (
    <Box m={1} color="white">
      <LoadingProgress color="inherit" size={12} />
    </Box>
  ) : (
    <TimelineConsolidationInfo data={targetRelations.map(x => x.title)} />
  );
};
