import React from "react";
import { useDocumentInfo } from "../../../../document-context";
import { useShouldAlwaysShowRelationsAction } from "../../../../DocumentActionMenu/document-actions/relations-action/use-should-always-show-relations-action";
import { useSectionHasRelations } from "../use-document-relation-groups";
import { SectionRelationChips } from "./SectionRelationChips";

export interface SectionRelationChipsProps {
  sectionId: string;
}

/**
 * Maybe a weird name, but used to enable conditional loading of SectionRelationChips
 * In large documents, with ids on stk we potentially have 8000+ of these loaded,
 * and we dont want to instantiate react-query hooks of all of these, since it is often just a handful
 * or maybe max 100 sections in document that actually have relations
 */
export const DelayedSectionRelationChips: React.FC<SectionRelationChipsProps> =
  ({ sectionId }) => {
    const { hiveId, fullName, taxons } = useDocumentInfo();
    const { shouldAlwaysShowRelationsAction: shouldShowRelationsActionFast } =
      useShouldAlwaysShowRelationsAction(taxons);
    const hasRelations = useSectionHasRelations(sectionId, hiveId, fullName);

    return hasRelations || shouldShowRelationsActionFast ? (
      <SectionRelationChips sectionId={sectionId} />
    ) : null;
  };
