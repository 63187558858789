import { usePortalApi } from "../../api-access/use-portal-api";
import { HardCodedFieldSetName } from "../document-fieldsets";
import { mapFieldsToRelationItem } from "./map-fields-to-prep-chain-item";
import {
  documentIsLoaded,
  pageConfigurationIsLoaded,
  relationTypesIsConfigured,
  useConsolidationAndPageConfig
} from "./preparatory-work-helpers";
import { RelationItemModel } from "./RelationItemModel";

export function useGetSourceRelations(
  hiveId: string,
  fullName: string,
  relationTypeCode: string,
  bookmark: string | undefined = undefined
): [RelationItemModel[], boolean] {
  const {
    pageConfiguration,
    pageConfigurationStatus,
    consolidationRelationTypeCode,
    preparationRelationTypeCode
  } = useConsolidationAndPageConfig();

  const { data, isFetching } = usePortalApi(
    client =>
      client.getDocumentSourceRelationFieldsSets(
        hiveId,
        fullName,
        relationTypeCode,
        HardCodedFieldSetName,
        bookmark ?? ""
      ),
    [
      "getDocumentSourceRelationFieldsSets",
      hiveId,
      fullName,
      relationTypeCode,
      HardCodedFieldSetName,
      bookmark ?? ""
    ],
    {
      enabled:
        documentIsLoaded(hiveId, fullName) &&
        pageConfigurationIsLoaded(pageConfigurationStatus, pageConfiguration) &&
        relationTypesIsConfigured(
          consolidationRelationTypeCode,
          preparationRelationTypeCode
        )
    }
  );

  const mappedRelations = data?.map(x => {
    return mapFieldsToRelationItem(
      pageConfiguration,
      x.fieldSet,
      x.sourceBookmarkIds
    );
  });
  return [mappedRelations || [], isFetching];
}
