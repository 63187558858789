import { EventBus, PageRenderedEvent } from "pdfjs-dist/legacy/web/pdf_viewer";
import { useEffect } from "react";

export function usePdfJsEvents(
  eventBus: EventBus | undefined,
  pdfJsPageRenderedHandler: (e: PageRenderedEvent) => void,
  pdfJsPagesLoadedHandler: (e: PageRenderedEvent) => void,
  pdfJsTextLayerRenderedHandler: (e: PageRenderedEvent) => void
) {
  useEffect(() => {
    if (eventBus === undefined) return;
    eventBus.on("pagerendered", pdfJsPageRenderedHandler as (e: Event) => void);
    eventBus.on("pagesloaded", pdfJsPagesLoadedHandler as (e: Event) => void);
    eventBus.on(
      "textlayerrendered",
      pdfJsTextLayerRenderedHandler as (e: Event) => void
    );
    // Clean up event handlers
    return () => {
      eventBus.off(
        "pagerendered",
        pdfJsPageRenderedHandler as (e: Event) => void
      );
      eventBus.off(
        "pagesloaded",
        pdfJsPagesLoadedHandler as (e: Event) => void
      );
      eventBus.off(
        "textlayerrendered",
        pdfJsTextLayerRenderedHandler as (e: Event) => void
      );
    };
  }, [
    eventBus,
    pdfJsPageRenderedHandler,
    pdfJsPagesLoadedHandler,
    pdfJsTextLayerRenderedHandler
  ]);
}
