import { SearchTermsAnalyzerModel } from "@rhinestone/portal-web-api";

export interface RegexBasedTermsAnalyzerResult {
  analyzedTerms: string;
  analyzedTermsParts: string[];
  remainingTerms: string;
}

/**
 * This term analyzer is based on regex to "activate" and analyze terms for criteria creation.
 * In the future we *might* have more analyzer that rely on other forms of term analyzation than regex.
 */
export class RegexBasedTermsAnalyzer {
  providerKey: string;
  regex: RegExp;
  replaceExpression: string;

  constructor(searchTermAnalyzer: SearchTermsAnalyzerModel) {
    this.providerKey = searchTermAnalyzer.criteriaProviderKey;
    this.regex = RegExp(searchTermAnalyzer.regex, "iu");
    this.replaceExpression = searchTermAnalyzer.replaceExpression;
  }

  analyzeTerms(termsInput: string): RegexBasedTermsAnalyzerResult | undefined {
    const regexMatch = termsInput.trim().match(this.regex);

    if (!regexMatch || regexMatch.length < 1) return undefined;

    const normalizedInput = regexMatch[0].replace(
      this.regex,
      this.replaceExpression
    );

    // Split the normalized input into parts using the regex groups
    const groups = (regexMatch || []).slice(1).map(x => (x ? x : ""));

    return {
      analyzedTerms: normalizedInput,
      analyzedTermsParts: groups,
      remainingTerms: termsInput
        .replace(this.regex, "") // Subtract whatever the regex matched
        .replace(/\s+/g, " ") // Replace double spaces with single space
        .trim()
    };
  }
}
