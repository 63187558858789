import React from "react";
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps
} from "@material-ui/core";
import { FormFieldProps } from "../forms/controls/FormFieldProps";

export type TextFieldStylingProps = Pick<
  MuiTextFieldProps,
  | "id"
  | "fullWidth"
  | "label"
  | "required"
  | "autoFocus"
  | "autoComplete"
  | "inputProps"
  | "className"
  | "disabled"
  | "multiline"
  | "rows"
  | "rowsMax"
  | "type"
  | "onChange"
>;
type TextFieldProps = TextFieldStylingProps & FormFieldProps;

export function TextField(props: TextFieldProps) {
  const { innerRef, ...rest } = props;
  return <MuiTextField {...rest} inputRef={innerRef} />;
}
