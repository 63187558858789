import { useScrollEvents } from "../../../../browser-utils/use-scroll";
import { SectionAncestorMap } from "./document-components/FoldableSection";
import { useMemo } from "react";
import { ArticleAction } from "../../DocumentActionMenu";
import processDocumentHeader from "./processing-instructions/process-document-header";
import processDocumentTitleTags from "./processing-instructions/process-document-title-tags";
import processInlineNotes from "./processing-instructions/process-inline-notes";
import processImages from "./processing-instructions/process-images";
import processSectionsWithIds from "./processing-instructions/process-section-with-ids";
import processSectionHeaderTags from "./processing-instructions/process-section-header-tags";
import processLocalNoteTags from "./processing-instructions/process-local-notes-tags";
import processDefaultTags from "./processing-instructions/process-default-tags";
import { Parser as HtmlToReactParser } from "html-to-react";
import processAnnotations from "./processing-instructions/process-annotations";
import { processLinks } from "./processing-instructions/process-links";
import { SectionAction } from "./rhinestone-document-helper-types";
import { HtmlAnnotationState } from "../../annotations/html/html-annotation-state";
import processTables from "./processing-instructions/process-tables";
import processAnonymousImg from "./processing-instructions/process-anonymous-img-tags";
import { useApiTicket } from "../../../api-access/use-api-ticket";
import processHeaderTagsDowngrade from "./processing-instructions/process-header-tags-downgrade";
import { usePortalProperty } from "../../use-portal-property";
import { DocumentTaxonDto } from "@rhinestone/portal-web-api";

const htmlToReactParser = new HtmlToReactParser();

/**
 * This function is a copy from
 * `\Platform.Management.Web\ClientApp\utils\tryToFixInvalidHTML.ts`
 *
 * Returns rhinestoneXml parsed as a valid HTML document. This is important as the content is not
 * rendered directly to the browser, but piped through react that expects valid html to work.
 *
 * Unresolvable parser errors will be attached in a <parsererror> element at the top of the output.
 */
export const parseRhinestoneXml = (rhinestoneXml: string) => {
  const parser = new DOMParser();
  const parsedAsXml = parser.parseFromString(rhinestoneXml, "text/xml");

  //remove empty anchor tags
  let inputString = parsedAsXml.documentElement.outerHTML.replace(
    /<a [^>]*\/>/g,
    ""
  );
  //handle empty tags to prevent errors when parsing as html
  inputString = inputString.replace(
    /(<([a-z1-6]*) [^>]*)(\/>)/g,
    (_, b, c) => b + "></" + c + ">"
  );

  const parsedAsHtml = parser.parseFromString(inputString, "text/html");

  removeInvalidChildren(
    parsedAsHtml,
    "table, thead, tbody, tfoot, tr",
    child => child.nodeType === child.TEXT_NODE
  );

  return parsedAsHtml.body;
};

/**
 * Mutable function handles cases with invalid nesting of DOM-nodes. For example, #text-nodes
 * are not allowed in <tr> tags. This is a no-op, and browsers might handle this
 * differently causing issues.
 * @param documentOut The Document instance that should be mutated.
 * @param querySelector
 * @param shouldRemovedChild Any node returning truthy in this filter will be removed.
 */
const removeInvalidChildren = (
  documentOut: Document,
  querySelector: string,
  shouldRemovedChild: (node: ChildNode) => boolean
) => {
  Array.from(documentOut.querySelectorAll(querySelector)).forEach(element => {
    Array.from(element.childNodes)
      .filter(shouldRemovedChild)
      .forEach(child => element.removeChild(child));
  });
};

export function useRhinestoneXmlToReactParser(
  rhinestoneXml: string,
  renderAdditionalDocumentInformation: () => React.ReactNode = () => null,
  renderArticleActions: ArticleAction = () => null,
  renderSectionActions: SectionAction = () => null,
  annotationState: HtmlAnnotationState | undefined
) {
  const ticketId = useApiTicket();

  const { value: disableHttpToHttpsLinks } = usePortalProperty<boolean>(
    "DisableHttpToHttpsLinks"
  );

  return useMemo(() => {
    // using mutable map for performance reasons (many thousand sections is possible in documents)
    const mutableSectionIdAncestorMap: SectionAncestorMap = {};

    const parsedAsDocument = parseRhinestoneXml(rhinestoneXml);
    /*
      Because some documents have thousands of sections of ids which means document loading takes more than 20 sec, sometimes more than minute
      we introduce this experimental optimization of utilising MUIs unmountOnExit (https://material-ui.com/components/accordion/#performance) 
      and force default collapse state of foldable sections if we have one of these massive documents.
    */

    const rhinestoneXmlAsReactComponentTree =
      htmlToReactParser.parseWithInstructions(
        parsedAsDocument.innerHTML,
        () => true,
        [
          processDocumentHeader(
            renderAdditionalDocumentInformation,
            renderArticleActions
          ),
          processDocumentTitleTags(),
          processInlineNotes(),
          processSectionsWithIds(
            mutableSectionIdAncestorMap,
            renderSectionActions
          ),
          processSectionHeaderTags(),
          processLocalNoteTags(),
          processLinks(disableHttpToHttpsLinks),
          processTables(),
          processAnonymousImg(ticketId),
          processImages(),
          processAnnotations(annotationState),
          processHeaderTagsDowngrade(),
          processDefaultTags
        ],
        []
      );
    return {
      rhinestoneDocumentContent: rhinestoneXmlAsReactComponentTree,
      sectionAncestorMap: mutableSectionIdAncestorMap
    };
  }, [
    ticketId,
    annotationState,
    renderAdditionalDocumentInformation,
    renderArticleActions,
    renderSectionActions,
    rhinestoneXml,
    disableHttpToHttpsLinks
  ]);
}

// TODO: This function has some performance-issues 🍆
// Consider refactor to using IntersectionObserver API to check which section comes into view in an event-based way.
export function useTrackElementInView(
  documentContainerElement: HTMLDivElement | null,
  scrollTrackOffset?: number
) {
  // we throttle scroll events using some sensible wait in milliseconds because it looks like it gives better experience
  // not sure if it might that we could loose some events, resulting bad state for current element in view
  const lastScrollDirection = useScrollEvents(100);
  if (!lastScrollDirection || !documentContainerElement) return;

  // find all sections with ids, since we expect only section elements to be target in toc for example.
  // we can consider changing this to '*[id]' to broadcast about any element with an id, if we find there are
  // other elements with ids, which we would like to now about is in view
  const elementsWithIdsInDocument =
    documentContainerElement.querySelectorAll("section[id]");

  const [firstSectionInViewPort] = Array.from(elementsWithIdsInDocument || [])
    .reverse()
    .filter(e => isElementInView(e, scrollTrackOffset));

  return firstSectionInViewPort;
}

function isElementInView(section: Element, offset = 0) {
  const rectTop = Math.floor(section.getBoundingClientRect().top);
  return rectTop <= offset;
}

export function scrollToElement(
  rootDocElement: HTMLDivElement | null,
  hash: string | undefined
) {
  // this method can be called to scroll to an element using query selector provided
  // standard anchor scroll when changing hash after document is loaded works using standard builtin browser anchor scroll
  if (!rootDocElement || !hash) return;

  // this setTimeout was added while working on deepling scroll support for IE11. Consider removing it since it appears to work in IE11 anyway, and it is definiately not needed for modern browsers
  setTimeout(() => {
    /* 
      We don't use hash as selector, as some documents have id's starting with digits
      and this is not allowed because its invalid html
      querySelector explodes with error if sending #1someidselectorstartingwithdigit
    */
    const [, id] = hash.split("#");

    if (!id) return;
    rootDocElement.querySelector(`[id='${id}']`)?.scrollIntoView({
      block: "start",
      inline: "nearest"
    });
  });
}

// Only certain law documents needs special styling. (PBI: 95637)
// This can be removed when class name = paragraf or skt has been removed from "not" law documents
export function isDanishLawDocument(taxons: DocumentTaxonDto[]) {
  const LawDocuments: string[] = [
    "Love",
    "Ændringslove",
    "Bekendtgørelser",
    "Ændringsbekendtgørelser",
    "Fremsat lovforslag",
    "Vedtaget lovforslag",
    "Vedtaget"
  ];
  return taxons.some(x => LawDocuments.includes(x.taxonKey));
}
