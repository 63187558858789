import FormNumberField, {
  FormNumberFieldProps
} from "../../../ui-components/forms/controls/FormNumberField";

export type CreateLinkFormNumberProps = {
  periodYear: number;
  periodMonth: number;
  periodDay: number;
};
export type CreateLinkFormNumberKeys = keyof CreateLinkFormNumberProps;

export const TypedCreateLinkNumberField: (
  props: FormNumberFieldProps<CreateLinkFormNumberKeys>
) => JSX.Element = FormNumberField;
