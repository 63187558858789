import * as React from "react";
import TreeView, { TreeItemData } from "../../../../../ui-components/TreeView";
import { Translate, TranslateFunction } from "react-localize-redux";

import { translationKeys } from "../../../../../../gen/translation-keys";
import { orderBy } from "lodash";
import { Badge } from "../../../../../ui-components/Badge";
import DateFormatter, {
  FormatLocalizedFunction
} from "../../../../../localization/DateFormatter";
import { DocumentPageLink } from "../../../../DocumentPageLink";
import {
  BookmarkDto,
  RelatedDocumentDto,
  RelationGroupDto
} from "@rhinestone/portal-web-api";

export interface RelationGroupTreeProps {
  relationGroup: RelationGroupDto;
  openLinksInNewTab: boolean;
}

/**
 * HERE BE DRAGONS 🔥🐉🔥
 *
 * ported angular-js relationTree.html which was very difficult
 *
 * we can consider going forward what to with this
 *
 * tried to comment on what is going on as good as possible
 */
export const RelationGroupTree: React.FC<RelationGroupTreeProps> = ({
  relationGroup,
  openLinksInNewTab
}) => {
  if (!relationGroup.groups) return null;

  const treeViewData = recursivelyBuildDocumentGroupTreeViewItems(
    relationGroup.groups,
    openLinksInNewTab
  );

  return <TreeView items={treeViewData}></TreeView>;
};
/**
 * Entry point for building tree view data based on relation groups
 *
 * It is pretty funky logic that has been ported over from old relationTree.html
 *
 * It has subtle assumptions about the structure of the dataset, and the view logic will properly break if special cases of document relation group data is passed in
 *
 * Hopefully, it will work for the current use cases we have
 *
 * We can consider doing some refactoring of this, to better express all the various cases there are instead
 * of all cases are built into the same typed dataset (DocumentGroupInfo)
 * @param documentGroupInfo
 * @param startNodeId
 */
function recursivelyBuildDocumentGroupTreeViewItems(
  documentGroupInfo: RelationGroupDto[],
  openLinksInNewTab: boolean,
  startNodeId = "1"
): TreeItemData[] {
  return [
    // check if group doesn't contain nested groups but documents and render those flat out
    // this is the case where documents exists that are not in any group
    ...renderUnGroupedDocumentsOfHiddenGroups(
      documentGroupInfo,
      startNodeId,
      openLinksInNewTab
    ),
    // render document groups as default groups
    ...renderGroupAsDefaultGroup(
      documentGroupInfo,
      startNodeId,
      openLinksInNewTab
    ),
    // render special case groups after first render pass-through that contains the un-grouped documents of the group
    ...renderGroupsAsUnGroupedDocumentsGroup(
      documentGroupInfo,
      startNodeId,
      openLinksInNewTab
    )
  ];
}

function renderGroupAsDefaultGroup(
  documentGroupInfo: RelationGroupDto[],
  startNodeId: string,
  openLinksInNewTab: boolean
): TreeItemData[] {
  return (
    documentGroupInfo
      // but jump over groups with showHeader = false (but still render their child groups recursively)
      .reduce<RelationGroupDto[]>(skipGroupLevelReducer, [])
      // filter out groups that doesn't have any relations
      .filter(dg => dg.uniqueRelatedDocumentsCount > 0)
      .map((dg, index) => {
        return renderDocumentGroup(
          dg,
          `${startNodeId}.default-group.${index + 1}`,
          () => renderDefaultGroupHeader(dg),
          openLinksInNewTab
        );
      })
  );
}

function renderGroupsAsUnGroupedDocumentsGroup(
  documentGroupInfo: RelationGroupDto[],
  startNodeId: string,
  openLinksInNewTab: boolean
) {
  return (
    documentGroupInfo
      // check to find groups that contain documents and no sub groups
      // ported 1:1, not sure of the logic in it
      .filter(
        dg =>
          (dg.groups?.length || 0) > 0 &&
          dg.relations &&
          dg.relations.length > 0
      )
      .map((dg, index) => {
        const internalDg = {
          ...dg,
          uniqueRelatedDocumentsCount: dg.relations?.length || 0
        };
        return renderDocumentGroup(
          internalDg,
          `${startNodeId}.un-grouped.${index + 1}`,
          () => renderUnGroupedDocumentsHeader(internalDg),
          openLinksInNewTab,
          true // skip child groups, since they are handled above and only render the un-grouped documents
        );
      })
  );
}

function renderUnGroupedDocumentsOfHiddenGroups(
  documentGroupInfo: RelationGroupDto[],
  startNodeId: string,
  openLinksInNewTab: boolean
) {
  return (
    documentGroupInfo
      // ensure to render any documents in groups that are otherwise skipped
      .filter(
        dg =>
          !dg.showHeader &&
          (dg.groups?.length || 0) === 0 &&
          dg.relations &&
          dg.relations.length > 0
      )
      .map((dg, index) =>
        renderDocuments(
          dg.relations,
          `${startNodeId}.docs.${index + 1}`,
          openLinksInNewTab
        )
      )
      .reduce((acc, current) => [...acc, ...current], [])
  );
}

function skipGroupLevelReducer(
  state: RelationGroupDto[],
  current: RelationGroupDto
) {
  // this reducer decides if we should skip rendering of group in tree, but it will still ensure to render child groups
  const shouldSkipLevel = !current.showHeader;
  return [...state, ...(shouldSkipLevel ? current.groups || [] : [current])];
}

function renderDocumentGroup(
  dg: RelationGroupDto,
  startNodeId: string,
  labelRenderer: () => JSX.Element,
  openLinksInNewTab: boolean,
  skipChildGroups?: boolean
): {
  label: JSX.Element;
  heading: string | undefined;
  nodeId: string;
  children: TreeItemData[];
} {
  return {
    label: labelRenderer(),
    heading: dg.relationGroupName || undefined,
    nodeId: startNodeId,
    children: [
      ...(!skipChildGroups && dg.groups
        ? recursivelyBuildDocumentGroupTreeViewItems(
            dg.groups,
            openLinksInNewTab,
            `${startNodeId}.1`
          )
        : []),
      ...renderDocuments(dg.relations, `${startNodeId}.2`, openLinksInNewTab)
    ]
  };
}

function renderDocuments(
  documents: RelatedDocumentDto[] | undefined,
  startNodeId: string,
  openLinksInNewTab: boolean
): TreeItemData[] {
  if (!documents) {
    return [];
  }

  return documents.map((d, index) => ({
    label: renderDocumentLabel(d, openLinksInNewTab),
    heading: d.label,
    nodeId: `${startNodeId}.${index + 1}`,
    children:
      d.bookmarks.length > 1
        ? orderBy(d.bookmarks, d => d.bookmarkRank).map((r, relationIndex) =>
            renderRelation(
              d,
              r,
              `${startNodeId}.${index + 1}.${relationIndex + 1}`,
              openLinksInNewTab
            )
          )
        : []
  }));
}

function renderRelation(
  document: RelatedDocumentDto,
  relation: BookmarkDto,
  startNodeId: string,
  openLinksInNewTab: boolean
): TreeItemData {
  // not sure why we use the parent document label to show as title
  // but that is how it was in relationTree.html
  // it works, but POs have expressed that we bugs here
  // (because we group documents under documents, which are different than the document that they are grouped under. This should not happen)
  // we should only render "under documents" if it is multiple different bookmark relations for a give document. They claim we have bug here.
  const target = openLinksInNewTab ? "_blank" : "";

  return {
    value: startNodeId,
    title: `${document.label} ${
      relation.bookmarkTitle || relation.bookmark || ""
    }`,
    label: (
      <DocumentPageLink
        bookmark={relation.bookmark}
        fullName={document.fullName}
        hiveId={document.hiveId}
        target={target}
      >
        {renderRelationLinkText(document, relation)}
      </DocumentPageLink>
    ),
    children: []
  };
}

function renderRelationLinkText(
  document: RelatedDocumentDto,
  relation: BookmarkDto
) {
  return (
    <Translate>
      {({ translate }) =>
        `${
          !relation.bookmark
            ? `${
                document.label
                  ? document.label
                  : translate(translationKeys.document_page.field_empty)
              } `
            : ""
        }${relation.bookmarkTitle || relation.bookmark || ""}`
      }
    </Translate>
  );
}

function renderDefaultGroupHeader(dg: RelationGroupDto) {
  return (
    <Badge badgeContent={dg.uniqueRelatedDocumentsCount} top={2} left={5}>
      {dg.relationGroupNameTranslationKey ? (
        <Translate id={dg.relationGroupNameTranslationKey} />
      ) : (
        dg.relationGroupName
      )}
    </Badge>
  );
}

function renderUnGroupedDocumentsHeader(dg: RelationGroupDto) {
  return (
    <Badge badgeContent={dg.uniqueRelatedDocumentsCount} top={2} left={5}>
      <Translate
        id={translationKeys.document_page.relations_with_outstanding_documents}
      />
    </Badge>
  );
}

function renderDocumentLabel(
  { label, date, bookmarks, hiveId, fullName, hideDate }: RelatedDocumentDto,
  openLinksInNewTab: boolean
) {
  const [firstRelation] = bookmarks;

  // I have no clue why we are building a label of the first relations and adding that to link when there is exactly one relation
  // In the samples i have seen bookmarkTitle and bookmark property is always undefined when a single relation is there
  // I just ported existing code 1:1
  const firstRelationLabel =
    firstRelation?.bookmarkTitle || firstRelation?.bookmark || "";

  const target = openLinksInNewTab ? "_blank" : "";
  return renderWithDateFormattingAndTranslation((format, translate) =>
    // if only one relation under document render as link
    // otherwise it will be expandable
    bookmarks.length === 1 ? (
      <DocumentPageLink
        title={`${label} ${firstRelationLabel}`}
        target={target}
        bookmark={firstRelation?.bookmark}
        fullName={fullName}
        hiveId={hiveId}
      >
        {`${generateLabelText(
          date,
          hideDate,
          label,
          format,
          translate
        )} ${firstRelationLabel}`}
      </DocumentPageLink>
    ) : (
      <>{generateLabelText(date, hideDate, label, format, translate)}</>
    )
  );
}

function generateLabelText(
  date: Date | undefined,
  hideDate: boolean | undefined,
  label: string | undefined,
  format: FormatLocalizedFunction,
  translate: TranslateFunction
) {
  return `${date && !hideDate ? format(new Date(date)) : ""} 
          ${
            label ? label : translate(translationKeys.document_page.field_empty)
          }`;
}

function renderWithDateFormattingAndTranslation(
  action: (
    format: FormatLocalizedFunction,
    translate: TranslateFunction
  ) => React.ReactNode
) {
  return (
    <DateFormatter>
      {format => (
        <Translate>{({ translate }) => action(format, translate)}</Translate>
      )}
    </DateFormatter>
  );
}
