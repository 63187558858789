import { SvgIcon, SvgIconProps } from "@material-ui/core";
import * as React from "react";

export const ChatBubbleIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z" />
    </SvgIcon>
  );
};
