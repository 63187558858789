import * as React from "react";
import { DocumentStatus } from "../document-status";
import {
  ComingStatusIcon,
  EffectiveStatusIcon,
  HistoricStatusIcon,
  LatestLbkStatusIcon,
  StatusIconProps,
  UnknownStatusIcon
} from "./StatusIcons";

export const DocumentStatusIcon: React.FC<
  {
    status: DocumentStatus;
  } & StatusIconProps
> = ({ status, ...rest }) => {
  switch (status) {
    case DocumentStatus.Coming:
      return <ComingStatusIcon {...rest} />;
    case DocumentStatus.Effective:
      return <EffectiveStatusIcon {...rest} />;
    case DocumentStatus.Historic:
      return <HistoricStatusIcon {...rest} />;
    // TODO The LatestLbk can be removed from DocumentStatus when danish law status feature toggle has been removed
    case DocumentStatus.LatestLbk:
      return <LatestLbkStatusIcon {...rest} />;
    default:
      return <UnknownStatusIcon {...rest} />;
  }
};
