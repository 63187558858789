import { AnnotationModel } from "@rhinestone/portal-web-api";
import { Annotation } from "./annotation-state";

export default function getAnnotationHighlightClassName<
  T extends AnnotationModel
>(annotation: Annotation<T> | undefined) {
  return annotation && ContextTypeCssClassMap[annotation.contextType];
}

const ContextTypeCssClassMap = {
  user: "user-annotation-highlight",
  "asset-collection": "asset-annotation-highlight"
};
