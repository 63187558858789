//a material ui context menu component
import React from "react";
import ContextMenu, { ContextMenuPosition, MenuItem } from "./ContextMenu";

interface BasicContextMenuProps {
  menuItems?: MenuItem[];
  position?: ContextMenuPosition;
}

const BasicContextMenu: React.FC<BasicContextMenuProps> = ({
  children,
  menuItems,
  position
}) => {
  return (
    <React.Fragment>
      {menuItems && menuItems.some(i => !i.hidden?.() || false) ? (
        <>
          <ContextMenu position={position} menuItems={menuItems} />
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </React.Fragment>
  );
};

export default BasicContextMenu;
