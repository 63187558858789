import React, { useEffect } from "react";
import { DatePossiblyUndecidedFormatter } from "../../../localization/DateFormatter/DateFormatterPossiblyUndecided";
import { CenteredTimelineItem } from "../../../ui-components/split-timeline";
import { getDocumentUrl } from "../../document-page/document-page-helpers";
import { DanishLawStatusIcon } from "../../StatusIcons/LegalStatusIcons";
import { DanishLawTimelineNode } from "./DanishLawHistoryTimeline";
import { LatestEffectiveChip } from "./LatestEffectiveChip";
import { ConsolidationInfo } from "./ConsolidationInfo";
import { EffectiveLbkChip } from "./EffectiveLbkChip";

const DanishLawHistoryTimelineItem: React.FC<{ node: DanishLawTimelineNode }> =
  ({ node }) => {
    useEffect(() => {
      if (node.isActive) {
        const element = document.getElementById(node.documentId);
        if (element) {
          element.scrollIntoView({ block: "center" });
        }
      }
    }, [node]);

    return (
      <CenteredTimelineItem
        title={
          <>
            <DatePossiblyUndecidedFormatter value={node.title} />
            {node.isLatestEffective && <LatestEffectiveChip />}
            {node.isLatestLbk && <EffectiveLbkChip />}
          </>
        }
        subTitle={node.subTitle}
        icon={
          <DanishLawStatusIcon
            fontSize="small"
            status={node.status}
            active={node.isActive}
            aria-label={node.title}
          />
        }
        labels={[
          node.consolidationDocuments && (
            <ConsolidationInfo
              consolidationDocuments={node.consolidationDocuments}
            />
          )
        ]}
        active={node.isActive}
        isFirstItem={node.isFirst}
        isLastItem={node.isLast}
        icon-aria-label={node.fullName}
        linkTo={getDocumentUrl({ ...node })}
        id={node.documentId}
        useLazyLoading={true}
      />
    );
  };

export default DanishLawHistoryTimelineItem;
