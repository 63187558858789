import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { TextKeys, TextField } from "./TextField";

interface NameFormFieldProps {
  disabled?: boolean;
  fieldName: TextKeys;
  label?: React.ReactNode;
}
const NameFormField: React.FC<NameFormFieldProps> = ({
  disabled,
  fieldName,
  label
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <TextField
          fieldName={fieldName}
          label={label}
          fullWidth
          fieldOptions={{
            required: translate(
              translationKeys.document_page.send_document_link_to_name_required
            ).toString(),
            maxLength: {
              value: 250,
              message: translate(
                translationKeys.document_page.send_document_link_name_too_long
              ).toString()
            }
          }}
          disabled={disabled}
        />
      )}
    </Translate>
  );
};

export default NameFormField;
