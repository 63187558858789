import React, { useEffect } from "react";
import { ParagraphInTime } from "@rhinestone/portal-web-api";
import { ParagraphTimeline } from "./ParagraphTimeline";
import { useParagraphZoomContext } from "../ParagraphZoomContext";
import {
  calculateInitialParagraphInTimeToShow,
  useParagraphZoomData
} from "../paragraph-zoom-helpers";
import { useDocumentInfo } from "../../document-context";
import { useCreateToolTip } from "../panes/create-tooltip";
import { ParagraphZoomDataError } from "../errors/ParagraphZoomDataError";
import { ApiFetchIndicator } from "../../../ui-components/ApiFetchIndicator";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { PaneTitle } from "../../../ui-components/layouts/PaneTitle";
import { Skeleton } from "@material-ui/lab";

interface ParagraphTimelineContainerProps {
  paragraphId: string | undefined;
}
export const ParagraphTimelineContainer: React.FC<ParagraphTimelineContainerProps> =
  ({ paragraphId }) => {
    const createToolTip = useCreateToolTip();

    const { hiveId, familyName, effectiveDate } = useDocumentInfo();

    const { data, status, error } = useParagraphZoomData(
      hiveId,
      familyName,
      paragraphId || ""
    );

    const {
      currentParagraphInTime,
      setCurrentParagraphInTime,
      setError,
      setStatus
    } = useParagraphZoomContext();

    // when data is loaded set current depending on effective date starting point
    useEffect(
      () =>
        setCurrentParagraphInTime(
          data
            ? calculateInitialParagraphInTimeToShow(
                data.law.paragraphInTimes,
                effectiveDate
              )
            : undefined
        ),
      [effectiveDate, data, setCurrentParagraphInTime]
    );
    useEffect(() => {
      setStatus(status);
    }, [status, setStatus]);

    useEffect(() => {
      setError(error);
    }, [error, setError]);

    const items = data?.law.paragraphInTimes || [];

    return (
      <ApiFetchIndicator
        status={status}
        errorComponent={<ParagraphZoomDataError error={error} />}
        loadingIndicator={<Skeleton variant="rect" height={500} />}
      >
        <PaneTitle>
          <Translate
            id={translationKeys.document_page.document_history_timeline}
          />
        </PaneTitle>
        <ParagraphTimeline
          items={items}
          currentDocumentId={
            currentParagraphInTime?.portalDocumentContext.documentId
          }
          elementOnClick={setCurrentParagraphInTime}
          createToolTip={(paragraph: ParagraphInTime) => {
            return createToolTip(
              paragraph.portalDocumentContext.hiveId,
              paragraph.portalDocumentContext.fullName
            );
          }}
        />
      </ApiFetchIndicator>
    );
  };
