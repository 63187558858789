import { useEffect } from "react";
import { AnnotationLayerFactory } from "../annotations/annotation-layer-factory";
import {
  PdfAnnotation,
  PdfAnnotationState
} from "../../../../annotations/pdf/pdf-annotation-state";
import { useTheme } from "@material-ui/core";

/**
 * Encapsulates the bridging between react state and the AnnotationLayerFactory parsed to pdfjs *
 */
export function usePdfJsReactAnnotationsBridge(
  annotationState: PdfAnnotationState | undefined,
  annotationLayerFactory: AnnotationLayerFactory | undefined
) {
  const { isShown, annotations } = annotationState ?? {};
  const { userPdfAnnotationHighlightColor, assetPdfAnnotationHighlightColor } =
    useTheme();
  useEffect(() => {
    if (!annotationLayerFactory) return;
    annotationLayerFactory?.toggleVisibility(Boolean(isShown));
  }, [annotationLayerFactory, isShown]);

  useEffect(() => {
    if (!annotationLayerFactory) return;
    annotationLayerFactory.mergeAnnotations(
      mapToAnnotationLayerViewModels(
        annotations,
        String(userPdfAnnotationHighlightColor),
        String(assetPdfAnnotationHighlightColor)
      )
    );
  }, [
    annotationLayerFactory,
    annotations,
    userPdfAnnotationHighlightColor,
    assetPdfAnnotationHighlightColor
  ]);
}

function mapToAnnotationLayerViewModels(
  annotations: PdfAnnotation[] | undefined,
  userAnnotationHighlightColor: string,
  assetAnnotationHighlightColor: string
) {
  const contextTypeToHighlightColorMap = {
    user: userAnnotationHighlightColor,
    "asset-collection": assetAnnotationHighlightColor
  };
  return (
    annotations?.map(a => ({
      annotation: a.annotation,
      highlightColor: contextTypeToHighlightColorMap[a.contextType]
    })) ?? []
  );
}
