import * as React from "react";
import {
  Badge as MuiBadge,
  BadgeProps as MuiBadgeProps,
  makeStyles
} from "@material-ui/core";

interface StyleProps {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

const useStyle = makeStyles({
  badge: {
    "& .MuiBadge-badge": {
      top: ({ top }: StyleProps) => top,
      bottom: ({ bottom }: StyleProps) => bottom,
      left: ({ left }: StyleProps) => left,
      right: ({ right }: StyleProps) => right,
      position: "inherit",
      transform: "none"
    }
  }
});

type BadgeProps = Pick<MuiBadgeProps, "badgeContent" | "max" | "anchorOrigin"> &
  StyleProps;

// MuiBadge caps at 99
const defaultMax = 9999999;

export const Badge: React.FC<BadgeProps> = ({
  badgeContent,
  max,
  anchorOrigin,
  children,
  ...styleProps
}) => {
  const style = useStyle(styleProps);
  return (
    <MuiBadge
      badgeContent={badgeContent}
      max={max || defaultMax}
      anchorOrigin={anchorOrigin}
      className={style.badge}
      color="secondary"
    >
      {children}
    </MuiBadge>
  );
};
