import * as React from "react";
import { Translate } from "react-localize-redux";
import { useIsDanishLaw } from "./use-is-danish-law";
import { translationKeys } from "../../../gen/translation-keys";

export const AboutDocumentFamilyTitle: React.FC = () => {
  const { isDanishLaw } = useIsDanishLaw();

  return (
    <Translate id={getAboutDocumentFamilyTitleTranslationKey(isDanishLaw)} />
  );
};

function getAboutDocumentFamilyTitleTranslationKey(
  isDanishLaw: boolean
): string {
  return isDanishLaw
    ? translationKeys.document_page.about_danish_law
    : translationKeys.document_page.about_document_family;
}
