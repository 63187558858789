import React from "react";
import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from "@material-ui/core";
import { LinkTab } from ".";

export interface Link {
  label: string;
  to: string;
}

export type LinkTabsProps = Pick<
  MuiTabsProps,
  "variant" | "indicatorColor" | "classes"
> & {
  links: Link[];
};

/**
 * LinkTabs supports showing list of links(to+label) as tab items. Current route is highlighted.
 **/
export const LinkTabs: React.FC<LinkTabsProps> = ({
  links,
  indicatorColor,
  ...rest
}) => {
  return (
    <MuiTabs {...rest} value={false} scrollButtons="auto">
      {links.map(link => (
        <LinkTab {...link} indicatorColor={indicatorColor} key={link.to} />
      ))}
    </MuiTabs>
  );
};
