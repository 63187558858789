import React from "react";
import { DocumentStatusIcon } from "../StatusIcons/DocumentStatusIcons";
import {
  DocumentStatus,
  documentStatusTranslationKeys
} from "../document-status";
import { Translate } from "react-localize-redux";
import { IconRenderProps } from "../../ui-components/split-timeline";

interface DocumentHistoryTimelineIconProps extends IconRenderProps {
  status: DocumentStatus;
  alt?: string;
}
export const DocumentHistoryTimelineIcon: React.FC<DocumentHistoryTimelineIconProps> =
  props => (
    <Translate>
      {({ translate }) => (
        <DocumentStatusIcon
          status={props.status}
          active={props.active}
          className={props.className}
          alt={
            translate(String(documentStatusTranslationKeys[props.status])) +
            (props.alt ? " (" + props.alt + ")" : "")
          }
          fontSize={props.fontSize}
        />
      )}
    </Translate>
  );
