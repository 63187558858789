import React from "react";
import clsx from "clsx";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  scroll: {
    height: "100%",
    borderColor: "rgba(0, 0, 0, 0.03)",
    transition: "border-color 0.75s linear",
    marginRight: 1,
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.1)",
      transition: "border-color 0.125s linear"
    },

    "&::-webkit-scrollbar, &::-webkit-scrollbar-corner": {
      borderRightStyle: "inset",
      borderRightWidth: " calc(100vw + 100vh)",
      borderColor: "rgba(0, 0, 0, 0.01)",
      opacity: "50%",
      paddingLeft: theme.spacing(1.5),
      width: theme.spacing(1),
      height: theme.spacing(1)
    },
    "&:hover::-webkit-scrollbar, &:hover::-webkit-scrollbar-corner": {
      borderColor: "inherit"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRightStyle: "inset",
      borderRadius: "100px",
      borderRightWidth: "calc(100vw + 100vh)",
      borderColor: "inherit"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      borderColor: "rgba(0, 0, 0, 0.30) !important"
    }
  },
  suppressScrollX: {
    overflowX: "hidden"
  },
  suppressScrollY: {
    overflowY: "hidden"
  },
  noSuppressScrollX: {
    overflowX: "auto"
  },
  noSuppressScrollY: {
    overflowY: "auto"
  }
}));

interface ScrollbarProps {
  suppressScrollX?: boolean;
  suppressScrollY?: boolean;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}
export const Scrollbar: React.FC<ScrollbarProps> = ({
  suppressScrollX,
  suppressScrollY,
  onScroll,
  children
}) => {
  const classes = useStyles();
  return (
    <div
      onScroll={onScroll}
      className={clsx(
        classes.scroll,
        suppressScrollX ? classes.suppressScrollX : classes.noSuppressScrollX,
        suppressScrollY ? classes.suppressScrollY : classes.noSuppressScrollY
      )}
    >
      {children}
    </div>
  );
};

export default Scrollbar;
