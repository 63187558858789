import * as React from "react";
import { Archive, ArchiveOutlined } from "@material-ui/icons";
import Fade from "@material-ui/core/Fade";
import { IconButton } from "../../../ui-components/IconButton";
import { AddToAssetCollectionContainer } from "../../AssetCollection/AddToAssetCollectionContainer";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";

export const AddToAssetCollectionAction: React.FC = props => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <Translate>
      {({ translate }) => (
        <>
          <IconButton
            {...props}
            size="small"
            title={translate(
              translationKeys.document_page.add_document_to_asset_collection
            ).toString()}
            onClick={() => setIsVisible(true)}
            data-test-id="add-to-new-asset-collection"
          >
            <Fade in={true}>
              {isVisible ? <Archive color={"primary"} /> : <ArchiveOutlined />}
            </Fade>
          </IconButton>
          {isVisible && (
            <AddToAssetCollectionContainer setOpen={setIsVisible} />
          )}
        </>
      )}
    </Translate>
  );
};
