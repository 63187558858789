import * as React from "react";
import { ThemeProvider } from "../ui-components/ThemeProvider";
import { LegacyGlobalCssOverrides } from "./LegacyGlobalCssOverrides";
import { TemporaryShellProps } from "./withTemporaryShell";

/**
 * This type models the angular services and providers we allow to be used by our temporary shell environment during transition
 *
 * Use this to register simple react components, which does not need translations, API access, routing etc.
 *
 * For other purposes use withTemporaryShell
 */
export const withLightweightTemporaryShell = <T extends Record<string, any>>(
  Component: React.ComponentType<T>
): React.ComponentType<TemporaryShellProps & T> => {
  return ({ portal, $translate, $scope, ...rest }) => {
    return (
      <ThemeProvider rhinestoneTheme={portal.rhinestoneTheme}>
        <LegacyGlobalCssOverrides />
        <Component {...(rest as T)} />
      </ThemeProvider>
    );
  };
};
