import {
  HtmlAnnotationModel,
  HtmlNewAnnotationDTO
} from "@rhinestone/portal-web-api";
import { Annotation, AnnotationState } from "../annotation-state";
import { useAnnotationState } from "../use-annotation-state";

export type HtmlAnnotationState = AnnotationState<
  HtmlAnnotationModel,
  HtmlNewAnnotationDTO
>;

export type HtmlAnnotation = Annotation<HtmlAnnotationModel>;

export function useHtmlAnnotationState(
  hiveId: string,
  fullName: string,
  annotationTargetId: string
): HtmlAnnotationState {
  return useAnnotationState<HtmlAnnotationModel, HtmlNewAnnotationDTO>(
    annotationTargetId,
    "html",
    [["getPrimaryVariantContentExcludeEditorialNotes", hiveId, fullName]]
  );
}
