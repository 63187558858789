import { Unauthorized } from "../../api-access/http-status-codes";
import { usePortalApi } from "../../api-access/use-portal-api";
import { useDocumentInfo } from "../document-context";

export function useGetChangedSections() {
  const { hiveId, fullName } = useDocumentInfo();
  return usePortalApi(
    client => client.getChangedSections(hiveId, fullName),
    ["getChangedSections", hiveId, fullName],
    {
      expectedErrorStatusCodes: [Unauthorized]
    }
  );
}
