import { Chip, makeStyles } from "@material-ui/core";
import * as React from "react";
import clsx from "clsx";
import { TimelineConnector } from "./TimelineConnector";
import { TimelineContent } from "./TimelineContent";
import { TimelineItemWrapper } from "./TimelineItemWrapper";
import { TimelineSeparator } from "./TimelineSeparator";
import TimelineActiveLink from "./TimelineActiveLink";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

const useStyles = makeStyles(theme => ({
  dot: { margin: 4 },
  dotLine: {
    marginRight: 9,
    marginLeft: 9,
    marginTop: -4
  },
  connectorLine: {
    marginTop: -3,
    width: 2
  },
  firstItemConnectorLine: {
    maxHeight: 17
  },
  childrenIndicator: {
    position: "absolute",
    height: 15,
    bottom: -9,
    borderRight: "2px dotted " + theme.palette.grey[400],
    backgroundColor: "transparent",
    width: 1
  },
  mergeChildrenIndicator: {
    bottom: -35,
    right: -11
  },
  content: {
    display: "flex",
    marginTop: -10
  },
  firstItemSeparator: {
    "& a": {
      lineHeight: 0
    }
  },
  firstContent: {
    display: "flex",
    marginTop: -1
  },
  mergeLine: {
    position: "relative",
    borderRight: "2px solid " + theme.palette.grey[400],
    borderTop: "2px solid " + theme.palette.grey[400],
    width: 30,
    height: 15,
    left: -26,
    "& svg": {
      position: "absolute",
      bottom: 0,
      right: -1,
      transform: "translate(50%, 100%)"
    }
  },
  chip: {
    position: "relative",
    left: -10
  }
}));
interface MergeItemElement {
  linkTo?: string;
  linkOnClick?: () => void;
  icon: React.ReactNode;
  hasAncestors?: boolean;
  "icon-aria-label"?: string;
}
interface TimelineMergeItemProps {
  id?: string;
  merges?: MergeItemElement[];
  firstItem?: MergeItemElement;
  openLinksInNewTab?: boolean;
  useLazyLoading?: boolean;
}

export const TimelineMergeItem: React.FC<TimelineMergeItemProps> = ({
  id,
  merges,
  firstItem,
  openLinksInNewTab,
  useLazyLoading
}): JSX.Element | null => {
  const classes = useStyles();
  if (!merges || merges.length === 0) return null;
  return (
    <TimelineItemWrapper
      sticky={!!firstItem}
      id={id}
      useLazyLoading={useLazyLoading}
    >
      <TimelineSeparator className={classes.firstItemSeparator}>
        <TimelineConnector
          className={clsx(
            classes.connectorLine,
            classes.firstItemConnectorLine
          )}
        />
        {firstItem ? (
          <>
            <TimelineActiveLink
              linkTo={firstItem?.linkTo}
              linkOnClick={firstItem?.linkOnClick}
              openLinksInNewTab={openLinksInNewTab}
              aria-label={firstItem?.["icon-aria-label"]}
            >
              {firstItem.icon}
            </TimelineActiveLink>
            {firstItem.hasAncestors && (
              <TimelineConnector
                className={clsx(
                  classes.dotLine,
                  classes.connectorLine,
                  classes.childrenIndicator
                )}
              />
            )}
          </>
        ) : (
          <TimelineConnector
            className={clsx(classes.dotLine, classes.connectorLine)}
          />
        )}
      </TimelineSeparator>
      <TimelineContent
        className={firstItem ? classes.firstContent : classes.content}
      >
        {merges.map((merge, index) => (
          <div className={classes.mergeLine} key={index}>
            {merge.hasAncestors && (
              <TimelineConnector
                className={clsx(
                  classes.dotLine,
                  classes.connectorLine,
                  classes.childrenIndicator,
                  classes.mergeChildrenIndicator
                )}
              />
            )}
            <TimelineActiveLink
              linkTo={merge?.linkTo}
              linkOnClick={merge?.linkOnClick}
              openLinksInNewTab={openLinksInNewTab}
              aria-label={merge?.["icon-aria-label"]}
            >
              {merge.icon}
            </TimelineActiveLink>
          </div>
        ))}
        <Translate>
          {({ translate }) => (
            <Chip
              label={String(
                translate(
                  translationKeys.document_page.document_timeline_merge_label
                )
              )}
              size="small"
              variant="outlined"
              className={classes.chip}
            />
          )}
        </Translate>
      </TimelineContent>
    </TimelineItemWrapper>
  );
};
