import React from "react";
import { RelationItemModel } from "../../preparatory-work/RelationItemModel";
import { getRelationItems } from "./helper-functions/get-relation-items";
import NoDataWarningTabContent from "./NoDataWarningTabContent";
import SectionTextTabContents from "./SectionTextTabContents";
import { SectionPayload } from "./helper-functions/SectionPayload";
import { SmallTextSkeleton } from "../../../ui-components/Skeleton/SmallSkeleton";

interface SpecialRemarksContentsProps {
  sectionData: {
    [key: string]: SectionPayload;
  };
  headerLinkText: JSX.Element;
}
export const SpecialRemarksContents: React.FC<SpecialRemarksContentsProps> = ({
  sectionData,
  headerLinkText
}) => {
  const allIsFetched = Object.values(sectionData).every(
    section => section.fetchState === "fetched"
  );
  const groupedSectionIds: RelationItemModel[] = getRelationItems(sectionData);

  const getSectionIds = (changeRelation?: RelationItemModel) =>
    changeRelation?.targetBookmarks || [];

  return (
    <>
      {allIsFetched ? (
        groupedSectionIds.length > 0 ? (
          groupedSectionIds.map((group, index) => (
            <SectionTextTabContents
              key={index}
              selectedChangeRelation={group}
              getSectionIds={getSectionIds}
              headerLinkText={headerLinkText}
            />
          ))
        ) : (
          <NoDataWarningTabContent />
        )
      ) : (
        <SmallTextSkeleton />
      )}
    </>
  );
};
