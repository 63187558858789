import React from "react";
import { PaneItem } from "./ThreePanesLayout";
import SidePane from "./SidePane";
import StackedPaneLayout from "./StackedPaneLayout";
import Hidden from "@material-ui/core/Hidden";
import ExcludeFromAnnotation from "../../document/annotations/ExcludeFromAnnotation";

interface RightPaneProps {
  right: PaneItem[];
}

const RightPane: React.FC<RightPaneProps> = ({ right }) => {
  return (
    <Hidden smDown>
      <SidePane>
        <ExcludeFromAnnotation>
          <StackedPaneLayout items={right} />
        </ExcludeFromAnnotation>
      </SidePane>
    </Hidden>
  );
};

export default RightPane;
