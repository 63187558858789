import * as React from "react";
import { HtmlAnnotationState } from "./html-annotation-state";
import { AnnotationComments } from "../comments/AnnotationComments";
import { useHtmlAnnotationsLayoutState } from "./use-html-annotations-layout-state";

interface HtmlAnnotationContainerProps {
  annotationState: HtmlAnnotationState;
  documentElementRef: HTMLDivElement | null;
}

export const HtmlAnnotationContainer: React.FC<HtmlAnnotationContainerProps> =
  ({ annotationState, documentElementRef }) => {
    const { annotations, saveAnnotation, removeAnnotationComment, isShown } =
      annotationState;
    const layoutState = useHtmlAnnotationsLayoutState(
      annotations,
      documentElementRef
    );
    return isShown && annotations ? (
      <AnnotationComments
        annotations={annotations}
        layoutState={layoutState}
        onSaveAnnotation={saveAnnotation}
        onDeleteAnnotationComment={removeAnnotationComment}
        markerRightPosition={"-60px"}
      ></AnnotationComments>
    ) : null;
  };
