import { SearchRequestClient } from "../../../search/search-request";
import { usePortalApi } from "../../../api-access/use-portal-api";
import { DocumentTileData } from "./DocumentTileData";

function jsonToDocumentTileData(x: {
  title: string;
  HiveId: string;
  FullName: string;
  DocumentId: string;
}): DocumentTileData {
  return {
    title: x.title,
    hiveId: x.HiveId,
    fullName: x.FullName,
    documentId: x.DocumentId
  };
}

export const useDocumentSearch = (
  productCode: string,
  taxonomyName: string,
  taxonKey?: string
): DocumentTileData[] => {
  const searchRequest: SearchRequestClient = {
    fieldSetName: "SearchResultFields",
    criteria: [
      {
        providerKey: "products",
        data: productCode
      },
      {
        providerKey: taxonomyName,
        data: taxonKey
      }
    ]
  };

  const documentsJson = usePortalApi(
    client => client.getDocuments(searchRequest),
    ["getDocuments", taxonKey],
    {
      enabled: Boolean(searchRequest)
    }
  ).data;

  return documentsJson?.results.map(jsonToDocumentTileData);
};

export default useDocumentSearch;
