import HtmlToReact, {
  HtmlToReactNode,
  HtmlToReactProcessingInstructions
} from "html-to-react";
import * as React from "react";

export default {
  replaceChildren: false,
  shouldProcessNode(node: HtmlToReactNode) {
    return true;
  },
  processNode: new HtmlToReact.ProcessNodeDefinitions(React).processDefaultNode
} as HtmlToReactProcessingInstructions;
