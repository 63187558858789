import { SearchResultsExportInfo } from "@rhinestone/portal-web-api";

export type Criteria = {
  readonly providerKey: string;
  readonly data: any;
};

export enum CriteriaChangeType {
  Add = 0,
  Remove = 1
}

export type CriteriaChange = {
  readonly changes: Array<{
    readonly criteria: Criteria;
    readonly type: CriteriaChangeType;
  }>;
};

export interface CriteriaChipModel {
  readonly displayValue: string;
  readonly longDisplayValue: string | undefined;
  readonly displayValueResource: string | undefined;
  readonly criteria: Criteria;
  readonly icon: string;
  readonly color: string;
}

export interface CriteriaProvider {
  readonly key: string;
  getCriteriaViewModel(criteria: Criteria): CriteriaChipModel | null;
  buildCriteriaDescription?(
    request: SearchResultsExportInfo
  ): SearchResultsExportInfo;
}
