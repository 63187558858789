import { useUserContext } from "../../../user/use-user-context";
import { usePortalProperty } from "../../use-portal-property";

export function useHideDocumentSubscriptionAlert(): boolean | undefined {
  const { isAuthenticated } = useUserContext();

  const { value: hideDocumentSubscriptionAlert, status } =
    usePortalProperty<boolean>("HideDocumentSubscriptionAlert");

  return status === "loading"
    ? true
    : !isAuthenticated || !!hideDocumentSubscriptionAlert;
}
