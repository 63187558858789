import { excludedFromAnnotationClassName } from "../../annotations/ExcludeFromAnnotation";

export const handleRange = (handler: (range: Range) => void) => {
  const selectedRange = document.getSelection()?.getRangeAt(0);
  if (selectedRange) {
    handler(selectedRange);
    document.getSelection()?.empty();
  }
};

export function selectionStartsAtExcludedElement(): boolean {
  const selection = document.getSelection();
  if (selection?.rangeCount && selection?.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    return (
      parentsContainExcludedClassName(range.startContainer.parentElement) ||
      !parentElementInDocumentContent(range.startContainer.parentElement)
    );
  }
  return false;
}

function parentsContainExcludedClassName(element: HTMLElement | null): boolean {
  if (!element) return false;

  const elementName = element.nodeName.toUpperCase();
  if (elementName === "ARTICLE" || elementName === "BODY") return false;
  if (element.classList.contains(excludedFromAnnotationClassName)) {
    return true;
  }

  return parentsContainExcludedClassName(element.parentElement);
}

function parentElementInDocumentContent(element: HTMLElement | null): boolean {
  if (!element) return false;
  if (
    element.nodeName.toUpperCase() === "DIV" &&
    element.id === "document-content"
  )
    return true;

  return parentElementInDocumentContent(element.parentElement);
}
