import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const EmptyDocIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M21 8v12.993A1 1 0 0 1 20.007 22H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8zm-2 1h-5V4H5v16h14V9z" />
      </g>
    </SvgIcon>
  );
};

export default EmptyDocIcon;
