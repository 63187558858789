import { Box, Button, makeStyles, Tooltip } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { HtmlToReactNode } from "html-to-react";
import * as React from "react";

import { DocumentPageLink } from "../../DocumentPageLink";
import { Section } from "./document-components/Section";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";

/**
 * Returns a Box that wraps the Section and the Document page link,
 * to be able to control the position of the link button to the section text.
 */
export default function SectionWithLinkButton(
  node: HtmlToReactNode,
  sectionTitleAsString: string | undefined,
  index: number,
  children: React.ReactChildren,
  hiveId: string,
  fullName: string,
  linkText: JSX.Element
) {
  const useStyles = makeStyles(theme => ({
    boxContainer: {
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(3)
    },
    sectionBox: {
      // Remove the top margin from the first <h?> child of the first section.
      // This is just for aesthetics, and won't break anything if the selector some day fails.
      "& > span.section-with-menu > section:first-child > h1, section:first-child > h2, section:first-child > h3":
        {
          marginTop: 0
        }
    },
    link: {
      alignSelf: "flex-end",
      right: 0
    }
  }));

  const classes = useStyles();
  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.link}>
        <DocumentPageLink
          hiveId={hiveId}
          fullName={fullName}
          bookmark={node?.attribs?.id}
          target="_blank"
        >
          <Tooltip title={linkText}>
            <Button endIcon={<OpenInNewIcon />}>
              <Translate id={translationKeys.paragraph_zoom.go_to_lff} />
            </Button>
          </Tooltip>
        </DocumentPageLink>
      </Box>
      <Box className={classes.sectionBox}>
        <Section
          id={node.attribs.id}
          className={node.attribs.class}
          renderSectionActions={() => null}
          index={index}
        >
          {children}
        </Section>
      </Box>
    </Box>
  );
}
