import React from "react";
import { ResponsiveTooltip } from "../ResponsiveTooltip/ResponsiveTooltip";

interface TimelineTooltipProps {
  tooltipContents: React.ReactNode;
}
export const TimelineTooltip: React.FC<TimelineTooltipProps> = ({
  children,
  tooltipContents
}) => {
  return (
    <ResponsiveTooltip
      iconSize="small"
      tooltip={tooltipContents}
      ariaLabel={"tooltipAriaLabel"}
    >
      {children}
    </ResponsiveTooltip>
  );
};
