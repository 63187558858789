import { sortBy } from "lodash";
import { CommentMarkerHeight } from "../CommentMarker";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { AnnotationLayoutState } from "../../annotation-layout-state";
import { Annotation } from "../../annotation-state";

export function annotationsWithKnownPageLayout<T extends AnnotationModel>(
  annotations: Annotation<T>[],
  layOutState: AnnotationLayoutState
) {
  // only render comments when we know where to position them (ie. layout is )
  return annotations.filter(a => layOutState[a.annotation.id] !== undefined);
}

export function groupAnnotations<T extends AnnotationModel>(
  annotations: Annotation<T>[],
  layOutState: AnnotationLayoutState
): Annotation<T>[][] {
  return sortBy(annotations, [
    a => layOutState[a.annotation.id]?.pageIndex,
    a => layOutState[a.annotation.id]?.y
  ]).reduce(reduceIntoGroups(layOutState), []);
}

/**
 * This function will group current item into last group in accumulator
 * if it matches the criteria of being on same page and "too" close to previous comment
 */
function reduceIntoGroups<T extends AnnotationModel>(
  layOutState: AnnotationLayoutState
): (
  accumulator: Annotation<T>[][],
  current: Annotation<T>
) => Annotation<T>[][] {
  return (accumulator, current) => {
    const currentGroup = accumulator[accumulator.length - 1];
    // no groups at all so creating new first group
    if (!currentGroup) return [[current]];

    // we compare against first item in group to decide whether to group or not
    const [firstItemInGroup] = currentGroup;

    const layoutStateForFirstItemInGroup =
      layOutState[firstItemInGroup.annotation.id];
    const layoutStateForCurrent = layOutState[current.annotation.id];
    // if item is not on same page create new group
    if (
      layoutStateForFirstItemInGroup.pageIndex !==
      layoutStateForCurrent.pageIndex
    )
      return [...accumulator, [current]];

    // if comment is far away, create new group
    if (
      layoutStateForCurrent.y - layoutStateForFirstItemInGroup.y >
      CommentMarkerHeight
    )
      return [...accumulator, [current]];

    // we group item into current group, so CommentMarkers doesn't render on top of each other
    return [...accumulator.slice(0, -1), [...currentGroup, current]];
  };
}
