import * as React from "react";
import FormActions from "../../ui-components/forms/controls/FormActions";
import { cancelLabel, submitLabel } from "./FormLabels";

interface FormActionsProps {
  onCancel: () => void;
}

const LocalFormActions: React.FC<FormActionsProps> = ({ onCancel }) => {
  return (
    <FormActions
      onCancel={onCancel}
      submitLabel={submitLabel}
      cancelLabel={cancelLabel}
    />
  );
};

export default LocalFormActions;
