import * as React from "react";
import { ModalDialog } from "../ui-components/ModalDialog";
import { ApiError } from "./PortalApiProvider";
import { Box } from "@material-ui/core";

interface ApiErrorDialogProps {
  errors: ApiError[];
  onClose: () => void;
}

/**
 * The idea with this component is to have a way to show unhandled exceptions in the portal
 *
 * Unhandled exceptions means all requests that either fails client side, or where server responds with unexpected status
 *
 * TODO: it needs to be prettified with Mui stuff.. i just slabbed this together to get functionality up and running
 *
 * @param param0
 */
export const ApiErrorDialog: React.FunctionComponent<ApiErrorDialogProps> = ({
  errors,
  onClose
}) => {
  if (errors.length === 0) return null;

  return (
    <ModalDialog open onClose={onClose} title={"Error calling api"}>
      {process.env.NODE_ENV !== "production" && renderApiDeveloperHelp()}
      <ul>
        {errors.map((e, i) => (
          <li key={i}>
            <div>
              {e.statusCode
                ? `Server error (${e.statusCode})`
                : "Client side error"}
              : {e.url}
            </div>
            {renderMessage(e)}
          </li>
        ))}
      </ul>
    </ModalDialog>
  );
};

function renderMessage(e: ApiError) {
  return (
    <Box whiteSpace="pre-wrap">
      {process.env.NODE_ENV !== "production" && e.correlationIdentifier ? (
        <>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`http://kibana.rhinestone.local:5601/app/kibana#/discover?_g=()&_a=(columns:!(_source),filters:!(),interval:auto,query:(language:lucene,query:'${e.correlationIdentifier}'),sort:!('@timestamp',desc))`}
          >
            {e.message}
          </a>
          {e.correlationIdentifier && (
            <div>CorrelationIdentifier: {e.correlationIdentifier}</div>
          )}
        </>
      ) : (
        <>
          {e.correlationIdentifier && (
            <div>CorrelationIdentifier: {e.correlationIdentifier}</div>
          )}
          <div>{e.message}</div>
        </>
      )}
    </Box>
  );
}

function renderApiDeveloperHelp() {
  return (
    <Box
      bgcolor="warning.main"
      padding="20px"
      position="sticky"
      bottom="0"
      left="0"
    >
      <h4>Note to developer:</h4> If you get this and it is expected for that
      endpoint to return some specific status code, because logic depends on it,
      add swagger specification to the endpoint as described{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://tfs.schultz.dk/tfs/SchultzCollection/_git/Schultz.Rhinestone?path=%2FSource%2FPortal.Web%2FClient%2Fportal-web-api%2FREADME.md&_a=preview"
      >
        here in portal-web-api readme
      </a>
      . Then it will not be treated as error. If you need to do special error
      handling based on status code (expected error status code), add
      expectedErrorStatusCodes to usePortalApi hook, and inspect error object
      when query is in error state
    </Box>
  );
}
