import * as React from "react";
import { DelayedSectionRelationChips } from "../../shared/document-relation-groups/RelationChips";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useLazyLoad } from "../../../../ui-components/LazyLoad/useLazyLoad";

interface Props {
  className: string;
  sectionId: string | undefined | null;
}

export const FoldableSectionHeader: React.FC<Props> = ({
  className,
  sectionId,
  children
}) => {
  const { ref, inView } = useLazyLoad();
  return (
    <h2 className={clsx(className, "foldable-section-header")} ref={ref}>
      {children}
      {sectionId && renderFoldableSectionChips(sectionId, inView)}
    </h2>
  );
};

function renderFoldableSectionChips(sectionId: string, inView: boolean) {
  return (
    // the box avoids folded section summary to expand/collapse to expands/collapse
    <Box
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
    >
      {inView && <DelayedSectionRelationChips sectionId={sectionId} />}
    </Box>
  );
}
