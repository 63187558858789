import * as React from "react";
import EditProfileForm, { EditProfileProps } from "./EditProfileForm";
import { useSetProfileEmail } from "./use-set-verified-user-email";
import { useHistory } from "react-router-dom";
import { useUserProfile } from "../../../user/use-user-profile";
import { ApiFetchIndicator } from "../../../ui-components/ApiFetchIndicator";

export const EditProfileFormContainer: React.FC = () => {
  const { data: profile, status } = useUserProfile();
  const { mutateAsync: setUserEmail, isLoading: isSettingEmail } =
    useSetProfileEmail();
  const history = useHistory();

  const handleSubmit = async (formData: EditProfileProps) => {
    await setUserEmail(formData.email);
    history.push("/account/myprofile/");
  };

  return (
    <ApiFetchIndicator status={status}>
      {profile && (
        <EditProfileForm
          displayName={profile.displayName}
          email={profile.emailAddresses ? profile.emailAddresses[0] : ""}
          isSubmitting={isSettingEmail}
          onSubmit={handleSubmit}
          onCancel={() => history.push("/account/myprofile")}
        />
      )}
    </ApiFetchIndicator>
  );
};
