import React from "react";
import { DocumentDto, DocumentModel } from "@rhinestone/portal-web-api";
import { useEffectiveVersion } from "./use-effective-version";
import { ApiFetchIndicator } from "../../ui-components/ApiFetchIndicator";
import { DocumentPageError } from "./DocumentPageError";
import { DocumentPage } from "./DocumentPage";
import { Redirect } from "react-router-dom";
import {
  getDocumentUrl,
  useCurrentDocumentPageParameters
} from "./document-page-helpers";

export const EffectiveVersionRedirect: React.FC<{
  document: DocumentDto | undefined;
}> = ({ document }) => {
  const { effectiveVersion, status, error } = useEffectiveVersion(document);
  const currentParameters = useCurrentDocumentPageParameters();
  const shouldRedirectToLatestEffectiveVersion =
    effectiveVersion && !isSameDocument(document, effectiveVersion);

  const effectiveVersionUrl = getDocumentUrl({
    ...currentParameters,
    hiveId: effectiveVersion?.hiveId ?? "",
    fullName: effectiveVersion?.fullName ?? "",
    showExact: true
  });

  return (
    <ApiFetchIndicator
      status={status}
      errorComponent={<DocumentPageError error={error} />}
    >
      {shouldRedirectToLatestEffectiveVersion ? (
        <Redirect to={effectiveVersionUrl} />
      ) : (
        <DocumentPage document={document} />
      )}
    </ApiFetchIndicator>
  );
};

function isSameDocument(
  left: DocumentDto | undefined,
  right: DocumentModel | undefined
) {
  return left?.hiveId === right?.hiveId && left?.fullName === right?.fullName;
}
