import React from "react";
import {
  TimelineItemModel,
  useReplacedBasedHistoryTimeline
} from "./document-history-timeline-helpers";
import { QueryStatus } from "react-query";

export interface BranchingHistoryTimelineProps {
  hiveId: string;
  fullName: string;
  children: (
    items: TimelineItemModel[],
    status: QueryStatus
  ) => React.ReactElement;
}

/**
 * Wrapper of hooks call to enable conditionally choosing api
 */
export const BranchingHistoryTimeLineData: React.FC<BranchingHistoryTimelineProps> =
  ({ hiveId, fullName, children }) => {
    const { data: items, status } = useReplacedBasedHistoryTimeline(
      hiveId,
      fullName
    );
    return children(items, status);
  };
