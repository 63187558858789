import { HtmlAnnotationModel } from "@rhinestone/portal-web-api";
import { AnnotationLayoutState } from "../annotation-layout-state";

export function convertToLayoutState(
  annotations: HtmlAnnotationModel[] | undefined,
  documentElement: HTMLDivElement | null
): AnnotationLayoutState {
  if (!documentElement || annotations?.length === 0) return {};
  const documentElementRect = documentElement.getBoundingClientRect();

  return annotations
    ? annotations.reduce((acc, current) => {
        const annotationElement = documentElement.querySelector(
          `[data-annotation-id='${current.id}']`
        );

        // we use getClientRects to get the individual parts of annotations which happens when it wraps multiple lines
        const firstAnnotationRect = annotationElement?.getClientRects()[0];

        if (!firstAnnotationRect) return acc;

        const { x, y } = calculateRelativePosition(
          firstAnnotationRect,
          documentElementRect
        );

        return {
          ...acc,
          [current.id]: {
            // there is only one page in html documents
            pageIndex: 0,
            x,
            y
          }
        };
      }, {})
    : {};
}

function calculateRelativePosition(
  firstAnnotationRect: DOMRect,
  documentElementRect: DOMRect
) {
  // in order to not have too many layout updates we round x, y to nearest integer
  // which is precise enough for our layout purpose
  // otherwise x, y is changing all the time because of 9 decimal precision
  const x = Math.round(firstAnnotationRect.left - documentElementRect.left);
  const y = Math.round(firstAnnotationRect.top - documentElementRect.top);
  return { x, y };
}
