import React from "react";
import { getDocumentUrl } from "../../../../document/document-page/document-page-helpers";
import useDocumentSearch from "../../helpers/document-helpers";
import DocumentListItem from "./DocumentListItem";

interface DocumentListItemsContainerProps {
  productCode: string;
  taxonomyName: string;
  taxonKey?: string;
}
const DocumentListItemsContainer: React.FC<DocumentListItemsContainerProps> = ({
  productCode,
  taxonomyName,
  taxonKey
}) => {
  const documents = useDocumentSearch(productCode, taxonomyName, taxonKey);
  return (
    <>
      {documents &&
        documents.map(document => {
          const link = getDocumentUrl({
            hiveId: document.hiveId,
            fullName: document.fullName
          });
          return (
            <DocumentListItem
              key={document.title}
              title={document.title}
              link={link}
            />
          );
        })}
    </>
  );
};

export default DocumentListItemsContainer;
