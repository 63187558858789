import * as React from "react";
import {
  ComingStatusIcon,
  EffectiveStatusIcon,
  HistoricStatusIcon,
  LatestLbkStatusIcon,
  PreviousVersionStatusIcon,
  StatusIconProps,
  UnknownStatusIcon
} from "./StatusIcons";
import {
  DanishLawStatus,
  danishLawStatusTranslationKeys
} from "../danish-law-status";
import { Translate } from "react-localize-redux";

export const DanishLawStatusIcon: React.FC<
  {
    status: DanishLawStatus;
  } & Omit<StatusIconProps, "alt">
> = ({ status, ...rest }) => {
  const iconProps = {
    ...rest,
    alt: <Translate id={danishLawStatusTranslationKeys[status]} />
  };

  const legalStatusIcons: { [key in DanishLawStatus]: React.ReactElement } = {
    [DanishLawStatus.Coming]: <ComingStatusIcon {...iconProps} />,
    [DanishLawStatus.Unknown]: <UnknownStatusIcon {...iconProps} />,
    [DanishLawStatus.Effective]: <EffectiveStatusIcon {...iconProps} />,
    [DanishLawStatus.Historic]: <HistoricStatusIcon {...iconProps} />,
    [DanishLawStatus.EffectiveLbk]: <LatestLbkStatusIcon {...iconProps} />,
    [DanishLawStatus.PreviousVersion]: (
      <PreviousVersionStatusIcon {...iconProps} />
    )
  };

  return legalStatusIcons[status];
};
