import MaterialUiTimeline from "@material-ui/lab/Timeline";
import * as React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 20
  }
}));

export const Timeline: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <MaterialUiTimeline className={classes.root}>{children}</MaterialUiTimeline>
  );
};
