import React from "react";
import ExpandLessIcon from "@material-ui/icons/UnfoldLess";
import ExpandMoreIcon from "@material-ui/icons/UnfoldMore";
import { IconButton } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import {
  useDocumentPathName,
  useFoldableSectionState
} from "../../content/rhinestone-xml/document-components/FoldableSection";

const ToggleCollapsibleSectionsAction: React.FC = props => {
  const { setExpandedAll, expandedState } = useFoldableSectionState();
  const documentPath = useDocumentPathName();
  const allExpanded = Object.keys(expandedState)
    .filter(key => key.startsWith(documentPath))
    .every(key => expandedState[key]);

  return (
    <Translate>
      {({ translate }) => (
        <IconButton
          {...props}
          size="small"
          title={translate(
            !allExpanded
              ? translationKeys.document_page.expand_all
              : translationKeys.document_page.collapse_all
          ).toString()}
          onClick={() => setExpandedAll(!allExpanded)}
        >
          {allExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      )}
    </Translate>
  );
};

export default ToggleCollapsibleSectionsAction;
