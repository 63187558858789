import React, { useContext, useState } from "react";

/**
 * Annotation client state type
 *
 * Expand this with more complex object and use of useReducer if need more complicated client state
 */
export type AnnotationClientState = [
  isVisible: boolean,
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
];

const AnnotationClientStateContext = (() => {
  const annotationClientContext = React.createContext<AnnotationClientState>([
    true,
    _ => undefined
  ]);
  annotationClientContext.displayName = "AnnotationClientStateContext";
  return annotationClientContext;
})();

/**
 * This provider encapsulated global client side state for annotations on document page
 *
 * Its separate from the server side state of actual annotations being fetched and update as per our state management guidelines and use of react-query
 */
export const AnnotationClientStateProvider: React.FC = ({ children }) => {
  // Expand this with more complex state and use of useReducer as described here https://kentcdodds.com/blog/how-to-use-react-context-effectively
  // if requirements to AnnotationClient state changes
  const state = useState(true);

  return (
    <AnnotationClientStateContext.Provider value={state}>
      {children}
    </AnnotationClientStateContext.Provider>
  );
};

export function useAnnotationClientContext() {
  return useContext(AnnotationClientStateContext);
}
