import * as React from "react";
import { Accordion } from "../../../../../ui-components/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import { useFoldableSectionState } from ".";
import clsx from "clsx";
import { FoldableSectionHeader } from "../FoldableSectionHeader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SectionAction } from "../../rhinestone-document-helper-types";

export interface FoldableSectionProps {
  defaultExpanded: boolean;
  sectionTitleAsString: string | undefined;
  sectionId: string;
  className: string;
  index: number;
  renderSectionActions: SectionAction;
}

export const FoldingTimeoutInMs = 10;

const useAccordionStyles = makeStyles({
  root: {
    borderBottom: "1px solid #ccc",
    boxShadow: "none",
    "& .MuiAccordionDetails-root, .MuiAccordionSummary-root": {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
});

const useStyles = makeStyles({
  label: {
    flexGrow: 1
  }
});

export const FoldableSection: React.FC<FoldableSectionProps> = ({
  defaultExpanded,
  sectionTitleAsString,
  sectionId,
  className,
  renderSectionActions,
  children
}) => {
  const { expanded, setExpanded } = useFoldableSectionState(
    sectionId,
    defaultExpanded
  );

  const deconstructedChildren = deconstructChildren(children);

  const accordionStyles = useAccordionStyles();
  const styles = useStyles();
  return (
    <span className="foldable-section-with-menu">
      <section id={sectionId} className={clsx(className, "foldable-section")}>
        <Accordion
          classes={accordionStyles}
          expanded={expanded}
          square
          // I had to comment out onChange event since section actions menu is now part of Accordion Summary (header)
          // and click event propagates to it. I could stop propagation of click events in all of the actions, but since
          // there can be many of them, I decided to handle onClick events of Accordion label and expand icon
          // to control expanded state of the Accordion
          //onChange={() => setExpanded(!expanded)}
          label={
            <>
              <div
                data-test-id={"accordion-label"}
                className={styles.label}
                onClick={() => setExpanded(!expanded)}
              >
                {deconstructedChildren.header}
              </div>
              {renderSectionActions({
                sectionId,
                sectionClassName: className,
                sectionTitle: sectionTitleAsString,
                sectionActionsClassName: "foldable-section-menu"
              })}
            </>
          }
          // timeout of transition is set to very low, because otherwise scrolling to nested element doesn't work properly
          // the browser scrolls to anchor, and if it starts before transitions is over (especially for long folded sections)
          // the scrolling ends it a very random place
          // i searched long time for solution to this, but could not find any solution
          // maybe we can ask in stackoverflow or material ui github issue how to handle anchor scroll to elements inside folded accordion
          // for now extremely short transition seems to work across all browsers (chrome, edge, ie11)
          foldingTransitionTimeout={FoldingTimeoutInMs}
          unmountOnExit={false}
          expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
        >
          {deconstructedChildren.rest}
        </Accordion>
      </section>
    </span>
  );
};

function deconstructChildren(children: React.ReactNode) {
  // We expect first element in Section to have been converted to a SectionHeader component
  // Checking on the React type to figure out if first element is really a header
  // if it is not, for now, convention is that this cannot be section title then
  const childrenAsArray = React.Children.toArray(children);

  const [sectionHeaderChild, ...rest] = childrenAsArray;
  const hasHeader = isSectionHeader(sectionHeaderChild);
  return {
    header: hasHeader ? sectionHeaderChild : null,
    rest: hasHeader ? rest : [sectionHeaderChild, ...rest]
  };
}

function isSectionHeader(sectionHeaderChild: React.ReactNode) {
  // First usage of React type checking in production code
  // i guess this is ok since type property is publicly available on ReactElement type.
  return (
    (sectionHeaderChild as React.ReactElement)?.type === FoldableSectionHeader
  );
}
