import { useEffect } from "react";
import { findBarClassNames } from "../../../FindBar/FindBar";
import { iconMenuClassNames } from "../../../../DocumentActionMenu";
import { PDFViewer } from "pdfjs-dist/legacy/web/pdf_viewer";

import { PDFFindBar } from "./in-sourced/pdf-find-bar";

export function useSetupFindBar(
  findBar: HTMLElement | null | undefined,
  pdfViewer: PDFViewer | undefined
) {
  useEffect(() => {
    if (!findBar || !pdfViewer) return;
    setupFindBar(pdfViewer, findBar);
  }, [pdfViewer, findBar]);
}

function setupFindBar(pdfViewer: PDFViewer, searchBarElement: HTMLElement) {
  const globalToggles = getGlobalToggleElementsForFindBar();
  const findBar = new PDFFindBar(
    {
      bar: searchBarElement,
      findField: searchBarElement.querySelector(
        `.${findBarClassNames.findInput}`
      ) as never,
      toggleButton: new ToggleButtonProxy([
        searchBarElement.querySelector(`.${findBarClassNames.findToggle}`),
        ...globalToggles
      ]) as never,
      findPreviousButton: searchBarElement.querySelector(
        `.${findBarClassNames.findPrevious}`
      ) as never,
      findNextButton: searchBarElement.querySelector(
        `.${findBarClassNames.findNext}`
      ) as never,
      findMsg: NoOperationElement,
      findResultsCount: searchBarElement.querySelector(
        `.${findBarClassNames.findResultsCount}`
      ) as never,
      highlightAllCheckbox: HighlightAllProxy,
      entireWordCheckbox: NoOperationElement,
      caseSensitiveCheckbox: NoOperationElement,
      matchDiacriticsCheckbox: NoOperationElement
    } as never,
    pdfViewer.eventBus,
    pdfViewer.l10n
  );

  pdfViewer.eventBus.on("updatefindmatchescount", ({ matchesCount }: any) => {
    findBar.updateResultsCount(matchesCount);
  });

  pdfViewer.eventBus.on(
    "updatefindcontrolstate",
    ({ state, previous, matchesCount }: any) => {
      findBar.updateUIState(state, previous, matchesCount);
    }
  );
}

function getGlobalToggleElementsForFindBar() {
  // using a universal selector here to avoid prop drilling or adding a new context containing reference to document actions
  // we might need to rework this if we experience issues with this
  // TODO: consider fixing this and pass it down/use context as everything else
  const documentAction = document.querySelector(
    `.${iconMenuClassNames.findToggle}`
  );

  return documentAction ? [documentAction] : [];
}

/**
 * The PDFFindBar expects an element for all functionalities, but we do not have UI ellements for all
 * This is a no-op object that we can pass to the PDFFindBar
 */
const NoOperationElement = {
  addEventListener: () => {
    return;
  },
  setAttribute: () => {
    return;
  },
  classList: {
    add: () => {
      return;
    },
    remove: () => {
      return;
    }
  } as Partial<DOMTokenList>
} as Partial<HTMLInputElement>;

/**
 * We don't have a UI element for the option to highlight all or not checkbox,
 * so we pass proxy element to PDFFindBar UI controller spoofing the "checked" state
 */
const HighlightAllProxy = {
  addEventListener: () => {
    return;
  },
  checked: true
} as Partial<HTMLInputElement>;

/**
 * Proxy object to enable us to have multiple toggle buttons for the find bar
 */
class ToggleButtonProxy {
  constructor(public elements: (Element | null)[]) {}
  /**
   * PDFFindBar calls this method when setting up event listeners
   */
  public addEventListener(event: string, callback: (event: Event) => void) {
    this.elements.forEach(element => {
      element?.addEventListener(event, callback);
    });
  }

  public classList = {
    add: () => {
      // NOOP
      return;
    },
    remove: () => {
      // NOOP
      return;
    }
  };

  public setAttribute() {
    // NOOP
    return;
  }
}
