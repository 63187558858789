import React from "react";
import TileTitle from "./TileTitle";
import SearchButton from "../../../ui-components/SearchButton/SearchButton";
import TaxonContentBaseTile from "./TaxonContentBaseTile";

interface FolderBaseTileProps {
  children: React.ReactNode;
  taxonTitle: string;
  link: string;
  searchLink: string;
  siblingCount?: number;
}
const FolderBaseTile: React.FC<FolderBaseTileProps> = ({
  children,
  taxonTitle,
  link,
  searchLink,
  siblingCount
}) => {
  return (
    <TaxonContentBaseTile
      key={taxonTitle}
      title={<TileTitle link={link} name={taxonTitle} />}
      actions={<SearchButton href={searchLink} />}
      siblingCount={siblingCount}
    >
      {children}
    </TaxonContentBaseTile>
  );
};

export default FolderBaseTile;
