import * as React from "react";
import MaterialUiTimelineItem from "@material-ui/lab/TimelineItem";
import { makeStyles } from "@material-ui/core/styles";
import { TimelineOppositeContent } from "@material-ui/lab";
import clsx from "clsx";
import { useLazyLoad } from "../LazyLoad/useLazyLoad";

const useStyles = makeStyles({
  item: { justifyContent: "flex-start" },
  oppositeContent: {
    display: "none"
  },
  sticky: {
    minHeight: 40
  }
});

type TimelineItemWrapperProps = {
  useLazyLoading?: boolean;
  sticky?: boolean;
  id?: string;
};

export const TimelineItemWrapper: React.FC<TimelineItemWrapperProps> = ({
  sticky,
  id,
  useLazyLoading,
  children
}) => {
  const classes = useStyles();
  const additionalMuiProps = {
    id
  };
  const { inView, ref } = useLazyLoad({ disabled: !useLazyLoading });
  return (
    <MaterialUiTimelineItem
      {...additionalMuiProps}
      className={clsx(classes.item, sticky ? classes.sticky : "")}
      ref={ref}
    >
      {inView && (
        <TimelineOppositeContent className={classes.oppositeContent} />
      )}
      {inView && children}
    </MaterialUiTimelineItem>
  );
};
