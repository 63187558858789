import { useDocumentInfo } from "../document-context";
import { useGetPrepatoryWorkTimelines } from "./use-get-prepatory-work-timelines";
import { useShouldShowPreparatoryWork } from "./use-should-show-preparatory-work";

export function useHasPreparatoryWork() {
  const { hiveId, fullName, properties } = useDocumentInfo();

  const shouldShowPrepWork = useShouldShowPreparatoryWork(
    hiveId,
    fullName,
    properties
  );

  const { timelineDocuments, isLoading } = useGetPrepatoryWorkTimelines(
    hiveId,
    fullName,
    config => config.consolidationRelationTypeCode
  );

  return (
    shouldShowPrepWork &&
    !isLoading &&
    timelineDocuments &&
    timelineDocuments.length > 0
  );
}
