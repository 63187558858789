import { CardSize } from "../tiles/Tile";
import {
  gridGap,
  lgUpColumnCount,
  mdColumnCount,
  rowHeight,
  smColumnCount,
  xsDownColumnCount
} from "../tiles/TileGrid";
import { useGetBreakpoint } from "./breakpoint-helpers";

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

export function useGetTileProps(
  childCount: number,
  siblingCount: number | undefined,
  innerHeight: number = window.innerHeight,
  innerUseGetBreakpoint: () => Breakpoint = useGetBreakpoint
): { showExpand: boolean; size: CardSize } {
  const breakpointColumns = {
    xs: xsDownColumnCount,
    sm: smColumnCount,
    md: mdColumnCount,
    lg: lgUpColumnCount,
    xl: lgUpColumnCount
  };

  const breakpoint = innerUseGetBreakpoint();
  const cardColumns = breakpointColumns[breakpoint];

  const cardRows = getRows(innerHeight);

  const cardsFitOnScreen = cardColumns * cardRows;

  const size: CardSize = getCardSize(
    childCount,
    siblingCount || 1,
    cardsFitOnScreen,
    breakpoint
  );

  const showExpand = getShowExpand(size, childCount);
  return { showExpand, size };
}
const smallChildCapacity = 5;
const mediumChildCapacity = 12;
const largeChildCapacity = 24;

function getShowExpand(size: string, childCount: number) {
  const cardCapacity =
    size === "small"
      ? smallChildCapacity
      : size === "medium"
      ? mediumChildCapacity
      : largeChildCapacity;
  const showExpand = childCount > cardCapacity;
  return showExpand;
}

function getCardSize(
  childCount: number,
  siblingCount: number,
  cardsFitOnScreen: number,
  breakpoint: Breakpoint
) {
  let size: CardSize = "small";
  if (
    childCount > mediumChildCapacity &&
    siblingCount < cardsFitOnScreen / 2 &&
    breakpoint !== "xs"
  ) {
    size = "large";
  } else if (
    childCount > smallChildCapacity &&
    siblingCount < cardsFitOnScreen / 1.5
  ) {
    size = "medium";
  }
  return size;
}

function getRows(innerHeight: number = window.innerHeight) {
  return innerHeight < 440
    ? 1
    : Math.round((innerHeight - 440) / (gridGap + rowHeight)) + 1;
}
