import {
  InputBase,
  makeStyles,
  Box,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import { ArrowForward, QuestionAnswer } from "@material-ui/icons";
import * as React from "react";

interface AiSearchInputFieldProps {
  prompt: string;
  isStreaming: boolean;
  doAiSearch: (prompt: string) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    paddingRight: "2px",
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    position: "relative",
    border: "1px solid #ccc",
    borderRadius: "4px"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  searchIcon: {
    marginLeft: "10px",
    color: "#c8c8c8"
  },
  searchButton: {
    height: "42px",
    width: "42px",
    backgroundColor: "#e6e6e6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    color: "black",
    "&:hover": {
      backgroundColor: "#ccc"
    },
    "&:disabled": {
      backgroundColor: "#ccc"
    },
    "&>.MuiIconButton-label": {
      height: "42px",
      width: "42px"
    }
  },
  spinner: {
    color: "black"
  }
}));

const AiSearchInputField: React.FunctionComponent<AiSearchInputFieldProps> = ({
  prompt,
  isStreaming,
  doAiSearch,
  handleChange
}) => {
  const classes = useStyles();

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      doAiSearch(prompt);
    }
  };

  return (
    <Box component="form" className={classes.root}>
      <QuestionAnswer className={classes.searchIcon} />
      <InputBase
        className={classes.input}
        placeholder="Stil et spørgsmål..."
        inputProps={{ "aria-label": "✨ Ai Search ✨" }}
        onChange={handleChange}
        value={prompt}
        onKeyDown={handleEnterPress}
      />
      <IconButton
        className={classes.searchButton}
        onClick={() => doAiSearch(prompt)}
        disabled={isStreaming}
      >
        {isStreaming ? (
          <CircularProgress size={20} className={classes.spinner} />
        ) : (
          <ArrowForward />
        )}
      </IconButton>
    </Box>
  );
};

export default AiSearchInputField;
