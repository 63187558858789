import { usePortalApi } from "../api-access/use-portal-api";

/**
 * @returns Static per portal configuration for the document page
 */
export function useDocumentPageConfig() {
  return usePortalApi(
    client => client.getDocumentPageConfiguration(),
    ["getDocumentPageConfiguration"]
  );
}
