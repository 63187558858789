import * as React from "react";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Fade,
  Tooltip
} from "@material-ui/core";
import { PaneItem } from "./ThreePanesLayout";
import { useState } from "react";
import { PaneTitle } from "./PaneTitle";

const useStyles = makeStyles({
  appBar: {
    top: "auto",
    bottom: 0,
    width: "inherit",
    right: "auto"
  },
  pane: {
    // Height of bottom AppBar
    paddingBottom: "72px"
  }
});

interface CollapsedPaneContentProps {
  items: PaneItem[];
}
export const CollapsedPaneContent: React.FC<CollapsedPaneContentProps> = ({
  items
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const classes = useStyles();
  return (
    <>
      {items.map((i, index) => (
        <Fade in={index === currentTabIndex} key={index}>
          <div hidden={index !== currentTabIndex} className={classes.pane}>
            <PaneTitle>{i.title}</PaneTitle>
            {i.content}
          </div>
        </Fade>
      ))}
      <AppBar color="primary" className={classes.appBar}>
        <Tabs
          value={currentTabIndex}
          variant="fullWidth"
          indicatorColor="secondary"
          onChange={(ev, newValue) => setCurrentTabIndex(newValue)}
        >
          {items.map((i, index) => (
            <Tooltip title={i.title ?? ""} key={index}>
              <Tab value={index} icon={i.icon || ""} label={i.title} />
            </Tooltip>
          ))}
        </Tabs>
      </AppBar>
    </>
  );
};
