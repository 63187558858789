import { Container, Typography } from "@material-ui/core";
import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import FormActions from "./FormActions";
import {
  CreateAssetCollectionTypedForm,
  TypedFormProps
} from "./TypedCreateAssetCollectionForm";
import { TypedTextField } from "./TypedFormTextField";
interface CreateAssetCollectionFormProps {
  onCancel: () => void;
  onSubmit: (formData: TypedFormProps) => void;
}

const CreateAssetCollectionForm: React.FC<CreateAssetCollectionFormProps> = ({
  onCancel,
  onSubmit
}) => {
  return (
    <Container>
      <CreateAssetCollectionTypedForm onSubmit={onSubmit}>
        <Typography>
          <Translate id={translationKeys.assets.new_collection_message} />
        </Typography>
        <Translate>
          {({ translate }) => (
            <TypedTextField
              fieldName="name"
              label={<Translate id={translationKeys.assets.name} />}
              fullWidth
              autoFocus
              fieldOptions={{
                required: translate(
                  translationKeys.assets.name_required
                ).toString()
              }}
            />
          )}
        </Translate>
        <TypedTextField
          fieldName="journal"
          label={<Translate id={translationKeys.assets.reference_number} />}
          fullWidth
        />
        <FormActions onCancel={onCancel} />
      </CreateAssetCollectionTypedForm>
    </Container>
  );
};

export default CreateAssetCollectionForm;
