/**
 * Definition of expected status codes we work with when calling portal api
 */
export const Unauthorized = 401;
export const Forbidden = 403;
export const NotFound = 404;

export type KnownHttpStatusCodes = (
  | typeof Unauthorized
  | typeof Forbidden
  | typeof NotFound
)[];
