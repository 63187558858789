import { ParagraphInTime } from "@rhinestone/portal-web-api";
import React from "react";
import { DatePossiblyUndecidedFormatter } from "../../../localization/DateFormatter/DateFormatterPossiblyUndecided";
import { TimelineItem } from "../../../ui-components/split-timeline";
import { ParagraphTimelineIcon } from "./ParagraphTimelineIcon";
import { ParagraphTimelineLabel } from "./ParagraphTimelineLabel";

interface ParagraphTimelineItemProps {
  paragraph: ParagraphInTime;
  active?: boolean;
  isLastItem?: boolean;
  selectParagraphInTime: (item: ParagraphInTime) => void;
  createToolTip: (
    paragraph: ParagraphInTime
  ) => React.ComponentType | undefined;
}
const ParagraphTimelineItem: React.FC<ParagraphTimelineItemProps> = ({
  paragraph,
  active,
  isLastItem,
  selectParagraphInTime,
  createToolTip
}) => {
  const createIcon = (item: ParagraphInTime, active?: boolean) => (
    <ParagraphTimelineIcon fontSize="small" {...item} active={active} />
  );

  return (
    <TimelineItem
      title={<DatePossiblyUndecidedFormatter value={paragraph.effectiveDate} />}
      subTitle={<ParagraphTimelineLabel repealed={paragraph.repealed} />}
      icon={createIcon(paragraph, active)}
      active={active}
      isLastItem={isLastItem}
      linkOnClick={() => selectParagraphInTime(paragraph)}
      tooltipComponent={createToolTip(paragraph)}
      icon-aria-label={paragraph.title}
    />
  );
};

export default ParagraphTimelineItem;
