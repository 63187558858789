import React, { useState } from "react";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { ClickAwayListener, Zoom, makeStyles } from "@material-ui/core";
import { IconButton } from "../IconButton";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

const useStyles = makeStyles({
  showMore: {
    position: "absolute"
  }
});

export const IconMenu: React.FC<{
  amount?: number;
  size?: "small" | "medium";
}> = ({ children, size, amount = 5 }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement[];
  const hiddenItems = childrenArray.slice(amount);

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <div>
        {/* Items always shown */}
        <span className={`icon-menu`}>
          {childrenArray.slice(
            0,
            hiddenItems.length === 1 ? amount + 1 : amount
          )}
        </span>
        {/* "Show more" icon button */}
        <span className={classes.showMore}>
          {!expanded && hiddenItems.length > 1 && (
            <Translate>
              {({ translate }) => (
                <IconButton
                  size={size}
                  onClick={() => setExpanded(true)}
                  aria-haspopup="true"
                  title={translate(
                    translationKeys.document_page.show_more
                  ).toString()}
                >
                  <MoreHoriz />
                </IconButton>
              )}
            </Translate>
          )}
        </span>
        {/* Items only shown when expanded */}
        <span>
          {hiddenItems.map((child, i) => (
            <Zoom
              key={i}
              style={{
                transitionDelay: `${Math.pow(i, 0.75) * 60}ms`
              }}
              in={expanded}
            >
              {child}
            </Zoom>
          ))}
        </span>
      </div>
    </ClickAwayListener>
  );
};
