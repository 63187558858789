import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { ChatMessageDto } from "@rhinestone/portal-web-api";
import * as React from "react";
import { FC } from "react";

interface ChatHistoryProps {
  chatHistory: ChatMessageDto[];
  isStreaming: boolean;
}

const useStyles = makeStyles(theme => ({
  history: {
    display: "flex",
    flexDirection: "column"
  },
  message: {
    border: "1px dashed #e0e0e0",
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  text: {
    whiteSpace: "pre-wrap"
  }
}));

const ChatHistory: FC<ChatHistoryProps> = ({ chatHistory, isStreaming }) => {
  const classes = useStyles();

  if (chatHistory.length === 0 || isStreaming) return null;

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Chat History</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.history}>
          {chatHistory.map((message, index) => (
            <div key={index} className={classes.message}>
              <b>{message.role}</b>
              <div className={classes.text}>{message.content}</div>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ChatHistory;
