import React from "react";
import { useCurrentDocumentPageParameters } from "../../document-page/document-page-helpers";
import { ArticleAllRelationDialog } from "./document-relation-groups/RelationsDialog/ArticleAllRelationDialog";
import { ArticleRelationDialog } from "./document-relation-groups/RelationsDialog/ArticleRelationDialog";
import PZoomRelationDialog from "./document-relation-groups/RelationsDialog/PZoomRelationDialog";
import { SectionRelationDialog } from "./document-relation-groups/RelationsDialog/SectionRelationDialog";

// Why have made a global dialog component instead of rendering the dialog components where "they belong"
// investigate and write explanantion here
// or rewrite the code so that the dialog components are rendered where they belong, and this component here can be removed
const DocumentPageRelationsDialogsContainer: React.FC = () => {
  const { relgroup, relsection, relshowall, pzoom } =
    useCurrentDocumentPageParameters();

  if (!relgroup) return null;

  if (pzoom)
    return <PZoomRelationDialog paragraphId={pzoom} groupName={relgroup} />;
  if (relsection)
    return (
      <SectionRelationDialog sectionId={relsection} groupName={relgroup} />
    );
  if (relshowall) return <ArticleAllRelationDialog groupName={relgroup} />;

  return <ArticleRelationDialog groupName={relgroup} />;
};

export default DocumentPageRelationsDialogsContainer;
