import {
  AssetCollectionTemplateViewModel,
  AssetCollectionViewModel
} from "@rhinestone/portal-web-api";
import { isEmpty } from "lodash";
import { useSearchParams } from "../../../browser-utils/useSearchParams";
import { usePortalApi } from "../../api-access/use-portal-api";

export type AssetCollectionContextType =
  | {
      assetCollectionId: string | undefined;
      assetCollectionTemplateId: string | undefined;
      assetCollectionTitle: string;
      isTemplate: boolean;
      isArchived: boolean;
    }
  | "loading"
  | "no-ac-context";

export function useAssetCollectionContext(): AssetCollectionContextType {
  const { assetCollectionId, assetCollectionTemplateId, isArchived } =
    useSearchParams() as {
      assetCollectionId: string;
      assetCollectionTemplateId: string;
      isArchived: string;
    };
  const assetCollection = usePortalApi(
    client => client.getAssetCollection(assetCollectionId),
    ["getAssetCollection", assetCollectionId],
    { enabled: !isEmpty(assetCollectionId) }
  ).data as AssetCollectionViewModel | null;

  const assetCollectionTemplate = usePortalApi(
    client => client.getAssetCollectionTemplate(assetCollectionTemplateId),
    ["getAssetCollectionTemplate", assetCollectionTemplateId],
    { enabled: !isEmpty(assetCollectionTemplateId) && !isArchived }
  ).data as AssetCollectionTemplateViewModel | null;

  // accessing archived templates is restricted to special role users,
  // that's why they are retrieved by a different endpoint
  const assetCollectionArchivedTemplate = usePortalApi(
    client =>
      client.getArchivedAssetCollectionTemplate(assetCollectionTemplateId),
    ["getArchivedAssetCollectionTemplate", assetCollectionTemplateId],
    { enabled: !isEmpty(assetCollectionTemplateId) && Boolean(isArchived) }
  ).data as AssetCollectionTemplateViewModel | null;

  if (!assetCollectionId && !assetCollectionTemplateId) {
    // there isn't an asset collection context at all
    return "no-ac-context";
  }

  return buildAssetCollectionContext(
    assetCollection,
    assetCollectionTemplate,
    assetCollectionArchivedTemplate
  );
}

export function useAssetItemContext() {
  const { assetCollectionId, assetCollectionTemplateId, assetItemId } =
    useSearchParams() as {
      assetCollectionId: string;
      assetCollectionTemplateId: string;
      assetItemId: string;
    };
  const assetCollectionItem = usePortalApi(
    client => client.getAssetCollectionItem(assetCollectionId, assetItemId),
    ["getAssetCollectionItem", assetCollectionId, assetItemId],
    { enabled: !isEmpty(assetCollectionId) }
  ).data;
  const assetCollectionTemplateItem = usePortalApi(
    client =>
      client.getAssetCollectionTemplateItem(
        assetCollectionTemplateId,
        assetItemId
      ),
    ["getAssetCollectionTemplateItem", assetCollectionTemplateId, assetItemId],
    { enabled: !isEmpty(assetCollectionTemplateId) }
  ).data;

  return assetCollectionItem || assetCollectionTemplateItem;
}

export function useHasAssetCollectionContext(): boolean {
  const context = useAssetCollectionContext();
  return context && context !== "loading" && context !== "no-ac-context";
}

export function mapToAssetCollection(
  assetCollectionContextType: AssetCollectionContextType
) {
  // return undefined if no assetCollection is loaded
  switch (assetCollectionContextType) {
    case "loading":
    case "no-ac-context":
      return;
  }

  return assetCollectionContextType;
}

function buildAssetCollectionContext(
  assetCollection: AssetCollectionViewModel | null,
  assetCollectionTemplate: AssetCollectionTemplateViewModel | null,
  assetCollectionArchivedTemplate: AssetCollectionTemplateViewModel | null
) {
  // context is loading
  let context: AssetCollectionContextType = "loading";

  if (assetCollection) {
    context = {
      assetCollectionId: assetCollection.assetCollectionId,
      assetCollectionTemplateId: undefined,
      assetCollectionTitle: assetCollection.title,
      isTemplate: false,
      isArchived: assetCollection.isArchived
    };
  } else if (assetCollectionTemplate) {
    context = {
      assetCollectionId: undefined,
      assetCollectionTemplateId:
        assetCollectionTemplate.assetCollectionTemplateId,
      assetCollectionTitle: assetCollectionTemplate.title,
      isTemplate: true,
      isArchived: false
    };
  } else if (assetCollectionArchivedTemplate) {
    context = {
      assetCollectionId: undefined,
      assetCollectionTemplateId:
        assetCollectionArchivedTemplate.assetCollectionTemplateId,
      assetCollectionTitle: assetCollectionArchivedTemplate.title,
      isTemplate: true,
      isArchived: true
    };
  }

  return context;
}
