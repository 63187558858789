import * as React from "react";
import { useParams } from "react-router-dom";
import {
  useCurrentDocumentPageParameters,
  useGetDocument
} from "./document-page-helpers";
import { ApiFetchIndicator } from "../../ui-components/ApiFetchIndicator";
import { DocumentPageError } from "./DocumentPageError";
import { DocumentPage } from "./DocumentPage";
import { EffectiveVersionRedirect } from "./EffectiveVersionRedirect";

export const DocumentPageContainer: React.FC = () => {
  const { hiveId, fullName } = useParams<{
    hiveId: string;
    fullName: string;
  }>();

  // We fetch the basic document info here on the DocumentPage since we don't want to render children until this first basic query has succeeded
  const {
    data: document,
    status: documentStatus,
    error
  } = useGetDocument(hiveId, fullName);
  const { showExact } = useCurrentDocumentPageParameters();

  React.useEffect(() => {
    if (!document) return;
    // Save timestamp of when document was opened. It is used to show
    // "last visited" in the search result.
    localStorage.setItem(
      "lastVisited_" + document.documentId,
      new Date().toISOString()
    );
  }, [document]);

  // We simply return "spinner" until DocumentInfo is loaded since there is nothing to display until then
  // most other API calls about a document needs DocumentInfo to start retrieving data
  // and it simplifies DocumentInfo null checking in rest of the codebase, since context will always be there
  return (
    <ApiFetchIndicator
      status={documentStatus}
      errorComponent={<DocumentPageError error={error} />}
    >
      {!showExact ? (
        <EffectiveVersionRedirect document={document} />
      ) : (
        <DocumentPage document={document} />
      )}
    </ApiFetchIndicator>
  );
};
