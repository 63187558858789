import React from "react";
import { RadioButtonGroup, RadioButtonGroupBaseProps } from "../../RadioButton";
import { BaseFormFieldProps } from "./FieldOptions";

import { connectToForm } from "./FormFieldWrapper";

export type FormRadioButtonGroupFieldProps<TFieldName extends string> =
  BaseFormFieldProps<TFieldName, string> & RadioButtonGroupBaseProps;

const FormRadioButtonGroupField: <TFieldName extends string>(
  props: FormRadioButtonGroupFieldProps<TFieldName>
) => JSX.Element = connectToForm(RadioButtonGroup);

export default FormRadioButtonGroupField;
