import * as React from "react";
import {
  CircularProgress,
  CircularProgressProps,
  Fade,
  Box
} from "@material-ui/core";
import { useState, useEffect } from "react";

type LoadingProgressProps = Pick<CircularProgressProps, "size" | "color">;

/**
 * This component encapsulates our progress indicator, when loading something from the api
 * which indeterminate
 *
 * According to
 * https://material-ui.com/components/progress/#progress
 * and
 * https://material.io/components/progress-indicators/
 * we should use the same progress type (either circular or flat) across the site for the same kind of progress kind
 */
export const LoadingProgress: React.FC<LoadingProgressProps> = props => {
  const [shouldShow, setShouldShow] = useState(false);
  useEffect(() => {
    // We delay showing of progress to follow UX directions that delays between 0.1-1 second does not need loading indicator
    // see:
    // https://material-ui.com/components/progress/#delaying-appearance
    // https://www.nngroup.com/articles/response-times-3-important-limits/

    // we set it to 850 ms, since Fade transition by default takes 150 ms
    // and we want to show it at 1 second
    const delayTime = 400;
    const timeout = setTimeout(() => setShouldShow(true), delayTime);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Fade in={shouldShow}>
        <CircularProgress {...props} />
      </Fade>
    </Box>
  );
};
