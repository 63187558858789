import React from "react";
import { Breadcrumbs, Link } from "@material-ui/core";
import { Home as HomeIcon } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface BreadcrumbProps {
  elements: { title: React.ReactNode; link: string }[];
}

const useStyles = makeStyles(theme => ({
  homeIcon: {
    marginBottom: -4,
    fontSize: 20,
    marginRight: 5
  }
}));

const Breadcrumb: React.FC<BreadcrumbProps> = ({ elements }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {elements.length > 0 && (
        <Link color="inherit" href={elements[0].link}>
          <HomeIcon className={classes.homeIcon} />
          {elements[0].title}
        </Link>
      )}
      {elements.slice(1, elements.length - 1).map((element, index) => (
        <Link
          color="inherit"
          href={element.link}
          key={element.title + "_" + index}
        >
          {element.title}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
