import React from "react";
import { Translate } from "react-localize-redux";
import {
  DocumentStatus,
  documentStatusColors,
  documentStatusTranslationKeys,
  getStatusFromFieldset
} from "../../../document-status";
import { Watermark } from ".";
import { useFieldSet } from "../../../use-fieldset";
import { useDocumentInfo } from "../../../document-context";
import { translationKeys } from "../../../../../gen/translation-keys";
import useTheme from "@material-ui/core/styles/useTheme";

const DocumentStatusWatermark: React.FC = () => {
  const { isAppealedLatestVerdict, hiveId, fullName } = useDocumentInfo();
  const fieldSet = useFieldSet(hiveId, fullName);
  const status = getStatusFromFieldset(fieldSet);
  const theme = useTheme();
  const translationKey = documentStatusTranslationKeys[status];
  const color = documentStatusColors(theme)[status];
  const enabled =
    status &&
    status !== DocumentStatus.Effective &&
    status !== DocumentStatus.Unknown;

  return enabled ? (
    <Translate>
      {({ translate }) =>
        isAppealedLatestVerdict ? (
          <Watermark
            label={translate(
              translationKeys.document_page.watermark_appealed
            ).toString()}
            color={theme.palette.status.appealed.main}
          />
        ) : (
          <Watermark label={String(translate(translationKey))} color={color} />
        )
      }
    </Translate>
  ) : (
    <></>
  );
};
export default DocumentStatusWatermark;
