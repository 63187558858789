import MaterialUITabs, {
  TabsProps as MuiTabsProps
} from "@material-ui/core/Tabs";
import * as React from "react";

export type TabsProps = Pick<
  MuiTabsProps,
  | "centered"
  | "children"
  | "value"
  | "indicatorColor"
  | "textColor"
  | "variant"
  | "className"
> & { onChange?: (event: React.ChangeEvent<unknown>, value: number) => void };

export const Tabs: React.FC<TabsProps> = ({ children, ...rest }) => (
  <MaterialUITabs {...rest}>{children}</MaterialUITabs>
);
