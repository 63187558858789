import * as React from "react";
import { IconButton } from "../../../ui-components/IconButton";
import { Search, SearchOutlined } from "@material-ui/icons";

import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { useFindInDocumentContext } from "../../FindInDocumentStateProvider";
import Fade from "@material-ui/core/Fade";
import { iconMenuClassNames } from "../DocumentActionsMenu";

export const FindInDocumentAction: React.FC = props => {
  const [findBarState, setFindBarState] = useFindInDocumentContext();

  return (
    <Translate>
      {({ translate }) => (
        <>
          <IconButton
            {...props}
            size="small"
            className={iconMenuClassNames.findToggle}
            title={translate(
              translationKeys.find_in_document.search_term
            ).toString()}
            onClick={() => {
              setFindBarState(prevState => ({
                findBarVisible: !prevState.findBarVisible
              }));
            }}
          >
            <Fade in={true}>
              {findBarState.findBarVisible ? (
                <Search color={"primary"} />
              ) : (
                <SearchOutlined />
              )}
            </Fade>
          </IconButton>
        </>
      )}
    </Translate>
  );
};
