import * as React from "react";
import MaterialUiTreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  isAllItemsSelected,
  expandSelection,
  TreeViewItemWithCheckbox,
  getAllIds,
  SelectableTreeItemData
} from ".";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { TreeViewMultiSelectItem } from "./TreeViewMultiSelectItem";

export type TreeViewMultiSelectProps = {
  items: SelectableTreeItemData[];
  onChange?: (selected: string[]) => void;
  selected?: string[];
};

export const TreeViewMultiSelect: React.FC<TreeViewMultiSelectProps> = ({
  items,
  selected = [],
  onChange
}) => {
  const [toggleAllSelected, setToggleAllSelected] = React.useState(false);

  const handleChange = (checked: boolean, treeItemId: string) => {
    const expandedSelection = expandSelection(
      checked,
      treeItemId,
      selected,
      items
    );
    const allSelected = isAllItemsSelected(expandedSelection, items);
    setToggleAllSelected(allSelected);
    onChange?.(expandedSelection);
  };

  const handleNodeSelect = (_event: React.ChangeEvent, nodeId: string) => {
    // Material-UI has nice keyboard controls, we want to keep these
    // we use the MUI treeview's in single select mode, and use selected to
    // to signify a checkbox was toggled
    if (_event.type !== "keydown") return;
    const checked = !selected.includes(nodeId);
    handleChange(checked, nodeId);
  };

  const toggleAll = (checked: boolean) => {
    setToggleAllSelected(checked);
    onChange?.(checked ? getAllIds(items) : []);
  };

  return (
    <>
      <Translate>
        {({ translate }) => (
          <TreeViewItemWithCheckbox
            onChange={toggleAll}
            label={translate(
              translationKeys.document_page.select_all
            ).toString()}
            checked={toggleAllSelected}
          ></TreeViewItemWithCheckbox>
        )}
      </Translate>
      <MaterialUiTreeView
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        selected={selected as any}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onNodeSelect={handleNodeSelect as any}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect={false}
      >
        {items.map(item => (
          <TreeViewMultiSelectItem
            key={item.treeItemId}
            item={item}
            selected={selected}
            onChange={handleChange}
          />
        ))}
      </MaterialUiTreeView>
    </>
  );
};
