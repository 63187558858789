import React from "react";
import FormModal from "./FormModal";
import SendLinkForm, { SendLinkDefaultValues } from "./SendLink/SendLinkForm";
import CreateLinkForm, {
  CreateLinkDefaultValues,
  CreateLinkMaxValues
} from "./CreateLink/CreateLinkForm";
import { CreateLinkFormProps } from "./CreateLink/TypedForm";
import { SendLinkFormProps } from "./SendLink/TypedForm";

interface SendDocumentLinkFormProps {
  isModalShown?: boolean;
  showCreateLinkForm?: boolean;
  handleClose: () => void;
  isSendingLink?: boolean;
  isCreatingLink?: boolean;
  handleCreateLinkSubmit: (formData: CreateLinkFormProps) => void;
  handleSendLinkLoad: () => void;
  handleSendLinkSubmit: (formData: SendLinkFormProps) => void;
  defaultValues: CreateLinkDefaultValues;
  maxValues: CreateLinkMaxValues;
  link: string | null;
  userDisplayName?: string;
  userEmails?: string[];
  isProfileMissingEmail: boolean;
}
const SendDocumentLinkForm: React.FC<SendDocumentLinkFormProps> = ({
  isModalShown,
  showCreateLinkForm,
  handleClose,
  isSendingLink,
  isCreatingLink,
  handleCreateLinkSubmit,
  handleSendLinkLoad,
  handleSendLinkSubmit,
  defaultValues,
  maxValues,
  link,
  userDisplayName,
  userEmails,
  isProfileMissingEmail
}) => {
  return (
    <FormModal
      isModalShown={!!isModalShown}
      setIsModalShown={handleClose}
      isLoading={!!isSendingLink || !!isCreatingLink}
    >
      {showCreateLinkForm ? (
        <CreateLinkForm
          {...defaultValues}
          {...maxValues}
          onCancel={handleClose}
          onSubmit={handleCreateLinkSubmit}
        />
      ) : (
        <SendLinkForm
          onLoad={handleSendLinkLoad}
          onCancel={handleClose}
          onSubmit={handleSendLinkSubmit}
          link={link}
          userDisplayName={userDisplayName}
          userEmails={userEmails}
          isProfileMissingEmail={isProfileMissingEmail}
        />
      )}
    </FormModal>
  );
};

export default SendDocumentLinkForm;
