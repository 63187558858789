import React, { useContext } from "react";
import { DocumentDto } from "@rhinestone/portal-web-api";
import {
  useGetAndRemoveSearchRequestFromUri,
  useDocumentSearchHighlights
} from "./document-highlights-helpers";

type DocumentHighlightsState = string[] | undefined;

const DocumentHighlightsContext = (() => {
  const documentPageContext =
    React.createContext<DocumentHighlightsState>(undefined);
  documentPageContext.displayName = "DocumentHightLightsContext";
  return documentPageContext;
})();

interface DocumentHighlightsProviderProps {
  document: DocumentDto;
}

/**
 * Even though we use react-query, in the case of highlights we decided
 * to create a context for this piece of server data, because it depended on reading and mutating the url for search request info
 * and we wanted to ensure this was only called once.
 * (alternatively we could have just created a context for the search request data,
 * but that probably felt weird since its tightly coupled and only used for for highlights on document page)
 * @param param0
 */
export const DocumentHighlightsProvider: React.FC<DocumentHighlightsProviderProps> =
  ({ document, children }) => {
    /**
     * This is a bit weird and funky but seems to get the job done. I write this comment after this was implemented
     * and i am not sure why it was implemented in this rather quirky way, of rewriting url and passing state this way.
     *
     * Maybe it was to solve issue of communicating state from angular search page to new design document page.
     *
     * Maybe it could have been solved, by saving state in location history object which i believe is possible to pass data from one page to the other, without having to do this url rewrite thing
     * Otherwise, it could be stored in angular service and passed down to temporary shell and from there to documentpage, but that is also rather clunky and not nice,
     * since its transient state only valid from one "instance" of page navigation from searchpage result to document page, and not after.
     *
     * Maybe it can be rewritten when search page is
     */
    const searchRequest = useGetAndRemoveSearchRequestFromUri();

    // Call Api here to get terms from endpoint based on "searchTermsFromUri"
    const highlights = useDocumentSearchHighlights(
      document.documentId,
      searchRequest
    );
    return (
      <DocumentHighlightsContext.Provider value={highlights}>
        {children}
      </DocumentHighlightsContext.Provider>
    );
  };

export function useDocumentHighlightsContext() {
  return useContext(DocumentHighlightsContext);
}
