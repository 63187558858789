import * as React from "react";
import { ParagraphStatus } from "../paragraph-zoom/paragraph-status";
import {
  ComingStatusIcon,
  EffectiveStatusIcon,
  HistoricStatusIcon,
  StatusIconProps
} from "./StatusIcons";

export const ParagraphStatusIcon: React.FC<
  {
    status: ParagraphStatus;
  } & StatusIconProps
> = ({ status, ...rest }) => {
  switch (status) {
    case ParagraphStatus.Coming:
      return <ComingStatusIcon {...rest} />;
    case ParagraphStatus.Effective:
      return <EffectiveStatusIcon {...rest} />;
    case ParagraphStatus.Historic:
      return <HistoricStatusIcon {...rest} />;
    default:
      return <EffectiveStatusIcon {...rest} />;
  }
};
