import * as React from "react";
import { DotIcon, TimelineSplitItem } from "../../ui-components/split-timeline";
import { SplitPathElement } from "./RelationTreeData";

interface PreparatoryWorkItemProps {
  splits?: SplitPathElement[];
  firstItem?: SplitPathElement;
  openLinksInNewTab?: boolean;
}

export const PreparatoryWorkTimelineSplitItem: React.FC<PreparatoryWorkItemProps> =
  ({ splits, firstItem, openLinksInNewTab }): JSX.Element | null => {
    if (!splits || splits.length === 0) return null;
    const mappedFirstItem = firstItem && {
      icon: <DotIcon alt={firstItem.hoverText} />,
      linkTo: firstItem.link,
      hasChildren: firstItem.hasChildren
    };
    return (
      <TimelineSplitItem
        firstItem={mappedFirstItem}
        splits={splits.map(split => ({
          icon: <DotIcon alt={split.hoverText} />,
          linkTo: split.link,
          hasChildren: split.hasChildren
        }))}
        openLinksInNewTab={openLinksInNewTab}
      />
    );
  };
