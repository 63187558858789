import { makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles(theme => ({
  text: {
    whiteSpace: "pre-wrap",
    backgroundColor: theme.alternativeBackgroundColor,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    fontSize: "1.1em"
  }
}));

const AiResponse: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.text}>{children}</div>;
};

export default AiResponse;
