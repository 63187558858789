import * as React from "react";
import MaterialUiToolbar from "@material-ui/core/Toolbar";

interface ToolbarProps {
  children?: React.ReactNode;
  className?: string;
  variant?: "dense" | "regular";
  disableGutters?: boolean;
}

export const Toolbar = React.forwardRef<HTMLDivElement, ToolbarProps>(
  (props, forwardedRef) => <MaterialUiToolbar ref={forwardedRef} {...props} />
);
