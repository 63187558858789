import { HtmlToReactNode } from "html-to-react";

const CollapsibleSectionDataAttr = "data-collapsible-type";
const SectionTagName = "section";

export function isCollapsibleSection(node: HtmlToReactNode) {
  return node.attribs[CollapsibleSectionDataAttr] === SectionTagName;
}

export function buildSectionTitleString(node: HtmlToReactNode) {
  const headerElement =
    node.type === "tag" &&
    node.children.find(node => node.type === "tag" && node.name === "h1");
  if (!headerElement) return undefined;
  const header = recursivelyBuildTitle(headerElement);
  return String(header.join(" "));
}

function recursivelyBuildTitle(
  node: HtmlToReactNode,
  current: string[] = []
): string[] {
  // Building section by mutating array since that is more efficient that creating new arrays
  // this has impact on large documents, and we are just preprocessing and it has nothing to do with React at this point
  switch (node.type) {
    case "text":
      current.push(node.data);
      return current;
    case "tag":
      return node.children.reduce(
        (prev, c) => recursivelyBuildTitle(c, prev),
        current
      );
  }
}
