import React from "react";
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Tooltip
} from "@material-ui/core";
import { ClipboardIcon } from "../icons/ClipboardIcon";

export type ClipboardFieldProps = {
  fullWidth: boolean;
  value?: string;
  label: JSX.Element;
  labelCopyButton: JSX.Element;
  onValueCopied?: () => void;
};

export function ClipboardField({
  value,
  label,
  labelCopyButton,
  fullWidth,
  onValueCopied
}: ClipboardFieldProps) {
  const copyValueToClipboard = async () => {
    if (!value) return;
    await navigator.clipboard.writeText(value);
    onValueCopied?.();
  };

  return (
    <FormControl variant="filled" fullWidth={fullWidth}>
      <InputLabel htmlFor="clipboard-field">{label}</InputLabel>
      <FilledInput
        id="clipboard-field"
        value={value}
        disabled={true}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title={labelCopyButton}>
              <IconButton onClick={copyValueToClipboard} edge="end">
                <ClipboardIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
