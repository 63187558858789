import React from "react";
import { IconButton } from "../../../../../ui-components/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Collapse, makeStyles } from "@material-ui/core";
import { translationKeys } from "../../../../../../gen/translation-keys";
import { Translate } from "react-localize-redux";

export interface RelationChipsProps {
  onClose: () => void;
  expanded?: boolean;
}
const useStyles = makeStyles(theme => ({
  relationChips: {
    "& .MuiCollapse-wrapper .MuiCollapse-wrapperInner": {
      flexWrap: "wrap",
      rowGap: "2px"
    }
  }
}));

const RelationChips: React.FC<RelationChipsProps> = ({
  expanded,
  onClose,
  children
}) => {
  const classes = useStyles();

  return (
    <Collapse
      in={expanded}
      className={clsx("relation-chips", classes.relationChips)}
    >
      {children}
      <Translate>
        {({ translate }) => (
          <IconButton
            size={"small"}
            onClick={onClose}
            title={translate(translationKeys.relation_groups.close).toString()}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Translate>
    </Collapse>
  );
};

export default RelationChips;
