import React from "react";
import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from "@material-ui/core";
import { LinkTabSkeleton } from ".";

export type LinkTabsSkeletonProps = Pick<
  MuiTabsProps,
  "variant" | "indicatorColor"
> & {
  numberOfTabs?: number;
  activeTab?: number;
};

/**
 * LinkTabs with placeholders for indicating loading state
 **/
export const LinkTabsSkeleton: React.FC<LinkTabsSkeletonProps> = ({
  indicatorColor,
  numberOfTabs,
  activeTab,
  ...rest
}) => {
  const count = numberOfTabs ?? 1;
  return (
    <MuiTabs {...rest} value={false}>
      {[...Array(count)].map((_, i) => (
        <LinkTabSkeleton
          active={activeTab !== undefined && i === activeTab}
          indicatorColor={indicatorColor}
          key={i}
        />
      ))}
    </MuiTabs>
  );
};
