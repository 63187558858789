import { Container } from "@material-ui/core";
import * as React from "react";
import { Grid } from "../../../ui-components/layouts/Grid";
import EmailFormField from "./EmailFormField";
import { TypedForm } from "./TypedForm";
import * as labels from "./FormLabels";
import OverlayLoader from "../../../ui-components/LoadingProgress/OverlayLoader";
import NameFormField from "./NameFormField";
import { submitLabel, cancelLabel } from "./FormLabels";
import FormActions from "../../../ui-components/forms/controls/FormActions";

export type EditProfileProps = {
  email: string;
  displayName: string;
};

export type EditProfileKeys = keyof EditProfileProps;

export type EditProfileFormProps = EditProfileProps & {
  onSubmit: (formData: EditProfileProps) => void;
  onCancel: () => void;
  isSubmitting: boolean;
};

export const EditProfileForm: React.FC<EditProfileFormProps> = ({
  displayName,
  email,
  onSubmit,
  onCancel,
  isSubmitting
}) => {
  return (
    <Container>
      <OverlayLoader isLoading={isSubmitting}>
        <TypedForm defaultValues={{ displayName, email }} onSubmit={onSubmit}>
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <NameFormField
                  fieldName="displayName"
                  label={labels.nameLabel}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <EmailFormField fieldName="email" label={labels.emailLabel} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid
                item
                container
                xs={12}
                md={12}
                justify="flex-end"
                direction="row"
              >
                <FormActions
                  submitLabel={submitLabel}
                  onCancel={onCancel}
                  cancelLabel={cancelLabel}
                />
              </Grid>
            </Grid>
          </>
        </TypedForm>
      </OverlayLoader>
    </Container>
  );
};

export default EditProfileForm;
