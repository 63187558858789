import React from "react";
import { IconButton } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import ExpandDocumentIcon from "./ExpandDocumentIcon";
import { useToggleFullDocumentViewAction } from "./use-toggle-full-document-view-action";
import { useDocumentActionsSettings } from "./DocumentActionsSettingsProvider";

// The "expand document" functionality is only used to show large tables in the document,
// so the text content in the document not widening is on purpose :-)
const ToggleLargeDocumentViewAction: React.FC = props => {
  const isFullDocumentView =
    useDocumentActionsSettings()?.state.isFullDocumentView;
  const toggleFullDocumentViewAction = useToggleFullDocumentViewAction();
  const onClick = () => {
    toggleFullDocumentViewAction();
  };
  return (
    <Translate>
      {({ translate }) => (
        <IconButton
          {...props}
          size="small"
          title={translate(
            !isFullDocumentView
              ? translationKeys.document_page.show_large_document_view
              : translationKeys.document_page.hide_large_document_view
          ).toString()}
          onClick={onClick}
        >
          <ExpandDocumentIcon expand={!isFullDocumentView} />
        </IconButton>
      )}
    </Translate>
  );
};
export default ToggleLargeDocumentViewAction;
