import { useSearchConfiguration } from "../../../../../search/use-search-configuration";
import { relationGroupSearchUrl } from "../relation-group-search-utils";
import { RelationQueryType } from "./RelationQueryType";

export function useRelationSearchUrl(
  hiveId: string,
  fullName: string,
  relationQueryType: RelationQueryType,
  sectionId?: string,
  relationGroupName?: string
) {
  const searchConfiguration = useSearchConfiguration();

  const searchUrl = relationGroupSearchUrl(
    relationGroupName || null,
    hiveId,
    fullName,
    sectionId || "",
    relationQueryType,
    searchConfiguration?.defaultSearchView
  );
  return searchUrl;
}
