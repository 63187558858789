import * as React from "react";
import { useDocumentRelationChipsState } from "../../../content/shared/document-relation-groups/DocumentRelationChipsStateProvider";
import { scrollToTop } from "../../../../../browser-utils/scroll-helpers";
import { QueryStatus } from "react-query";
import { RelationsButton } from "../../../content/shared/document-relation-groups/RelationsButton";

interface RelationsActionButtonProps {
  hasRelations?: boolean;
  status: QueryStatus | QueryStatus[];
}

export const RelationsActionButton: React.FC<RelationsActionButtonProps> = ({
  hasRelations,
  status
}) => {
  const { expanded, setExpanded } = useDocumentRelationChipsState();
  const onClick = (value: boolean) => {
    if (value)
      // Scroll to top if we are toggle RelationChips on
      // the reason we do this is because relations for document chips are embedded inside document below title
      // and if user activates relations using the floating toolbar when having scrolled down in document,
      // then nothing appears to happen when they click (but Relations chips are toggled at the top)
      scrollToTop();

    setExpanded(value);
  };

  return (
    <RelationsButton
      expanded={expanded}
      setExpanded={onClick}
      status={status}
      hasRelations={hasRelations}
    />
  );
};
