import { Skeleton } from "@material-ui/lab";
import React from "react";

export const SmallTextSkeleton: React.FC = () => (
  <>
    <Skeleton variant="text" />
    <Skeleton variant="text" />
  </>
);

export const LargeTextSkeleton: React.FC = () => (
  <>
    <Skeleton variant="text" />
    <Skeleton width={300} />
    <Skeleton variant="text" />
    <Skeleton width={400} />
    <Skeleton width={900} />
    <Skeleton variant="text" />
  </>
);
