import * as React from "react";
import { IconButton } from "../../../ui-components/IconButton";
import Fade from "@material-ui/core/Fade";
import { Highlight, HighlightOutlined } from "@material-ui/icons";
import { useAnnotationClientContext } from "../../annotations/AnnotationClientStateProvider";
import { Feature, useUserHasFeature } from "../../../user/features";
import { useIsMobile } from "./../../../ui-components/media-queries/use-is-mobile";
import { useUserContext } from "../../../user/use-user-context";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { useIsTouchScreen } from "./../../../../browser-utils/use-is-touch-screen";

export const ToggleAnnotationsAction: React.FC = props => {
  const [isVisible, setIsVisible] = useAnnotationClientContext();

  return (
    <Translate>
      {({ translate }) => (
        <IconButton
          {...props}
          size="small"
          title={translate(
            isVisible
              ? translationKeys.annotations.hide_annotations
              : translationKeys.annotations.show_annotations
          ).toString()}
          onClick={() => setIsVisible(prev => !prev)}
          data-test-id="document-action-highlights-icon"
        >
          <Fade in={true}>
            {isVisible ? (
              <Highlight color={"primary"} />
            ) : (
              <HighlightOutlined />
            )}
          </Fade>
        </IconButton>
      )}
    </Translate>
  );
};

export function useShouldShowToggleAnnotationsAction() {
  const isMobile = useIsMobile();
  const isTouchScreen = useIsTouchScreen();
  const { isAuthenticated } = useUserContext();
  const hasAnnotationsFeature = useUserHasFeature(Feature.MarkUpAndAnnotations);

  return (
    hasAnnotationsFeature && isAuthenticated && !isMobile && !isTouchScreen
  );
}
