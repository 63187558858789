import * as React from "react";
import { Toc as TocDataModel, TocEntry } from "@rhinestone/portal-web-api";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { flattenTocData } from "../Toc/toc-helpers";
import { AutoComplete } from "../../ui-components/AutoComplete/AutoComplete";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { FilterOptionsState } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    padding: "0px 20px 0px 20px"
  },
  inputRoot: {
    borderRadius: 20
  }
});

interface SearchInTocProps {
  tocData: TocDataModel;
  onChange?: (value: TocEntry | null) => void;
}

export const filterOptions = (
  options: TocEntry[],
  { inputValue }: FilterOptionsState<TocEntry>
) => {
  const input = inputValue.toLowerCase().replace(/\s/g, "");
  return options.filter(
    (option: TocEntry) =>
      option.heading.toLowerCase().replace(/\s/g, "").indexOf(input) > -1
  );
};

export const SearchInToc: React.FC<SearchInTocProps> = ({
  tocData,
  onChange
}) => {
  const itemsFlatten = useMemo(
    () => flattenTocData(tocData.entries),
    [tocData]
  );

  return (
    <AutoComplete
      classes={useStyles()}
      options={itemsFlatten}
      getOptionLabel={option => option.heading}
      onChange={onChange}
      id="search-in-toc"
      label={
        <Translate
          id={translationKeys.document_page.search_in_table_of_contents}
        />
      }
      filterOptions={filterOptions}
    />
  );
};
