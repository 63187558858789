import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { ChatResponseSourceDto } from "@rhinestone/portal-web-api";

interface NewCitationsProps {
  citations: ChatResponseSourceDto[];
  isStreaming: boolean;
  usedCitationIndexes: number[];
}

const useStyles = makeStyles(theme => ({
  citations: {
    fontSize: "0.8em",
    display: "flex",
    flexDirection: "column"
  },
  sourcesHeader: {
    fontWeight: "bold"
  },
  sourcesCount: {
    fontWeight: "bold",
    color: theme.palette.grey[600]
  }
}));

const NewCitations: React.FC<NewCitationsProps> = ({
  citations,
  isStreaming,
  usedCitationIndexes
}) => {
  const classes = useStyles();

  const usedCitations = citations.filter(x =>
    usedCitationIndexes.includes(x.index)
  );

  if (usedCitations.length === 0 || isStreaming) return null;

  return (
    <div className={classes.citations}>
      <p>
        <span className={classes.sourcesHeader}>Kilder</span>
      </p>
      {usedCitations.map(x => (
        <a href={x.url} target="_blank" rel="noopener noreferrer" key={x.index}>
          <b>[{x.index}]</b> {x.documentTitle}
          {x.sliceTitle ? ` (${x.sliceTitle})` : null}
        </a>
      ))}
    </div>
  );
};

export default NewCitations;
