import * as React from "react";

type State = {
  isFullDocumentView: boolean;
};

type Action = { type: "toggleFullDocumentView" };
type Dispatch = (action: Action) => void;
type DocumentActionsSettingsProviderProps = {
  children: React.ReactNode;
};

const DocumentActionsSettingsContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "toggleFullDocumentView":
      return { ...state, isFullDocumentView: !state.isFullDocumentView };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DocumentActionsSettingsProvider({
  children
}: DocumentActionsSettingsProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, {
    isFullDocumentView: false
  });
  const value = { state, dispatch };
  return (
    <DocumentActionsSettingsContext.Provider value={value}>
      {children}
    </DocumentActionsSettingsContext.Provider>
  );
}

function useDocumentActionsSettings() {
  const context = React.useContext(DocumentActionsSettingsContext);

  return context;
}

export { DocumentActionsSettingsProvider, useDocumentActionsSettings };
