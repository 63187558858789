import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { MenuItem } from "../../../ui-components/ContextMenu/ContextMenu";
import {
  addHighlightMenuItem,
  AddHighlightMenuItemCommonProps
} from "./addHighlightMenuItem";

export function addUserHighlightMenuItem(
  props: AddHighlightMenuItemCommonProps
): MenuItem {
  const labelText = (
    <Translate id={translationKeys.annotations.add_user_highlight} />
  );

  return addHighlightMenuItem({ ...props, labelText });
}
