import * as React from "react";
import { Box, Button, ButtonGroup, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useState } from "react";

interface NavigateItemsPanelProps<T> {
  items: T[];
  onSelectedItemChanged: (item: T) => void;
  defaultItem?: T;
}

export const NavigateItemsPanel = <T,>({
  items,
  onSelectedItemChanged,
  defaultItem = items[0]
}: NavigateItemsPanelProps<T>) => {
  const [current, setCurrent] = useState(defaultItem);
  const change = (newItem: T) => {
    setCurrent(newItem);
    onSelectedItemChanged(newItem);
  };
  return (
    <Box display="flex" alignItems="center">
      <ButtonGroup
        size={"small"}
        color="primary"
        aria-label="navigate elements"
      >
        <Button
          aria-label="next"
          onClick={() =>
            change(
              items.indexOf(current) === 0
                ? items[items.length - 1]
                : items[items.indexOf(current) - 1]
            )
          }
        >
          <NavigateBeforeIcon />
        </Button>
        <Button
          aria-label="previous"
          onClick={() =>
            change(
              items.length < items.indexOf(current) + 2
                ? items[0]
                : items[items.indexOf(current) + 1]
            )
          }
        >
          <NavigateNextIcon />
        </Button>
      </ButtonGroup>
      <Box marginLeft={1}>
        <Typography>{`${items.indexOf(current) + 1}/${
          items.length
        }`}</Typography>
      </Box>
    </Box>
  );
};
