import { useLocation } from "react-router-dom";
import queryString from "query-string-for-all";

/**
 * Return a list of search query parameters.
 *
 * This function is to work the same as useParams from react-router-dom, but for queries in the search part of the URL.
 *
 * For an explanation why this functionality does not come out of the box with react-router-dom, please see
 * https://stackoverflow.com/questions/35352638/react-how-to-get-parameter-value-from-query-string/48256676#48256676
 *
 * ## Example
 * ```js
 * // At URL: "/h/123/456?showExact=true&page=3"
 * const { showExact, page } = useUrlQueryParameters() // returns { showExact: true, page: 3 }
 * ```
 */
export const useSearchParams = <T,>() =>
  queryString.parse(useLocation().search, {
    parseBooleans: true,
    parseNumbers: true
  }) as unknown as T;
