import React, { SetStateAction } from "react";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { ModalDialog } from "../../ui-components/ModalDialog";
import Box from "@material-ui/core/Box";
import { Annotation } from "./annotation-state";

export interface DeleteAnnotationCommentConfirmationProps<
  T extends AnnotationModel
> {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  annotation: Annotation<T>;
  removeAnnotationComment: (annotation: Annotation<T>) => void;
}

export const DeleteAnnotationCommentConfirmation = <T extends AnnotationModel>({
  annotation,
  removeAnnotationComment,
  open,
  setOpen
}: DeleteAnnotationCommentConfirmationProps<T>) => {
  return (
    <ModalDialog
      title={
        <Translate id={translationKeys.annotations.confirm_delete_title} />
      }
      open={open}
      onClose={() => setOpen(false)}
      confirm={{
        action: () => {
          removeAnnotationComment(annotation);
          setOpen(false);
        },
        caption: (
          <span data-test-id="annotation-comment-delete-confirm">
            <Translate id={translationKeys.document_page.delete} />
          </span>
        )
      }}
      cancel={{
        action: () => setOpen(false),
        caption: <Translate id={translationKeys.document_page.cancel} />
      }}
      fullWidth={false}
    >
      <Box margin={"20px"}>
        <Translate id={translationKeys.annotations.confirm_delete_message} />
      </Box>
    </ModalDialog>
  );
};
