import React from "react";
import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  makeStyles
} from "@material-ui/core";

import LaunchIcon from "@material-ui/icons/Launch";

export type LinkProps = Pick<
  MuiLinkProps,
  "color" | "title" | "href" | "className" | "target"
>;

const useStyles = makeStyles(theme => ({
  wrapIcon: {
    verticalAlign: "baseline",
    display: "inline-flex",
    whiteSpace: "pre-wrap"
  },
  icon: {
    height: "unset"
  }
}));

/**
 * Use this to render link "out" of portal
 */
export const ExternalLink: React.FC<LinkProps> = ({
  children,
  ...muiProps
}) => {
  const classes = useStyles();
  return (
    <MuiLink {...muiProps} className={classes.wrapIcon}>
      {children}
      <LaunchIcon fontSize="small" className={classes.icon} />
    </MuiLink>
  );
};
