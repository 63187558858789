/* eslint-disable complexity */
import {
  FlowQuoteModel,
  HtmlNewAnnotationDTO
} from "@rhinestone/portal-web-api";
import React from "react";
import { MenuItem } from "../../ui-components/ContextMenu/ContextMenu";
import { defaultMenuActions } from "../../ui-components/ContextMenu/defaultMenuActions";
import SelectedTextContextMenu from "../../ui-components/ContextMenu/SelectedTextContextMenu";
import { Annotation, AnnotationContext } from "../annotations/annotation-state";
import { AddToAssetCollectionContainer } from "../AssetCollection/AddToAssetCollectionContainer";
import { useAddToAssetCollectionMenuItem } from "./menu-items/addToAssetCollectionMenuItem";
import { HtmlAnnotationFactory } from "./html-annotation-factory";
import { addAssetCollectionHighlightMenuItem } from "./menu-items/addAssetCollectionHighlightMenuItem";
import { addUserHighlightMenuItem } from "./menu-items/addUserHighlightMenuItem";

interface RhinestoneDocumentContextMenuProps {
  userContext: AnnotationContext | undefined;
  assetCollectionContext: AnnotationContext | undefined;
  addAnnotation:
    | ((
        annotation: Annotation<HtmlNewAnnotationDTO>
      ) => Promise<unknown> | undefined)
    | undefined;
  isShown: boolean;
  documentRef: HTMLDivElement | null;
}

const RhinestoneDocumentContextMenu: React.FC<RhinestoneDocumentContextMenuProps> =
  ({
    userContext,
    assetCollectionContext,
    addAnnotation,
    isShown,
    documentRef,
    children
  }) => {
    const [flowQuote, setFlowQuote] = React.useState<FlowQuoteModel>();
    const annotationFactory = new HtmlAnnotationFactory(documentRef);
    const userHighlightMenuItem = addUserHighlightMenuItem({
      annotationFactory,
      context: userContext,
      addAnnotation,
      documentRef
    });

    const assetCollectionHighlightMenuItem =
      addAssetCollectionHighlightMenuItem({
        annotationFactory,
        context: assetCollectionContext,
        addAnnotation,
        documentRef
      });
    const addToAssetCollectionMenuItem = useAddToAssetCollectionMenuItem(
      annotationFactory,
      setFlowQuote
    );

    const menuItems: MenuItem[] = [
      ...defaultMenuActions(documentRef),
      userHighlightMenuItem,
      assetCollectionHighlightMenuItem,
      addToAssetCollectionMenuItem
    ];

    if (isShown) {
      return (
        <>
          <SelectedTextContextMenu menuItems={menuItems}>
            {children}
          </SelectedTextContextMenu>
          {flowQuote && (
            <AddToAssetCollectionContainer
              setOpen={() => setFlowQuote(undefined)}
              passage={flowQuote}
              isPdf={false}
            />
          )}
        </>
      );
    }
    return <>{children}</>;
  };

export default RhinestoneDocumentContextMenu;
