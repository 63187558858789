import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

export interface ModalDialogTitleTypographyProps {
  // speculative "feature" needed by paragraph zoom to have "bold" header title
  // maybe discuss with PO if we want this dialog variance
  emphasized?: boolean;
  title?: string;
}
type StyleProps = Pick<ModalDialogTitleTypographyProps, "emphasized">;

const useStyles = makeStyles({
  root: {
    fontWeight: ({ emphasized }: StyleProps) =>
      emphasized ? "bold" : undefined
  }
});

/**
 * Typography we use in ModalDialogs
 *
 * Creates an h tag around children.
 *
 * Please be careful to not pass children component tree with invalid child element to header tags
 * which includes only "phrasing tags":
 * https://www.w3.org/TR/2014/REC-html5-20141028/dom.html#phrasing-content
 *
 * as explained here:
 * https://stackoverflow.com/questions/19779519/is-it-valid-to-have-paragraph-elements-inside-of-a-heading-tag-in-html5-p-insid
 */
export const ModalDialogTitleTypography: React.FC<ModalDialogTitleTypographyProps> =
  ({ emphasized = false, title, children }) => {
    const style = useStyles({ emphasized });
    return (
      <Typography title={title} variant="h4" className={style.root} noWrap>
        {children}
      </Typography>
    );
  };
