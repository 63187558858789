import { DocumentPageConfigurationModel } from "@rhinestone/portal-web-api";
import { getConsolidationConfiguration, label } from ".";
import { RelationItemModel } from "./RelationItemModel";
import { Split } from "./utils";

export function mapFieldsToRelationItem(
  pageConfiguration: DocumentPageConfigurationModel | undefined,
  fieldSet: { [propertyName: string]: any },
  targetBookmarks: string[],
  split?: Split[]
): RelationItemModel {
  const {
    labelFieldsetField,
    hiveIdFieldsetFieldName,
    fullNameFieldsetFieldName,
    consolidationTitleFieldName
  } = getConsolidationConfiguration(pageConfiguration);
  const title =
    fieldSet?.[consolidationTitleFieldName] ??
    fieldSet?.title ??
    "[missing configured title]";
  return (
    {
      label: label(fieldSet, labelFieldsetField),
      hiveId: fieldSet[hiveIdFieldsetFieldName],
      fullName: fieldSet[fullNameFieldsetFieldName],
      title,
      targetBookmarks,
      split: split?.map(splitElement => ({
        label: label(splitElement.documentProperties, labelFieldsetField),
        hiveId: splitElement.documentProperties[hiveIdFieldsetFieldName],
        fullName: splitElement.documentProperties[fullNameFieldsetFieldName],
        title:
          splitElement.documentProperties.title ?? "[missing configured title]",
        hasChildren: splitElement.hasChildren
      }))
    } ?? {}
  );
}
