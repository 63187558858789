import { ParagraphInTime } from "@rhinestone/portal-web-api";
import { NotFound } from "../../api-access/http-status-codes";
import { usePortalApi } from "../../api-access/use-portal-api";

export function calculateInitialParagraphInTimeToShow(
  paragraphInTimes: ParagraphInTime[],
  startEffectiveDateContext: Date | undefined
) {
  // if no starting point is supplied return first (which would be "newest" paragraphInTime version)
  if (!startEffectiveDateContext) return paragraphInTimes[0];

  // we assume timeline is sorted correctly from server, so first in timeline array is the newest
  // we should the latest matching effective date or latest effective from starting point
  const target = paragraphInTimes.find(
    p =>
      p.effectiveDate &&
      // js date.getTime() returns a tick number relative to 1970-01-01 (previous dates are simply negative)
      // if we run into trouble because of various times on day, and we want to do time independent compare
      // simply use .setTime(0,0,0,0) on a date copy to normalize like this:
      // const pDate = new Date(p.effectiveDate)
      // pDate.setTime(0,0,0,0)
      // const startEffectiveDateContextDate = new Date(startEffectiveDateContext)
      // startEffectiveDateContextDate.setTime(0,0,0,0)
      // pDate.getTime() <= startEffectiveDateContextDate.getTime()
      p.effectiveDate.getTime() <= startEffectiveDateContext.getTime()
  );

  // if unexpectedly we couldn't find any appropiate paragrapInTime
  // we return fi (which would be "newest" paragraphInTime version)
  return target || paragraphInTimes[0];
}

export function getParagraphLawTitle(currentParagraphInTime: ParagraphInTime) {
  // convention is to pick nickname if available or else title
  const lawTitle =
    currentParagraphInTime.lawPopularTitleAtTheTime ??
    currentParagraphInTime.lawTitleAtTheTime;
  return `${lawTitle} ${currentParagraphInTime.title}`;
}

export function useParagraphZoomData(
  familyHiveId: string,
  familyName: string | undefined,
  paragraphId: string
) {
  return usePortalApi(
    client =>
      client.getParagraphZoom(
        // We have disabled api call if familyName or effective date is not set
        familyHiveId,
        familyName || "",
        paragraphId
      ),
    ["getParagraphZoom", familyHiveId, familyName, paragraphId],
    {
      enabled: familyName !== undefined,
      expectedErrorStatusCodes: [NotFound]
    }
  );
}

export function useDocumentSections(
  hiveId: string,
  fullName: string,
  sectionIds: string[]
) {
  return usePortalApi(
    client => client.getDocumentSections(hiveId, fullName, sectionIds),
    ["getDocumentSections", hiveId, fullName, sectionIds]
  );
}
