import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const ChartIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          fill-rule="nonzero"
          d="M11 7h2v10h-2V7zm4 4h2v6h-2v-6zm-8 2h2v4H7v-4zm8-9H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z"
        />
      </g>
    </SvgIcon>
  );
};

export default ChartIcon;
