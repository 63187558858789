import { DocumentRelationNodeDto } from "@rhinestone/portal-web-api";
import { RelationItemModel } from "./RelationItemModel";

export function getDefaultPath(
  paths: RelationItemModel[][],
  hiveId: string,
  fullName: string
) {
  return (
    paths.find(path =>
      path.some(doc => doc.fullName === fullName && doc.hiveId === hiveId)
    ) ?? (paths?.length > 0 ? paths[0] : [])
  );
}

export function getPaths(tree?: DocumentRelationNodeDto): PathItem[][] {
  if (!tree) return [];
  const iter = (node: DocumentRelationNodeDto, path: PathItem[]) => {
    const newPath: PathItem[] = [
      ...path,
      {
        fieldSet: node.documentProperties,
        split:
          node.children?.length > 0
            ? node.children.map(x => ({
                documentProperties: x.documentProperties,
                hasChildren: x.children.length > 0
              }))
            : undefined
      }
    ];

    if (node.children?.length > 0) {
      node.children.forEach(child => iter(child, newPath));
      return;
    }
    paths.push(newPath);
  };

  const paths: PathItem[][] = [];
  iter(tree, []);
  return paths;
}

export type Split = {
  documentProperties: {
    [propertyName: string]: any;
  };
  hasChildren: boolean;
};

export interface PathItem {
  fieldSet: FieldSet;
  split?: Split[];
}

export type FieldSet = { [propertyName: string]: unknown };
