/* eslint-disable complexity */
import React from "react";
import { translationKeys } from "../../../../../../gen/translation-keys";
import { LoadingProgress } from "../../../../../ui-components/LoadingProgress";
import { WarningText } from "../../../../../ui-components/WarningText";

// TODO, could be ImgHTMLAttributes<HTMLImageElement>?
export type imgAttributes = {
  [key: string]: string | { [key: string]: string };
};

export interface AnonymousImageProps {
  additionalAttributes?: imgAttributes | undefined;
  data: Blob | undefined;
  isLoading: boolean;
}
const AnonymousImage: React.FC<AnonymousImageProps> = ({
  additionalAttributes,
  data,
  isLoading
}) => {
  if (isLoading) {
    <LoadingProgress />;
  }
  if (data) {
    const base64EncodedImage3 = URL.createObjectURL(data);
    const src = `${base64EncodedImage3}`;

    return <img {...additionalAttributes} src={src} />;
  }
  return (
    <WarningText
      label=""
      warningTranslationKey={translationKeys.document_page.image_not_found}
    />
  );
};

export default AnonymousImage;
