import React from "react";
import { useState } from "react";
import { Accordion } from "../../../../ui-components/Accordion";
import { HtmlRenderer } from "../../../../ui-components/HtmlRenderer/HtmlRenderer";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { processLinks } from "../../rhinestone-xml/processing-instructions/process-links";

interface MetadataRenderProps {
  label: React.ReactNode;
  text: string;
  noBackground: boolean;
}

const useStyles = makeStyles(theme => ({
  accordion: ({ noBackground }: Pick<MetadataRenderProps, "noBackground">) => ({
    backgroundColor: noBackground ? "white" : theme.alternativeBackgroundColor
  }),
  metadataRender: {
    marginBottom: "15px"
  },
  header: {
    marginTop: 0,
    marginBottom: 0
  }
}));

const MetadataRender: React.FC<MetadataRenderProps> = ({
  label,
  text,
  noBackground
}) => {
  const [expanded, setIsExpanded] = useState(true);
  const classes = useStyles({ noBackground });

  return (
    <div className={classes.metadataRender}>
      <Accordion
        expanded={expanded}
        onChange={() => setIsExpanded(!expanded)}
        label={renderHeaderLabel(label, classes.header)}
        className={classes.accordion}
        expandIcon={<ExpandMoreIcon />}
      >
        <HtmlRenderer html={text} processingInstructions={[processLinks()]} />
      </Accordion>
    </div>
  );
};

function renderHeaderLabel(label: React.ReactNode, className: string) {
  return <h2 className={className}>{label}</h2>;
}

export default MetadataRender;
