import { useEffect, useState } from "react";
import { RadioButtonGroup } from "../../../ui-components/RadioButton";

import React from "react";
import { RelationItemModel } from "../../preparatory-work/RelationItemModel";
import { Button, ButtonGroup, makeStyles } from "@material-ui/core";

interface ChangeLawSelectorProps {
  onElementSelected: (paragraphChangeRelation: RelationItemModel) => void;
  paragraphChangeRelations: RelationItemModel[];
  isFetching: boolean;
  hiveId?: string;
  fullName?: string;
  paragraphId?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "default",
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export const ChangeLawSelector: React.FC<ChangeLawSelectorProps> = ({
  onElementSelected,
  hiveId,
  fullName,
  paragraphId,
  paragraphChangeRelations,
  isFetching
}) => {
  const classes = useStyles();

  const [selectedChangeRelationIndex, setSelectedChangeRelationIndex] =
    useState<number>(0);

  const setSelected = (index: number) => {
    setSelectedChangeRelationIndex(index);
    onElementSelected(paragraphChangeRelations[index]);
  };

  useEffect(() => {
    setSelected(0);
    // When we finish fetching for the first time we 'select' the first element
    // in the list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiveId, fullName, paragraphId, isFetching]);

  const renderSingleButton = () => (
    <Button
      variant={"contained"}
      color={"primary"}
      className={classes.root}
      disableFocusRipple
      disableTouchRipple
      disableElevation
      disableRipple
    >
      {paragraphChangeRelations[0].title}
    </Button>
  );

  const renderButtonGroup = () => (
    <ButtonGroup>
      {paragraphChangeRelations.map((x, index) => {
        const isActive = selectedChangeRelationIndex === index;
        return (
          <Button
            variant={isActive ? "contained" : "outlined"}
            color={isActive ? "primary" : "default"}
            onClick={() => setSelected(index)}
          >
            {x.title}
          </Button>
        );
      })}
    </ButtonGroup>
  );

  return paragraphChangeRelations.length === 1
    ? renderSingleButton()
    : renderButtonGroup();
};
