import * as React from "react";
import MaterialUiTimelineSeparator from "@material-ui/lab/TimelineSeparator";

import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
const useStyles = makeStyles(theme => ({
  separator: {
    minHeight: "inherit",
    minWidth: 20
  }
}));

interface TimelineSeparatorProps {
  className?: string;
}
export const TimelineSeparator: React.FC<TimelineSeparatorProps> = ({
  className,
  children
}) => {
  const classes = useStyles();
  return (
    <MaterialUiTimelineSeparator className={clsx(classes.separator, className)}>
      {children}
    </MaterialUiTimelineSeparator>
  );
};
