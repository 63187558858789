import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import print from "print-js";

export const defaultMenuActions = (documentRef: HTMLDivElement | null) => [
  {
    onClick: () => document.execCommand("copy"),
    label: <Translate id={translationKeys.document_page.copy} />
  },
  {
    onClick: () => {
      const selObj = document.getSelection();
      if (selObj?.getRangeAt(0)) {
        if (documentRef) {
          const range = selObj.getRangeAt(0);
          const clonedSelection = range.cloneContents();

          const div = documentRef?.cloneNode();
          div?.appendChild(clonedSelection);
          const documentRange = document.cloneNode(true);
          (documentRange as Element).getElementsByTagName("body")[0].innerHTML =
            (div as Element)?.innerHTML;
          const printElement = new XMLSerializer().serializeToString(
            documentRange
          );
          print({
            printable: printElement.toString(),
            type: "raw-html",
            honorColor: true,
            targetStyles: ["*"]
          });
        }
      } else {
        window.print();
      }
    },
    label: <Translate id={translationKeys.document_page.print} />
  }
];
