import * as React from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import { TreeItemData, TreeViewItemLabel } from ".";
import { uniqueId } from "lodash";
import { useLazyLoad } from "../LazyLoad/useLazyLoad";

interface TreeViewItemProps {
  data: TreeItemData;
}

/**
 * This component disables the built in MUI treeview unmounting of children when collapsing
 * since this was very slow and frooze ui, and resulted in very large memory consumption
 * Instead, we let children be mounted and instead use intersection observer lazy load component
 * to only render expensive children when they are in viewport
 * It does come with an slightly slower experience when expanding an item with thousands of children
 * but should reduce risk of out of memory page crashes
 */
export const LazyLoadedTreeViewItem: React.FC<TreeViewItemProps> = ({
  data
}) => {
  const { label, value: nodeId, title, children: items } = data;

  const generatedId = uniqueId("tree-item-");

  // We use lazy load to only mount items in viewport. This keeps memory footprint law
  // We set keepMounted to false, otherwise the treeview becomes more and more slow as expanding and collapsing items
  const { inView, ref } = useLazyLoad({ keepMounted: false });

  const enableAnimation = items.length < 50;
  return (
    <div className={"tree-view-item-loader"} ref={ref}>
      {inView && (
        <TreeItem
          // For items with many thousand children it appears to better performance from a memory perspective
          // to not constantly unmount and remount the children when expanding and collapsing the tree item
          // which is the default MUI behavior. unmountOnExit: false disables this behavior and keeps children mounted
          // 'mountOnEnter: true' ensures that we still lazy mount child components when mounting the tree item and not mount entire tree up front
          // Instead we only mount the items in view using the intersection observer based 'useLazyLoad'.
          // This keeps memory footprint low
          TransitionProps={{
            mountOnEnter: true,
            unmountOnExit: false,
            // Disable animation to improve large relation group performance
            timeout: enableAnimation ? undefined : 0
          }}
          nodeId={generatedId}
          label={<TreeViewItemLabel label={label} />}
          title={title}
          data-target={nodeId}
        >
          {items.map((i, index) => (
            // Using index until we find a way to have a business related id for each tree item
            <LazyLoadedTreeViewItem
              data={i}
              key={index}
            ></LazyLoadedTreeViewItem>
          ))}
        </TreeItem>
      )}
    </div>
  );
};
