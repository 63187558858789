import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Hidden, Paper } from "@material-ui/core";

export const headerExtensionHeight = 47;
const useStyles = makeStyles(theme => ({
  container: {
    position: "fixed",
    width: "100%",
    overflow: "hidden",
    paddingBottom: 8,
    backgroundColor: theme.alternativeBackgroundColor
  },
  content: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 0,
    width: "100%",
    height: headerExtensionHeight + "px"
  }
}));

const HeaderExtension: React.FC = () => {
  const classes = useStyles();
  return (
    <Hidden xsDown>
      <div className={classes.container}>
        <Paper elevation={3} className={classes.content} />
      </div>
    </Hidden>
  );
};

export default HeaderExtension;
