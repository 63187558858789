import MuiListItemText, {
  ListItemTextProps
} from "@material-ui/core/ListItemText";
import * as React from "react";

export const ListItemText: React.FC<
  Pick<
    ListItemTextProps,
    "classes" | "primary" | "secondary" | "title" | "disableTypography"
  >
> = ({ children, ...rest }) => (
  <MuiListItemText {...rest}>{children}</MuiListItemText>
);
