import * as React from "react";
import MaterialUiTimelineConnector from "@material-ui/lab/TimelineConnector";

interface TimelineConnectorProps {
  className?: string;
}
export const TimelineConnector: React.FC<TimelineConnectorProps> = props => {
  return (
    <MaterialUiTimelineConnector className={props.className}>
      {props.children}
    </MaterialUiTimelineConnector>
  );
};
