import { Box } from "@material-ui/core";
import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

export const lgUpColumnCount = 4;
export const mdColumnCount = 3;
export const smColumnCount = 2;
export const xsDownColumnCount = 1;

export const gridGap = 30;
export const rowHeight = 235;

const useStyles = makeStyles(theme => ({
  grid: {
    paddingTop: 115,
    display: "grid",
    gridGap: gridGap,
    gridTemplateColumns: "80vw",
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(" + lgUpColumnCount + ", 380px)"
    },
    [theme.breakpoints.only("md")]: {
      gridTemplateColumns: "repeat(" + mdColumnCount + ", 380px)"
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(" + smColumnCount + ", 380px)"
    },
    gridAutoRows: rowHeight
  }
}));

const TileGrid: React.FC = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.grid}>{children}</Box>;
};

export default TileGrid;
