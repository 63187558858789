import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import Tile from "./Tile";

const NoTaxonomyErrorTile: React.FC = () => {
  const title = (
    <Translate id={translationKeys.taxon_page.no_taxonomy_error_tile_header} />
  );
  return (
    <Tile title={title} size={"small"}>
      <Translate
        id={translationKeys.taxon_page.no_taxonomy_error_tile_message}
      />
    </Tile>
  );
};

export default NoTaxonomyErrorTile;
