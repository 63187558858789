import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import * as React from "react";
import {
  LocalizeContextProps,
  Translate,
  withLocalize
} from "react-localize-redux";
import {
  DateRangePicker,
  DateRangeValue
} from "../../ui-components/DatePicker";
import { translationKeys } from "../../../gen/translation-keys";
import { SearchFilterPeriodModel } from "@rhinestone/portal-web-api";

interface PeriodSearchFilterProps {
  filter: SearchFilterPeriodModel;
  providerKey: string;
  onProviderKeyChange: (providerKey: string) => void;
  selectedDateRange: DateRangeValue;
  onSelectedDateRangeChange: (dateRangeValue: DateRangeValue) => void;
}

const PeriodSearchFilter: React.FunctionComponent<
  PeriodSearchFilterProps & LocalizeContextProps
> = ({
  translate,
  activeLanguage,
  filter,
  providerKey,
  onProviderKeyChange,
  selectedDateRange,
  onSelectedDateRangeChange
}) => {
  return (
    <>
      <FormControl>
        <InputLabel htmlFor={providerKey}>
          <Translate id={translationKeys.search.date} />
        </InputLabel>
        <Select
          inputProps={{
            id: providerKey
          }}
          value={providerKey}
          onChange={e => onProviderKeyChange(e.target.value as string)}
          autoWidth={true}
        >
          {(filter.options || []).map(option => (
            <MenuItem key={option.flag} value={option.flag}>
              <Translate id={option.titleResource} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <hr />

      <DateRangePicker
        dateFromLabel={translate(translationKeys.date_picker.from).toString()}
        dateToLabel={translate(translationKeys.date_picker.to).toString()}
        dateRangeValue={selectedDateRange}
        onChange={onSelectedDateRangeChange}
        localeCode={activeLanguage ? activeLanguage.code : ""}
      />
    </>
  );
};

export default withLocalize(PeriodSearchFilter);
