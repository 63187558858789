import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import * as React from "react";
import { useCallback } from "react";
import { Translate } from "react-localize-redux";
import {
  getDateFormat,
  getLocale
} from "../../localization/supported-date-locales";
import { FormFieldProps } from "../forms/controls/FormFieldProps";

export interface DatePickerStylingProps {
  label?: string | undefined;
  value?: DatePickerDate | undefined;
  id: string;
  onChange: (date: DatePickerDate) => void;
  labelFunc?: (date: MaterialUiPickersDate, invalidLabel: string) => string;
  localeCode: string;
  minDate?: DatePickerDate;
  maxDate?: DatePickerDate;
  variant?: Variant;
}

type DatePickerProps = DatePickerStylingProps & FormFieldProps;

export declare type DatePickerDate = Date | null;
export declare type Variant = "dialog" | "inline" | "static";
export const MIN_DATE: DatePickerDate = new Date("1900-01-01");
export const MAX_DATE: DatePickerDate = new Date("2100-01-01");

export const DatePicker: React.FunctionComponent<DatePickerProps> = ({
  label,
  value,
  id,
  onChange,
  labelFunc,
  localeCode,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  variant = "inline",
  helperText,
  error,
  innerRef,
  name,
  ...rest
}) => {
  const handleDateChange = useCallback(
    (date: DatePickerDate) => {
      onChange(date);
    },
    [onChange]
  );

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={getLocale(localeCode)}
    >
      <KeyboardDatePicker
        id={id}
        variant={variant}
        margin="normal"
        format={getDateFormat(localeCode)}
        invalidDateMessage={<Translate id="date_picker.invalid_date" />}
        maxDateMessage={<Translate id="date_picker.invalid_max_date" />}
        minDateMessage={<Translate id="date_picker.invalid_min_date" />}
        label={label}
        autoOk={true}
        value={value}
        minDate={minDate || MIN_DATE}
        maxDate={maxDate || MAX_DATE}
        labelFunc={labelFunc}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        error={error}
        helperText={helperText}
        name={name}
        inputRef={innerRef}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};
