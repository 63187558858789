import { noop } from "lodash";
import React, { useContext, useState } from "react";

interface FindInDocumentState {
  findBarVisible: boolean;
}

type FindInDocumentContextProps = [
  FindInDocumentState,
  React.Dispatch<React.SetStateAction<FindInDocumentState>>
];

export function useFindInDocumentContext() {
  return useContext(FindInDocumentContext);
}

export const FindInDocumentContext = (() => {
  const newContext = React.createContext<FindInDocumentContextProps>([
    { findBarVisible: false },
    noop
  ]);
  newContext.displayName = "FindInDocumentContext";
  return newContext;
})();

interface FindInDocumentStateProviderProps {
  initialState?: FindInDocumentState;
}

export const FindInDocumentStateProvider: React.FC<FindInDocumentStateProviderProps> =
  ({ children }) => {
    const [findBarVisible, setFindBarVisible] = useState<FindInDocumentState>({
      findBarVisible: false
    });

    return (
      <FindInDocumentContext.Provider
        value={[findBarVisible, setFindBarVisible]}
      >
        {children}
      </FindInDocumentContext.Provider>
    );
  };
