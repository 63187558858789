import { usePortalApi } from "../../../../api-access/use-portal-api";
import { DocumentRelationsLocationsModel } from "@rhinestone/portal-web-api";

/**
 * hook for getting a list of sections that have any relations
 */
export function useSectionsWithRelationsForDocument(
  hiveId: string,
  fullName: string
) {
  const { data, status } = usePortalApi(
    client => client.getLocationsWithRelations(hiveId, fullName),
    ["getLocationsWithRelations", hiveId, fullName]
  );
  return { data, status };
}

export function useSectionHasRelations(
  sectionId: string,
  hiveId: string,
  fullName: string
): boolean {
  const { data: documentRelationInfo } = useSectionsWithRelationsForDocument(
    hiveId,
    fullName
  );

  return hasBookmarksForSection(documentRelationInfo, sectionId);
}

function hasBookmarksForSection(
  documentRelationInfo: DocumentRelationsLocationsModel | undefined,
  sectionId: string
): boolean {
  // this is called many thousands time for big documents and indexOf is performing better that includes in chrome
  return Boolean(
    documentRelationInfo &&
      documentRelationInfo?.bookmarkIds?.indexOf(sectionId) > -1
  );
}

/**
 * hook for getting relation groups for all relations (with or without bookmark)
 */
export function useDocumentRelationGroupsForAll(
  enabled: boolean,
  hiveId: string,
  fullName: string
) {
  const { data, status } = usePortalApi(
    client => client.getDocumentRelationGroupsForAll(hiveId, fullName),
    ["getDocumentRelationGroupsForAll", hiveId, fullName],
    { enabled }
  );

  return {
    data,
    status
  };
}

/**
 * hook for getting relation groups for section (bookmark)
 */
export function useDocumentRelationGroupsForBookmark(
  enabled: boolean,
  hiveId: string,
  fullName: string,
  sectionId: string
) {
  const { data, status } = usePortalApi(
    client =>
      client.getDocumentRelationGroupsForBookmark(hiveId, fullName, sectionId),
    ["getDocumentRelationGroupsForBookmark", hiveId, fullName, sectionId],
    { enabled }
  );

  return {
    data,
    status
  };
}
/**
 * hook for getting relation groups for top of document (relations without bookmark)
 */
export function useDocumentRelationGroupsForTopOfDocument(
  enabled: boolean,
  hiveId: string,
  fullName: string
) {
  const { data, status } = usePortalApi(
    client =>
      client.getDocumentRelationGroupsForTopOfDocument(hiveId, fullName),
    ["getDocumentRelationGroupsForTopOfDocument", hiveId, fullName],
    { enabled }
  );

  return {
    data,
    status
  };
}
