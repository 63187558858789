import * as React from "react";
import { makeStyles, Fab, SwipeableDrawer } from "@material-ui/core";
import { useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { isIos } from "../../../browser-utils/user-agent";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

type DrawerStyleProps = Pick<DrawerProps, "anchor">;

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    // Set drawer width on tablet devices
    [theme.breakpoints.down("xl")]: {
      width: "40vw"
    },
    // Set drawer width on small mobile devices
    [theme.breakpoints.down("xs")]: {
      width: "90vw"
    }
  },
  mobileMenuButton: {
    position: "fixed",
    zIndex: 5,
    left: ({ anchor }: DrawerStyleProps) =>
      anchor === "left" ? 10 : undefined,
    right: ({ anchor }: DrawerStyleProps) =>
      anchor === "right" ? 10 : undefined,
    bottom: 10
  }
}));

interface DrawerProps {
  anchor: "left" | "right";
}

export const MobileDrawer: React.FC<DrawerProps> = ({ children, anchor }) => {
  // see: https://material-ui.com/components/drawers/#swipeable for explanation for iOS check (its performance)
  const isIosDevice = isIos();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const classes = useStyles({ anchor });
  return (
    <>
      <Translate>
        {({ translate }) => (
          <Fab
            color="primary"
            onClick={handleDrawerToggle}
            className={classes.mobileMenuButton}
            title={translate(
              translationKeys.document_page.about_document
            ).toString()}
            aria-label={translate(
              translationKeys.document_page.about_document
            ).toString()}
          >
            <MoreHorizIcon />
          </Fab>
        )}
      </Translate>
      <SwipeableDrawer
        variant="temporary"
        anchor={anchor}
        open={mobileOpen}
        onOpen={() => setMobileOpen(true)}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        disableBackdropTransition={!isIosDevice}
        disableDiscovery={isIosDevice}
      >
        <div className={classes.contentWrapper}>{children}</div>
      </SwipeableDrawer>
    </>
  );
};
