import React, { ReactNode } from "react";
import PageTitleContainer from "../../ui-components/layouts/PageTitleTile";
import Breadcrumb from "./TaxonBreadcrumb";

interface TaxonPageTitleProps {
  title: string | ReactNode;
  breadcrumbElements: { title: ReactNode; link: string }[];
}
const TaxonPageTitle: React.FC<TaxonPageTitleProps> = ({
  title,
  breadcrumbElements
}) => {
  const subHeader = <Breadcrumb elements={breadcrumbElements} />;
  return <PageTitleContainer titleText={title} subHeader={subHeader} />;
};

export default TaxonPageTitle;
