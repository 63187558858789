import React from "react";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { Button } from "../../ui-components/Button";
import { OrphanedAnnotationString } from "./OrphanedAnnotationString";
import { Alert } from "../../ui-components/Alert";
import { Annotation } from "./annotation-state";
import { Box, makeStyles } from "@material-ui/core";
import { DeleteAnnotationConfirmation } from "./DeleteAnnotationConfirmation";

export interface OrphanedAnnotationProps<T extends AnnotationModel> {
  orphanedAnnotation: Annotation<T>;
  highlightClassName?: string;
  removeAnnotation: (annotation: Annotation<T>) => Promise<T> | undefined;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    }
  },
  label: {
    color: theme.palette.error.contrastText
  }
}));

export const OrphanedAnnotation = <T extends AnnotationModel>({
  orphanedAnnotation,
  highlightClassName,
  removeAnnotation
}: OrphanedAnnotationProps<T>) => {
  const classes = useStyles();
  const orphan = orphanedAnnotation.annotation;
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  return orphan.isOrphan ? (
    <>
      <Box marginBottom={"20px"}>
        <Alert severity={"warning"} icon={false}>
          <Translate>
            {({ translate }) => (
              <dl>
                <dt>{translate(translationKeys.document_page.comment)}</dt>
                <dd>{orphan.comment}</dd>
                <dt>
                  {translate(
                    translationKeys.document_page.original_comment_location
                  )}
                </dt>
                <dd>
                  <OrphanedAnnotationString
                    orphan={orphan}
                    highlightClassName={highlightClassName}
                    translate={translate}
                  />
                </dd>
                <dt>
                  {translate(translationKeys.document_page.last_edited_by)}
                </dt>
                <dd>{orphan.userName}</dd>
                <dt>{translate(translationKeys.document_page.date)}</dt>
                <dd>{orphan.createdAtDisplayString}</dd>
              </dl>
            )}
          </Translate>

          <Button
            classes={{ root: classes.root, label: classes.label }}
            variant={"contained"}
            onClick={() => setConfirmationOpen(true)}
          >
            <Translate id={translationKeys.document_page.delete} />
          </Button>
        </Alert>
      </Box>
      <DeleteAnnotationConfirmation
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        annotation={orphanedAnnotation}
        removeAnnotation={removeAnnotation}
      />
    </>
  ) : null;
};
