import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Link,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  listItem: {
    width: 390,
    paddingTop: 3,
    paddingBottom: 3
  },
  icon: {
    width: 30,
    minWidth: 30
  },
  text: {
    "&>*": {
      textOverflow: "ellipsis",
      maxWidth: 260,
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  }
}));

interface ListItemProps {
  title: string;
  link: string;
  icon: JSX.Element;
}
const ListItem: React.FC<ListItemProps> = ({ title, link, icon }) => {
  const classes = useStyles();
  return (
    <MuiListItem
      title={title}
      key={title}
      className={classes.listItem}
      component={Link}
      href={link}
      color="inherit"
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText className={classes.text} primary={title} />
    </MuiListItem>
  );
};

export default ListItem;
