import { QueryStatus } from "react-query";
import { usePortalProperty } from "../../../use-portal-property";
import { DocumentTaxonDto } from "@rhinestone/portal-web-api";

// We need to be able to show the relations action (buttons), regardless of wether the document/top-of-document/section has relations.
// This should only apply to specific document types though.
// These document type taxon keys, are configured in  portal property "RelationHeavyTaxonKeys".
export const useShouldAlwaysShowRelationsAction = (
  documentTaxons: DocumentTaxonDto[] | undefined
): { shouldAlwaysShowRelationsAction: boolean; status: QueryStatus } => {
  const { value: relationHeavyTaxonKeysProp, status } =
    usePortalProperty<string>("RelationHeavyTaxonKeys");

  const relationHeavyTaxonKeys = tryParsePropertyToArray(
    relationHeavyTaxonKeysProp
  );

  const shouldAlwaysShowRelationsAction =
    documentTaxons
      ?.map(x => x.taxonKey)
      ?.some(x => relationHeavyTaxonKeys?.includes(x)) ?? false;

  return {
    shouldAlwaysShowRelationsAction: shouldAlwaysShowRelationsAction,
    status
  };
};

const tryParsePropertyToArray = (value: string | undefined) => {
  if (!value) return [];

  try {
    return JSON.parse(value?.replaceAll("'", '"') || "[]");
  } catch (e) {
    console.error(`Error parsing portal property value: ${value}`, e);
    return [];
  }
};
