import React from "react";
import { LoadingProgress } from "../../../../../ui-components/LoadingProgress";
import { useDocumentInfo } from "../../../../document-context";
import { useDocumentRelationChipsState } from "../DocumentRelationChipsStateProvider";
import { useDocumentRelationGroupsForAll } from "../use-document-relation-groups";
import { RelationWrapper } from "./RelationWrapper";

export const ArticleAllRelationChips: React.FC = () => {
  const { expanded, setExpanded } = useDocumentRelationChipsState();
  const { hiveId, fullName } = useDocumentInfo();

  const { data, status } = useDocumentRelationGroupsForAll(
    expanded,
    hiveId,
    fullName
  );

  if (status === "loading") return <LoadingProgress />;
  return status === "success" && expanded ? (
    <RelationWrapper
      expanded={expanded}
      relationData={data}
      setExpanded={setExpanded}
      sectionId=""
      showAll
    />
  ) : null;
};
