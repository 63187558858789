import * as React from "react";
import { green, grey, blue } from "@material-ui/core/colors";
import { common } from "@material-ui/core/colors";
import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  makeStyles
} from "@material-ui/core";

type color = "default" | "green" | "blue";

const colorValues: {
  [key in color]: string;
} = {
  default: grey[500],
  green: green[500],
  blue: blue[500]
};

export type ChipProps = Pick<MuiChipProps, "label" | "variant" | "size"> & {
  color: color;
};

const useStyles = makeStyles(() => {
  return {
    chip: {
      borderColor: ({ color }: ChipProps) => colorValues[color],
      color: ({ variant, color }: ChipProps) =>
        variant === "outlined" ? colorValues[color] : common.white,
      background: ({ variant, color }: ChipProps) =>
        variant === "outlined" ? common.white : colorValues[color]
    }
  };
});

export const Chip: React.FC<ChipProps> = ({
  label,
  size = "medium",
  variant = "outlined",
  color = "default"
}) => {
  const classes = useStyles({ color, variant });
  return (
    <MuiChip
      label={label}
      size={size}
      variant={variant}
      className={classes.chip}
    />
  );
};
