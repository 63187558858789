import { useState, useEffect } from "react";
import { QueryStatus } from "react-query";

export const useGetBlobText = (
  status: QueryStatus,
  rawData: { blobBody?: Promise<Blob> | undefined } | undefined
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (status === "loading") {
      setIsLoading(true);
    }
  }, [status]);

  useEffect(() => {
    let isMounted = true;

    if (status === "success" && rawData) {
      const fetchBlobData = async () => await rawData.blobBody;
      const blobContent = fetchBlobData();

      blobContent
        .then(blob => {
          blob?.text().then((text: string) => {
            if (isMounted) {
              setData(text);
            }
          });
        })
        .finally(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    }

    return () => {
      setIsLoading(false);
      isMounted = false;
    };
  }, [status, rawData]);

  return { isLoading, data };
};
