import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";

export const fromNameLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_from_name}
  />
);
export const fromEmailLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_from_email}
  />
);
export const fromHeader = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_from_title}
  />
);
export const toHeader = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_to_title}
  />
);
export const toNameLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_to_name}
  />
);
export const toEmailLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_to_email}
  />
);
export const messageLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_message}
  />
);
export const linkLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_link}
  />
);
export const linkCopyLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_link_copy}
  />
);
export const linkCopySuccess = (
  <Translate
    id={
      translationKeys.document_page.send_document_link_dialog_link_copy_success
    }
  />
);
export const cancelLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_cancel}
  />
);
export const generateTicketLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_generate_ticket}
  />
);
export const submitLabel = (
  <Translate
    id={translationKeys.document_page.send_document_link_dialog_send}
  />
);
