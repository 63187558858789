import MaterialUiButton from "@material-ui/core/Button";
import { ButtonProps as MaterialUiButtonProps } from "@material-ui/core/Button";
import * as React from "react";

type CustomButtonProps = {
  children?: React.ReactNode;
  target?: string;
};

export type ButtonProps = Pick<
  MaterialUiButtonProps,
  | "variant"
  | "color"
  | "href"
  | "onClick"
  | "disabled"
  | "classes"
  | "size"
  | "type"
  | "style"
> &
  CustomButtonProps;

export const Button = (props: ButtonProps) => <MaterialUiButton {...props} />;
