import * as React from "react";
import { DelayedSectionRelationChips } from "../../shared/document-relation-groups/RelationChips";
import { useLazyLoad } from "../../../../ui-components/LazyLoad/useLazyLoad";

interface Props {
  className: string;
  sectionId: string | undefined | null;
}

export const SectionHeader: React.FC<Props> = ({
  className,
  sectionId,
  children
}) => {
  const { ref, inView } = useLazyLoad();

  return (
    <>
      <h2 className={className} ref={ref}>
        {children}
      </h2>
      {sectionId && inView && (
        <DelayedSectionRelationChips sectionId={sectionId} />
      )}
    </>
  );
};
