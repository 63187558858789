import { FlowQuoteModel } from "@rhinestone/portal-web-api";
import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { MenuItem } from "../../../ui-components/ContextMenu/ContextMenu";
import { useHideAddToAssetCollectionAction } from "../../DocumentActionMenu/document-actions/use-hide-add-to-asset-collection";
import { HtmlAnnotationFactory } from "../html-annotation-factory";
import {
  handleRange,
  selectionStartsAtExcludedElement
} from "./html-highlight-helpers";

export function useAddToAssetCollectionMenuItem(
  annotationFactory: HtmlAnnotationFactory,
  setFlowQuote: React.Dispatch<React.SetStateAction<FlowQuoteModel | undefined>>
): MenuItem {
  const hideAddToAssetCollection = useHideAddToAssetCollectionAction();
  return {
    onClick: () =>
      handleRange(range => {
        const htmlAnnotation = annotationFactory.annotationFromRange(range);
        if (htmlAnnotation) {
          setFlowQuote(htmlAnnotation as FlowQuoteModel);
        }
      }),
    label: (
      <span data-test-id="add-to-asset-collection">
        <Translate id={translationKeys.ribbon.add_to_asset_collection} />
      </span>
    ),
    hidden: () => hideAddToAssetCollection || selectionStartsAtExcludedElement()
  };
}
