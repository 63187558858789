import * as React from "react";
import { Box, Grid, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    position: "absolute",
    padding: "16px",
    gap: "16px",
    backgroundColor: theme.alternativeBackgroundColor
  },
  leftGrid: {
    height: "100%",
    width: "unset",
    minWidth: "200px",
    margin: 0,
    maxWidth: "fit-content",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  center: {
    height: "100%",
    maxWidth: "unset",
    margin: 0,
    flexGrow: 1,
    overflow: "hidden"
  },
  paper: {
    height: "100%",
    padding: "8px 16px"
  },
  rightGrid: {
    height: "100%",
    margin: 0,
    width: "unset",
    minWidth: "260px",
    maxWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

/**
 * Idea is that this can be used for layout of content in dialogs taking up the entire screen
 *
 * It supports from three to four children. It puts the first to the left and the last to the right. The 1-2 in the middle are put in the middle
 *
 * Tried this approach instead of sending them in as props, since it makes it easier to do shallow rendering testing
 * of parent component where testing is interested in what is put into this layout
 * @param param0
 */
export const ParagraphZoomLayout: React.FC = ({ children }) => {
  const style = useStyles();
  const childrenArray = React.Children.toArray(children);

  const left = childrenArray[0];
  const right = childrenArray[childrenArray.length - 1];
  const centerChildren = childrenArray.slice(1, childrenArray.length - 1);

  return (
    <Box position="relative" height="100%">
      <Grid container spacing={0} className={style.container}>
        <Grid
          item
          container
          direction="column"
          sm={3}
          md={3}
          className={style.leftGrid}
        >
          <Paper className={style.paper} elevation={2}>
            {left}
          </Paper>
        </Grid>

        {centerChildren.map((child, index) => (
          <Grid
            key={index}
            item
            xs={centerChildren.length === 2 ? 6 : 12}
            sm={centerChildren.length === 2 ? 3 : 6}
            md={centerChildren.length === 2 ? 3 : 6}
            className={style.center}
          >
            {child}
          </Grid>
        ))}
        <Grid
          container
          className={style.rightGrid}
          direction="column"
          item
          sm={3}
          md={3}
        >
          <Paper className={style.paper} elevation={2}>
            {right}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
