export function isIos() {
  // see: https://material-ui.com/components/drawers/#swipeable for explanation for iOS check (its performance)
  return window && navigator && /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function isIE11() {
  // taken from: https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    !!(window as Window & typeof globalThis & { MSInputMethodContext?: string })
      .MSInputMethodContext &&
    !!(document as Document & { documentMode?: string }).documentMode
  );
}
