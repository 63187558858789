import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import FormTextField from "../../../ui-components/forms/controls/FormTextField";
import { EditProfileKeys } from "./EditProfileForm";

interface EmailFormFieldProps {
  disabled?: boolean;
  fieldName: EditProfileKeys;
  label?: React.ReactNode;
}
const EmailFormField: React.FC<EmailFormFieldProps> = ({
  disabled,
  fieldName,
  label
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <FormTextField
          disabled={disabled}
          fieldName={fieldName}
          label={label}
          fullWidth
          fieldOptions={{
            required: translate(
              translationKeys.account.my_profile_form_email_required
            ).toString(),
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@&"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(;.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: translate(
                translationKeys.account.my_profile_form_email_invalid
              ).toString()
            },
            maxLength: {
              value: 250,
              message: translate(
                translationKeys.account.my_profile_form_email_too_long
              ).toString()
            }
          }}
        />
      )}
    </Translate>
  );
};

export default EmailFormField;
