import * as React from "react";
import { AnnotationLayoutState } from "../annotation-layout-state";
import { useCallback, useEffect, useState } from "react";
import { useWindowSize } from "../../../../browser-utils/useWindowSize";
import { useMutationObserver } from "../../../../browser-utils/use-mutation-observer";
import { HtmlAnnotationModel } from "@rhinestone/portal-web-api";
import { convertToLayoutState } from "./html-annotation-layout-helper";
import { isEqual } from "lodash";
import { HtmlAnnotation } from "./html-annotation-state";

/**
 * This is hook that will ensure to recalculate html annotation highlight positions
 * whenever the DOM tree changes or window resizes and highlight position changes
 */
export function useHtmlAnnotationsLayoutState(
  annotations: HtmlAnnotation[] | undefined,
  documentElementRef: HTMLDivElement | null
) {
  const [layoutState, setLayoutState] = useState<AnnotationLayoutState>({});

  // listen for windows resize events to reposition accordingly
  const size = useWindowSize(300);

  // update layout if window resizes, annotations or positional container ref changes
  useEffect(() => {
    updateLayoutIfChanged(
      annotations?.map(a => a.annotation),
      documentElementRef,
      setLayoutState
    );
  }, [size, annotations, documentElementRef]);

  // also listen for any dom tree changes on container ref and recalculate position
  const mutationObserverCallback = useCallback(() => {
    updateLayoutIfChanged(
      annotations?.map(a => a.annotation),
      documentElementRef,
      setLayoutState
    );
  }, [annotations, documentElementRef]);

  useMutationObserver(documentElementRef, mutationObserverCallback);

  return layoutState;
}

function updateLayoutIfChanged(
  annotations: HtmlAnnotationModel[] | undefined,
  documentElementRef: HTMLDivElement | null,
  setLayoutState: React.Dispatch<React.SetStateAction<AnnotationLayoutState>>
) {
  const newLayoutState = convertToLayoutState(annotations, documentElementRef);

  setLayoutState(prev => {
    // to prevent unnecessary rerenders downstream, we only set new state object
    // if layout actually changed using lodash deep equality check
    const hasLayoutStateChanged = !isEqual(newLayoutState, prev);
    if (hasLayoutStateChanged)
      console.debug(
        "setting new html annotations layout.",
        "previous:",
        prev,
        "new:",
        newLayoutState
      );
    return hasLayoutStateChanged ? newLayoutState : prev;
  });
}
