import * as React from "react";
import { useDocumentFamilyProperties } from "./use-document-family-properties";
import { AboutDocumentFamily } from "./AboutDocumentFamily";

export const AboutDocumentFamilyContainer: React.FC = () => {
  const { fieldModels, properties: fields } =
    useDocumentFamilyProperties() ?? {};

  return fields && fieldModels ? (
    <AboutDocumentFamily fields={fields} fieldModels={fieldModels} />
  ) : null;
};
