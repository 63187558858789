import React from "react";
import { buildSearchPageDeepLinkFromTileData } from "../../../search/search-link-helper";
import { getLink } from "../helpers/link-helper-functions";
import { useGetPathList } from "../helpers/taxon-helper-functions";
import FolderBaseTile from "./FolderBaseTile";
import DocumentListItemsContainer from "./list-item/DocumentListItemsContainer";
import FolderListItem from "./list-item/FolderListItem";

interface FolderBaseTileContainerProps {
  productCode: string;
  taxonomyName: string;
  taxonKey?: string;
  folderName: string;
  siblingCount?: number;
  subFolders: { folderName: string }[];
}

const FolderBaseTileContainer: React.FC<FolderBaseTileContainerProps> = ({
  productCode,
  taxonomyName,
  taxonKey,
  folderName,
  subFolders,
  siblingCount
}) => {
  const searchLink = buildSearchPageDeepLinkFromTileData(
    taxonKey || "",
    taxonomyName,
    productCode
  );
  const pathList = useGetPathList();
  return (
    <FolderBaseTile
      taxonTitle={folderName}
      key={folderName}
      searchLink={searchLink}
      link={getLink([...pathList, folderName])}
      siblingCount={siblingCount}
    >
      {subFolders &&
        subFolders.map(folder => {
          return (
            <FolderListItem
              key={folder.folderName}
              title={folder.folderName}
              link={getLink([...pathList, folderName, folder.folderName])}
            />
          );
        })}
      <DocumentListItemsContainer
        productCode={productCode}
        taxonomyName={taxonomyName}
        taxonKey={taxonKey}
      />
    </FolderBaseTile>
  );
};

export default FolderBaseTileContainer;
