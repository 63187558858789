import { usePortalApi } from "../../../api-access/use-portal-api";

export function useDanishLawBasedTimeline(
  hiveId: string,
  fullName: string,
  familyName: string
) {
  return usePortalApi(
    client => client.getDanishLawBasedTimeline(hiveId, fullName, familyName),
    // Notice, excluding fullname from cache key
    // this is an optimization so history timeline data is reused across
    // documents in timeline and basically only fetched once
    // version based api uses family name to get timeline so timeline data
    // is the same no matter the document in timeline
    ["getDanishLawBasedTimeline", hiveId, familyName]
  );
}
