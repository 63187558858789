import React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";

const processParagraphSectionHeaderSkip =
  (): HtmlToReactProcessingInstructions => {
    return {
      replaceChildren: false,
      shouldProcessNode: (node: HtmlToReactNode) => {
        return (
          node.name === "h1" &&
          node.parent &&
          node.parent.name === "section" &&
          node.parent.attribs &&
          node.parent.attribs["class"] === "paragraf"
        );
      },
      processNode: (node, children, index) => {
        return (
          <b className={node.attribs.class} key={index}>
            {children}
          </b>
        );
      }
    };
  };

export default processParagraphSectionHeaderSkip;
