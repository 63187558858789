import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

import { useDocumentActionsSettings } from "../../document/DocumentActionMenu/document-actions/DocumentActionsSettingsProvider";
import { smallWidth as sidepaneSmallWidth } from "./../layouts/SidePane";

export const useFullWidthStyles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    position: "relative",
    "& table": {
      tableLayout: "fixed",
      backgroundColor: theme.palette.background.paper,
      maxWidth: "99%" //to avoid scrollbar when table fits in wrapper
    }
  },
  fullWidth: {
    [theme.breakpoints.up("sm")]: {
      maxWidth:
        "calc(100vw - " +
        (sidepaneSmallWidth + theme.spacing() * 7) +
        "px) !important",
      width: "155% !important"
    }
  }
}));

interface DocumentFullWidthComponentProps {
  className?: string | undefined;
}
const DocumentFullWidthComponent: React.FC<DocumentFullWidthComponentProps> = ({
  className,
  children
}) => {
  const classes = useFullWidthStyles();
  const isFullDocumentView =
    useDocumentActionsSettings()?.state.isFullDocumentView;

  return (
    <Box
      className={clsx(
        className,
        classes.wrapper,
        isFullDocumentView && classes.fullWidth
      )}
    >
      {children}
    </Box>
  );
};

export default DocumentFullWidthComponent;
