import * as React from "react";
import MaterialUiFormControl from "@material-ui/core/FormControl";

interface FormControlProps {
  color?: "primary" | "secondary";
  disabled?: boolean;
  required?: boolean;
  variant?: "standard" | "outlined" | "filled";
  children?: React.ReactNode;
  error?: boolean;
  fullWidth?: boolean;
}

/**
 * Provides context such as filled/focused/error/required for form inputs.
 *
 * Use together with **InputLabel**, **Select** & ...
 */
export const FormControl = (props: FormControlProps) => (
  <MaterialUiFormControl {...props} />
);
