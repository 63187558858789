import MaterialUiCardHeader from "@material-ui/core/CardHeader";
import * as React from "react";

export const CardHeader = (props: {
  title?: React.ReactNode;
  action?: React.ReactNode;
  avatar?: React.ReactNode;
  subheader?: React.ReactNode;
  className?: string;
}) => <MaterialUiCardHeader {...props} />;
