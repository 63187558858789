import { Chip } from "../../../ui-components/Chip";
import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  content: {
    paddingLeft: "8px",
    display: "inline-flex"
  }
}));

export const EffectiveLbkChip: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <Chip
        variant="outlined"
        color="blue"
        size="small"
        label={
          <Translate
            id={translationKeys.document_page.document_timeline_effective_lbk}
          />
        }
      />
    </Box>
  );
};
