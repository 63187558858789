import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { TextField } from "./TextField";

interface MessageFieldProps {
  textLabel: JSX.Element;
}
const MessageField: React.FC<MessageFieldProps> = ({ textLabel }) => {
  return (
    <Translate>
      {({ translate }) => (
        <TextField
          fieldName="message"
          label={textLabel}
          fullWidth
          multiline
          rows={4}
          rowsMax={4}
          fieldOptions={{
            required: translate(
              translationKeys.document_page.send_document_link_message_required
            ).toString(),
            maxLength: {
              value: 3000,
              message: translate(
                translationKeys.document_page
                  .send_document_link_message_too_long
              ).toString()
            }
          }}
        />
      )}
    </Translate>
  );
};

export default MessageField;
