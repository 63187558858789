import * as React from "react";
import { Redirect } from "react-router-dom";
import { NotFound, Unauthorized } from "../../api-access/http-status-codes";
import { PortalApiError } from "../../api-access/use-portal-api";

interface DocumentPageErrorProps {
  error: PortalApiError | null;
}

export const DocumentPageError: React.FC<DocumentPageErrorProps> = ({
  error
}) => {
  switch (error?.statusCode) {
    case NotFound:
      return <Redirect to="/NotFound" />;
    case Unauthorized:
      return (
        <Redirect
          to={`/Account/SignIn?returnUrl=${encodeURIComponent(
            window.location.pathname + window.location.search
          )}`}
        />
      );
    default:
      return null;
  }
};
