import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { Alert } from "../../../ui-components/Alert";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  alert: {
    //hides any additional warnings, so only one is displayed
    "& ~ $alert": {
      display: "none"
    }
  }
});
const NoDataWarningTabContent: React.FC = () => {
  const classes = useStyles();
  const title = (
    <Translate id={translationKeys.paragraph_zoom.tabs_no_data_title} />
  );
  const contents = (
    <Translate id={translationKeys.paragraph_zoom.tabs_no_data_contents} />
  );
  return (
    <Alert
      title={title}
      severity="warning"
      variant="outlined"
      className={classes.alert}
    >
      {contents}
    </Alert>
  );
};

export default NoDataWarningTabContent;
