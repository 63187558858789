import * as React from "react";
import { Toc as TocDataModel, TocEntry } from "@rhinestone/portal-web-api";
import { useState, useEffect, useMemo, useRef } from "react";
import {
  findNodesToExpandFromHash,
  buildNodeMap,
  getExpandableAncestorsAndSelf,
  scrollElementIntoView
} from "./toc-helpers";
import { uniq } from "lodash";
import { useLayoutEffect } from "react";
import { TocItemData, TocTreeView } from "./TocTreeView";

interface TocProps {
  tocData: TocDataModel;
  targetElementId?: string;
  onTargetElementChanged?: (elementId: string) => void;
}

export const Toc: React.FC<TocProps> = ({
  tocData,
  targetElementId,
  onTargetElementChanged
}) => {
  // using useMemo around the build of treeview data and node map,
  // since everytime toc is clicked this Toc re renders due to hash changes
  // didn't notice any visible performance issues, but it seems wasteful to keep rebuilding this data

  // build treeview items
  const items = useMemo(
    () => recursiveMapTocDataToTreeViewUiItems(tocData.entries),
    [tocData]
  );

  // build a flat dictionary of all node ids in tree with corresponding ancestors
  // this is needed to find node ids from hash we need to expand
  const nodeIdAncestorMap = useMemo(() => buildNodeMap(items), [items]);

  const initiallyExpandedNodes = getExpandableAncestorsAndSelf(
    findNodesToExpandFromHash(nodeIdAncestorMap, targetElementId)
  );

  // we track treeitem expanded states so we can set it programmatically
  const [expandedNodes, setExpandedNodeIds] = useState<string[]>(
    initiallyExpandedNodes
  );

  const [selectedNodes, setSelectedNodeIds] = useState<string[]>([]);

  useEffect(() => {
    if (!targetElementId) return;
    const node = findNodesToExpandFromHash(nodeIdAncestorMap, targetElementId);
    if (!node) return;
    // if hash is provided set expanded node state to expand all ancestor items in toc
    setExpandedNodeIds(uniq(getExpandableAncestorsAndSelf(node)));
    setSelectedNodeIds([node.nodeId]);
  }, [nodeIdAncestorMap, targetElementId]);

  const ref = useRef<HTMLDivElement>(null);

  // When selected node changes, make sure it's in the view of the TOC, otherwise scroll to it.
  useLayoutEffect(() => {
    if (selectedNodes.length !== 1) {
      return;
    }

    // Find TOC node in DOM so we can scroll to it.
    const target = ref.current?.querySelector(
      `[data-target='${selectedNodes[0]}']`
    );

    scrollElementIntoView(target);
  }, [selectedNodes]);

  const handleSelectedNodeIdChange = (nodeIds: string) => {
    if (expandedNodes.includes(nodeIds)) {
      setExpandedNodeIds(expandedNodes.filter(x => x !== nodeIds));
    } else if (nodeIdAncestorMap[nodeIds].hasChildren) {
      setExpandedNodeIds([...expandedNodes, nodeIds]);
    }

    if (!onTargetElementChanged) return;
    const targetElementId = nodeIds.split(";")[0];
    onTargetElementChanged(targetElementId);
  };

  return (
    <TocTreeView
      ref={ref}
      items={items}
      expandedNodeIds={expandedNodes}
      selectedNodeIds={selectedNodes}
      onSelectedNodeIdChange={handleSelectedNodeIdChange}
    />
  );
};

function recursiveMapTocDataToTreeViewUiItems(
  entries: TocEntry[],
  parentTocLevelIndicator = ""
): TocItemData[] {
  return entries.map((entry, index) => {
    // we postfix nodeids with for treeview unique toclevel indicator
    // this ensures that if any tocentry targets is the same anywhere in toc nodeids will always be unique
    // we keep target information as prefix, since this means we can find nodes again based on target id
    const tocLevelIndicator = parentTocLevelIndicator
      ? `${parentTocLevelIndicator}.${index + 1}`
      : String(index + 1);

    return {
      target: entry.target,
      heading: entry.heading,
      nodeId: `${entry.target};${tocLevelIndicator}`,
      children: recursiveMapTocDataToTreeViewUiItems(
        entry.children,
        tocLevelIndicator
      )
    };
  });
}
