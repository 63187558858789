import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";

export const periodHeader = (
  <Translate
    id={translationKeys.document_page.create_link_dialog_period_title}
  />
);
export const versionHeader = (
  <Translate
    id={translationKeys.document_page.create_link_dialog_version_title}
  />
);
export const versionActual = (
  <Translate
    id={translationKeys.document_page.create_link_dialog_version_actual}
  />
);
export const versionNewest = (
  <Translate
    id={translationKeys.document_page.create_link_dialog_version_newest}
  />
);
export const createLinkSubmitLabel = (
  <Translate id={translationKeys.document_page.create_link_dialog_commit} />
);
