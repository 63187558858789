import { DialogActions as MuiDialogActions } from "@material-ui/core";
import * as React from "react";
import { Button } from "../Button";

export interface ModalDialogAction {
  action: () => void;
  caption: React.ReactNode;
  disabled?: boolean;
}

export type ModalDialogActionsProps = {
  cancel: ModalDialogAction | undefined;
  confirm: ModalDialogAction | undefined;
};

export function ModalDialogActions({
  confirm,
  cancel
}: ModalDialogActionsProps) {
  if (!cancel && !confirm) return null;

  return (
    <MuiDialogActions>
      {cancel && (
        <Button onClick={cancel.action} disabled={cancel.disabled}>
          {cancel.caption}
        </Button>
      )}
      {confirm && (
        <Button
          color={"primary"}
          variant={"contained"}
          onClick={confirm.action}
          disabled={confirm.disabled}
        >
          {confirm.caption}
        </Button>
      )}
    </MuiDialogActions>
  );
}
