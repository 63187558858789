import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import { SectionAncestorMap } from "../document-components/FoldableSection";
import { isCollapsibleSection } from "./process-section-helpers";
import { FoldableSection } from "../document-components/FoldableSection/FoldableSection";
import { Section } from "../document-components/Section";
import { buildSectionTitleString } from "./process-section-helpers";
import { SectionAction } from "../rhinestone-document-helper-types";

const processSectionsWithIds = (
  mutableSectionAncestorMap: SectionAncestorMap,
  renderSectionActions: SectionAction
): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) =>
      isSectionWithId(node) && !isNoteSection(node),
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      const sectionTitleAsString = buildSectionTitleString(node);

      if (isCollapsibleSection(node)) {
        buildAndSetSectionAncestorMap(node, mutableSectionAncestorMap);
        return (
          <FoldableSection
            key={index}
            sectionId={node.attribs.id}
            sectionTitleAsString={sectionTitleAsString}
            defaultExpanded={
              node.attribs["data-collapsible-default-state"] === "expanded"
            }
            className={node.attribs.class}
            renderSectionActions={renderSectionActions}
            index={index}
          >
            {children}
          </FoldableSection>
        );
      } else {
        return (
          <Section
            key={index}
            id={node.attribs.id}
            sectionTitleAsString={sectionTitleAsString}
            className={node.attribs.class}
            renderSectionActions={renderSectionActions}
            index={index}
          >
            {children}
          </Section>
        );
      }
    }
  };
};

function buildAndSetSectionAncestorMap(
  node: HtmlToReactNode,
  mutableSectionAncestorMap: SectionAncestorMap
) {
  const ancestorIds = getAncestors(node);
  // for performance reasons when having thousands of sections, we mutate this map
  // its ok because we wont use the map until we have parsed the entire document
  mutableSectionAncestorMap[node.attribs.id] = ancestorIds;
}

function getAncestors(
  node: HtmlToReactNode,
  ancestors: string[] = []
): string[] {
  if (node.parent?.attribs.id) {
    ancestors.push(node.parent.attribs.id);
    getAncestors(node.parent, ancestors);
  }
  return ancestors;
}

function isNoteSection(node: HtmlToReactNode) {
  return (
    node.attribs.class && String(node.attribs.class).includes("noteonstruct")
  );
}

function isSectionWithId(node: HtmlToReactNode) {
  return node.attribs && node.name === "section" && node.attribs.id;
}

export default processSectionsWithIds;
