import React from "react";
import { FormFieldProps } from "../forms/controls/FormFieldProps";
import { TextField, TextFieldStylingProps } from "./TextField";

export type NumberFieldStylingProps = Omit<TextFieldStylingProps, "type">;

type NumberFieldProps = NumberFieldStylingProps & FormFieldProps;

export function NumberField(props: NumberFieldProps) {
  return <TextField {...props} type="number" />;
}
