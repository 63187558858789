import { useLayoutEffect, useState } from "react";
import { throttle } from "lodash";

export function useWindowSize(throttleScrollEvents?: number) {
  const [size, setSize] = useState<{
    innerWidth: number;
    innerHeight: number;
  }>({ innerHeight: 0, innerWidth: 0 });
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      });
    };
    const handler = throttleScrollEvents
      ? throttle(updateSize, throttleScrollEvents)
      : updateSize;
    window.addEventListener("resize", handler);
    updateSize();
    return () => window.removeEventListener("resize", handler);
  }, [throttleScrollEvents]);
  return size;
}
