import React from "react";
import {
  TimelineItemModel,
  useVersionBasedHistoryTimeline
} from "./document-history-timeline-helpers";
import { QueryStatus } from "react-query";

export interface VersionBasedHistoryTimelineProps {
  hiveId: string;
  fullName: string;
  familyName: string;
  children: (
    items: TimelineItemModel[],
    status: QueryStatus
  ) => React.ReactElement;
}

/**
 * Wrapper of hooks call to enable conditionally choosing api
 */
export const VersionBasedHistoryTimeLineData: React.FC<VersionBasedHistoryTimelineProps> =
  ({ hiveId, fullName, familyName, children }) => {
    const { data: items, status } = useVersionBasedHistoryTimeline(
      hiveId,
      fullName,
      familyName
    );
    return children(items, status);
  };
