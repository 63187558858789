// Ported from old design
export function normalizeIndex(index: number, maxIndex: number) {
  let normalizedIndex = index % maxIndex;
  if (normalizedIndex < 0) {
    normalizedIndex += maxIndex;
  }

  return normalizedIndex;
}

// Ported from old design
export function scrollToElementIfOutOfBounds(element: HTMLElement) {
  const documentElement = document.documentElement;

  const documentBodyScrollTop =
    (window.pageYOffset || documentElement.scrollTop) -
    (documentElement.clientTop || 0);

  const elementTop =
    element.getBoundingClientRect().top + documentElement.scrollTop;
  const elementBottom =
    element.getBoundingClientRect().bottom + documentElement.scrollTop;

  const elementAboveViewport = elementTop <= documentBodyScrollTop;
  const elementBelowViewport =
    elementBottom >= documentBodyScrollTop + documentElement.clientHeight;

  if (elementAboveViewport || elementBelowViewport) {
    window.scrollTo(0, elementTop - documentElement.offsetHeight * 0.5);
  }
}
