import * as React from "react";
import { makeStyles, TextareaAutosize } from "@material-ui/core";
import { Button } from "../../../ui-components/Button";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { AnnotationModel } from "@rhinestone/portal-web-api";
import { NavigateItemsPanel } from "../../../ui-components/NavigateItemsPanel";
import { Annotation } from "../annotation-state";
import { useState } from "react";
import { DeleteAnnotationCommentConfirmation } from "../DeleteAnnotationCommentConfirmation";
interface AnnotationCommentEditorFieldsProps<T extends AnnotationModel> {
  annotations: Annotation<T>[];
  defaultActiveAnnotation: Annotation<T>;
  onClose: () => void;
  onSave: (annotation: Annotation<T>, newComment: string) => void;
  onDelete?: (annotation: Annotation<T>) => void;
  onActiveAnnotationChange: (annotation: Annotation<T>) => void;
}

const useStyles = makeStyles(theme => ({
  content: {
    "& span": {
      display: "flex",
      justifyContent: "flex-end"
    },
    "& button": {
      marginLeft: "8px"
    }
  },
  autoSize: { width: "100%", resize: "vertical" }
}));

// Unable (don't know how) to declare generic React.FC<Props<T>> so leaving it out here....
export const AnnotationCommentEditorFields = <T extends AnnotationModel>({
  annotations,
  defaultActiveAnnotation,
  onClose,
  onSave,
  onDelete,
  onActiveAnnotationChange
}: AnnotationCommentEditorFieldsProps<T>) => {
  const classes = useStyles();
  const [activeAnnotation, setActiveAnnotation] = useState(
    defaultActiveAnnotation
  );
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  // holds the edited comment
  const [editedComment, setEditedComment] = useState(
    annotations[0].annotation.comment
  );
  const updateActiveAnnotation = (a: Annotation<T>) => {
    setActiveAnnotation(a);
    setEditedComment(a.annotation.comment);
    onActiveAnnotationChange(a);
  };

  return (
    <div className={classes.content}>
      {Boolean(annotations.length > 1) && (
        <NavigateItemsPanel
          items={annotations}
          onSelectedItemChanged={i => updateActiveAnnotation(i)}
        />
      )}
      <TextareaAutosize
        data-test-id="annotation-comment-input"
        value={editedComment}
        className={classes.autoSize}
        onChange={e => setEditedComment(e.target.value)}
      />
      <Translate>
        {({ translate }) => (
          <>
            <div>
              {translate(
                translationKeys.document_page.last_edited_by
              ).toString()}
              {": "}
              {activeAnnotation.annotation.userName}{" "}
              {activeAnnotation.annotation.updatedAtDisplayString}
            </div>
            <span>
              {onDelete !== undefined ? (
                <Button
                  onClick={e => setConfirmationOpen(true)}
                  variant="outlined"
                  data-test-id="delete-annotation-comment"
                >
                  {translate(translationKeys.document_page.delete).toString()}
                </Button>
              ) : (
                <Button onClick={e => onClose()} variant="outlined">
                  {translate(translationKeys.document_page.cancel).toString()}
                </Button>
              )}
              <Button
                disabled={activeAnnotation.annotation.comment === editedComment}
                onClick={e => onSave(activeAnnotation, editedComment)}
                color="primary"
                variant="contained"
                data-test-id="save-annotation-comment"
              >
                {translate(translationKeys.document_page.save).toString()}
              </Button>
            </span>
          </>
        )}
      </Translate>
      {onDelete && (
        <DeleteAnnotationCommentConfirmation
          open={confirmationOpen}
          setOpen={setConfirmationOpen}
          annotation={activeAnnotation}
          removeAnnotationComment={onDelete}
        />
      )}
    </div>
  );
};
