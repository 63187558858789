import { react2angular } from "react2angular";
import {
  TemporaryShellProps,
  withTemporaryShell
} from "./components/legacy-app-support/withTemporaryShell";
import { withLightweightTemporaryShell } from "./components/legacy-app-support/withLightweightTemporaryShell";

// we do this hacky way of referring to angular in global scope, to prevent angular from being bundled twice
// in the old angular application.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export declare const angular: any;

/**
 *
 * @param moduleName
 * @param componentToRegister
 * @param componentNameInAngular
 * Directive name for the Component in angular application
 * @param bindingNamesToUseInAngular
 * If supplied is a strongly typed array of the property names for the Component Props
 */
export function registerReactComponentWithAngularModule<
  Props extends Record<string, any>
>(
  moduleName: string,
  componentToRegister: React.ComponentType<Props>,
  componentNameInAngular: string,
  bindingNamesToUseInAngular?: (keyof Props)[] | null,
  variant: "lightweight" | "full" = "full"
) {
  const directivePrefix = "rhReact";
  // Shell injections are the list of angular services that we allow to become available to the temporary shell environment during transition phase
  const shellInjections: (keyof TemporaryShellProps)[] = [
    "$translate",
    "$scope",
    "portal"
  ];
  return angular
    .module(moduleName)
    .component(
      `${directivePrefix}${componentNameInAngular}`,
      react2angular<Props & TemporaryShellProps>(
        variant === "lightweight"
          ? withLightweightTemporaryShell(componentToRegister)
          : withTemporaryShell(componentToRegister),
        bindingNamesToUseInAngular,
        shellInjections
      )
    );
}
