import * as React from "react";
import { SearchInToc } from "./SearchInToc";
import { useTocData } from "../Toc/toc-helpers";
import { TocEntry } from "@rhinestone/portal-web-api";
import { useHistory } from "react-router-dom";

export const SearchInTocContainer: React.FC = () => {
  const { data } = useTocData();
  const { replace, location } = useHistory();

  if (!data || data.entries.length === 0) {
    return null;
  }

  const handleChange = (value: TocEntry | null) => {
    replace({
      ...location,
      hash: value?.target
    });
  };

  return <SearchInToc tocData={data} onChange={handleChange} />;
};
