import * as React from "react";
import {
  PaneItem,
  ThreePanesLayout
} from "../../ui-components/layouts/ThreePanesLayout";
import { useCallback, useState } from "react";
import ContentZone from "../content/ContentZone";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import PreparatoryWorkIcon from "@material-ui/icons/HowToVoteOutlined";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import AssetCollectionIcon from "@material-ui/icons/FolderOutlined";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { InstanceRelationsContainer } from "../InstanceRelations";
import { AboutDocumentContainer } from "../AboutDocument/AboutDocumentContainer";
import { BreadcrumbContainer } from "../Breadcrumb/BreadcrumbContainer";
import { AssetCollectionInfoContainer } from "../AssetCollection/AssetCollectionInfoContainer";
import { useDocumentHasHistoryTimelineStatus } from "../document-history-timeline/document-history-timeline-helpers";
import { DocumentHistoryTimelineContainer } from "../document-history-timeline";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { ArticleContent } from "../../ui-components/layouts/ArticleContent";
import { useDocumentOpenedGTMEvent } from "../use-document-opened-gtm-event";
import { useSetPageTitle } from "../use-set-page-title";
import { useApiTicket } from "../../api-access/use-api-ticket";
import ExcludeFromAnnotation from "../annotations/ExcludeFromAnnotation";
import { useToc } from "./use-toc";
import TimelineAccordionPanelContainer from "../preparatory-work/TimelineAccordionPanelContainer";
import AttachmentContainer from "../Attachment/AttachmentContainer";
import { useHasAssetCollectionContext } from "../AssetCollection/asset-collection-context";
import { useHasPreparatoryWork } from "../preparatory-work/use-has-preparatory-work";
import { useHasDocumentAttachments } from "../Attachment/use-document-attachments";
import { AboutDocumentFamilyContainer } from "../AboutDocumentFamily/AboutDocumentFamilyContainer";
import { AboutDocumentFamilyTitle } from "../AboutDocumentFamily/AboutDocumentFamilyTitle";
import { useIsDanishLaw } from "../AboutDocumentFamily/use-is-danish-law";
import { LoadingProgress } from "../../ui-components/LoadingProgress";

export const DocumentLayout: React.FC = () => {
  const [currentDocumentElementInView, setCurrentDocumentElementInView] =
    useState<Element>();
  const onTargetElementInView = useCallback(
    (e: Element) => setCurrentDocumentElementInView(e),
    []
  );

  useDocumentOpenedGTMEvent();
  useSetPageTitle();

  const timeline = useTimeline();
  const toc = useToc(currentDocumentElementInView);
  const center = useCenterContent(onTargetElementInView);
  const rightPaneItems = useRightPaneItems();

  return (
    <ThreePanesLayout
      timeline={timeline}
      toc={toc}
      center={center}
      right={rightPaneItems}
    />
  );
};

function useRightPaneItems() {
  const hasAssetCollectionContext = useHasAssetCollectionContext();
  const hasPreparatoryWork = useHasPreparatoryWork();
  const hasDocumentAttachments = useHasDocumentAttachments();
  const { isDanishLaw, isFetching: isFetchingIsDanishLaw } = useIsDanishLaw();

  return React.useMemo(() => {
    const items: PaneItem[] = [];

    if (hasAssetCollectionContext)
      items.push({
        icon: <AssetCollectionIcon />,
        title: <Translate id={translationKeys.document_page.asset_colletion} />,
        key: "asset-collection",
        content: <AssetCollectionInfoContainer />
      });

    items.push(renderAboutBox(isFetchingIsDanishLaw, isDanishLaw));

    if (hasPreparatoryWork)
      items.push({
        icon: <PreparatoryWorkIcon />,
        title: (
          <Translate id={translationKeys.document_page.preparatory_work} />
        ),
        key: "preparatory-work",
        content: <TimelineAccordionPanelContainer />
      });

    if (hasDocumentAttachments)
      items.push({
        icon: <AttachmentIcon />,
        title: (
          <Translate
            id={translationKeys.document_page.attachments_section_header}
          />
        ),
        key: "document-attachments",
        content: <AttachmentContainer />
      });

    return items;
  }, [
    hasAssetCollectionContext,
    hasDocumentAttachments,
    hasPreparatoryWork,
    isDanishLaw,
    isFetchingIsDanishLaw
  ]);
}

function renderAboutBox(isFetching: boolean, isDanishLaw: boolean): PaneItem {
  if (isFetching) {
    return {
      icon: <InfoIcon />,
      title: <LoadingProgress />,
      content: <></>,
      key: "about-document"
    };
  }

  if (isDanishLaw)
    return {
      icon: <InfoIcon />,
      title: <AboutDocumentFamilyTitle />,
      content: <AboutDocumentFamilyContainer />,
      key: "about-document-family",
      variant: "grey"
    };
  else
    return {
      icon: <InfoIcon />,
      title: <Translate id={translationKeys.document_page.about_document} />,
      key: "about-document",
      content: (
        <>
          <BreadcrumbContainer />
          <AboutDocumentContainer />
        </>
      )
    };
}

function useCenterContent(
  onTargetElementInView: (e: Element) => void
): JSX.Element {
  // Notice usage of useMemo to not re-render component when state changes as it would re-render
  // the entire document.
  return React.useMemo(
    () => (
      <ArticleContent>
        <InstanceRelationsContainer variant="fullWidth" />
        <ContentZone onTargetElementInView={onTargetElementInView} />
      </ArticleContent>
    ),
    [onTargetElementInView]
  );
}

function useTimeline() {
  const ticketId = useApiTicket();
  const { data: timelineStatus } = useDocumentHasHistoryTimelineStatus();

  return React.useMemo(() => {
    if (ticketId) {
      return null;
    }
    if (timelineStatus?.hasTimeline) {
      const docHistoryPane = {
        icon: <HistoryOutlinedIcon />,
        key: "document-history",
        title: (
          <Translate
            id={translationKeys.document_page.document_history_timeline}
          />
        ),
        content: (
          <ExcludeFromAnnotation>
            <DocumentHistoryTimelineContainer timelineStatus={timelineStatus} />
          </ExcludeFromAnnotation>
        )
      };
      return docHistoryPane;
    }
    return null;
  }, [ticketId, timelineStatus]);
}
