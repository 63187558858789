import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";

const processHeaderTagsDowngrade = (): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => node.name === "h1",
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      return (
        <h2 className={node.attribs.class} key={index}>
          {children}
        </h2>
      );
    }
  };
};

export default processHeaderTagsDowngrade;
