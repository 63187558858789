import { Theme } from "@material-ui/core/styles";
import { FieldNames } from "./document-fieldsets";
import { FieldSet, FieldSetValue } from "./use-fieldset";

export enum DocumentStatus {
  None = 0,
  Unknown = 1,
  Coming = 2,
  Effective = 3,
  Historic = 4,
  // TODO The LatestLbk can be removed from DocumentStatus when danish law
  // status feature toggle has been removed
  LatestLbk = 5
}

export const documentStatusTranslationKeys: Record<DocumentStatus, string> = {
  [DocumentStatus.Coming]: "status.coming",
  [DocumentStatus.Effective]: "status.effective",
  [DocumentStatus.Historic]: "status.historic",
  [DocumentStatus.LatestLbk]: "status.latestlbk",
  [DocumentStatus.None]: "status.unknown",
  [DocumentStatus.Unknown]: "status.unknown"
};

export const documentStatusColors: (
  theme: Theme
) => Record<DocumentStatus, string> = theme => ({
  [DocumentStatus.Coming]: theme.palette.status.coming.main,
  [DocumentStatus.Effective]: theme.palette.status.effective.main,
  [DocumentStatus.Historic]: theme.palette.status.historic.main,
  [DocumentStatus.LatestLbk]: theme.palette.status.latestLbk.main,
  [DocumentStatus.None]: theme.palette.status.unknown.main,
  [DocumentStatus.Unknown]: theme.palette.status.unknown.main
});

/**
 * Helper function to lookup expected "hardcoded" status field
 */
export function getStatusFromFieldset(fieldSet: FieldSet | undefined) {
  const statusFieldValue = fieldSet?.[FieldNames.Status];
  return getStatusFromFieldsetValue(statusFieldValue);
}

export function getStatusFromFieldsetValue(
  statusFieldValue: FieldSetValue | undefined
) {
  return DocumentStatusKeys[String(statusFieldValue)] ?? DocumentStatus.None;
}

// Document status is stored as a string value (not the enum)
// in document fields, so we need to map to get the value
// Keep this object up-to-date with above enum.
const DocumentStatusKeys: { [key: string]: DocumentStatus } = {
  None: DocumentStatus.None,
  Unknown: DocumentStatus.Unknown,
  Coming: DocumentStatus.Coming,
  Effective: DocumentStatus.Effective,
  Historic: DocumentStatus.Historic,
  // TODO The LatestLbk can be removed from DocumentStatus when danish law
  // status feature toggle has been removed
  LatestLbk: DocumentStatus.LatestLbk
};
