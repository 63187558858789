/* eslint-disable complexity */
import { useMemo } from "react";
import { useUserContext } from "../../user/use-user-context";
import { useAnnotationClientContext } from "./AnnotationClientStateProvider";
import {
  AnnotationModel,
  HtmlNewAnnotationDTO,
  PdfNewAnnotationDTO
} from "@rhinestone/portal-web-api";
import {
  useAddAnnotation,
  useAddAnnotationComment,
  useGetAnnotations,
  useRemoveAnnotation,
  useRemoveAnnotationComment,
  useSaveAnnotation
} from "./annotation-server-hooks";
import {
  Annotation,
  AnnotationContext,
  AnnotationContextType,
  AnnotationState
} from "./annotation-state";
import {
  mapToAssetCollection,
  useAssetCollectionContext
} from "../AssetCollection/asset-collection-context";
import { AnnotationTargetType } from "../../api-access/portal-api-access";
import { PortalApiQueryKey } from "../../api-access/use-portal-api";
import { Feature, useUserHasFeature } from "../../user/features";
import { useIsMobile } from "../../ui-components/media-queries";
import { useIsTouchScreen } from "../../../browser-utils/use-is-touch-screen";

export function useAnnotationContext(): AnnotationContext | undefined {
  /// gets logged in user
  const { user } = useUserContext();

  /// gets current asset collection (if any)
  const assetCollectionContext = useAssetCollectionContext();
  const assetCollection = mapToAssetCollection(assetCollectionContext);
  const assetCollectionIsTemplate = assetCollection?.isTemplate || false;

  const context = useMemo(
    () =>
      buildAnnotationContext(
        user?.id,
        assetCollection?.assetCollectionId,
        assetCollectionIsTemplate
      ),
    [user, assetCollection, assetCollectionIsTemplate]
  );

  return context;
}

/**
 * This component encapsulates the data fetching and update of annotations data
 * it depends on other contexts being established such as user context
 * It also combines the client side annotation state of whether or not to show annotations
 *
 * @param annotationTargetId
 * @param targetType {@link AnnotationTargetType}
 * @param extraQueryKeysToInvalidate keys for extra queries that should be invalidated when annotations are updated
 * @returns the combined server side and client side state for annotations for the given target
 */
export function useAnnotationState<
  T extends AnnotationModel,
  V extends HtmlNewAnnotationDTO | PdfNewAnnotationDTO
>(
  annotationTargetId: string | undefined,
  targetType: AnnotationTargetType,
  extraQueryKeysToInvalidate?: PortalApiQueryKey[]
): AnnotationState<T, V> {
  const [isVisible] = useAnnotationClientContext();
  const hasAnnotationsFeature = useUserHasFeature(Feature.MarkUpAndAnnotations);
  const isMobile = useIsMobile();
  const hasTouchScreen = useIsTouchScreen();
  const context = useAnnotationContext();

  const isShown =
    isVisible &&
    hasAnnotationsFeature &&
    !isMobile &&
    !hasTouchScreen &&
    !!context;

  const { contextAnnotations: userAnnotations } = useAnnotations<T>(
    annotationTargetId,
    targetType,
    context,
    "user"
  );

  const { contextAnnotations: assetCollectionAnnotations } = useAnnotations<T>(
    annotationTargetId,
    targetType,
    context,
    "asset-collection"
  );

  const saveAnnotation = useSaveAnnotation<T>(
    annotationTargetId,
    extraQueryKeysToInvalidate
  );

  const addAnnotation = useAddAnnotation<T, V>(
    annotationTargetId,
    extraQueryKeysToInvalidate
  );

  const removeAnnotation = useRemoveAnnotation<T>(
    annotationTargetId,
    extraQueryKeysToInvalidate
  );

  const addAnnotationComment = useAddAnnotationComment<T>(
    annotationTargetId,
    extraQueryKeysToInvalidate
  );

  const removeAnnotationComment = useRemoveAnnotationComment<T>(
    annotationTargetId,
    extraQueryKeysToInvalidate
  );

  /// there can be ONLY user annotations or ONLY asset collection annotations
  const annotations = userAnnotations || assetCollectionAnnotations;

  // using useMemo to ensure that object returned is only recreated when values actually change
  // otherwise subtle unnecessary rendering might can occur if any calling code depends on the object returned
  return useMemo(
    () => ({
      isShown,
      annotations,
      context,
      addAnnotation,
      saveAnnotation,
      removeAnnotation,
      addAnnotationComment,
      removeAnnotationComment
    }),
    [
      isShown,
      annotations,
      context,
      addAnnotation,
      saveAnnotation,
      removeAnnotation,
      addAnnotationComment,
      removeAnnotationComment
    ]
  );
}

/// builds current document context, there may be only one
/// if looking at document in asset collection TEMPLATE - there is no context
/// if looking at document in asset collection - asset-collection context
/// otherwise, get user context (if logged in)
function buildAnnotationContext(
  userId: string | undefined,
  assetCollectionId: string | undefined,
  isTemplate: boolean
): AnnotationContext | undefined {
  if (isTemplate) {
    return undefined;
  }

  if (assetCollectionId) {
    return { contextId: assetCollectionId, type: "asset-collection" };
  }

  if (userId) {
    return { contextId: userId, type: "user" };
  }

  return undefined;
}

function useAnnotations<T extends AnnotationModel>(
  annotationTargetId: string | undefined,
  targetType: AnnotationTargetType,
  context: AnnotationContext | undefined,
  targetContextType: AnnotationContextType
) {
  /// fetch annotations of given context type only (user | asset-collection)
  const contextId =
    context?.type === targetContextType ? context.contextId : undefined;
  const serverAnnotationModels = useGetAnnotations<T>(
    contextId,
    annotationTargetId,
    targetType
  );

  const contextAnnotations = useMemo(
    () =>
      buildClientSideAnnotation<T>(
        serverAnnotationModels,
        contextId,
        targetContextType
      ),
    [serverAnnotationModels, contextId, targetContextType]
  );

  return { contextAnnotations };
}

function buildClientSideAnnotation<T extends AnnotationModel>(
  serverAnnotationModels: T[] | undefined,
  contextId: string | undefined,
  contextType: AnnotationContextType
): Annotation<T>[] | undefined {
  return serverAnnotationModels && contextId
    ? serverAnnotationModels.map(a => ({
        annotation: a,
        contextId,
        contextType
      }))
    : undefined;
}
