import React, { useCallback } from "react";
import { SectionPayload } from "./SectionPayload";

export function useReportSectionData(
  setSectionIds: React.Dispatch<{
    type: "set";
    payload: { [key: string]: SectionPayload };
  }>
) {
  return useCallback(
    (
      targetBookmark: string,
      reportedHiveId: string,
      reportedFullName: string,
      reportedSectionIds: string[],
      reportedIsFetching: boolean
    ) => {
      setSectionIds({
        type: "set",
        payload: {
          [targetBookmark]: {
            sectionIds: reportedSectionIds,
            fetchState: reportedIsFetching ? "fetching" : "fetched",
            hiveId: reportedHiveId,
            fullName: reportedFullName
          }
        }
      });
    },
    [setSectionIds]
  );
}
