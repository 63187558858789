import NativeSelect from "@material-ui/core/NativeSelect";
import * as React from "react";
import { FormFieldProps } from "../forms/controls/FormFieldProps";
import { InputLabel } from "../InputLabel";
import { FormControl } from "../FormControl";

interface SelectProps {
  fullWidth?: boolean;
  children?: React.ReactNode;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  style?: React.CSSProperties;
  label?: React.ReactNode;
}

export const Select = (props: SelectProps & FormFieldProps) => {
  const {
    helperText,
    innerRef,
    children,
    name,
    label,
    error,
    fullWidth,
    ...rest
  } = props;
  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <InputLabel htmlFor="uncontrolled-native">{label}</InputLabel>
      <NativeSelect
        {...rest}
        name={name}
        inputRef={innerRef}
        inputProps={{
          name: name,
          id: "uncontrolled-native"
        }}
      >
        {children}
      </NativeSelect>
    </FormControl>
  );
};
