import {
  FlowQuoteModel,
  GeometricSelectionModel,
  HtmlNewAnnotationDTO,
  PdfNewAnnotationDTO,
  TicketInfo
} from "@rhinestone/portal-web-api";
import { DocumentLinkInfo } from "@rhinestone/portal-web-api";
import {
  SchultzRhinestonePortalWeb,
  portalWebRequestPolicies,
  CreateOrUpdateDocumentVersionSubscriptionModel,
  AssetCollectionGetAssetCollectionsForCurrentUserOptionalParams,
  AssetCollectionDocumentAdditionModel,
  HtmlAnnotationModel,
  PdfAnnotationModel,
  AnnotationModel,
  RestError
} from "@rhinestone/portal-web-api";
import {
  SearchRequestClient,
  SearchRequestServer
} from "../search/search-request";
import { SearchRequestMapper } from "../search/SearchRequestMapper";
import { SlaMeasurement } from "../sla-measurement/sla-measurement-api";

/**
 * Wrapper around calls to generated autorest client
 */
export class PortalApiAccess {
  constructor(
    private client: SchultzRhinestonePortalWeb,
    private portalId: string,
    public ticketId?: string
  ) {}

  relativeBasePortalApiUrl() {
    return `/api/Portals(${this.portalId})`;
  }

  getHighlights(documentId: string, searchRequest: SearchRequestClient) {
    const serverRequest =
      SearchRequestMapper.mapToServerSearchRequest(searchRequest);

    return this.client.search.getHighlights(
      this.portalId,
      documentId,
      serverRequest
    );
  }

  getDocuments(searchRequest: SearchRequestClient) {
    const serverRequest =
      SearchRequestMapper.mapToServerSearchRequest(searchRequest);
    return this.client.search.search(this.portalId, serverRequest);
  }

  getLocalizations() {
    return this.client.localizations.getLocalizations(this.portalId, {
      requestOptions: {
        shouldDeserialize: true
      }
    });
  }

  getTranslations(lang: string) {
    return this.client.translations.getTranslations(this.portalId, lang);
  }

  async getTaxonomy(taxonomyName: string) {
    return this.client.taxonomyOperations.getPortalTaxonomy(
      this.portalId,
      taxonomyName
    );
  }

  getProduct(productCode: string) {
    return this.client.product.get(this.portalId, productCode);
  }

  getLoggedInUser() {
    return this.client.user.getUserContext(this.portalId);
  }

  getUserProfile() {
    return this.client.userProfile.getUserProfile(this.portalId);
  }

  getDocument(hiveId: string, fullName: string) {
    return this.client.document.getDocument(
      this.portalId,
      `${hiveId}/${fullName}`
    );
  }

  getTimelineStatus(hiveId: string, fullName: string) {
    return this.client.timeline.getTimelineStatus(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getBranchingHistoryTimeline(hiveId: string, fullName: string) {
    return this.client.timeline.getBranchingHistoryTimeline(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getVersionBasedHistoryTimeline(
    hiveId: string,
    fullName: string,
    familyName: string
  ) {
    return this.client.timeline.getVersionBasedHistoryTimeline(
      this.portalId,
      familyName,
      hiveId,
      fullName
    );
  }

  getDanishLawBasedTimeline(
    hiveId: string,
    fullName: string,
    familyName: string
  ) {
    return this.client.timeline.getDanishLawBasedTimeline(
      this.portalId,
      familyName,
      hiveId,
      fullName
    );
  }

  getDocumentPageConfiguration() {
    return this.client.pageConfigurations.getDocumentPageConfiguration(
      this.portalId
    );
  }

  downloadAttachment(hiveId: string, fullname: string, identifier: string) {
    return this.client.documentAttachment.getDocumentAttachment(
      this.portalId,
      hiveId,
      fullname,
      {
        identifier
      }
    );
  }

  getDocumentTargetRelationFieldsSets(
    hiveId: string,
    fullName: string,
    relationType: string,
    fieldSetName: string,
    sourceBookmark: string
  ) {
    return this.client.documentRelation.getDocumentTargetRelationFieldsSets(
      this.portalId,
      hiveId,
      fullName,
      fieldSetName,
      relationType,
      sourceBookmark ?? ""
    );
  }

  getDocumentSourceRelationFieldsSets(
    hiveId: string,
    fullName: string,
    relationType: string,
    fieldSetName: string,
    targetBookmark: string
  ) {
    return this.client.documentRelation.getDocumentSourceRelationFieldsSets(
      this.portalId,
      hiveId,
      fullName,
      relationType,
      fieldSetName,
      targetBookmark ?? ""
    );
  }

  getDirectionalDocumentRelationsByType(
    hiveId: string,
    fullName: string,
    relationType: string[],
    fieldSetName: string,
    direction: RelationDirection
  ) {
    return this.client.documentRelation.getDirectionalDocumentRelationsByType(
      this.portalId,
      hiveId,
      fullName,
      relationType.join(","),
      fieldSetName,
      direction
    );
  }

  getRelationTree(
    hiveId: string,
    fullName: string,
    relationType: string[],
    fieldSetName: string
  ) {
    return this.client.documentRelation.getDocumentRelationTree(
      this.portalId,
      hiveId,
      fullName,
      relationType.join(","),
      fieldSetName
    );
  }

  getDocumentTargetRelationFieldsSetsByFamily(
    hiveId: string,
    familyName: string,
    fullName: string,
    relationType: string,
    fieldSetName: string,
    sourceBookmark: string
  ) {
    return this.client.documentRelation.getDocumentTargetRelationFieldsSetsByFamily(
      this.portalId,
      hiveId,
      familyName,
      fullName,
      fieldSetName,
      relationType,
      sourceBookmark ?? ""
    );
  }

  async getPrimaryVariantContentExcludeEditorialNotes(
    hiveId: string,
    fullName: string,
    assetCollectionId?: string,
    slaMeasurement?: SlaMeasurement
  ) {
    // If a sla measurement is specified we add headers to the request
    let requestOptions = {};
    if (slaMeasurement) {
      requestOptions = {
        customHeaders: {
          "x-sla-measurement-tool": slaMeasurement.slaTool,
          "x-sla-measurement-transaction": slaMeasurement.slaTransaction
        }
      };
    }

    return await this.client.document.getPrimaryVariantContentExcludeEditorialNotes(
      this.portalId,
      hiveId,
      fullName,
      {
        assetCollectionId,
        requestOptions: requestOptions
      }
    );
  }

  getDocumentFieldset(hiveId: string, fullName: string, fieldsetName: string) {
    return this.client.document.getFieldSetValues(
      this.portalId,
      fieldsetName,
      hiveId,
      fullName
    );
  }

  getDocumentFamilyProperties(hiveId: string, fullName: string) {
    return this.client.documentFamily.getDocumentFamilyProperties(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getDocumentRelationGroupsForAll(hiveId: string, fullName: string) {
    return this.client.documentRelationGroup.getDocumentRelationGroupsForAllV2(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getDocumentRelationGroupsForBookmark(
    hiveId: string,
    fullName: string,
    bookmark: string
  ) {
    return this.client.documentRelationGroup.getDocumentRelationGroupsForBookmarkV2(
      this.portalId,
      hiveId,
      fullName,
      {
        bookmark
      }
    );
  }

  getDocumentRelationGroupsForTopOfDocument(hiveId: string, fullName: string) {
    return this.client.documentRelationGroup.getDocumentRelationGroupsForTopOfDocumentV2(
      this.portalId,
      hiveId,
      fullName
    );
  }
  getLocationsWithRelations(hiveId: string, fullName: string) {
    return this.client.documentRelationGroup.getLocationsWithRelations(
      this.portalId,
      hiveId,
      fullName
    );
  }

  documentHasParagraphZoom(documentId: string) {
    return this.client.paragraphZoom.documentHasParagraphZoom(
      this.portalId,
      documentId
    );
  }

  getParagraphZoom(
    familyHiveId: string,
    familyName: string,
    paragraphId: string
  ) {
    return this.client.paragraphZoom.getParagraphZoom(
      this.portalId,
      familyHiveId,
      familyName,
      paragraphId
    );
  }

  getDocumentSection(hiveId: string, fullName: string, sectionName: string) {
    return this.client.document.getDocumentSection(
      this.portalId,
      "primaryVariant",
      sectionName,
      hiveId,
      fullName
    );
  }
  getDocumentSections(hiveId: string, fullName: string, sectionIds: string[]) {
    return this.client.document.getDocumentSections(
      this.portalId,
      "primaryVariant",
      sectionIds,
      hiveId,
      fullName
    );
  }

  getMergeDocument(hiveId: string, fullName: string) {
    return this.client.mergeDocument.getMergeDocuments(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getToc(hiveId: string, fullName: string) {
    return this.client.document.getDocumentTableOfContent(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getMyAssetCollections(
    options?: AssetCollectionGetAssetCollectionsForCurrentUserOptionalParams
  ) {
    return this.client.assetCollection.getAssetCollectionsForCurrentUser(
      this.portalId,
      options
    );
  }

  search(request: SearchRequestServer, headers?: Record<string, string>) {
    return this.client.search.search(this.portalId, request, {
      requestOptions: {
        customHeaders: headers
      }
    });
  }

  getSearchPreviews() {
    return this.client.searchPreview.getSearchPreviews(this.portalId);
  }

  getSearchViews() {
    return this.client.search.getViews(this.portalId);
  }

  addDocumentsToNewAssetCollection({
    assetCollectionTitle,
    referenceNumber,
    documents
  }: {
    assetCollectionTitle: string;
    referenceNumber: string;
    documents: AssetCollectionDocumentAdditionModel[];
  }) {
    return this.client.assetCollection.addDocumentsToNewCollection(
      this.portalId,
      {
        title: assetCollectionTitle,
        referenceNumber,
        documents
      }
    );
  }

  addDocumentsToExistingAssetCollection({
    assetCollectionId,
    documents
  }: {
    assetCollectionId: string;
    documents: AssetCollectionDocumentAdditionModel[];
  }) {
    return this.client.assetCollection.addDocumentsToExistingCollection(
      this.portalId,
      assetCollectionId,
      documents
    );
  }

  addHtmlPassageToExistingAssetCollection({
    assetCollectionId,
    documentId,
    revisionNumber,
    passage
  }: {
    assetCollectionId: string;
    documentId: string;
    revisionNumber: number;
    passage: FlowQuoteModel;
  }) {
    return this.client.assetCollection.postQuoteAssetToExistingCollection(
      this.portalId,
      assetCollectionId,
      documentId,
      revisionNumber,
      passage
    );
  }

  addPdfPassageToExistingAssetCollection({
    assetCollectionId,
    documentId,
    revisionNumber,
    passage
  }: {
    assetCollectionId: string;
    documentId: string;
    revisionNumber: number;
    passage: GeometricSelectionModel;
  }) {
    return this.client.assetCollection.postPdfQuoteAssetToExistingCollection(
      this.portalId,
      assetCollectionId,
      documentId,
      revisionNumber,
      passage
    );
  }

  addHtmlPassageToNewAssetCollection({
    assetCollectionTitle,
    referenceNumber,
    documentId,
    revisionNumber,
    passage
  }: {
    assetCollectionTitle: string;
    referenceNumber: string;
    documentId: string;
    revisionNumber: number;
    passage: FlowQuoteModel;
  }) {
    return this.client.assetCollection.postQuoteAssetToNewCollection(
      this.portalId,
      documentId,
      revisionNumber,
      { title: assetCollectionTitle, referenceNumber, quote: passage }
    );
  }

  addPdfPassageToNewAssetCollection({
    assetCollectionTitle,
    referenceNumber,
    documentId,
    revisionNumber,
    passage
  }: {
    assetCollectionTitle: string;
    referenceNumber: string;
    documentId: string;
    revisionNumber: number;
    passage: GeometricSelectionModel;
  }) {
    return this.client.assetCollection.postPdfQuoteAssetToNewCollection(
      this.portalId,
      documentId,
      revisionNumber,
      { title: assetCollectionTitle, referenceNumber, quote: passage }
    );
  }

  getAssetCollection(assetCollectionId: string) {
    return this.client.assetCollection.getAssetCollectionById(
      this.portalId,
      assetCollectionId
    );
  }

  getAssetCollectionTemplate(assetCollectionTemplateId: string) {
    return this.client.assetCollectionTemplate.getAssetCollectionTemplateById(
      this.portalId,
      assetCollectionTemplateId
    );
  }

  getArchivedAssetCollectionTemplate(assetCollectionTemplateId: string) {
    return this.client.assetCollectionTemplate.getArchivedAssetCollectionTemplateById(
      this.portalId,
      assetCollectionTemplateId
    );
  }

  getAssetCollectionItem(assetCollectionId: string, assetItemId: string) {
    return this.client.assetCollectionItem.getNode(
      this.portalId,
      assetCollectionId,
      assetItemId
    );
  }

  getAssetCollectionTemplateItem(
    assetCollectionTemplateId: string,
    assetItemId: string
  ) {
    return this.client.assetCollectionTemplate.getNode(
      this.portalId,
      assetCollectionTemplateId,
      assetItemId
    );
  }

  getSubscriptionsForDocument(hiveId: string, fullName: string) {
    return this.client.documentVersionSubscription.getSubscriptionsForDocument(
      this.portalId,
      hiveId,
      fullName
    );
  }

  createSubscriptionForDocument(
    model: CreateOrUpdateDocumentVersionSubscriptionModel
  ) {
    return this.client.documentVersionSubscription.createOrUpdate(
      this.portalId,
      model
    );
  }

  deleteSubscriptionsForDocument(hiveId: string, fullName: string) {
    return this.client.documentVersionSubscription.deleteByCurrentUser(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getNextDocumentVersion(hiveId: string, fullName: string) {
    return this.client.documentVersionSubscription.getNextDocumentVersionFullName(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getEffectiveVersion(hiveId: string, fullName: string) {
    return this.client.documentVersion.getEffectiveVersion(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getPortalConfigurationPortalProperties() {
    return this.client.portal.getPortalProperties(this.portalId);
  }

  getAnnotationTarget(contextId: string, targetId: string) {
    return this.client.annotation.getAnnotationTarget(
      this.portalId,
      contextId,
      targetId
    );
  }

  getAnnotations(
    contextId: string,
    targetId: string,
    type: AnnotationTargetType
  ) {
    // we have separate endpoints to return each type of annotations
    // in order to have autorest serialization behave correctly
    // couldn't get autorest to work with polymorphic lists
    switch (type) {
      case "html":
        return this.client.annotation.getHtmlAnnotations(
          this.portalId,
          contextId,
          targetId
        );
      case "pdf":
        return this.client.annotation.getPdfAnnotations(
          this.portalId,
          contextId,
          targetId
        );
      default:
        throw Error(
          "Unknown annotation type, cant find endpoint to call. Something is wrong with code and we shouldn't end up here"
        );
    }
  }

  saveAnnotation({ contextId, annotation }: UpdateAnnotationArguments) {
    if (isPdfAnnotationModel(annotation))
      return this.client.annotation.putPdfAnnotation(
        this.portalId,
        contextId,
        annotation.id,
        annotation
      );
    if (isHtmlAnnotationModel(annotation))
      return this.client.annotation.putAnnotation(
        this.portalId,
        contextId,
        annotation.id,
        annotation
      );
    throw Error(
      "Unknown annotation type, cant find endpoint to call. Something is wrong with code and we shouldn't end up here"
    );
  }

  removeAnnotation({ contextId, annotation }: UpdateAnnotationArguments) {
    return this.client.annotation.deleteAnnotation(
      this.portalId,
      contextId,
      annotation.id
    );
  }

  addAnnotation({ contextId, targetId, annotation }: AddAnnotationArguments) {
    if (isPdfNewAnnotationDTO(annotation))
      return this.client.annotation.postPdfAnnotation(
        this.portalId,
        contextId,
        targetId,
        annotation
      );
    if (isHtmlNewAnnotationDTO(annotation))
      return this.client.annotation.postHtmlAnnotation(
        this.portalId,
        contextId,
        targetId,
        annotation
      );
    throw Error(
      "Unknown annotation type, cant find endpoint to call. Something is wrong with code and we shouldn't end up here"
    );
  }

  createDocumentLink({
    ticketInfo,
    hiveId,
    fullName
  }: {
    ticketInfo: TicketInfo;
    hiveId: string;
    fullName: string;
  }) {
    return this.client.ticket.createDocumentLink(
      hiveId,
      fullName,
      this.portalId,
      ticketInfo
    );
  }
  sendDocumentLink({
    documentLinkInfo,
    hiveId,
    fullName
  }: {
    documentLinkInfo: DocumentLinkInfo;
    hiveId: string;
    fullName: string;
  }) {
    return this.client.email.sendDocumentLink(
      hiveId,
      fullName,
      this.portalId,
      documentLinkInfo
    );
  }

  getChangedSections(hiveId: string, fullName: string) {
    return this.client.documentSection.getChangedSections(
      this.portalId,
      hiveId,
      fullName
    );
  }

  getAllDocumentSectionsTreeStructure(hiveId: string, fullName: string) {
    return this.client.documentSection.getAllDocumentSectionsTreeStructure(
      this.portalId,
      hiveId,
      fullName
    );
  }

  setProfileEmail(email: string) {
    return this.client.userProfile.setProfileEmail(this.portalId, email);
  }

  getFeatureFlag(name: string) {
    return this.client.featureFlag.getFeatureFlag(name);
  }

  getDocumentLegalStatus(documentId: string) {
    return this.client.legalStatus.getDocumentLegalStatus(
      this.portalId,
      documentId
    );
  }

  getConfigurations() {
    return this.client.aiAssistant.getConfigurations(this.portalId);
  }

  IsDkLaw(hiveId: string, fullName: string) {
    return this.client.legalAttributes.isDkLaw(this.portalId, hiveId, fullName);
  }
}

export function createPortalApiAccess(
  portalId: string,
  isAuthenticated: boolean,
  ticketId?: string
) {
  return new PortalApiAccess(
    new SchultzRhinestonePortalWeb("", {
      endpoint: window.location.origin,
      noRetryPolicy: true,
      requestPolicyFactories: portalWebRequestPolicies(
        isAuthenticated,
        ticketId
      )
    }),
    portalId,
    ticketId
  );
}

interface AspNetWebApiErrorMessage {
  Message: string;
  ExceptionMessage?: string;
  ExceptionType?: string;
  StackTrace?: string;
}

/**
 * Safely attempts to parse error.message as an AspNetWebApiErrorMessage
 *
 * If portal.web api is run locally with custom errors off, it will parse any exception and StackTraced based properties
 *
 * @param restError
 */
export function buildDefaultPortalApiErrorMessage(restError: RestError) {
  try {
    const {
      Message,
      ExceptionMessage,
      ExceptionType,
      StackTrace
    }: AspNetWebApiErrorMessage = JSON.parse(restError.message);

    return `${Message}${
      ExceptionMessage ? `\nExceptionMessage: ${ExceptionMessage}` : ""
    }${ExceptionType ? `\nExceptionType: ${ExceptionType}` : ""}${
      StackTrace ? `\nStackTrace: ${StackTrace}` : ""
    }`;
  } catch (error) {
    console.warn(
      "Error parsing error message property as Json. Original message property is:",
      restError.message,
      "parsing error:",
      error
    );
    return restError.message || "unknown error";
  }
}

export function getCorrelationIdentifier(restError: RestError) {
  return restError.response?.headers?.get("x-correlationidentifier");
}

export enum RelationDirection {
  /**
   * forward means relations from document to other document (Document is source)
   */
  Forward = "forward",
  /**
   * reverse means relations pointing to document (Document is target)
   */
  Reverse = "reverse"
}

/**
 * type defined to allow generic endpoint to get annotations.
 *
 * Used for type switching
 */
export type AnnotationTargetType = "pdf" | "html";

export interface AddAnnotationArguments {
  contextId: string;
  targetId: string;
  annotation: HtmlNewAnnotationDTO | PdfNewAnnotationDTO;
}

export interface UpdateAnnotationArguments {
  contextId: string;
  annotation: AnnotationModel;
}

function isPdfNewAnnotationDTO(
  model: HtmlNewAnnotationDTO | PdfNewAnnotationDTO
): model is PdfNewAnnotationDTO {
  return (model as PdfNewAnnotationDTO).startX !== undefined;
}

function isHtmlNewAnnotationDTO(
  model: HtmlNewAnnotationDTO | PdfNewAnnotationDTO
): model is HtmlNewAnnotationDTO {
  return (model as HtmlNewAnnotationDTO).startOffset !== undefined;
}

function isPdfAnnotationModel(
  model: AnnotationModel
): model is PdfAnnotationModel {
  return (model as PdfAnnotationModel).startX !== undefined;
}

function isHtmlAnnotationModel(
  model: AnnotationModel
): model is HtmlAnnotationModel {
  return (model as HtmlAnnotationModel).startOffset !== undefined;
}
