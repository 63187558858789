/* eslint-disable complexity */
import * as React from "react";
import { useState } from "react";
import { useDocumentInfo } from "../document-context";
import {
  getDocumentUrl,
  useCurrentDocumentPageParameters
} from "../document-page/document-page-helpers";
import { VersionValues } from "./CreateLink/RadioButtonField";
import { CreateLinkFormProps } from "./CreateLink/TypedForm";
import Form from "./SendDocumentLinkForm";
import {
  DocumentVersionOption,
  useCreateDocumentLink,
  useSendDocumentLink
} from "./send-document-link-helpers";
import { SendLinkFormProps } from "./SendLink/TypedForm";
import { useIsProfileMissingEmail } from "../../account/profile-email-required/use-is-profile-missing-email";
import { useUserProfile } from "../../user/use-user-profile";
import { usePortalProperty } from "../use-portal-property";

interface SendDocumentLinkFormProps {
  isModalShown: boolean;
  setIsModalShown: (isShown: boolean) => void;
}

export const SendDocumentLinkFormContainer: React.FC<SendDocumentLinkFormProps> =
  ({ isModalShown, setIsModalShown }) => {
    const [createLinkData, setCreateLinkData] =
      React.useState<CreateLinkFormProps | null>(null);

    const { mutateAsync: sendDocumentLink, isLoading: isSendingLink } =
      useSendDocumentLink();
    const defaultValues = {
      defaultPeriodYear: 2,
      defaultPeriodMonth: 11,
      defaultPeriodDay: 31,
      version: "Newest" as VersionValues
    };
    const maxValues = {
      maxPeriodYear: 2,
      maxPeriodMonth: 11,
      maxPeriodDay: 31
    };

    const { value: useTickets } = usePortalProperty<boolean>(
      "UseAdvancedSendLinkForm"
    );
    const [documentLink, setDocumentLink] = useState<string | null>(null);
    const { mutateAsync: createDocumentLink, isLoading: isCreatingLink } =
      useCreateDocumentLink();
    const { hiveId, fullName } = useDocumentInfo();

    const createLinkParams = createLinkData && {
      expirationYears:
        +createLinkData.periodYear ?? defaultValues.defaultPeriodYear,
      expirationMonths:
        +createLinkData.periodMonth ?? defaultValues.defaultPeriodMonth,
      expirationDays:
        +createLinkData.periodDay ?? defaultValues.defaultPeriodDay,
      documentVersionOption:
        (createLinkData.version ?? defaultValues.version) === "Actual"
          ? DocumentVersionOption.Exact
          : DocumentVersionOption.Effective
    };
    const { bookmark } = useCurrentDocumentPageParameters();
    const { data: profile } = useUserProfile();
    const isProfileMissingEmail = useIsProfileMissingEmail();

    const handleSendLinkLoad = async () => {
      const documentRelativeUrl = getDocumentUrl({
        hiveId,
        fullName,
        bookmark: bookmark,
        anonymous: true
      });
      const url = new URL(documentRelativeUrl, window.location.origin);
      if (useTickets && createLinkParams) {
        createDocumentLink({
          ticketInfo: {
            originalLink: url.toString(),
            ...createLinkParams
          }
        }).then(res => {
          setDocumentLink(res);
        });
      } else {
        setDocumentLink(url.toString());
      }
    };

    const handleClose = () => {
      setDocumentLink(null);
      setCreateLinkData(null);
      setIsModalShown(false);
    };

    const handleSubmit = (data: SendLinkFormProps) => {
      if (documentLink) {
        sendDocumentLink({
          documentLinkInfo: {
            fromEmail: data.fromEmail || "noreply@noreply.org",
            fromName: data.fromName,
            toEmail: data.toEmail,
            toName: data.toName,
            message: data.message,
            link: documentLink,
            language: "da"
          }
        }).then(() => {
          handleClose();
        });
      }
    };

    const showCreateLinkForm = !createLinkData && useTickets;
    return (
      <Form
        isModalShown={isModalShown}
        showCreateLinkForm={showCreateLinkForm}
        handleClose={handleClose}
        isSendingLink={isSendingLink}
        isCreatingLink={isCreatingLink}
        handleCreateLinkSubmit={setCreateLinkData}
        handleSendLinkLoad={handleSendLinkLoad}
        handleSendLinkSubmit={handleSubmit}
        defaultValues={defaultValues}
        maxValues={maxValues}
        link={documentLink}
        userDisplayName={profile?.displayName}
        userEmails={profile?.emailAddresses}
        isProfileMissingEmail={isProfileMissingEmail}
      />
    );
  };
