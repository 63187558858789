import React from "react";
import { useSectionsWithRelationsForDocument as useDocumentRelationLocations } from "../../../content/shared/document-relation-groups/use-document-relation-groups";
import { useDocumentInfo } from "../../../document-context";
import { RelationsActionButton } from "./RelationsActionButton";
import { usePortalProperty } from "../../../use-portal-property";
import { QueryStatus } from "react-query";

// This component  show the relations action only if the document/top-of-document has relations.
// Fetching relations from API  potentially be slow if the document has a lot of relations.
const RelationsAction: React.FC<{ status: QueryStatus }> = ({ status }) => {
  const { hiveId, fullName } = useDocumentInfo();

  const { data: documentRelationLocations, status: relationLocationStatus } =
    useDocumentRelationLocations(hiveId, fullName);

  // Portal property configures if we should show all relations on top of document,
  // or only those with a blank bookmark (i.e. top-of-document relations)
  const { value: showAllRelationsProperty, status: showAllRelationsStatus } =
    usePortalProperty<boolean>("ShowAllRelations");

  const statuses = [status, relationLocationStatus, showAllRelationsStatus];

  return showAllRelationsProperty ? (
    <RelationsActionButton
      hasRelations={documentRelationLocations?.documentHasRelations}
      status={statuses}
    />
  ) : (
    <RelationsActionButton
      hasRelations={
        documentRelationLocations?.documentHasRelationsToTopOfDocument
      }
      status={statuses}
    />
  );
};

export default RelationsAction;
