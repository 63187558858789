import { Collapse, Divider, List } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import * as React from "react";
import { Translate } from "react-localize-redux";
import { ListSelectorItem } from ".";
import { translationKeys } from "../../../gen/translation-keys";

export interface ListSelectorProps {
  actions?: React.ReactNode;
  amount?: number;
}
export const ListSelector: React.FC<ListSelectorProps> = ({
  actions,
  amount = 3,
  children
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement[];
  const hiddenItems = childrenArray.slice(amount);

  return (
    <List>
      {/* Items always shown */}
      {childrenArray.slice(0, hiddenItems.length === 1 ? amount + 1 : amount)}

      {/* "Show more" icon button */}
      <Collapse in={!expanded && hiddenItems.length > 1}>
        <ListSelectorItem
          primary={
            <>
              <Translate id={translationKeys.document_page.show_more} />{" "}
              <span>({childrenArray.length - amount})</span>
            </>
          }
          icon={<AddIcon />}
          onClick={() => setExpanded(true)}
          variant="default"
        />
      </Collapse>

      {/* Items only shown when expanded */}
      <Collapse in={expanded}>{hiddenItems}</Collapse>

      {actions && (
        <>
          <Divider />
          {actions}
        </>
      )}
    </List>
  );
};
