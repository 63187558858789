import { useApiTicket } from "../../../api-access/use-api-ticket";
import { useUserContext } from "../../../user/use-user-context";

export function useShouldShowExportDocumentAction(
  getIsAuthenticated = useUserContext,
  getApiTicket = useApiTicket
) {
  const { isAuthenticated } = getIsAuthenticated();
  const ticketId = getApiTicket();

  // If user is not authenticated, and there is a ticket, then we should not show the export action
  // when this is the case, the user does not have anonymous access to the document and therefore
  // cannot export it. The call to print api requires direct access to the document without ticket.
  return isAuthenticated || ticketId === null || ticketId === undefined;
}
