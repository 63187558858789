import * as React from "react";
import { InlineNote } from "../document-components/InlineNote";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import ExcludeFromAnnotation from "../../../annotations/ExcludeFromAnnotation";

export default function processInlineNotes(
  hideNotes?: boolean
): HtmlToReactProcessingInstructions {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => {
      const className = node.attribs?.class;
      const nodeName = node.name;
      return (
        (nodeName === "note" && ["footnote", "eu"].includes(className)) ||
        className === "inline-note" ||
        (nodeName === "span" && className === "note")
      );
    },
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      const noteId = node.attribs.id;
      const noteIndex = node.attribs["footnote-index"] ?? node.attribs?.title;
      return hideNotes ? null : (
        <ExcludeFromAnnotation>
          <InlineNote noteId={noteId} noteIndex={noteIndex} key={noteId}>
            {children}
          </InlineNote>
        </ExcludeFromAnnotation>
      );
    }
  };
}
