import { makeStyles } from "@material-ui/core";
import * as React from "react";

interface TypingAnimationProps {
  isStreaming: boolean;
}

const useStyles = makeStyles({
  container: {
    height: "20px"
  },
  typing: {
    width: "5em",
    height: "2em",
    position: "relative",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto"
  },
  dot: {
    width: "8px",
    height: "8px",
    margin: "0 4px",
    backgroundColor: "#8d8c91",
    borderRadius: "50%",
    opacity: 0,
    animation: `$loadingFade 1s infinite`,
    "&:nth-of-type(1)": {
      animationDelay: "0s"
    },
    "&:nth-of-type(2)": {
      animationDelay: "0.2s"
    },
    "&:nth-of-type(3)": {
      animationDelay: "0.4s"
    }
  },
  "@keyframes loadingFade": {
    "0%": {
      opacity: 0
    },
    "50%": {
      opacity: 0.8
    },
    "100%": {
      opacity: 0
    }
  }
});

const TypingAnimation: React.FunctionComponent<TypingAnimationProps> = ({
  isStreaming
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {isStreaming && (
        <div className={classes.typing}>
          <div className={classes.dot}></div>
          <div className={classes.dot}></div>
          <div className={classes.dot}></div>
        </div>
      )}
    </div>
  );
};

export default TypingAnimation;
