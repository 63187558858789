import React from "react";
import { TocItemData } from "./TocTreeView";
import { makeStyles } from "@material-ui/core";
import TocElement from "./TocElement";

const useStyles = makeStyles(theme => ({
  groups: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    marginLeft: 12
  }
}));
interface TocElementListProps {
  children: TocItemData[];
  expandedNodeIds?: string[];
  selectedNodeIds?: string[];
  onSelectedNodeIdChange?: (nodeId: string) => void;
}
const TocElementList: React.FC<TocElementListProps> = ({
  children,
  expandedNodeIds,
  selectedNodeIds,
  onSelectedNodeIdChange
}) => {
  const classes = useStyles();
  return (
    <ul className={classes.groups}>
      {children &&
        children.map(child => {
          const expanded =
            expandedNodeIds?.includes(String(child.nodeId)) || false;
          const selected =
            selectedNodeIds?.includes(String(child.nodeId)) || false;
          return (
            <TocElement
              key={String(child.nodeId)}
              nodeId={child.nodeId}
              heading={child.heading}
              children={child.children}
              target={child.target}
              expandedNodeIds={expandedNodeIds}
              selectedNodeIds={selectedNodeIds}
              onSelectedNodeIdChange={onSelectedNodeIdChange}
              expanded={expanded}
              selected={selected}
            />
          );
        })}
    </ul>
  );
};

export default React.memo(TocElementList, (prevProps, nextProps) => {
  return (
    prevProps.children === nextProps.children &&
    prevProps.expandedNodeIds === nextProps.expandedNodeIds &&
    prevProps.selectedNodeIds === nextProps.selectedNodeIds
  );
});
