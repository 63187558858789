import { withStyles } from "@material-ui/core";
import { headerExtensionHeight } from "../ui-components/layouts/HeaderExtension";

/**
 * Exposing height of legacy app menu bar
 */
export const LegacyNavBarHeightInPixels = 64;

/**
 * This is used to do some global css overrides, which is applied by legacy styling solution
 * and conflicts with our new Material-ui design system
 *
 * Maybe when upgrading Material-UI we can switch to ScopedCssBaseline which looks like it will be available in next major version (5.X)
 */
export const LegacyGlobalCssOverrides = withStyles({
  // @global is handled by jss-plugin-global.
  "@global": {
    html: {
      // This makes sure scrolling is offset by the top navbar/fixed position headers
      // This is not really are Legacy app thing but something we always want to have
      // so should probably be moved to a more appropriate place than this component here
      scrollPaddingTop: `${
        LegacyNavBarHeightInPixels + headerExtensionHeight + 10
      }px`
    },
    body: {
      // we need to reset height which is set to 100% by legacy angular-material-ui library
      // otherwise Material-ui theme does not work properly since palette.background is applied at body element
      // which only have viewport height when height is 100% resulting in clipped body styling
      // default browser height for html and body ensures body height is set to content height and not viewport height
      height: "auto"
    },
    // Since the global-menu lives in the old solution and overlays by 64px, we need to push some fixed/sticky components
    // by the height of the global-menu. This can be moved to our theme once the global-menu has been migrated as well.
    "div.document-toolbar, div.left-pane, div.right-pane": {
      top: `${LegacyNavBarHeightInPixels}px`,
      maxHeight: `calc(100vh - ${LegacyNavBarHeightInPixels}px)`
    }
  }
})(() => null);
