import React from "react";
import DocumentBaseTile from "./DocumentBaseTile";
import DocumentListItemsContainer from "./list-item/DocumentListItemsContainer";

interface DocumentBaseTileContainerProps {
  productCode: string;
  taxonomyName: string;
  taxonKey?: string;
  siblingCount?: number;
}
const DocumentBaseTileContainer: React.FC<DocumentBaseTileContainerProps> = ({
  productCode,
  taxonomyName,
  taxonKey,
  siblingCount
}) => {
  return (
    <DocumentBaseTile siblingCount={siblingCount}>
      <DocumentListItemsContainer
        productCode={productCode}
        taxonomyName={taxonomyName}
        taxonKey={taxonKey}
      />
    </DocumentBaseTile>
    //   )
  );
};

export default DocumentBaseTileContainer;
