import {
  PdfAnnotationModel,
  PdfNewAnnotationDTO
} from "@rhinestone/portal-web-api";
import { Annotation, AnnotationState } from "../annotation-state";
import { useAnnotationState } from "../use-annotation-state";

export type PdfAnnotationState = AnnotationState<
  PdfAnnotationModel,
  PdfNewAnnotationDTO
>;
export type PdfAnnotation = Annotation<PdfAnnotationModel>;

export function usePdfAnnotationState(
  annotationTargetId: string | undefined
): PdfAnnotationState {
  return useAnnotationState<PdfAnnotationModel, PdfNewAnnotationDTO>(
    annotationTargetId,
    "pdf"
  );
}
