import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { FC } from "react";

interface PreviousPromptProps {
  prompt: string;
}

const useStyles = makeStyles(theme => ({
  prompt: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    fontStyle: "italic"
  }
}));

const PreviousPrompt: FC<PreviousPromptProps> = ({ prompt }) => {
  const classes = useStyles();

  return prompt ? <div className={classes.prompt}>{prompt}</div> : null;
};

export default PreviousPrompt;
