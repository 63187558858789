import * as React from "react";
import { SectionHeader } from "../document-components/SectionHeader";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import { isCollapsibleSection } from "./process-section-helpers";
import { FoldableSectionHeader } from "../document-components/FoldableSectionHeader";

const processSectionHeaderTags = (): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => {
      return node.name === "h1" && node.parent?.name === "section";
    },
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      return parentIsCollapsibleSection(node) ? (
        <FoldableSectionHeader
          className={node.attribs.class}
          key={index}
          sectionId={node.parent?.attribs.id}
        >
          {children}
        </FoldableSectionHeader>
      ) : (
        <SectionHeader
          className={node.attribs.class}
          key={index}
          sectionId={node.parent?.attribs.id}
        >
          {children}
        </SectionHeader>
      );
    }
  };
};

function parentIsCollapsibleSection(node: HtmlToReactNode) {
  return node.parent ? isCollapsibleSection(node.parent) : false;
}

export default processSectionHeaderTags;
