import * as React from "react";
import { QueryStatus } from "react-query";
import { LoadingProgress } from "../LoadingProgress";

interface ApiFetchIndicatorProps {
  status: QueryStatus | QueryStatus[];
  errorComponent?: React.ReactNode;
  idleComponent?: React.ReactNode;
  size?: string | number | undefined;
  loadingIndicator?: React.ReactNode;
}

/**
 * Generalized component to do common decision making what to do based on react query status
 */
export const ApiFetchIndicator: React.FC<ApiFetchIndicatorProps> = ({
  status,
  errorComponent = null,
  idleComponent = null,
  children,
  size = undefined,
  loadingIndicator
}) => {
  // using object lookup to lower cyclomatic complexity and increase readability
  // https://ultimatecourses.com/blog/deprecating-the-switch-statement-for-object-literals
  type statusComponent = { precedence: number; component: React.ReactNode };
  const statusBasedRender: Record<QueryStatus, statusComponent> = {
    success: { precedence: 0, component: children },
    loading: {
      precedence: 1,
      component: loadingIndicator || <LoadingProgress size={size} />
    },
    idle: { precedence: 2, component: idleComponent },
    error: { precedence: 3, component: errorComponent }
  };

  const compareStatusComponent = (
    left: statusComponent,
    right: statusComponent
  ) => ((left?.precedence ?? -1) > (right?.precedence ?? -1) ? left : right);

  // When showing status indicator based on multiple queries,
  // the most "important" status should take precedence
  const statusToRender =
    status instanceof Array
      ? status.map(s => statusBasedRender[s]).reduce(compareStatusComponent)
      : statusBasedRender[status];

  return <>{statusToRender.component}</>;
};
