import * as React from "react";
import { FeatureFlag as FlagNames, useFeatureFlag } from "./use-feature-flag";
import { LoadingProgress } from "../../ui-components/LoadingProgress";

type FeatureFlagProps = {
  name: FlagNames;
  children: React.ReactNode | ((isEnabled: boolean) => React.ReactNode);
};

const FeatureFlag: React.FC<FeatureFlagProps> = ({ name, children }) => {
  const { isEnabled, isLoading, isError } = useFeatureFlag(name);
  const isChildrenFunction = typeof children === "function";

  return (
    <>
      {isLoading && <LoadingProgress />}
      {!isError &&
        isEnabled &&
        (isChildrenFunction ? children(isEnabled) : children)}
    </>
  );
};

export default FeatureFlag;
