import * as React from "react";
import { FullScreenModalDialog } from "../../ui-components/ModalDialog";
import { ApiFetchIndicator } from "../../ui-components/ApiFetchIndicator";
import { Box, Card, makeStyles } from "@material-ui/core";
import ChangedSection from "./ChangedSection";
import { useGetChangedSections } from "./use-get-changed-sections";
import ParagraphZoom from "../paragraph-zoom/ParagraphZoom";
import { FullScreenDialogTitle } from "../../ui-components/ModalDialog/FullScreenDialogTitle";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { Scrollbar } from "../../ui-components/Scrollbar";

interface LawZoomModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  topSection: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    backgroundColor: theme.alternativeBackgroundColor,
    maxHeight: "20%"
  },
  pZoom: {
    flexGrow: 1
  },
  card: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2)
  },
  sectionsList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(1)
  }
}));

const LawZoomModal: React.FunctionComponent<LawZoomModalProps> = ({
  open,
  onClose
}) => {
  const classes = useStyles();
  const { data: changedSections, status } = useGetChangedSections();
  const [currentParagraphId, setCurrentParagraphId] = React.useState<
    string | undefined
  >(undefined);

  React.useEffect(() => {
    setCurrentParagraphId(changedSections?.[0]?.sectionId);
  }, [changedSections]);

  return (
    <FullScreenModalDialog
      open={open}
      onClose={onClose}
      title={
        <FullScreenDialogTitle
          left={<Translate id={translationKeys.law_zoom.title} />}
          center={<Translate id={translationKeys.law_zoom.changed_sections} />}
        />
      }
    >
      <div className={classes.wrapper}>
        <ApiFetchIndicator status={status}>
          <Box className={classes.topSection}>
            <Card elevation={2} variant="elevation" className={classes.card}>
              <Scrollbar>
                <div className={classes.sectionsList}>
                  {changedSections?.map((section, index) => (
                    <ChangedSection
                      key={index}
                      onClick={setCurrentParagraphId}
                      isActive={currentParagraphId === section.sectionId}
                      sectionId={section.sectionId}
                      sectionTitle={section.sectionTitle}
                    />
                  ))}
                </div>
              </Scrollbar>
            </Card>
          </Box>
        </ApiFetchIndicator>
        <Box className={classes.pZoom}>
          <ParagraphZoom paragraphId={currentParagraphId} />
        </Box>
      </div>
    </FullScreenModalDialog>
  );
};

export default LawZoomModal;
