import { useEffect, useState } from "react";

export function useIsTouchScreen() {
  const [hasTouchScreen, setHasTouchScreen] = useState(false);

  useEffect(() => {
    setHasTouchScreen(deviceHasTouchScreenOnly());
  }, []);

  return hasTouchScreen;
}

function deviceHasTouchScreenOnly() {
  const deviceHasAMouseSupport = matchMedia("(pointer:fine)").matches;
  // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
  const deviceHasTouchScreen =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;
  return deviceHasTouchScreen && !deviceHasAMouseSupport;
}
