import React from "react";
import { Link as MuiLink } from "@material-ui/core";
import { Link as RhinestoneLink } from "../../ui-components/Link";

export interface TimelineLinkProps {
  className?: string;
  linkTo?: string;
  linkOnClick?: () => void;
  openLinksInNewTab?: boolean;
  "aria-label"?: string;
}

export const TimelineLink: React.FC<TimelineLinkProps> = props => {
  const linkProps = {
    href: "#",
    className: props.className,
    target: props.openLinksInNewTab ? "_blank" : "",
    "aria-label": props["aria-label"]
  };
  return props.linkTo ? (
    <RhinestoneLink color="inherit" {...linkProps} to={props.linkTo || ""}>
      {props.children}
    </RhinestoneLink>
  ) : (
    <MuiLink color="inherit" {...linkProps} onClick={props.linkOnClick}>
      {props.children}
    </MuiLink>
  );
};
