export function generateAssetCollectionLink(
  isTemplate: boolean,
  isArchived: boolean,
  assetCollectionId: string | undefined,
  assetCollectionTemplateId: string | undefined
) {
  if (isTemplate) {
    return isArchived
      ? `/AssetCollection/EditArchivedTemplate/${assetCollectionTemplateId}`
      : `/AssetCollection/EditTemplate/${assetCollectionTemplateId}`;
  }

  return `/Samlinger/Rediger/${assetCollectionId}`;
}

export function generateAssetCollectionItemLink(
  isTemplate: boolean,
  assetCollectionId: string | undefined,
  assetCollectionTemplateId: string | undefined,
  assetItemId: string
): string {
  return isTemplate
    ? `/AssetCollectionTemplates(${assetCollectionTemplateId})/Node(${assetItemId})/Content`
    : `/AssetCollections(${assetCollectionId})/Node(${assetItemId})/Content`;
}
