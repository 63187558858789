import React from "react";
import { ApiFetchIndicator } from "../../ui-components/ApiFetchIndicator";
import { TimelineItemModel } from "./document-history-timeline-helpers";
import { useDocumentInfo } from "../document-context";
import DocumentHistoryTimelineItem from "./DocumentHistoryTimelineItem";
import { Timeline } from "../../ui-components/split-timeline";
import { QueryStatus } from "react-query";
import { BranchingHistoryTimeLineData } from "./BranchingHistoryTimelineData";
import { VersionBasedHistoryTimeLineData } from "./VersionBasedHistoryTimelineData";
import {
  DocumentPageConfigurationModel,
  RelatedTargetDocumentFieldSetsDto,
  TimelineStatusDto
} from "@rhinestone/portal-web-api";
import { useGetTargetRelationsByFamily } from "./use-get-target-relations-by-family";
import { TimelineConsolidationInfo } from "../consolidation-Information/TimelineConsolidationInfo/TimelineConsolidationInfo";
import {
  mapToRelationItemModel,
  useConsolidationAndPageConfig
} from "../preparatory-work/preparatory-work-helpers";
import { DanishLawHistoryTimelineContainer } from "./danish-law/DanishLawHistoryTimelineContainer";

interface DocumentHistoryTimelineContainerProps {
  timelineStatus: TimelineStatusDto;
}

const getToolTipComponent = (
  relatedTargetDocuments: RelatedTargetDocumentFieldSetsDto[] | undefined,
  sourceDocumentId: string,
  config: DocumentPageConfigurationModel | undefined
) => {
  const relatedTargetDocument = relatedTargetDocuments?.find(
    x => x.sourceDocumentId === sourceDocumentId
  );
  if (!relatedTargetDocument) return undefined;

  return () => (
    <TimelineConsolidationInfo
      data={mapToRelationItemModel(relatedTargetDocument?.targets, config).map(
        x => x.title
      )}
    />
  );
};

export const DocumentHistoryTimelineContainer: React.FC<DocumentHistoryTimelineContainerProps> =
  ({ timelineStatus: timelineRelationsStatus }) => {
    const { hiveId, fullName, familyName } = useDocumentInfo();

    const findInitialActiveItem = (items: TimelineItemModel[]) =>
      items.find(item => item.fullName === fullName);

    const pageConfig = useConsolidationAndPageConfig().pageConfiguration;

    const [relatedTargetDocuments] = useGetTargetRelationsByFamily(
      hiveId,
      fullName,
      familyName
    );

    const renderUi = (items: TimelineItemModel[], status: QueryStatus) => (
      <ApiFetchIndicator status={status}>
        {items && (
          <Timeline>
            {items.map((item, index) => (
              <DocumentHistoryTimelineItem
                key={`${item.documentId}`}
                item={item}
                index={index}
                initialActiveItem={findInitialActiveItem(items)}
                items={items}
                toolTipComponent={getToolTipComponent(
                  relatedTargetDocuments,
                  item.documentId,
                  pageConfig
                )}
              />
            ))}
          </Timeline>
        )}
      </ApiFetchIndicator>
    );

    // We have three apis to load timeline from
    // 1)  specific for danish law, based on document versions and special
    // requirements for status.

    // 2) is based on document versions where we expect documents is part of a family
    // this api is fast because we query by using the family name

    // 3) default is for timelines based on a "replace" logic where a configured
    // relationTypeCode is configured but documents are different and not versions
    // this endpoint is slow because it supports branching in the timeline
    // maybe in the future this will be fast as well and we will merge the endpoints into one

    // We use familyName to distinguish between which api to use
    // this might prove wrong in the future if documents that are not versioned needs a timeline
    // but at the same time is part of a family
    if (timelineRelationsStatus.isVersionedDkLaw)
      return (
        <DanishLawHistoryTimelineContainer
          fullName={fullName}
          hiveId={hiveId}
          familyName={familyName ?? ""}
        />
      );

    if (timelineRelationsStatus.isVersionedDocument)
      return (
        <VersionBasedHistoryTimeLineData
          hiveId={hiveId}
          fullName={fullName}
          familyName={familyName ?? ""}
        >
          {(items, status) => renderUi(items, status)}
        </VersionBasedHistoryTimeLineData>
      );

    return (
      <BranchingHistoryTimeLineData hiveId={hiveId} fullName={fullName}>
        {(items, status) => renderUi(items, status)}
      </BranchingHistoryTimeLineData>
    );
  };
