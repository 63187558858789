import React from "react";
import Box from "@material-ui/core/Box";
import { translationKeys } from "../../../../gen/translation-keys";
import { Translate } from "react-localize-redux";
export interface TimelineConsolidationInfoProps {
  data: string[] | undefined;
}

export const TimelineConsolidationInfo: React.FC<TimelineConsolidationInfoProps> =
  ({ data }) => {
    if (!data || !data.length) {
      return (
        <Box my={1}>
          <Translate
            id={translationKeys.document_page.consolidated_by_not_found}
          />
        </Box>
      );
    }

    return (
      <>
        <Box my={1} fontWeight={700}>
          <Translate id={translationKeys.document_page.consolidated_by} />
        </Box>
        {data.map((consolidationInfo, index) => (
          <Box my={1} key={index}>
            {consolidationInfo}
          </Box>
        ))}
      </>
    );
  };
