import React from "react";
import {
  usePortalApi,
  usePortalApiMutation
} from "../../api-access/use-portal-api";
import { useSnackbar } from "../../ui-components/Snackbar";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import {
  AssetCollectionDocumentAdditionModel,
  FlowQuoteModel,
  GeometricSelectionModel
} from "@rhinestone/portal-web-api";
import { Link } from "../../ui-components/Link";

export function useMyAssetCollection() {
  // for now we just get all asset collections
  // in old solution, top 3 was fetched initially by paging against the endpoint
  // (it takes a count property)
  // and then also all collections was retrieved.
  // Here we fetch all once and they are already sorted by the server.
  // If we end up having performance issues with this, we can introduce paging
  return usePortalApi(
    client => client.getMyAssetCollections(),
    ["getMyAssetCollections"],
    {
      // we need to let staleTime be 0 since asset collections can created from other pages in old design,
      // so we need to make sure they are always re-fetched
      // when query is re-evaluated
      // can be removed when all interactions with asset collections are migrated to new design
      staleTime: 0
    }
  ).data;
}

export function useAddDocumentToExistingAssetCollection() {
  const { enqueueSuccess } = useSnackbar();
  return usePortalApiMutation(
    client =>
      (variables: {
        assetCollectionId: string;
        documents: AssetCollectionDocumentAdditionModel[];
        assetCollectionTitle: string;
      }) =>
        client.addDocumentsToExistingAssetCollection(variables),
    [["getMyAssetCollections"]],
    {
      onSuccess: (data, { assetCollectionTitle, assetCollectionId }) => {
        enqueueSuccess(
          <SuccessAddedToAssetCollection
            title={assetCollectionTitle}
            assetCollectionId={assetCollectionId}
            successTranslationKey={translationKeys.assets.doc_added_success}
          />
        );
      }
    }
  );
}

export function useAddDocumentToNewAssetCollection() {
  const { enqueueSuccess } = useSnackbar();
  return usePortalApiMutation(
    client => client.addDocumentsToNewAssetCollection,
    [["getMyAssetCollections"]],
    {
      onSuccess: ({ assetCollectionId, title }) => {
        enqueueSuccess(
          <SuccessAddedToAssetCollection
            title={title}
            assetCollectionId={assetCollectionId}
            successTranslationKey={translationKeys.assets.doc_added_success}
          />
        );
      }
    }
  );
}

// adds current selection aka quote aka passage to existing asset collection
export function useAddPassageToExistingAssetCollection() {
  const { enqueueSuccess } = useSnackbar();
  return usePortalApiMutation(
    client =>
      (variables: {
        assetCollectionId: string;
        assetCollectionTitle: string;
        documentId: string;
        revisionNumber: number;
        passage: FlowQuoteModel | GeometricSelectionModel;
        isPdf: boolean;
      }) =>
        variables.isPdf
          ? client.addPdfPassageToExistingAssetCollection({
              ...variables,
              passage: variables.passage as GeometricSelectionModel
            })
          : client.addHtmlPassageToExistingAssetCollection({
              ...variables,
              passage: variables.passage as FlowQuoteModel
            }),
    [["getMyAssetCollections"]],
    {
      onSuccess: (data, { assetCollectionTitle, assetCollectionId }) => {
        enqueueSuccess(
          <SuccessAddedToAssetCollection
            title={assetCollectionTitle}
            assetCollectionId={assetCollectionId}
            successTranslationKey={translationKeys.assets.asset_added_success}
          />
        );
      }
    }
  );
}

// adds current selection aka quote aka passage to new asset collection
export function useAddPassageToNewAssetCollection() {
  const { enqueueSuccess } = useSnackbar();
  return usePortalApiMutation(
    client =>
      (variables: {
        assetCollectionTitle: string;
        referenceNumber: string;
        documentId: string;
        revisionNumber: number;
        passage: FlowQuoteModel | GeometricSelectionModel;
        isPdf: boolean;
      }) =>
        variables.isPdf
          ? client.addPdfPassageToNewAssetCollection({
              ...variables,
              passage: variables.passage as GeometricSelectionModel
            })
          : client.addHtmlPassageToNewAssetCollection({
              ...variables,
              passage: variables.passage as FlowQuoteModel
            }),
    [["getMyAssetCollections"]],
    {
      onSuccess: ({ assetCollectionId, title }) => {
        enqueueSuccess(
          <SuccessAddedToAssetCollection
            title={title}
            assetCollectionId={assetCollectionId}
            successTranslationKey={translationKeys.assets.asset_added_success}
          />
        );
      }
    }
  );
}

function SuccessAddedToAssetCollection({
  title,
  assetCollectionId,
  successTranslationKey
}: {
  title: string;
  assetCollectionId: string;
  successTranslationKey: string;
}) {
  return (
    <Translate>
      {({ translate }) => (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {String(translate(successTranslationKey))
            // in existing code the title is part of the translation file as data, but in new design we render a link instead
            // so "patching" translation temporarily to exclude the parameterized value
            // eslint-disable-next-line no-template-curly-in-string
            .replace("${title}", "")}{" "}
          <Link
            color="inherit"
            title={title}
            to={`/Samlinger/Rediger/${assetCollectionId}`}
          >
            {title}
          </Link>
        </span>
      )}
    </Translate>
  );
}
