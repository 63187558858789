import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { ModalDialog } from "../../ui-components/ModalDialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import OverlayLoader from "../../ui-components/LoadingProgress/OverlayLoader";

interface FormModalProps {
  isModalShown: boolean;
  setIsModalShown: (isShown: boolean) => void;
  isLoading: boolean;
}

const useStyles = makeStyles(theme => ({
  dialog: {
    minHeight: "300px"
  }
}));

const FormModal: React.FC<FormModalProps> = ({
  isModalShown,
  setIsModalShown,
  isLoading,
  children
}) => {
  const classes = useStyles();
  return (
    <ModalDialog
      title={
        <Translate
          id={translationKeys.document_page.send_document_link_dialog_title}
        />
      }
      open={isModalShown}
      onClose={() => setIsModalShown(false)}
      fullWidth={false}
      className={classes.dialog}
    >
      <OverlayLoader isLoading={isLoading}>{children}</OverlayLoader>
    </ModalDialog>
  );
};

export default FormModal;
