import * as React from "react";
import MaterialUiTreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TreeItemData } from ".";
import { makeStyles } from "@material-ui/core";
import { LazyLoadedTreeViewItem } from "./LazyLoadedTreeViewItem";

export type TreeViewProps = {
  items: TreeItemData[];
  selected?: string[];
};

const useStyles = makeStyles({
  root: {
    // Using global treeview style here to avoid using useStyles for every item component
    // since there can be many thousands of items in our document page relation groups
    "& .tree-view-item": {
      padding: "7px 0",
      minHeight: "40px"
    },
    "& .tree-view-item-loader": {
      minHeight: "40px",
      listStyle: "none"
    }
  }
});

// TODO: Why use a forwardRef here?
export const TreeView = React.forwardRef<HTMLDivElement, TreeViewProps>(
  ({ items, selected = [] }, ref) => {
    const styles = useStyles();

    return (
      <>
        <MaterialUiTreeView
          className={styles.root}
          ref={ref}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          selected={selected as any}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          multiSelect={false}
        >
          {items.map((i, index) => (
            // Using index until we find a way to have a business related id for each tree item
            <LazyLoadedTreeViewItem
              data={i}
              key={index}
            ></LazyLoadedTreeViewItem>
          ))}
        </MaterialUiTreeView>
      </>
    );
  }
);
