import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { Alert } from "../../../ui-components/Alert";

interface MissingSectionDataWarningProps {
  sectionTitle: string;
}

const MissingSectionDataWarning: React.FC<MissingSectionDataWarningProps> = ({
  sectionTitle
}) => {
  const title = (
    <Translate
      id={translationKeys.paragraph_zoom.tab_section_no_data_title}
      data={{ title: sectionTitle }}
    />
  );
  return <Alert title={title} severity="warning" variant="outlined" />;
};

export default MissingSectionDataWarning;
