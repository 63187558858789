import { useDocumentInfo } from "../document-context";
import { usePortalApi } from "../../api-access/use-portal-api";
import { RelationDirection } from "../../api-access/portal-api-access";
import { HardCodedFieldSetName } from "../document-fieldsets";

/**
 * This is hardcoded for now as it was in old solution. This could be considered to be made configurable in our DocumentPage configuration
 */
const InstanceRelationTypeCode = "INSTANS";

export function useGetInstanceRelationsData() {
  const { hiveId, fullName } = useDocumentInfo();
  const { data: reverseRelationsData, isFetching: reverseRelationsFetching } =
    usePortalApi(
      client =>
        client.getDirectionalDocumentRelationsByType(
          hiveId,
          fullName,
          [InstanceRelationTypeCode],
          HardCodedFieldSetName,
          RelationDirection.Reverse
        ),
      [
        "getDirectionalDocumentRelationsByType",
        hiveId,
        fullName,
        [InstanceRelationTypeCode],
        HardCodedFieldSetName,
        RelationDirection.Reverse
      ]
    );

  const reverseRelations = reverseRelationsData ?? [];

  const { data: forwardRelationsData, isFetching: forwardRelationsFetching } =
    usePortalApi(
      client =>
        client.getDirectionalDocumentRelationsByType(
          hiveId,
          fullName,
          [InstanceRelationTypeCode],
          HardCodedFieldSetName,
          RelationDirection.Forward
        ),
      [
        "getDirectionalDocumentRelationsByType",
        hiveId,
        fullName,
        [InstanceRelationTypeCode],
        HardCodedFieldSetName,
        RelationDirection.Forward
      ]
    );
  const forwardRelations = forwardRelationsData ?? [];
  const isFetching = reverseRelationsFetching || forwardRelationsFetching;
  return { reverseRelations, forwardRelations, isFetching };
}
