import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { NotFound } from "../../../api-access/http-status-codes";
import { PortalApiError } from "../../../api-access/use-portal-api";
import { Alert } from "../../../ui-components/Alert";
import { ParagraphZoomNotFound } from "./ParagraphZoomNotFound";

interface ParagraphZoomErrorProps {
  error: PortalApiError | null;
}

export const ParagraphZoomDataError: React.FC<ParagraphZoomErrorProps> = ({
  error
}) => {
  // For now we only handle not found
  return error?.statusCode === NotFound ? (
    <ParagraphZoomNotFound />
  ) : (
    <Alert severity="warning" variant="outlined">
      <Translate id={translationKeys.paragraph_zoom.unknown_error} />
    </Alert>
  );
};
