import {
  DocumentFieldModel,
  DocumentPageConfigurationModel
} from "@rhinestone/portal-web-api";
import { FieldSet, FieldSetTaxonValue } from "../use-fieldset";
import { useDocumentPageConfig } from "../use-document-page-config";
import { RelationItemModel } from "./RelationItemModel";
import { usePortalProperty } from "../use-portal-property";
import { QueryStatus } from "react-query";
import { RelatedTargetDocumentFieldSetModel } from "@rhinestone/portal-web-api";
import { mapFieldsToRelationItem } from "./map-fields-to-prep-chain-item";

export function relationTypesIsConfigured(
  consolidationRelationTypeCode: string | undefined,
  preparationRelationTypeCode: string | undefined
): boolean {
  return Boolean(consolidationRelationTypeCode && preparationRelationTypeCode);
}

export function pageConfigurationIsLoaded(
  pageConfigurationStatus: string,
  pageConfiguration: DocumentPageConfigurationModel | undefined
): boolean {
  return Boolean(pageConfiguration && pageConfigurationStatus === "success");
}

export function documentIsLoaded(
  hiveId: string | undefined,
  fullName: string | undefined
): boolean {
  return Boolean(hiveId && fullName);
}

export function useEnablePrepChain(): boolean | undefined {
  const { value } = usePortalProperty<boolean>("EnablePrepChain");
  return value;
}

export interface ConsolidationConfiguration {
  consolidationRelationTypeCode: string;
  paragraphConsolidationRelationTypeCode: string;
  preparationRelationTypeCode: string;
  proposedChangeToParagraphRelationTypeCode: string;
  remarksOnRelationTypeCode: string;
  labelFieldsetField: DocumentFieldModel | undefined;
  hiveIdFieldsetFieldName: string;
  fullNameFieldsetFieldName: string;
  consolidationTitleFieldName: string;
  preparatoryWorkConditionFieldValues: string[] | undefined;
  preparatoryWorkConditionFieldName: string;
}

export type ConsolidationAndPageConfig = ConsolidationConfiguration & {
  pageConfiguration?: DocumentPageConfigurationModel;
  pageConfigurationStatus: QueryStatus;
};

export function useConsolidationAndPageConfig(): ConsolidationAndPageConfig {
  const { data: pageConfiguration, status: pageConfigurationStatus } =
    useDocumentPageConfig();
  const consolidationConfiguration =
    getConsolidationConfiguration(pageConfiguration);
  return {
    ...consolidationConfiguration,
    pageConfiguration,
    pageConfigurationStatus
  };
}
// Get configured values or empty string if nothing is configured
export function getConsolidationConfiguration(
  pageConfiguration: DocumentPageConfigurationModel | undefined
): ConsolidationConfiguration {
  if (!pageConfiguration) {
    return {
      consolidationRelationTypeCode: "",
      paragraphConsolidationRelationTypeCode: "",
      preparationRelationTypeCode: "",
      proposedChangeToParagraphRelationTypeCode: "",
      remarksOnRelationTypeCode: "",
      labelFieldsetField: undefined,
      hiveIdFieldsetFieldName: "",
      fullNameFieldsetFieldName: "",
      consolidationTitleFieldName: "",
      preparatoryWorkConditionFieldValues: undefined,
      preparatoryWorkConditionFieldName: ""
    };
  }
  return pageConfiguration.consolidationConfiguration;
}

// Get the label from the current document's FieldSet.
// Here we assume that it's the same FieldSet as configured
// in consolidation configuration and the same field name.
export const label = (
  fieldset: FieldSet | undefined,
  field: DocumentFieldModel | undefined
) => {
  if (!field) return "";

  const fieldValue = fieldset ? fieldset[field.fieldName] : "";
  if (field.fieldType === "Classification" && fieldValue) {
    return (fieldValue as FieldSetTaxonValue[])?.map(v => v.Title).join(", ");
  }

  return fieldValue as string;
};

// We can assume the current document is the actual law if it
// has one or more outgoing CHANGED_BY relations.
export const isLawDocument = (outgoingChangedBy: RelationItemModel[]) =>
  outgoingChangedBy && outgoingChangedBy.length > 0;

export const noPreparatoryRelationsExists = (relations: RelationItemModel[]) =>
  !relations || relations.length === 0;

export const mapToRelationItemModel = (
  relatedTargetDocuments: RelatedTargetDocumentFieldSetModel[] | undefined,
  pageConfiguration: DocumentPageConfigurationModel | undefined
) => {
  return (
    relatedTargetDocuments?.map(x => {
      return mapFieldsToRelationItem(
        pageConfiguration,
        x.fieldSet,
        x.targetBookmarkIds
      );
    }) || []
  );
};
