/* eslint-disable complexity */
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid, Hidden, Paper } from "@material-ui/core";
import { PaneItem } from "./ThreePanesLayout";
import { headerExtensionHeight } from "./HeaderExtension";
import { LegacyNavBarHeightInPixels } from "../../legacy-app-support/LegacyGlobalCssOverrides";
import { useDocumentActionsSettings } from "../../document/DocumentActionMenu/document-actions/DocumentActionsSettingsProvider";
import { PaneTitle } from "./PaneTitle";
import { useGetDesktopSize } from "./use-get-desktop-size";
import PaperFixedTop from "./PaperFixedTop";

export const largeExtraWidth = 368;
export const normalDesktopContentWidth = 855;
export const largeDesktopContentWidth = 1215;

const useStyles = (props: {
  documentContentSize: "normal" | "large";
  hasToc: boolean;
  hasTimeLine: boolean;
}) =>
  makeStyles(theme => ({
    mobile: {
      display: "flex",
      flex: 1,
      backgroundColor: theme.palette.background.default,
      margin: 0,
      justifyContent: "center",
      marginLeft: theme.spacing(-0.5),
      marginRight: theme.spacing(-0.5),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1.5)
    },
    normalDesktop: {
      flex:
        "0 3 " +
        (normalDesktopContentWidth +
          (props?.documentContentSize === "large" ? largeExtraWidth : 0)) +
        "px",
      [theme.breakpoints.only("xl")]: {
        flexBasis:
          (props?.hasToc && props?.hasTimeLine
            ? largeDesktopContentWidth
            : normalDesktopContentWidth) +
          (props?.documentContentSize === "large" ? largeExtraWidth : 0) +
          "px"
      },
      zIndex: 2,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginTop: "3px",
      minHeight: "100%",
      display: "flex",
      position: "relative"
    },
    // This seems fishy as this is not the place to do hardcoded toc styling
    // in the generic sounding CenterPane component.
    toc: {
      flex: "0 0 360px",
      maxWidth: 360,
      position: "fixed",
      top:
        LegacyNavBarHeightInPixels + headerExtensionHeight + theme.spacing(1.5),
      height:
        "calc(100% - " +
        (LegacyNavBarHeightInPixels +
          headerExtensionHeight +
          theme.spacing(3)) +
        "px);"
    },
    content: {
      flex:
        "0 0 " +
        (normalDesktopContentWidth +
          (props?.documentContentSize === "large" ? largeExtraWidth : 0)) +
        "px",
      width:
        normalDesktopContentWidth +
        (props?.documentContentSize === "large" ? largeExtraWidth : 0) +
        "px",
      [theme.breakpoints.between("sm", "md")]: {
        flexShrink: 3,
        width: "100%"
      },
      [theme.breakpoints.only("xl")]: {
        marginLeft: props?.hasToc ? 360 : 0
      },
      borderLeft: "1px solid rgb(0 0 0 / 8%)"
    },
    contentPaper: { width: "100%" }
  }));

interface CenterPaneProps {
  center: React.ReactNode;
  hasTimeline: boolean;
  toc?: PaneItem | null;
}

// This component should either be moved to the document folder or rewritten to not have hardcoded logic,
// knowledge and expectations about the document page - since it currently lives in the ui-components folder.
const CenterPane: React.FC<CenterPaneProps> = ({
  center,
  hasTimeline,
  toc
}) => {
  const isFullDocumentView =
    useDocumentActionsSettings()?.state.isFullDocumentView;
  const desktopSize = useGetDesktopSize();

  const classes = useStyles({
    documentContentSize: isFullDocumentView ? "large" : "normal",
    hasToc: !!toc,
    hasTimeLine: hasTimeline
  })();

  return (
    <>
      <Hidden smUp>
        <Grid item className={classes.mobile}>
          {center}
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Grid item className={classes.normalDesktop}>
          <PaperFixedTop
            large={desktopSize === "xlarge" && !!toc && hasTimeline}
            noLeftPane={!(toc && hasTimeline)}
          />
          <Paper elevation={2} className={classes.contentPaper}>
            {toc && hasTimeline ? (
              <Grid container>
                <Hidden lgDown>
                  <Grid item className={classes.toc}>
                    <PaneTitle>{toc.title}</PaneTitle>
                    {toc.content}
                  </Grid>
                </Hidden>
                <Grid item className={classes.content}>
                  {center}
                </Grid>
              </Grid>
            ) : (
              center
            )}
          </Paper>
        </Grid>
      </Hidden>
    </>
  );
};

export default CenterPane;
