import { Grid, Paper, PaperProps, makeStyles } from "@material-ui/core";
import React from "react";

import { PaneTitle } from "./PaneTitle";
import { PaneItem, paneItemVariant } from "./ThreePanesLayout";
import { Scrollbar } from "../Scrollbar";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  item: { width: "100%" },
  grey: { backgroundColor: grey[100] }
}));

const StackedPaneLayout: React.FC<{ items: PaneItem[] }> = ({ items }) => {
  const classes = useStyles();
  const variants: { [key in paneItemVariant]: Partial<PaperProps> } = {
    default: { elevation: 2 },
    grey: { elevation: 5, className: classes.grey }
  };
  return (
    <Scrollbar suppressScrollX>
      <Grid
        spacing={2}
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        {items.map((paneItem, index) => (
          <Grid item className={classes.item} key={index}>
            <Paper key={index} {...variants[paneItem.variant ?? "default"]}>
              {paneItem.title && <PaneTitle>{paneItem.title}</PaneTitle>}
              {paneItem.content}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Scrollbar>
  );
};

export default StackedPaneLayout;
