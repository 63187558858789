import * as React from "react";
import { Typography } from "@material-ui/core";
import { CheckboxList } from "../../ui-components/Checkbox";
import { translationKeys } from "../../../gen/translation-keys";
import {
  withLocalize,
  Translate,
  LocalizeContextProps
} from "react-localize-redux";
export interface ExportOptions {
  showAbstract: boolean;
  showComments: boolean;
  commentsEnabled: boolean;
  printToc: boolean;
  printCover: boolean;
}

export interface ExportDocumentOptionsProps extends LocalizeContextProps {
  options: ExportOptions;
  onChange: (options: ExportOptions) => void;
  hasAnnotations: boolean;
  hasAbstract: boolean;
}

const ExportDocumentOptions: React.FC<ExportDocumentOptionsProps> = ({
  translate,
  options,
  onChange,
  hasAnnotations,
  hasAbstract
}) => {
  const checkboxItems = [];

  hasAbstract &&
    checkboxItems.push({
      key: "showAbstract",
      label: String(
        translate(translationKeys.document_page.export_show_abstract)
      ),
      checked: options.showAbstract,
      disabled: false
    });

  hasAnnotations &&
    checkboxItems.push({
      key: "showComments",
      label: String(
        translate(translationKeys.document_page.export_show_comments)
      ),
      checked: options.showComments,
      disabled: !options.commentsEnabled,
      disabledTooltip: (
        <Translate id={translationKeys.annotations.comments_disabled_tooltip} />
      )
    });
  checkboxItems.push({
    key: "printToc",
    label: String(translate(translationKeys.document_page.export_print_toc)),
    checked: options.printToc,
    disabled: false
  });
  checkboxItems.push({
    key: "printCover",
    label: String(translate(translationKeys.document_page.export_print_cover)),
    checked: options.printCover,
    disabled: false
  });

  const handleChange = (key: string, checked: boolean) => {
    const opt = {
      ...options,
      [key]: checked
    };
    onChange(opt);
  };

  return (
    <>
      <Typography variant="subtitle1">
        <Translate id={translationKeys.document_page.export_with} />
      </Typography>
      <CheckboxList items={checkboxItems} onChange={handleChange} />
    </>
  );
};

export default withLocalize<ExportDocumentOptionsProps>(ExportDocumentOptions);
