import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import { FullScreenDialogTitle } from "../../ui-components/ModalDialog/FullScreenDialogTitle";

interface ParagraphZoomDialogTitleProps {
  title: React.ReactNode | string | undefined;
}

export const ParagraphZoomDialogTitle: React.FC<ParagraphZoomDialogTitleProps> =
  ({ title }) => {
    return (
      <Translate>
        {({ translate }) => (
          <FullScreenDialogTitle
            left={translate(
              translationKeys.document_page.paragraph_zoom
            ).toString()}
            center={title}
          />
        )}
      </Translate>
    );
  };
