import * as React from "react";
import { FindBar } from "./FindBar";

export interface PdfFindBarProps {
  onClose: () => void;
}

/**
 * The find bar for the PDF viewer.
 *
 * Note that the updating of the DOM elements are handled by pdf-find-bar.js by passing down the ref until we rewrite it to plain React, so the props are not updated
 */
export const PdfFindBar = React.forwardRef<HTMLDivElement, PdfFindBarProps>(
  ({ onClose }, ref) => {
    return (
      <FindBar
        ref={ref}
        totalMatches={null}
        currentMatch={null}
        onClose={onClose}
        findNext={() => null}
        findPrevious={() => null}
      />
    );
  }
);
