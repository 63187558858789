import React from "react";
import { TabPaneLayout } from "../../../ui-components/layouts/TabPaneLayout";
import { PaneItem } from "../../../ui-components/layouts/ThreePanesLayout";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  children: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(1)
  },
  tabs: {
    flex: "1 0 100px",
    overflow: "hidden"
  }
}));

interface ParagraphZoomTabsProps {
  items: PaneItem[];
}
export const ParagraphZoomTabs: React.FC<ParagraphZoomTabsProps> = ({
  items,
  children
}) => {
  const classes = useStyles();
  return (
    <div data-test-id="paragraph-zoom-tabs" className={classes.container}>
      <div className={classes.children}>{children}</div>
      <div className={classes.tabs}>
        <TabPaneLayout variant="fullWidth" items={items} />
      </div>
    </div>
  );
};
