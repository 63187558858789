import React from "react";
import ConnectForm from "../form/ConnectForm";
import { BaseFormFieldProps } from "./FieldOptions";
import { FormFieldProps } from "./FormFieldProps";

export function connectToForm<
  TFieldName extends string,
  TFieldValues,
  TFieldStylingProps
>(WrappedComponent: (props: FormFieldProps) => JSX.Element) {
  function FormFieldWrapper(
    props: BaseFormFieldProps<TFieldName, TFieldValues> & TFieldStylingProps
  ) {
    const { fieldName, fieldOptions, ...rest } = props;
    return (
      <ConnectForm>
        {({ register, formState, control }) => {
          const fieldError = formState?.errors[fieldName];
          return (
            <WrappedComponent
              name={fieldName}
              innerRef={register(fieldOptions)}
              helperText={fieldError && fieldError.message}
              error={!!fieldError}
              control={control}
              {...rest}
            />
          );
        }}
      </ConnectForm>
    );
  }
  return FormFieldWrapper;
}
