import { Chip, makeStyles } from "@material-ui/core";
import * as React from "react";
import clsx from "clsx";
import { TimelineConnector } from "./TimelineConnector";
import { TimelineContent } from "./TimelineContent";
import { TimelineItemWrapper } from "./TimelineItemWrapper";
import { TimelineSeparator } from "./TimelineSeparator";
import TimelineActiveLink from "./TimelineActiveLink";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

const useStyles = makeStyles(theme => ({
  dot: { margin: 4 },
  dotLine: {
    marginRight: 9,
    marginLeft: 9,
    marginTop: -4
  },
  connectorLine: {
    width: 2
  },
  childrenIndicator: {
    position: "absolute",
    height: 15,
    top: -11,
    borderRight: "2px dotted " + theme.palette.grey[400],
    backgroundColor: "transparent",
    width: 1,
    transform: "translate(-1px)"
  },
  firstChildrenIndicator: {
    transform: "translate(0px)"
  },
  splitChildrenIndicator: {
    top: -31,
    right: -12
  },
  content: {
    display: "flex",
    marginTop: -10
  },
  firstItemSeparator: {
    "& a": {
      lineHeight: 0
    }
  },
  firstContent: {
    display: "flex",
    marginTop: -1
  },
  splitLine: {
    position: "relative",
    borderRight: "2px solid " + theme.palette.grey[400],
    borderBottom: "2px solid " + theme.palette.grey[400],
    width: 30,
    height: 8,
    left: -26,
    bottom: -21,
    "& svg": {
      position: "absolute",
      top: 0,
      right: -1,
      transform: "translate(50%, -100%)"
    }
  },
  chip: {
    position: "relative",
    left: -10
  }
}));
interface SplitItemElement {
  linkTo?: string;
  linkOnClick?: () => void;
  icon: React.ReactNode;
  hasChildren?: boolean;
  "icon-aria-label"?: string;
}
interface TimelineSplitItemProps {
  id?: string;
  splits?: SplitItemElement[];
  firstItem?: SplitItemElement;
  openLinksInNewTab?: boolean;
  useLazyLoading?: boolean;
}

export const TimelineSplitItem: React.FC<TimelineSplitItemProps> = ({
  id,
  splits,
  firstItem,
  openLinksInNewTab,
  useLazyLoading
}): JSX.Element | null => {
  const classes = useStyles();
  if (!splits || splits.length === 0) return null;

  return (
    <TimelineItemWrapper sticky id={id} useLazyLoading={useLazyLoading}>
      <TimelineSeparator className={classes.firstItemSeparator}>
        {firstItem ? (
          <>
            {firstItem.hasChildren && (
              <TimelineConnector
                className={clsx(
                  classes.dotLine,
                  classes.connectorLine,
                  classes.childrenIndicator,
                  classes.firstChildrenIndicator
                )}
              />
            )}
            <TimelineActiveLink
              linkTo={firstItem?.linkTo}
              linkOnClick={firstItem?.linkOnClick}
              openLinksInNewTab={openLinksInNewTab}
              aria-label={firstItem?.["icon-aria-label"]}
            >
              {firstItem.icon}
            </TimelineActiveLink>
          </>
        ) : (
          <TimelineConnector
            className={clsx(classes.dotLine, classes.connectorLine)}
          />
        )}
        <TimelineConnector className={classes.connectorLine} />
      </TimelineSeparator>
      <TimelineContent
        className={firstItem ? classes.firstContent : classes.content}
      >
        {splits.map((split, index) => (
          <div className={classes.splitLine} key={index}>
            {split.hasChildren && (
              <TimelineConnector
                className={clsx(
                  classes.dotLine,
                  classes.connectorLine,
                  classes.childrenIndicator,
                  classes.splitChildrenIndicator
                )}
              />
            )}
            <TimelineActiveLink
              linkTo={split?.linkTo}
              linkOnClick={split?.linkOnClick}
              openLinksInNewTab={openLinksInNewTab}
              aria-label={split?.["icon-aria-label"]}
            >
              {split.icon}
            </TimelineActiveLink>
          </div>
        ))}
        <Translate>
          {({ translate }) => (
            <Chip
              label={String(
                translate(
                  translationKeys.document_page.document_timeline_split_label
                )
              )}
              size="small"
              variant="outlined"
              className={classes.chip}
            />
          )}
        </Translate>
      </TimelineContent>
    </TimelineItemWrapper>
  );
};
