import React from "react";
import { Translate } from "react-localize-redux";

interface ChipLabelProps {
  translationKey?: string | null | undefined;
  relationGroupName?: string | null | undefined;
}
const ChipLabel: React.FC<ChipLabelProps> = ({
  translationKey,
  relationGroupName
}) => {
  return (
    <>
      {(translationKey && <Translate id={translationKey} />) ||
        relationGroupName}
    </>
  );
};

export default ChipLabel;
