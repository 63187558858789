/* eslint-disable complexity */
import * as React from "react";
import { RhinestoneDocument } from "./RhinestoneDocument";
import { usePortalApi } from "../../../api-access/use-portal-api";
import { DocumentSectionActions } from "./document-components/document-section-actions/DocumentSectionActions";
import { useLocation } from "react-router-dom";
import { Abstract } from "../shared/Abstract";
import { LoadingProgress } from "../../../ui-components/LoadingProgress";
import { InstanceRelationsContainer } from "../../InstanceRelations";
import { DocumentToolbar } from "../shared/DocumentToolbar";
import { DocumentActionsMenu } from "../../DocumentActionMenu";
import { ArticleRelationChipsGroup } from "../shared/document-relation-groups/RelationChips";
import { FindInDocumentStateProvider } from "../../FindInDocumentStateProvider";
import { Box } from "@material-ui/core";
import { useDocumentHighlightsContext } from "../../highlights/DocumentHighlightsProvider";
import { useHtmlAnnotationState } from "../../annotations/html/html-annotation-state";
import { HtmlAnnotationContainer } from "../../annotations/html/HtmlAnnotationContainer";
import { useCallback, useEffect, useState } from "react";
import {
  RhinestoneTargetLocation,
  SectionActionProps
} from "./rhinestone-document-helper-types";
import { useDocumentInfo } from "../../document-context";
import {
  OrphanedAnnotationsContainer,
  useOrphanedAnnotations
} from "../../annotations/OrphanedAnnotationsContainer";
import { useSearchParams } from "../../../../browser-utils/useSearchParams";
import ExcludeFromAnnotation from "../../annotations/ExcludeFromAnnotation";
import { useGetBlobText } from "../../../api-access/use-get-blob-text";
import { isDanishLawDocument } from "./rhinestone-document-util";

interface RhinestoneDocumentContainerProps {
  onTargetElementInView?: (element: Element) => void;
}

const RhinestoneDocumentContainer: React.FC<RhinestoneDocumentContainerProps> =
  ({ onTargetElementInView }) => {
    const { hiveId, fullName, taxons, annotationTarget } = useDocumentInfo();
    const { assetCollectionId } = useSearchParams() as {
      assetCollectionId: string | undefined;
    };

    const {
      status: statusPrimaryVariantResponseRequest,
      data: rawPrimaryVariantResponse,
      error
    } = usePortalApi(
      client =>
        client.getPrimaryVariantContentExcludeEditorialNotes(
          hiveId,
          fullName,
          assetCollectionId
        ),
      [
        "getPrimaryVariantContentExcludeEditorialNotes",
        hiveId,
        fullName,
        assetCollectionId
      ]
    );

    const { data, isLoading: isLoadingBlobData } = useGetBlobText(
      statusPrimaryVariantResponseRequest,
      rawPrimaryVariantResponse
    );

    // we need to set targetLocation in state so, it only changes if location actually changes
    const [targetLocation, setTargetLocation] =
      useState<RhinestoneTargetLocation>();

    const { hash, key } = useLocation();

    useEffect(() => {
      setTargetLocation({ hash, key });
    }, [hash, key]);

    const highlights = useDocumentHighlightsContext();
    const annotationState = useHtmlAnnotationState(
      hiveId,
      fullName,
      annotationTarget.annotationTargetId
    );

    const orphanedAnnotations = useOrphanedAnnotations(annotationState);

    const renderAnnotationComments = useCallback(
      (documentRef: HTMLDivElement | null) =>
        // if only mount annotations container when visible, then we make sure not to track layout state changes when not showing annotations
        annotationState.isShown && (
          <HtmlAnnotationContainer
            annotationState={annotationState}
            documentElementRef={documentRef}
          ></HtmlAnnotationContainer>
        ),
      [annotationState]
    );

    // we do not show loader if there is already data since this way, we can have background fetch with no blinks

    if (
      statusPrimaryVariantResponseRequest === "loading" ||
      isLoadingBlobData ||
      rawPrimaryVariantResponse === undefined ||
      data === undefined
    ) {
      return <LoadingProgress />;
    }

    if (statusPrimaryVariantResponseRequest === "error") {
      return (
        <div>
          Error loading document:{" "}
          {error instanceof Error ? error.message : error}
        </div>
      );
    }

    if (
      statusPrimaryVariantResponseRequest === "success" &&
      !data &&
      !rawPrimaryVariantResponse
    ) {
      return <div>Empty document</div>;
    }

    return (
      <FindInDocumentStateProvider>
        {orphanedAnnotations && (
          <OrphanedAnnotationsContainer
            orphanedAnnotations={orphanedAnnotations}
            removeAnnotation={annotationState.removeAnnotation}
          />
        )}
        <RhinestoneDocument
          rhinestoneXml={data.toString()}
          highlights={highlights}
          renderSectionActions={renderSectionActions}
          renderArticleActions={renderArticleActions}
          renderAdditionalDocumentInformation={renderAdditionalInformation}
          targetLocation={targetLocation}
          onTargetElementInView={onTargetElementInView}
          isLawDocument={isDanishLawDocument(taxons)}
          annotationState={annotationState}
          renderAnnotationsComments={renderAnnotationComments}
        />
      </FindInDocumentStateProvider>
    );
  };

export default RhinestoneDocumentContainer;

function renderAdditionalInformation() {
  return (
    <ExcludeFromAnnotation>
      <Abstract />
    </ExcludeFromAnnotation>
  );
}

function renderSectionActions({ ...props }: SectionActionProps) {
  return <DocumentSectionActions {...props} />;
}

function renderArticleActions(props: Record<string, any>) {
  return (
    <ExcludeFromAnnotation>
      <DocumentToolbar>
        <DocumentActionsMenu {...props} />
        <div className="instance-tabs">
          <InstanceRelationsContainer variant="scrollable" />
        </div>
      </DocumentToolbar>
      <Box marginBottom={3}>
        <ArticleRelationChipsGroup />
      </Box>
    </ExcludeFromAnnotation>
  );
}
