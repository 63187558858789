import { DocumentPageConfigurationModel } from "@rhinestone/portal-web-api";
import { documentIsLoaded, pageConfigurationIsLoaded } from ".";

export const useGetCanFetchRelationTree = (
  hiveId: string,
  fullName: string,
  followsRelationTypeCode: string,
  pageConfiguration: DocumentPageConfigurationModel | undefined,
  pageConfigurationStatus: string,
  getDocumentIsLoaded = documentIsLoaded,
  getPageConfigurationIsLoaded = pageConfigurationIsLoaded
) => {
  const portalApiEnabled =
    getDocumentIsLoaded(hiveId, fullName) &&
    getPageConfigurationIsLoaded(pageConfigurationStatus, pageConfiguration) &&
    Boolean(followsRelationTypeCode);

  return portalApiEnabled;
};
