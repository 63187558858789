import { usePortalApiMutation } from "../../../api-access/use-portal-api";

export function useSetProfileEmail() {
  return usePortalApiMutation(
    client => (email: string) => client.setProfileEmail(email),
    [["setProfileEmail"]],
    {
      onSuccess: data => {
        return data;
      }
    }
  );
}
