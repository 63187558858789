import * as React from "react";
import BillTextTabContents from "./BillTextTabContents";
import GeneralRemarksTabContents from "./GeneralRemarksTabContents";
import { ParagraphZoomTabs } from "./ParagraphZoomTabs";
import SpecialRemarksTabContents from "./SpecialRemarksTabContents";
import { useState } from "react";
import { ChangeLawSelector } from "./ChangeLawSelector";
import { RelationItemModel } from "../../preparatory-work/RelationItemModel";
import { useGetTargetRelations } from "../../preparatory-work/use-get-target-relations";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { SmallTextSkeleton } from "../../../ui-components/Skeleton/SmallSkeleton";

interface TabContentContainerProps {
  hiveId: string;
  fullName: string;
  paragraphId: string;
}
export const TabContentContainer: React.FC<TabContentContainerProps> = ({
  hiveId,
  fullName,
  paragraphId
}) => {
  const [selectedChangeRelation, setSelectedChangeRelation] =
    useState<RelationItemModel>();

  const [targetRelations, isLoading] = useGetTargetRelations({
    hiveId,
    fullName,
    bookmark: paragraphId,
    getRelationTypeCode: config =>
      config?.proposedChangeToParagraphRelationTypeCode || ""
  });

  return (
    <Translate>
      {({ translate }) => (
        <ParagraphZoomTabs
          items={[
            {
              key: "billText",
              title: translate(
                translationKeys.paragraph_zoom.bill_text_tab_title
              ).toString(),
              content: isLoading ? (
                <SmallTextSkeleton />
              ) : (
                <BillTextTabContents
                  selectedChangeRelation={selectedChangeRelation}
                  headerLinkText={
                    <Translate
                      id={
                        translationKeys.paragraph_zoom
                          .bill_text_header_link_text
                      }
                    />
                  }
                />
              )
            },
            {
              key: "generalRemarks",
              title: translate(
                translationKeys.paragraph_zoom.general_remarks_tab_title
              ).toString(),
              content: isLoading ? (
                <SmallTextSkeleton />
              ) : (
                <GeneralRemarksTabContents
                  selectedChangeRelation={selectedChangeRelation}
                  headerLinkText={
                    <Translate
                      id={
                        translationKeys.paragraph_zoom
                          .general_remarks_header_link_text
                      }
                    />
                  }
                />
              )
            },
            {
              key: "specialRemarks",
              title: translate(
                translationKeys.paragraph_zoom.special_remarks_tab_title
              ).toString(),
              content: isLoading ? (
                <SmallTextSkeleton />
              ) : (
                <SpecialRemarksTabContents
                  selectedChangeRelation={selectedChangeRelation}
                  headerLinkText={
                    <Translate
                      id={
                        translationKeys.paragraph_zoom
                          .special_remarks_header_link_text
                      }
                    />
                  }
                />
              )
            }
          ]}
        >
          <ChangeLawSelector
            hiveId={hiveId}
            fullName={fullName}
            paragraphId={paragraphId}
            paragraphChangeRelations={targetRelations}
            isFetching={isLoading}
            onElementSelected={setSelectedChangeRelation}
          />
        </ParagraphZoomTabs>
      )}
    </Translate>
  );
};
