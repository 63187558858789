import { Hidden } from "@material-ui/core";
import React from "react";
import { TabPaneLayout } from "./TabPaneLayout";
import { PaneItem } from "./ThreePanesLayout";
import SidePane from "./SidePane";

interface LeftPaneProps {
  timeline?: PaneItem | null;
  toc?: PaneItem | null;
}

const LeftPane: React.FC<LeftPaneProps> = ({ timeline, toc }) => {
  return (
    <Hidden xsDown>
      <SidePane>
        <Hidden xlUp>
          {(timeline || toc) && (
            <TabPaneLayout
              useIconOnSmallScreen
              items={[toc, timeline].filter(Boolean) as PaneItem[]}
            />
          )}
        </Hidden>
        <Hidden lgDown>
          {timeline && (
            <TabPaneLayout useIconOnSmallScreen items={[timeline]} />
          )}
          {!timeline && toc && (
            <TabPaneLayout useIconOnSmallScreen items={[toc]} />
          )}
        </Hidden>
      </SidePane>
    </Hidden>
  );
};

export default LeftPane;
