import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";

export const nameLabel = (
  <Translate id={translationKeys.account.my_profile_form_name_label} />
);
export const emailLabel = (
  <Translate id={translationKeys.account.my_profile_form_email_label} />
);
export const cancelLabel = (
  <Translate id={translationKeys.account.my_profile_form_cancel} />
);
export const submitLabel = (
  <Translate id={translationKeys.account.my_profile_form_submit} />
);
