import * as React from "react";
import { List } from "@material-ui/core";
import { FieldItem } from "../AboutDocument/FieldItem";
import {
  DocumentFamilyPropertyDto,
  DocumentFieldModel
} from "@rhinestone/portal-web-api";
import {
  CompleteDocumentPageUrl,
  getDocumentUrl
} from "../document-page/document-page-helpers";

type LinkDocumentFieldModel = DocumentFieldModel & {
  linkDocumentFullNameField: string;
  linkHiveIdField: string;
};

export interface AboutDocumentFamilyProps {
  fields: DocumentFamilyPropertyDto[];
  fieldModels: (LinkDocumentFieldModel | DocumentFieldModel)[];
}

export const AboutDocumentFamily: React.FC<AboutDocumentFamilyProps> = ({
  fields,
  fieldModels
}) => {
  return (
    <List dense>
      {fieldModels.map((field, index: number) => (
        <FieldItem
          {...field}
          key={index}
          fieldValue={
            fields.find(v => v.fieldName === field.fieldName)?.fieldValue ?? ""
          }
          linkTo={
            field.fieldType === "Link"
              ? generateLink(fields, field as LinkDocumentFieldModel)
              : ""
          }
        />
      ))}
    </List>
  );
};

function generateLink(
  fields: DocumentFamilyPropertyDto[],
  field: LinkDocumentFieldModel
): string | undefined {
  const hiveId =
    fields.find(v => v.fieldName === field.linkHiveIdField)?.fieldValue ?? "";
  const fullName =
    fields.find(v => v.fieldName === field.linkDocumentFullNameField)
      ?.fieldValue ?? "";

  return hiveId && fullName ? getDocumentUrl({ hiveId, fullName }) : "";
}
