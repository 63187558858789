import React from "react";
import { EditProfileFormContainer } from "./form/EditProfileFormContainer";
import Page from "../../ui-components/layouts/Page";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

const EditProfilePage: React.FC = () => {
  return (
    <Page
      title={<Translate id={translationKeys.account.profile_page_header} />}
    >
      <EditProfileFormContainer />
    </Page>
  );
};

export default EditProfilePage;
