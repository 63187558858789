import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { format } from "date-fns";
import { getDateFormat } from "../supported-date-locales";

export interface FormatLocalizedFunction {
  (date: Date | string): string;
}

export type DateFormatterProps = LocalizeContextProps & {
  value?: Date | string;
  children?: (format: FormatLocalizedFunction) => React.ReactNode;
};

function isValidDate(dateString: string) {
  // Date.parse returns NaN if the string is not a valid date
  // 1970-01-01T00:00:00Z is 0, everything before is negative and everything after is a positive number
  const date = Date.parse(dateString);
  return !isNaN(date);
}

/**
 * To format date, provide either a value as input
 * or use it with a render function as a child
 * @param param0
 */
export const DateFormatter = ({
  activeLanguage,
  value,
  children
}: DateFormatterProps) => {
  const formatString = getDateFormat(activeLanguage.code);
  const formatLocalized = (date: string | Date) =>
    isValidDate(date as string)
      ? format(new Date(date), formatString)
      : `invalid date or date format (${value})`;
  return (
    <>
      {value && <span>{formatLocalized(value)}</span>}
      {children && children(formatLocalized)}
    </>
  );
};

export default withLocalize(DateFormatter);
