import React from "react";
import {
  AnnotationModel,
  HtmlAnnotationModel,
  PdfAnnotationModel
} from "@rhinestone/portal-web-api";
import { TranslateFunction } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";

export interface OrphanedAnnotationStringProps {
  orphan: AnnotationModel;
  highlightClassName?: string;
  translate: TranslateFunction;
}

export const OrphanedAnnotationString: React.FC<OrphanedAnnotationStringProps> =
  ({ orphan, highlightClassName, translate }) => {
    const htmlOrphan = orphan as HtmlAnnotationModel;
    if (htmlOrphan?.annotatedText) {
      return (
        <div>
          ...{htmlOrphan.leadingAnnotationText}
          <span className={highlightClassName}>{htmlOrphan.annotatedText}</span>
          {htmlOrphan.trailingAnnotationText}...
        </div>
      );
    }

    const pdfOrphan = orphan as PdfAnnotationModel;
    if (pdfOrphan?.pageIndex) {
      return (
        <div>
          {translate(translationKeys.document_page.page)}{" "}
          {pdfOrphan.pageIndex + 1}
        </div>
      );
    }

    return null;
  };
