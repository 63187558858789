import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import AnonymousImage from "../document-components/AnonymousImage/AnonymousImageContainer";
import { convertToReactAttribs } from "./process-attribute-helpers";

const processAnonymousImg = (
  ticketId: string | undefined
): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => {
      return ticketId && node.name === "img";
    },
    processNode: (node: HtmlToReactNode) => {
      const origSrc = node.attribs.src;
      const attributes = convertToReactAttribs(node.attribs);

      return (
        <AnonymousImage
          originalSrc={origSrc}
          additionalAttributes={attributes}
        />
      );
    }
  };
};

export default processAnonymousImg;
