import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "../../../../ui-components/Toolbar";
import { LegacyNavBarHeightInPixels } from "../../../../legacy-app-support/LegacyGlobalCssOverrides";
import { useShouldShowDocumentToolbar } from "./document-toolbar-helpers";
import clsx from "clsx";
import { useDocumentActionsSettings } from "../../../DocumentActionMenu/document-actions/DocumentActionsSettingsProvider";
import {
  largeExtraWidth,
  normalDesktopContentWidth
} from "../../../../ui-components/layouts/CenterPane";
import { useFullWidthStyles } from "../../../../ui-components/layouts/DocumentFullWidthComponent";

// TODO: This is a bit much custom styling, maybe the <Toolbar> could have a "sticky"
// prop to handle the sticky-logic and feel to free up this component to simply wrap it.
const useStyles = makeStyles(theme => ({
  toolbarStatic: {
    position: "static",
    display: "flex",
    paddingRight: theme.spacing() * 6,
    justifyContent: "space-between",
    minWidth: "100%",
    flexWrap: "wrap-reverse",
    width: "calc(100% + 52px)"
  },
  toolbarSticky: {
    position: "sticky",
    backgroundColor: theme.palette.background.default,
    zIndex: 25,
    marginBottom: "15px",
    borderBottom: "1px solid " + theme.palette.grey[300],
    marginLeft: "-2px",
    marginRight: "-62px",
    "& .icon-menu": {
      borderTop: `medium solid ${theme.palette.primary.main}`,
      padding: "8px 0px 4px 3px"
    },
    "& .instance-tabs": {
      maxWidth: "100%",
      opacity: 0,
      visibility: "hidden"
    }
  },
  toolbarLarge: {
    width: normalDesktopContentWidth + largeExtraWidth - 14 + "px"
  },
  toolbarTriggered: {
    // Used to hide the top border of the IconMenu when it is sticking to the top
    "& .icon-menu": {
      borderTopColor: "transparent",
      padding: "2px 0 5px 3px"
    }
  },
  tabsTriggered: {
    "& .instance-tabs": {
      opacity: 1,
      visibility: "visible",
      transition: "opacity 200ms ease-in-out"
    }
  }
}));

/**
 * It seems the toolbar has a height of 48px.
 *
 * This is just a simple way to expose this height.
 *
 * Best way would of course to have it calculated where it is needed.
 *
 */
export const DocumentToolBarHeightInPixels = 48;

export const DocumentToolbar: React.FC = props => {
  const classes = useStyles();
  const fullWidthClasses = useFullWidthStyles();
  const shouldDisplay = useShouldShowDocumentToolbar();
  const ref = useRef<HTMLDivElement | null>(null);

  // TODO: set to height of AppBar or 0 if no AppBar. (for storybook etc.)
  const stickyInfo = useCheckStickiness(
    ref,
    LegacyNavBarHeightInPixels,
    DocumentToolBarHeightInPixels
  );
  const isFullDocumentView =
    useDocumentActionsSettings()?.state.isFullDocumentView;
  if (!shouldDisplay) {
    return null;
  }

  return (
    <Toolbar
      variant="dense"
      disableGutters={true}
      className={clsx(
        "document-toolbar",
        isFullDocumentView && fullWidthClasses.fullWidth,
        classes.toolbarStatic,
        classes.toolbarSticky,
        stickyInfo.isStuckOnTop && [
          "document-toolbar-triggered",
          classes.toolbarTriggered
        ],
        stickyInfo.tabsTriggered && [classes.tabsTriggered]
      )}
      {...props}
    />
  );
};

function useCheckStickiness(
  ref: React.RefObject<HTMLElement | null>,
  stickyY = 0,
  tabsTriggeredY = 0
) {
  const [isStuckOnTop, setStuckOnTop] = useState(false);
  const [tabsTriggered, setTabsTriggered] = useState(false);
  useEffect(() => {
    const scrollHandler = (e: Event) => {
      setStuckOnTop(
        Math.round(ref.current?.getBoundingClientRect().top || 0) === stickyY
      );
      setTabsTriggered(Math.round(window.scrollY || 0) > tabsTriggeredY);
    };

    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [ref, stickyY, tabsTriggeredY]);

  return {
    isStuckOnTop,
    tabsTriggered
  };
}
