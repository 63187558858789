import React from "react";
import { useDocumentInfo } from "../../../../document-context";
import { RelationQueryType } from "../RelationChips/RelationQueryType";
import { useRelationSearchUrl } from "../RelationChips/use-get-relation-search-url";
import { useDocumentRelationGroupsForAll } from "../use-document-relation-groups";
import RelationsDialogContainer from "./RelationsDialogContainer";

interface ArticleAllRelationDialogProps {
  groupName: string;
}

export const ArticleAllRelationDialog: React.FC<ArticleAllRelationDialogProps> =
  ({ groupName }) => {
    const { hiveId, fullName } = useDocumentInfo();

    const { data, status } = useDocumentRelationGroupsForAll(
      true,
      hiveId,
      fullName
    );
    const searchUrl = useRelationSearchUrl(
      hiveId,
      fullName,
      RelationQueryType.All,
      undefined,
      groupName
    );

    return (
      <RelationsDialogContainer
        data={data}
        status={status}
        groupName={groupName}
        openLinksInNewTab={true}
        searchUrl={searchUrl}
      />
    );
  };
