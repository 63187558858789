import * as React from "react";

interface TreeViewItemLabelProps {
  label: React.ReactNode;
}

export const TreeViewItemLabel: React.FC<TreeViewItemLabelProps> = ({
  label
}) => {
  return <div className={"tree-view-item"}>{label}</div>;
};
