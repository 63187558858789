import { useDocumentActionsSettings } from "./DocumentActionsSettingsProvider";

export function useToggleFullDocumentViewAction() {
  const dispatch = useDocumentActionsSettings()?.dispatch;

  return (): void => {
    dispatch &&
      dispatch({
        type: "toggleFullDocumentView"
      });
  };
}
