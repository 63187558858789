import * as React from "react";
import { Alert } from "../../../ui-components/Alert";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { Box } from "@material-ui/core";

export const ParagraphZoomNotFound: React.FC = () => {
  return (
    <Box m={2}>
      <Alert
        title={
          <Translate id={translationKeys.paragraph_zoom.not_found.title} />
        }
        severity="warning"
        variant="outlined"
      >
        <Translate id={translationKeys.paragraph_zoom.not_found.message} />
      </Alert>
    </Box>
  );
};
