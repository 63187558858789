import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import * as React from "react";
import { ModalDialogTitle, ModalDialogActions, ModalDialogAction } from ".";

const defaultMuiMarginInPixel = 2;

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: defaultMuiMarginInPixel + "rem",
    "& > .MuiDialog-container > .MuiDialog-paperScrollPaper": {
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden"
    }
  }
}));
export type FullScreenModalDialogProps = {
  open: boolean;
  onClose: () => void;
  title: React.ReactNode;
  confirm?: ModalDialogAction;
  cancel?: ModalDialogAction;
};

export const FullScreenModalDialog: React.FC<FullScreenModalDialogProps> = ({
  open,
  onClose,
  title,
  children,
  confirm,
  cancel,
  ...rest
}) => {
  const customStyles = useStyles();
  return (
    <Dialog
      {...rest}
      open={open}
      keepMounted={false}
      fullScreen
      onClose={onClose}
      className={customStyles.dialog}
    >
      <ModalDialogTitle onClose={!confirm && !cancel ? onClose : undefined}>
        {title}
      </ModalDialogTitle>
      {children}
      <ModalDialogActions cancel={cancel} confirm={confirm} />
    </Dialog>
  );
};
