import * as React from "react";
import { useState } from "react";
import { createContext, useReducer, useContext, useEffect } from "react";
import { PortalApiAccess, createPortalApiAccess } from "./portal-api-access";
import { ApiErrorDialog } from "./ApiErrorDialog";
import { useApiTicket } from "./use-api-ticket";
import { useInitialAuthState } from "./use-initial-auth-state";
export interface ApiError {
  url?: string;
  statusCode?: number;
  message?: string;
  correlationIdentifier?: string;
}

export type PortalApiContextType = [
  PortalApiAccess,
  React.Dispatch<ApiErrorActions>
];

export const PortalApiContext = (() => {
  const newContext = createContext<PortalApiContextType>([
    {} as PortalApiAccess,
    arg => arg
  ]);
  newContext.displayName = "PortalApiContext";
  return newContext;
})();

export type PortalIdContextType = {
  portalIdentifier: string;
};

export const PortalIdContext = (() => {
  const newContext = createContext<PortalIdContextType>({
    portalIdentifier: ""
  });
  newContext.displayName = "PortalIdContext";
  return newContext;
})();

interface PortalApiProviderProps {
  portalIdentifier: string;
}

export const PortalApiProvider: React.FC<PortalApiProviderProps> = ({
  portalIdentifier,
  children
}) => {
  const [errorState, dispatchErrorState] = useReducer(apiErrorReducer, []);
  const [apiAccess, setApiAccess] = useState<PortalApiAccess>();
  const ticketId = useApiTicket();
  const { isAuthenticated, isLoadingAuthState } =
    useInitialAuthState(portalIdentifier);

  useEffect(() => {
    if (isLoadingAuthState) return;
    setApiAccess(
      createPortalApiAccess(portalIdentifier, isAuthenticated, ticketId)
    );
  }, [ticketId, portalIdentifier, isAuthenticated, isLoadingAuthState]);

  return apiAccess ? (
    <PortalApiContext.Provider value={[apiAccess, dispatchErrorState]}>
      <PortalIdContext.Provider value={{ portalIdentifier }}>
        {children}
        <ApiErrorDialog
          errors={errorState}
          onClose={() => dispatchErrorState({ action: "CLEAR_ERRORS" })}
        />
      </PortalIdContext.Provider>
    </PortalApiContext.Provider>
  ) : null;
};

function apiErrorReducer(state: ApiError[], action: ApiErrorActions) {
  switch (action.action) {
    case "ERROR_OCCURRED":
      return [...state, ...action.newErrors];
    case "CLEAR_ERRORS":
      return [];
  }
}

export type ApiErrorActions =
  | {
      action: "ERROR_OCCURRED";
      newErrors: ApiError[];
    }
  | { action: "CLEAR_ERRORS" };

export function useRelativeApiBasePath() {
  const [portalApiClient] = useContext(PortalApiContext);
  return portalApiClient.relativeBasePortalApiUrl();
}
