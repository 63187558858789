export class Rect {
  constructor(
    public startX: number,
    public startY: number,
    public w: number,
    public h: number
  ) {}

  public endX() {
    return this.startX + this.w;
  }
  public endY() {
    return this.startY + this.h;
  }

  public reScale(scale: number): Rect {
    return new Rect(
      this.startX / scale,
      this.startY / scale,
      this.w / scale,
      this.h / scale
    );
  }

  // makes sure width and height are positive (recalculating starting point if needed)
  public normalize(): void {
    if (this.w < 0) {
      this.startX = this.startX + this.w;
      this.w = -this.w;
    }

    if (this.h < 0) {
      this.startY = this.startY + this.h;
      this.h = -this.h;
    }
  }

  // checks if given point is inside the rectangle
  public inside(x: number, y: number): boolean {
    return (
      (x - this.startX) * (x - this.endX()) <= 0 &&
      (y - this.startY) * (y - this.endY()) <= 0
    );
  }
}
