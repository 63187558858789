import * as React from "react";
import { FC } from "react";
import AiResponse from "./AiResponse";
import NewCitations from "./NewCitations";
import { ChatResponseSourceDto } from "@rhinestone/portal-web-api";
import { Marked } from "@ts-stack/markdown";

interface AiResponseContainerProps {
  response: string;
  isStreaming: boolean;
  citations: ChatResponseSourceDto[];
  usedCitationIndexes: number[];
  registerUsedCitationIndexes: (usedCitations: number[]) => void;
}

const AiResponseContainer: FC<AiResponseContainerProps> = ({
  response,
  isStreaming,
  citations,
  usedCitationIndexes,
  registerUsedCitationIndexes
}) => {
  React.useEffect(() => {
    if (isStreaming) return;

    const usedCitationsRefs = response.match(/\[[^[\]]+\]/g);
    const usedCitationRefValues = usedCitationsRefs
      ? usedCitationsRefs.map(ref => +ref.substring(1, ref.length - 1))
      : [];
    if (usedCitationRefValues)
      registerUsedCitationIndexes(usedCitationRefValues);
  }, [isStreaming, response, registerUsedCitationIndexes]);

  if (!response) return null;

  return (
    <AiResponse>
      <div dangerouslySetInnerHTML={{ __html: Marked.parse(response) }} />
      {!isStreaming && (
        <NewCitations
          citations={citations}
          isStreaming={isStreaming}
          usedCitationIndexes={usedCitationIndexes}
        />
      )}
    </AiResponse>
  );
};

export default AiResponseContainer;
