import {
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent
} from "@material-ui/core";
import React, { ReactNode } from "react";

const useStyles = makeStyles(theme => ({
  titleCard: {
    zIndex: 1200,
    width: 790
  },
  cardHeader: {
    marginTop: -25
  },

  divider: {
    marginLeft: -25,
    marginRight: -25
  },
  subHeaderContainer: {
    height: 25,
    marginTop: 5,
    marginBottom: -10
  }
}));

interface PageTitleTilePropsContainer {
  titleText: string | ReactNode;
  subHeader?: string | ReactNode;
  children?: ReactNode;
}
const PageTitleTile: React.FC<PageTitleTilePropsContainer> = ({
  titleText,
  subHeader,
  children
}) => {
  const classes = useStyles();
  const subHeaderElement = (
    <>
      <Divider className={classes.divider} />
      {subHeader ? (
        <div className={classes.subHeaderContainer}>{subHeader}</div>
      ) : null}
    </>
  );
  return (
    <Card className={classes.titleCard}>
      <CardHeader
        className={classes.cardHeader}
        title={<h3>{titleText}</h3>}
        subheader={subHeaderElement}
      />
      {children && <CardContent>{children}</CardContent>}
    </Card>
  );
};

export default PageTitleTile;
