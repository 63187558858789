import React from "react";
import { LoadingProgress } from "../../ui-components/LoadingProgress";
import { useDocumentInfo } from "../document-context";
import TimelineAccordionContainer from "./TimelineAccordionContainer";
import { useGetPrepatoryWorkTimelines } from "./use-get-prepatory-work-timelines";
import { useShouldShowPreparatoryWork } from "./use-should-show-preparatory-work";

const TimelineAccordionPanelContainer: React.FC = () => {
  const { hiveId, fullName, properties } = useDocumentInfo();

  const shouldShowPrepWork = useShouldShowPreparatoryWork(
    hiveId,
    fullName,
    properties
  );

  const { timelineDocuments, isLoading } = useGetPrepatoryWorkTimelines(
    hiveId,
    fullName,
    config => config.consolidationRelationTypeCode
  );
  if (!shouldShowPrepWork) {
    return null;
  }
  return (
    <>
      {isLoading ? (
        <LoadingProgress />
      ) : (
        timelineDocuments.map(doc => (
          <TimelineAccordionContainer
            key={`${doc.hiveId}_${doc.fullName}`}
            hiveId={doc.hiveId}
            fullName={doc.fullName}
            openLinksInNewTab
          />
        ))
      )}
    </>
  );
};

export default TimelineAccordionPanelContainer;
