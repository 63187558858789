// TODO Could it be typed better with react's HtmlAttributes? E.g.:
// convertToReactAttribs = (attribs: { [key: string]: string }) : AllHTMLAttributes<HTMLElement>: AllHTMLAttributes<HTMLElement>
export type validHtmlAttribs = {
  [key: string]: string | { [key: string]: string };
};

export const convertToReactAttribs = (attribs: {
  [key: string]: string;
}): validHtmlAttribs => {
  const reactAttribs: validHtmlAttribs = {};
  Object.keys(attribs).forEach(key => {
    const reactKey = htmlToValidReactAttribs?.[key];
    if (reactKey) {
      reactAttribs[reactKey] = attribs[key];
    }
    if (key === "style") {
      reactAttribs[key] = convertStyleToReactStyle(attribs[key]);
    }
  });

  return reactAttribs;
};
const convertStyleToReactStyle = (style: string) => {
  const reactStyle: { [key: string]: string } = {};
  const styleArray = style.split(";");
  styleArray.forEach(style => {
    const styleArray = style.split(":");
    if (styleArray.length === 2) {
      const formattedProperty = formatStringToCamelCase(styleArray[0]).trim();
      reactStyle[formattedProperty] = styleArray[1];
    }
  });
  return reactStyle;
};
const formatStringToCamelCase = (str: string) => {
  const splitted = str.split("-");
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join("")
  );
};

// TODO Could it be typed better with react's HtmlAttributes? E.g.:
// const htmlToValidReactAttribs: { [key: string]: keyof AllHTMLAttributes<HTMLElement> } = {
// Notice, not all attributes listed here are valid attributes for AllHtmlAttributes, some e.g. bgColor are "pure" HTML attributes
const htmlToValidReactAttribs: { [key: string]: string } = {
  accesskey: "accessKey",
  class: "className",
  contenteditable: "contentEditable",
  contextmenu: "contextMenu",
  data: "data",
  draggable: "draggable",
  hidden: "hidden",
  id: "id",
  spellcheck: "spellCheck",
  tabindex: "tabIndex",
  title: "title",
  abbr: "abbr",
  align: "align",
  bgcolor: "bgColor",
  border: "border",
  cellpadding: "cellPadding",
  cellspacing: "cellSpacing",
  frame: "frame",
  rules: "rules",
  summary: "summary",
  width: "width",
  height: "height",
  src: "src",
  alt: "alt"
};
