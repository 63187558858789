/**
 * Portal configuration allows for multiple configurable fieldsests
 *
 * But until now all portals have been configured with a single fieldset for document page viewing and one for search
 * The one for document page is called DefaultPortalFields, and this is hardcoded here for now  for use in multiple places
 *
 * This could change in the future, where different fieldsets for different use cases could be imagined
 */
export const HardCodedFieldSetName = "DefaultPortalFields";

export type DocumentFieldSet = { [propertyName: string]: any } | undefined;

/**
 * TODO: Some fields are "know fields", i.e. they are used in specific components, and have logic. E.g. value of field "abstract" is shown in special abstract component. The value of field "status" has
 * an icon depending on the value.
 * The problem; Different portals have different  metadata-schema and therefore their own naming of fieldset, e.g "abstract" might not be named "abstract" in every portal.
 * Thus making a need for making this configurable. In the future we need to make these "known fields" configurable, so that it can be used generically.
 **/
export enum FieldNames {
  Instance = "instans",
  Court = "court",
  HiveId = "HiveId",
  FullName = "FullName",
  DisplayType = "visningstype",
  Abstract = "abstract",
  Status = "status"
}

export enum DisplayType {
  Dom = "DOM",
  Lov = "LOV",
  Guide = "GUIDE",
  Litterature = "LITERATURE"
}
