import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import { FormFieldProps } from "../forms/controls/FormFieldProps";

export interface RadioButtonGroupBaseProps {
  label?: React.ReactNode;
  "aria-label"?: string;
  items?: { value: unknown; label: React.ReactNode; id?: string }[];
  row?: boolean;
  value?: unknown;
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
    | undefined;
}
type RadioButtonGroupProps = RadioButtonGroupBaseProps & FormFieldProps;

export function RadioButtonGroup(props: RadioButtonGroupProps) {
  const {
    label,
    items,
    row,
    value,
    onChange,
    name,
    helperText,
    error,
    innerRef,
    control,
    ...rest
  } = props;

  const radioButtonGroup = (
    <RadioGroup
      row={row}
      aria-label={rest["aria-label"]}
      onChange={onChange}
      value={value}
    >
      {items &&
        items?.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            name={item.id}
            control={<Radio size="small" id={item.id} />}
            label={item.label}
          />
        ))}
    </RadioGroup>
  );
  return (
    <FormControl component="fieldset" error={error}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      {control ? (
        <Controller
          as={radioButtonGroup}
          name={name || "radio"}
          control={control}
        />
      ) : (
        radioButtonGroup
      )}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
