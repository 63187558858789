import React from "react";
import { Scrollbar } from "../Scrollbar";
import { getAriaKey } from "./tab-helpers";

export type TabPanelProps = {
  value: number;
  index: number;
  paneKey: string;
  children: React.ReactNode;
  className?: string;
  noScrollbar?: boolean;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

export const TabPanel: React.FC<TabPanelProps> = ({
  value,
  index,
  paneKey,
  children,
  className,
  noScrollbar,
  onScroll,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${paneKey}`}
    aria-labelledby={getAriaKey(paneKey)}
    style={{ height: "100%", paddingBottom: 8 }}
    className={className}
    {...other}
  >
    {value === index &&
      (noScrollbar ? (
        children
      ) : (
        <Scrollbar onScroll={onScroll}>{children}</Scrollbar>
      ))}
  </div>
);
