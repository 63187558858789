import { parseRhinestoneXml } from "../../content/rhinestone-xml/rhinestone-document-util";

function paragraphXmlToSectionIds(xml: string) {
  const parsedAsDocument = parseRhinestoneXml(xml);
  return parsedAsDocument?.querySelector("section[id]")?.id;
}
export function getMissingIds(sections: string[], sectionIds: string[]) {
  const returnedSectionIds = sections?.map((x: string) =>
    paragraphXmlToSectionIds(x)
  );
  const missingIds = sectionIds.filter(x => !returnedSectionIds?.includes(x));
  return missingIds;
}
