import React from "react";
import { Box, Grid } from "@material-ui/core";
import { ModalDialogTitleTypography } from "./ModalDialogTitleTypography";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface FullScreenDialogTitleProps {
  left: React.ReactNode | string | undefined;
  center: React.ReactNode | string | undefined;
}

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: "baseline",
    padding: "0 16px",
    gap: "40px",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "6px",
      "& h4": {
        margin: 0
      }
    }
  },
  left: {
    minWidth: 0,
    maxWidth: "fit-content",
    [theme.breakpoints.down("xs")]: {
      "& h4": {
        fontSize: "1rem"
      }
    }
  },
  right: {
    minWidth: 0,
    maxWidth: "max-content"
  }
}));

export const FullScreenDialogTitle: React.FC<FullScreenDialogTitleProps> = ({
  left,
  center
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid className={classes.left} item>
        <Box>
          <ModalDialogTitleTypography emphasized>
            {left}
          </ModalDialogTitleTypography>
        </Box>
      </Grid>
      <Grid className={classes.right} item>
        <Box>
          <ModalDialogTitleTypography emphasized>
            {center}
          </ModalDialogTitleTypography>
        </Box>
      </Grid>
    </Grid>
  );
};
