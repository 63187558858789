import * as React from "react";
import {
  HtmlToReactPreprocessingInstructions,
  Parser as HtmlToReactParser
} from "html-to-react";
import { parseRhinestoneXml } from "../../content/rhinestone-xml/rhinestone-document-util";
import processDefaultTags from "../../content/rhinestone-xml/processing-instructions/process-default-tags";
import processHeaderTagsDowngrade from "../../content/rhinestone-xml/processing-instructions/process-header-tags-downgrade";
import processInlineNotes from "../../content/rhinestone-xml/processing-instructions/process-inline-notes";

import processLocalNoteTags from "../../content/rhinestone-xml/processing-instructions/process-local-notes-tags";
import processRemoveLinks from "../../content/rhinestone-xml/processing-instructions/process-remove-links";
import { DocumentTypography } from "../../content/shared/DocumentTypography";

const htmlToReactParser = new HtmlToReactParser();

/*
This function will transform the xml into react nodes and make some symantic adjustments eg. H1 -> H2
*/
function paragraphXmlToReactWithParsing(
  xml: string,
  htmlToReactParser: HtmlToReactParser,
  additionalParsers: HtmlToReactPreprocessingInstructions[]
) {
  const parsedAsDocument = parseRhinestoneXml(xml);
  return htmlToReactParser.parseWithInstructions(
    parsedAsDocument.innerHTML,
    () => true,
    [
      processLocalNoteTags(true),
      processInlineNotes(),
      ...additionalParsers,
      processHeaderTagsDowngrade(),
      processRemoveLinks(),
      processDefaultTags
    ],
    []
  );
}

interface ParagraphZoomTextProps {
  paragraphXml: string;
  additionalParsers?: HtmlToReactPreprocessingInstructions[];
}
export const ParagraphZoomText: React.FC<ParagraphZoomTextProps> = ({
  paragraphXml,
  additionalParsers
}) => {
  // let convert the incoming xml to react
  const paragraphXmlAsReactNode = paragraphXmlToReactWithParsing(
    paragraphXml,
    htmlToReactParser,
    additionalParsers || []
  );

  return (
    <DocumentTypography isLawDocument={true}>
      {paragraphXmlAsReactNode}
    </DocumentTypography>
  );
};
