import { Grid } from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { LegacyNavBarHeightInPixels } from "../../legacy-app-support/LegacyGlobalCssOverrides";
import { headerExtensionHeight } from "./HeaderExtension";

export const smallWidth = 275;
export const largeWidth = 360;

const useStyles = makeStyles(theme => ({
  pane: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up("lg")]: {
      flex: `0 0 ${largeWidth}px`
    },
    [theme.breakpoints.down("md")]: {
      flex: `0 0 ${smallWidth}px`
    }
  },
  fixedPanel: {
    [theme.breakpoints.up("lg")]: {
      width: `${largeWidth}px`
    },
    [theme.breakpoints.down("md")]: {
      width: `${smallWidth}px`
    },
    position: "fixed",
    top:
      LegacyNavBarHeightInPixels + headerExtensionHeight + theme.spacing(1.5),
    height:
      "calc(100% - " +
      (LegacyNavBarHeightInPixels + headerExtensionHeight + theme.spacing(3)) +
      "px);"
  }
}));

interface SidePaneProps {
  width?: number;
}

const SidePane: React.FC<SidePaneProps> = ({ children, width }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.pane}>
      <div className={classes.fixedPanel}>{children}</div>
    </Grid>
  );
};

export default SidePane;
