import React from "react";
import { ToolTipContent } from "../../document-history-timeline/ToolTipContent";

export const useCreateToolTip = () => {
  const createToolTip = (hiveId: string, fullName: string) => () =>
    (
      <>
        <ToolTipContent
          hiveId={hiveId}
          fullName={fullName}
          getRelationTypeCode={config =>
            config?.paragraphConsolidationRelationTypeCode || ""
          }
        />
      </>
    );
  return createToolTip;
};
