/* eslint-disable complexity */
import { AnnotationModel } from "@rhinestone/portal-web-api";
import React from "react";
import { MenuItem } from "../../ui-components/ContextMenu/ContextMenu";
import MouseContextMenu from "../../ui-components/ContextMenu/MouseContextMenu";
import { Annotation, AnnotationState } from "../annotations/annotation-state";
import AnnotationCommentEditorModal from "../annotations/comments/AnnotationCommentEditorModal";
import { DeleteAnnotationConfirmation } from "../annotations/DeleteAnnotationConfirmation";
import { addCommentMenuItem } from "./menu-items/addCommentMenuItem";
import { deleteAnnotationMenuItem } from "./menu-items/deleteAnnotationMenuItem";

interface ExistingAnnotationContextMenuProps {
  annotationState?: AnnotationState<AnnotationModel, never>;
  annotationId: string;
}

const ExistingAnnotationContextMenu: React.FC<ExistingAnnotationContextMenuProps> =
  ({ children, annotationState, annotationId }) => {
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [openEditor, setOpenEditor] = React.useState(false);

    const menuItems: MenuItem[] = [];

    const annotation = annotationState?.annotations?.find(
      x => x.annotation.id === annotationId
    );

    menuItems.push(
      deleteAnnotationMenuItem(annotation?.annotation, setOpenConfirmation)
    );
    menuItems.push(addCommentMenuItem(annotation?.annotation, setOpenEditor));

    const saveComment = async (
      annotation: Annotation<AnnotationModel>,
      newComment: string
    ) => {
      await annotationState?.addAnnotationComment({
        ...annotation,
        annotation: { ...annotation.annotation, comment: newComment }
      });
    };
    const shouldDisplayMenu = () =>
      !document.getSelection()?.toString()?.length;
    return annotationState?.isShown ? (
      <MouseContextMenu
        menuItems={menuItems}
        shouldDisplayMenu={shouldDisplayMenu}
      >
        {children}
        {annotation && (
          <DeleteAnnotationConfirmation
            open={openConfirmation}
            setOpen={setOpenConfirmation}
            removeAnnotation={annotationState.removeAnnotation}
            annotation={annotation}
          />
        )}
        {annotation && (
          <AnnotationCommentEditorModal
            openEditor={openEditor}
            setOpenEditor={setOpenEditor}
            annotations={[annotation]}
            saveComment={saveComment}
          />
        )}
      </MouseContextMenu>
    ) : (
      <>{children}</>
    );
  };

export default ExistingAnnotationContextMenu;
