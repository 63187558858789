import * as React from "react";
import MainHeader from "../document-components/MainHeader";
import ExcludeFromAnnotation from "../../../annotations/ExcludeFromAnnotation";
import { ArticleAction } from "../../../DocumentActionMenu";

interface DocumentHeaderProps {
  index: number;
  notNote: (React.ReactChild | React.ReactFragment | React.ReactPortal)[];
  renderArticleActions?: ArticleAction;
  note: (React.ReactChild | React.ReactFragment | React.ReactPortal)[];
  renderAdditionalDocumentInformation?: () => React.ReactNode;
}

const DocumentHeader: React.FC<DocumentHeaderProps> = ({
  index,
  notNote,
  note,
  renderArticleActions,
  renderAdditionalDocumentInformation
}) => {
  return (
    <MainHeader key={index}>
      <ExcludeFromAnnotation>
        {notNote}
        {renderArticleActions?.({})}
        {note}
        {renderAdditionalDocumentInformation?.()}
      </ExcludeFromAnnotation>
    </MainHeader>
  );
};

export default DocumentHeader;
