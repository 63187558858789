import FileSaver from "file-saver";
import { usePortalApi } from "../../api-access/use-portal-api";
import { useDocumentInfo } from "../document-context";
import { useEffect, useState } from "react";

export const useDownloadAttachment = (
  identifier: string,
  fileName: string,
  enabled: boolean
) => {
  const [isFetchingBlob, setIsFetchingBlob] = useState(false);
  const { hiveId, fullName } = useDocumentInfo();

  const { data: rawData, status } = usePortalApi(
    client => client.downloadAttachment(hiveId, fullName, identifier),
    ["downloadAttachment", hiveId, fullName, identifier],
    { enabled, staleTime: 0 }
  );

  useEffect(() => {
    if (status === "loading") {
      setIsFetchingBlob(true);
    }
  }, [status]);

  useEffect(() => {
    let isMounted = true;

    if (status === "success" && rawData && enabled) {
      const fetchBlobData = async () => await rawData.blobBody;
      const blobContent = fetchBlobData();

      blobContent
        .then(blob => {
          FileSaver.saveAs(blob as unknown as string, fileName);
        })
        .finally(() => {
          if (isMounted) {
            setIsFetchingBlob(false);
          }
        });
    }

    return () => {
      setIsFetchingBlob(false);
      isMounted = false;
    };
  }, [status, rawData, enabled, fileName]);

  return { isFetching: status === "loading" || isFetchingBlob };
};
