import * as React from "react";
import { IconButton } from "../../../../ui-components/IconButton";
import { Translate } from "react-localize-redux";
import Fade from "@material-ui/core/Fade";
import { translationKeys } from "../../../../../gen/translation-keys";
import { RelationIcon } from "../../../../ui-components/icons";
import { QueryStatus } from "react-query";
import { ApiFetchIndicator } from "../../../../ui-components/ApiFetchIndicator";

interface RelationsButtonProps {
  hasRelations?: boolean;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  status: QueryStatus | QueryStatus[];
}

export const RelationsButton: React.FC<RelationsButtonProps> = ({
  hasRelations,
  expanded,
  setExpanded,
  status
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <IconButton
          size="small"
          disabled={!hasRelations}
          title={translate(getTitleResource(hasRelations)).toString()}
          onClick={() => setExpanded(!expanded)}
        >
          <ApiFetchIndicator status={status} size={24}>
            <Fade in={true}>
              <RelationIcon
                data-test-id="document-action-relations-icon"
                color={expanded ? "primary" : "inherit"}
              />
            </Fade>
          </ApiFetchIndicator>
        </IconButton>
      )}
    </Translate>
  );
};

function getTitleResource(documentHasRelations?: boolean) {
  return documentHasRelations
    ? translationKeys.document_page.relation
    : translationKeys.document_page.relations_none;
}
