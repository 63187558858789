import { useMediaQuery, useTheme } from "@material-ui/core";

export function useGetDesktopSize() {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const desktopSize = isXl
    ? "xlarge"
    : isLarge
    ? "large"
    : isMedium
    ? "medium"
    : isSmall
    ? "small"
    : "xsmall";
  return desktopSize;
}
