import FormTextField, {
  FormTextFieldProps
} from "../../ui-components/forms/controls/FormTextField";

export type CreateAssetCollectionFormTextProps = {
  name: string;
  journal: string;
};
type CreateAssetCollectionFormTextKeys =
  keyof CreateAssetCollectionFormTextProps;

export const TypedTextField: (
  props: FormTextFieldProps<CreateAssetCollectionFormTextKeys>
) => JSX.Element = FormTextField;
