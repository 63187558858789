import * as React from "react";
import { FindBar } from "./FindBar";
import Mark from "mark.js";
import {
  normalizeIndex,
  scrollToElementIfOutOfBounds
} from "./rhinestone-find-bar.utils";
import { documentContentId } from "../rhinestone-xml/ContentBox";

export interface RhinestoneFindBarProps {
  onClose: () => void;
}

export const RhinestoneFindBar: React.FC<RhinestoneFindBarProps> = ({
  onClose
}) => {
  const [currentTerm, setCurrentTerm] = React.useState<string | null>(null);
  const [results, setResults] = React.useState<HTMLElement[] | null>(null);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  React.useEffect(() => {
    if (!results || results.length === 0) return;
    const currentResult = results[currentIndex];
    scrollToElementIfOutOfBounds(currentResult);
    results.forEach(x => x.classList.remove("selected"));
    currentResult.classList.add("selected");
  }, [currentIndex, results]);

  const documentContent: HTMLElement | null = document.querySelector(
    `#${documentContentId}`
  );
  const markInstance: Mark = new Mark(documentContent);

  const unmark = () => {
    markInstance.unmark();
  };

  const findNext = (term: string) => {
    if (results && term === currentTerm) {
      const normalizedIndex = normalizeIndex(currentIndex + 1, results.length);
      setCurrentIndex(normalizedIndex);
      return;
    }
    find(term);
    setCurrentIndex(0);
  };

  const findPrevious = (term: string) => {
    if (results && term === currentTerm) {
      const normalizedIndex = normalizeIndex(currentIndex - 1, results.length);
      setCurrentIndex(normalizedIndex);
      return;
    }
    find(term);
  };

  const find = (term: string) => {
    if (!documentContent) return;
    markInstance.unmark({
      done: () => {
        markInstance.mark(term, {
          done: () => {
            const markElements = documentContent.querySelectorAll("mark");
            // If tslint compilerOptions.target was set to es6 or higher, we could just do: const array = [...documentContent.querySelectorAll("mark")];    :(
            const results = [].slice.call(markElements, 0);
            setResults(results);
            setCurrentTerm(term);
          },
          separateWordSearch: false
        });
      }
    });
  };

  return (
    <FindBar
      totalMatches={results ? results.length : null}
      currentMatch={currentIndex + 1} // We add one because it's index 0
      onClose={() => {
        unmark();
        onClose();
      }}
      findNext={findNext}
      findPrevious={findPrevious}
    />
  );
};
