import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import { DocumentTitle } from "../../shared/DocumentTitle";
import ExcludeFromAnnotation from "../../../annotations/ExcludeFromAnnotation";

const processDocumentTitleTags = (): HtmlToReactProcessingInstructions => {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => {
      return (
        node.name === "h1" &&
        node.parent &&
        node.parent.name &&
        node.parent.name === "header"
      );
    },
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      return (
        <ExcludeFromAnnotation key={index}>
          <DocumentTitle>{children}</DocumentTitle>
        </ExcludeFromAnnotation>
      );
    }
  };
};

export default processDocumentTitleTags;
