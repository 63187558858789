import React from "react";
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  UnpackNestedValue,
  useForm
} from "react-hook-form/dist/index.ie11";

export interface FormProps<TFormProps> {
  children: React.ReactNode;
  defaultValues?: UnpackNestedValue<DeepPartial<TFormProps>> | undefined;
  onSubmit?: (data: TFormProps) => void;
}

function Form<TFormProps extends FieldValues>({
  children,
  defaultValues,
  onSubmit
}: FormProps<TFormProps>) {
  const methods = useForm<TFormProps>({ defaultValues });

  const onFormSubmit = methods.handleSubmit(data => {
    // Hack - Default values does not play well with react-hook-form v6
    // and the way we use register: https://react-hook-form.com/kr/v6/api/#useForm
    if (defaultValues) replaceUndefinedFields(data, defaultValues);
    onSubmit && onSubmit(data as unknown as TFormProps);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit}>{children}</form>
    </FormProvider>
  );
}

function replaceUndefinedFields(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValues: Record<string, any>
): void {
  for (const key in defaultValues) {
    if (data[key] === undefined) {
      data[key] = defaultValues[key];
    }
  }
}

export default Form;
