import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import { Link } from "../../../../ui-components/Link";
import { ExternalLink } from "../../../../ui-components/ExternalLink";

/**
 * Links are rendered with our <Link> component, this has a slight overhead compared to using
 * the default <a>, but allows the react-router to work correctly and material-ui to style
 * links nicely. If performance is too poor, consider using a standard <a> with custom styling
 * for very large documents.
 */
export function processLinks(
  disableHttpToHttpsLinks = false
): HtmlToReactProcessingInstructions {
  return {
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => {
      return node.name === "a";
    },
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      if (!node.attribs.href) {
        return <>{children}</>;
      }
      const isExternal = isLinkExternal(
        window.location.hostname,
        node.attribs.href
      );
      const isEmail = /\S+@\S+\.\S+/.test(node.attribs.href);

      mutateAndPreprocessNode(
        node,
        isExternal,
        isEmail,
        disableHttpToHttpsLinks
      );

      const { href = "#", class: className, ...attribs } = node.attribs;
      // TODO: when spreading attribs, we should make sure they are valid react attributes
      // const attributes = convertToReactAttribs(attribs);

      if (isExternal || isEmail) {
        return (
          <ExternalLink
            {...attribs}
            key={index}
            className={className}
            href={href}
          >
            {children}
          </ExternalLink>
        );
      }

      const { pathname, hash, search } = parseLink(href);
      return (
        <Link
          {...attribs}
          key={index}
          className={className}
          renderBlankTargetIcon={true}
          to={{
            pathname: pathname.replace(/^\/\//, "/"),
            hash,
            search
          }}
        >
          {children}
          {/* Consider adding icons for attachments linked from here. Like ".PDF"-, ".docx"-icon */}
        </Link>
      );
    }
  };
}

function mutateAndPreprocessNode(
  node: HtmlToReactNode,
  isExternal: boolean,
  isEmail: boolean,
  disableHttpToHttpsLinks: boolean
) {
  // TODO: please revisit this entire function, since it looks pretty weird that we are mutating the incoming HtmlToReact node.
  // This is something that should be normally done in preprocessing instructions. Please check HtmlToReact docs, and consider moving this code to a preprocess instruction
  // Also consider if there really a need to mutate the HtmlToReactNode?? instead of just reading out the data and pass it to our components we render??

  // always remove style attribute
  delete node.attribs.style;

  if (isEmail) {
    if (!node.attribs.href.startsWith("mailto:")) {
      node.attribs.href = `mailto:${node.attribs.href}`;
    }

    return;
  }

  if (isExternal) {
    // External links should always open in a new tab with no referrer headers
    node.attribs.target = "_blank";
    node.attribs.rel = "noopener noreferrer";
    if (!disableHttpToHttpsLinks) {
      node.attribs.href = forceHttps(node.attribs.href);
    }
    return;
  }
}

function forceHttps(href: string) {
  if (/^http:\/\//.test(href)) {
    return href.replace("http:", "https:");
  }
  return href;
}

function parseLink(href: string) {
  const linkParser = document.createElement("a");
  linkParser.href = href;
  return linkParser;
}

function isLinkExternal(hostname: string, url: string) {
  if (/^https?:\/\//.test(url)) {
    return hostname !== parseLink(url).hostname;
  }

  // File Links
  if (/^(\w:(\\|\/)|(\\\\|\/\/)\w+)/.test(url)) {
    return true;
  }

  // Other Protocol links (non http/https)
  if (/^(?!https?)\w+:\/\//.test(url)) {
    return true;
  }

  return false;
}
