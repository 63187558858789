import { useDocumentInfo } from "../../../document-context";
import { usePortalApi } from "../../../../api-access/use-portal-api";

/**
 * Since both local notes and editorial notes are extracted from the same API data
 * it's best to keep the data raw here so we only fetch once. We should consider
 * to have separate endpoints for getting editorialnote and localnote as we sometimes
 * only ask for one of them. We could even consider having an endpoint to get editorial-
 * notes for a single section and one for getting sections with notes like we do with
 * relations. That's for the future and the hooks below can easily be modified to get
 * their data from any place.
 */
function useMergeDocuments() {
  const { hiveId, fullName } = useDocumentInfo();
  return usePortalApi(
    client => client.getMergeDocument(hiveId, fullName),
    ["getMergeDocument", hiveId, fullName]
  );
}

export function useEditorialNotes() {
  const { data } = useMergeDocuments();

  return (
    data
      ?.filter(x => x.mergeType === "editorialnote")
      ?.flatMap(x => x.mergeSections) || []
  );
}

export function useLocalNotes() {
  const { data } = useMergeDocuments();

  return (
    data
      ?.filter(x => x.mergeType === "localnote")
      ?.flatMap(x => x.mergeSections) || []
  );
}
