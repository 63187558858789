import * as React from "react";
import { useUserContext } from "../../../user/use-user-context";
import { LoadingProgress } from "../../../ui-components/LoadingProgress";
import ShowProfile from "../ShowProfile/ShowProfile";
import { useHistory } from "react-router-dom";
import { useUserProfile } from "../../../user/use-user-profile";
import { ApiFetchIndicator } from "../../../ui-components/ApiFetchIndicator";

export const ShowProfileContainer: React.FC = () => {
  const { data: profile, status } = useUserProfile();
  const { user } = useUserContext();
  const history = useHistory();
  return (
    <ApiFetchIndicator status={status}>
      {profile && (
        <ShowProfile
          emails={profile.emailAddresses}
          name={profile.displayName}
          organizationName={user?.organizationName}
          canEdit={!profile.hasEmailFromClaims}
          edit={() => history.push("/account/myprofile/edit")}
        />
      )}
    </ApiFetchIndicator>
  );
};
