import * as React from "react";
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps
} from "@material-ui/core";

type AvatarProps = Pick<MuiAvatarProps, "alt" | "sizes"> & {
  children: React.ReactNode;
};

export const Avatar: React.FC<AvatarProps> = ({ children, ...rest }) => (
  <MuiAvatar {...rest}>{children}</MuiAvatar>
);
