import { IntersectionOptions, useInView } from "react-intersection-observer";

export function useLazyLoad(
  options?: Pick<IntersectionOptions, "threshold"> & {
    keepMounted?: boolean;
    disabled?: boolean;
  }
) {
  const { ref, inView } = useInView({
    triggerOnce: options?.keepMounted ?? true,
    skip: options?.disabled,
    // if lazy loading is disabled we set initialInView to true
    initialInView: options?.disabled,
    threshold: options?.threshold
  });
  if (!window.IntersectionObserver) return { inView: true };

  return { ref, inView };
}
