import Fade from "@material-ui/core/Fade";
import React from "react";
import { LoadingProgress } from "../../../ui-components/LoadingProgress";
import { CreateSubscription } from "../../document-version-subscription/CreateSubscription";
import { RemoveSubscription } from "../../document-version-subscription/RemoveSubscription";
import { IconButton } from "@material-ui/core";
import { useDocumentInfo } from "../../document-context";
import { usePortalApi } from "../../../api-access/use-portal-api";
import { DocumentVersionSubscriptionInfo } from "@rhinestone/portal-web-api";

export const ToggleSubscriptionAction: React.FC = () => {
  const { hiveId, fullName, title } = useDocumentInfo();

  const {
    data: subscription,
    isFetching: isFetchingSubscriptions,
    isSuccess: isSuccessSubscriptions
  } = useSubscriptionsForDocument(hiveId, fullName);

  const {
    data: newerDocumentVersion,
    isFetching: isFetchingNextDocumentVersion,
    isSuccess: isSuccessNextDocumentVersion
  } = useNextDocumentVersion(hiveId, fullName);

  if (
    (isFetchingSubscriptions || isFetchingNextDocumentVersion) &&
    subscription === undefined
  ) {
    // We are still fetching from API and will show a loading "spinner" meanwhile.
    return (
      <IconButton disabled>
        <LoadingProgress size={24} />
      </IconButton>
    );
  } else {
    // Fetching from API is done.
    return handleSubscriptionIcon(
      isSuccessSubscriptions,
      isSuccessNextDocumentVersion,
      hiveId,
      fullName,
      title,
      subscription,
      newerDocumentVersion
    );
  }
};

function handleSubscriptionIcon(
  isSuccessSubscriptions: boolean,
  isSuccessNextDocumentVersion: boolean,
  hiveId: string,
  fullName: string,
  title: string,
  subscription?: DocumentVersionSubscriptionInfo[],
  newerDocumentVersion?: string
) {
  return isSuccessSubscriptions &&
    isSuccessNextDocumentVersion &&
    showSubscriptionIcon(subscription, newerDocumentVersion) ? (
    <Fade in>
      {getCreateOrRemoveSubscription(
        (subscription?.length || 0) > 0,
        hiveId,
        fullName,
        title
      )}
    </Fade>
  ) : null; // If subscription is either suspended or a new version of the document exists, or if the api does not return successfully, we don't show the icon.
}

function getCreateOrRemoveSubscription(
  hasSubscription: boolean,
  hiveId: string,
  fullName: string,
  title: string
): React.ReactElement<any, any> | undefined {
  return hasSubscription ? (
    <RemoveSubscription hiveId={hiveId} fullName={fullName} />
  ) : (
    <CreateSubscription hiveId={hiveId} fullName={fullName} title={title} />
  );
}

function showSubscriptionIcon(
  subscription?: DocumentVersionSubscriptionInfo[],
  newerDocumentVersion?: string
) {
  if (newerDocumentVersion) return false; // If a newer version of the document exist, then the subscription icon for this version should not be shown.

  // If a subscription exist, check if it is suspended or not.
  if ((subscription?.length || 0) > 0) {
    const subscriptionSuspended = subscription
      ? subscription[0].suspended
      : false;

    return !subscriptionSuspended;
  }
  return true;
}

/** In this function we set "Staletime" to 0. This is needed here because the solution gives the user the option to change the server e.g. suspend or unsuspend (activate) a subscription.
 * With a Staletime of 0, the function will always fetch the data when the component remounts.
 */
function useSubscriptionsForDocument(hiveId: string, fullName: string) {
  return usePortalApi(
    client => client.getSubscriptionsForDocument(hiveId, fullName),
    ["getSubscriptionsForDocument", hiveId, fullName],
    {
      keepPreviousData: true,
      staleTime: 0 // Needed to force a re-fetching when navigating between pages (not sites), because we can change the state of subscriptions server side from old design parts of the site.
    }
  );
}

function useNextDocumentVersion(hiveId: string, fullName: string) {
  return usePortalApi(
    client => client.getNextDocumentVersion(hiveId, fullName),
    ["getNextDocumentVersion", hiveId, fullName],
    {
      keepPreviousData: true
    }
  );
}
