import { DialogActions as MuiDialogActions } from "@material-ui/core";
import * as React from "react";
import { Button } from "../../Button";

interface FormActionsProps {
  onCancel?: () => void;
  cancelLabel?: string | JSX.Element;
  submitLabel: string | JSX.Element;
  cancelDisabled?: boolean;
  submitDisabled?: boolean;
}
interface TwoButtonActionsProps {
  onSubmit?: () => void;
  onCancel?: () => void;
  cancelLabel?: string | JSX.Element;
  submitLabel: string | JSX.Element;
  cancelDisabled?: boolean;
  submitDisabled?: boolean;
}
interface InternalFormActionsProps extends TwoButtonActionsProps {
  submitOnSubmitButton: boolean;
}

const InternalFormActions: React.FC<InternalFormActionsProps> = ({
  submitOnSubmitButton = true,
  cancelLabel,
  submitLabel,
  onSubmit,
  onCancel,
  cancelDisabled,
  submitDisabled
}) => {
  return (
    <MuiDialogActions>
      {onCancel && cancelLabel !== undefined && (
        <Button
          type="button"
          color="primary"
          variant="outlined"
          onClick={onCancel}
          disabled={cancelDisabled}
        >
          {cancelLabel}
        </Button>
      )}
      {submitOnSubmitButton ? (
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={submitDisabled}
        >
          {submitLabel}
        </Button>
      ) : (
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={submitDisabled}
        >
          {submitLabel}
        </Button>
      )}
    </MuiDialogActions>
  );
};

export const TwoButtonActions: React.FC<TwoButtonActionsProps> = props => (
  <InternalFormActions {...props} submitOnSubmitButton={false} />
);

export const FormActions: React.FC<FormActionsProps> = ({
  onCancel,
  cancelLabel,
  submitLabel,
  cancelDisabled,
  submitDisabled
}) => (
  <InternalFormActions
    onCancel={onCancel}
    cancelLabel={cancelLabel}
    submitLabel={submitLabel}
    submitOnSubmitButton
    cancelDisabled={cancelDisabled}
    submitDisabled={submitDisabled}
  />
);

export default FormActions;
