import * as React from "react";
import {
  HtmlToReactProcessingInstructions,
  HtmlToReactNode
} from "html-to-react";
import { LocalNote } from "../../shared/LocalNote";

export default function processLocalNoteTags(
  hideNotes?: boolean
): HtmlToReactProcessingInstructions {
  return {
    // replaceChildren:false means root element section doesn't get written out
    // instead we let LocalNote component write out section to have full control over classes being written
    // and not get extra dom element just for styling
    replaceChildren: false,
    shouldProcessNode: (node: HtmlToReactNode) => {
      return (
        node.attribs &&
        node.attribs["data-mergetype"] &&
        node.attribs["data-mergetype"] === "localnote"
      );
    },
    processNode: (
      node: HtmlToReactNode,
      children: React.ReactNode,
      index: number
    ) => {
      return hideNotes ? null : <LocalNote key={index}>{children}</LocalNote>;
    }
  };
}
