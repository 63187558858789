import { PdfAnnotationModel } from "@rhinestone/portal-web-api";
import {
  AnnotationViewModel,
  PdfPageAnnotationLayer
} from "./pdf-page-annotation-layer";
import { PdfSelection } from "./pdf-selection";
import { PDFPageProxy } from "pdfjs-dist/legacy/build/pdf";

export class AnnotationLayerFactory {
  public trackSelection!: boolean;
  public isVisible = true;

  private layers: PdfPageAnnotationLayer[] = [];

  public onLayoutChanged!: (pageNumber: number) => void;
  public onContextMenu?: (
    mouseX: number,
    mouseY: number,
    selection?: PdfSelection,
    annotation?: PdfAnnotationModel
  ) => void;

  private annotationViewModels: AnnotationViewModel[] = [];

  // Interface method from PDF.JS AnnotationLayerFactory
  // This is called every time a page reappears in view
  public createAnnotationLayerBuilder({
    pageDiv,
    pdfPage
  }: {
    pageDiv: HTMLDivElement;
    pdfPage: PDFPageProxy;
  }): PdfPageAnnotationLayer {
    const pageIndex = pdfPage._pageIndex;

    const layer = new PdfPageAnnotationLayer(pageIndex, pageDiv, this);
    this.layers[pageIndex] = layer;

    this.addAnnotationsToLayer(pageIndex, layer);

    return layer;
  }

  private addAnnotationsToLayer(
    pageIndex: number,
    layer: PdfPageAnnotationLayer
  ) {
    const pageAnnotations = this.annotationViewModels.filter(
      vm => vm.annotation.pageIndex === pageIndex
    );

    if (pageAnnotations) {
      layer.loadAnnotations(pageAnnotations);
    }
  }

  public toggleVisibility(shouldShow: boolean) {
    if (this.isVisible === shouldShow) return;

    this.isVisible = shouldShow;
    this.redraw();
    this.toggleAnnotationSelectionMode(this.isVisible);
  }

  // toggles annotating on/off
  private toggleAnnotationSelectionMode(enable: boolean) {
    this.trackSelection = enable;

    this.layers.forEach(layer => {
      // Only a certain range of pages are loaded at a time to reduce resource consumption
      if (layer) {
        layer.toggleCreateAnnotationTracking(this.trackSelection);
      }
    });
  }

  public mergeAnnotations(annotationViewModels: AnnotationViewModel[]) {
    // TODO: compare incoming annotation state with existing so we can add remove annotations correctly against annotationFactory when it is already loaded
    // This function will be called every time annotations array changes
    this.annotationViewModels = annotationViewModels;
    // update layers with loaded annotations if layers were loaded before annotations
    this.layers?.forEach(l => this.addAnnotationsToLayer(l.pageIndex, l));
    this.redraw();
  }

  public onSelection(currentLayer: PdfPageAnnotationLayer) {
    this.layers.forEach((layer: PdfPageAnnotationLayer) => {
      // Only a certain range of pages are loaded at a time to reduce resource consumption
      if (layer) {
        if (layer.pageIndex !== currentLayer.pageIndex) {
          layer.clearSelection(true);
        }
      }
    });
  }

  public onSelectionStarted(currentLayer: PdfPageAnnotationLayer) {
    this.layers.forEach((layer: PdfPageAnnotationLayer) => {
      // Only a certain range of pages are loaded at a time to reduce resource consumption
      if (layer) {
        if (layer.pageIndex !== currentLayer.pageIndex) {
          layer.clearSelection(true);
        }
      }
    });
  }

  public redraw() {
    this.layers.forEach(layer => {
      // Only a certain range of pages are loaded at a time to reduce resource consumption
      layer?.clearSelection(true);
    });
  }

  public addAnnotation(annotationViewModel: AnnotationViewModel) {
    const layer = this.layers.find(
      (x: PdfPageAnnotationLayer) =>
        x && x.pageIndex === annotationViewModel.annotation.pageIndex
    );
    layer?.addAnnotation(annotationViewModel);
  }

  public removeAnnotation(annotation: PdfAnnotationModel) {
    const layer = this.layers.find(
      (x: PdfPageAnnotationLayer) => x && x.pageIndex === annotation.pageIndex
    );
    layer?.removeAnnotation(annotation);
  }

  public layoutChanged(pageNumber: number) {
    if (this.onLayoutChanged) {
      this.onLayoutChanged(pageNumber);
    }
  }

  public clear() {
    this.annotationViewModels = [];
    this.layers = [];
  }
}
