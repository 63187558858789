import { useDocumentInfo } from "../document-context";

export function useDocumentAttachments() {
  const { attachments } = useDocumentInfo();
  return attachments;
}

export function useHasDocumentAttachments() {
  const attachments = useDocumentAttachments();
  return attachments && attachments.length > 0;
}
