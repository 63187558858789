import * as React from "react";
import { Scrollbar } from "../../../ui-components/Scrollbar";
import { Card, CardContent } from "../../../ui-components/Card";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  scrollContainer: {
    paddingRight: "20px"
  },
  card: {
    height: "100%",
    "& > div": {
      height: "calc(100% + 8px)"
    }
  }
}));

interface OutlinedBoxProps {
  withScroll?: boolean;
}
export const OutlinedBox: React.FC<OutlinedBoxProps> = ({
  withScroll,
  children
}) => {
  const style = useStyles();
  return (
    <Card elevation={2} variant="elevation" className={style.card}>
      <CardContent>
        {withScroll ? <Scrollbar>{children}</Scrollbar> : children}
      </CardContent>
    </Card>
  );
};
