import * as React from "react";
import { LoadingProgress } from "../../ui-components/LoadingProgress";
import CreateAssetCollectionForm from "./CreateAssetCollectionForm";
import { SelectAssetCollection } from "./SelectAssetCollection";

type AssetCollectionFormProps = {
  assetCollections:
    | { id: string; title: string; lastModified: Date }[]
    | undefined;
  onSubmit: (name?: string, journal?: string) => Promise<void>;
  handleSelectExisting: (id: string, title: string) => Promise<void>;
  onClose: () => void;
};

export const AssetCollectionForm: React.FC<AssetCollectionFormProps> = ({
  assetCollections,
  onClose,
  onSubmit,
  handleSelectExisting
}) => {
  const [mode, setMode] = React.useState<"select" | "create">("select");

  return (
    <>
      {mode === "select" &&
        (assetCollections ? (
          <SelectAssetCollection
            onSelectExisting={handleSelectExisting}
            onCreateNew={() => setMode("create")}
            assetCollections={assetCollections}
          />
        ) : (
          <LoadingProgress />
        ))}
      {mode === "create" && (
        <CreateAssetCollectionForm
          onCancel={onClose}
          onSubmit={formProps => onSubmit(formProps.name, formProps.journal)}
        />
      )}
    </>
  );
};
