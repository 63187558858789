import { usePortalApi } from "../../api-access/use-portal-api";
import { NotFound, Unauthorized } from "../../api-access/http-status-codes";
import { DocumentDto } from "@rhinestone/portal-web-api";
import { RelativeUrl } from "../../../browser-utils/relative-url";
import { useSearchParams } from "../../../browser-utils/useSearchParams";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApiTicket } from "../../api-access/use-api-ticket";
import { useDocumentInfo } from "../document-context";

export type DocumentPageBaseUrl = Pick<DocumentDto, "hiveId" | "fullName">;

export type DocumentPageParameters = {
  showExact?: boolean;
  bookmark?: string;
  pzoom?: string;
  relgroup?: string;
  relsection?: string;
  relshowall?: boolean;
  anonymous?: boolean;
  ticketId?: string;
};

export type CompleteDocumentPageUrl = DocumentPageBaseUrl &
  DocumentPageParameters;

export function useGetDocument(hiveId: string, fullName: string) {
  return usePortalApi<DocumentDto>(
    client => client.getDocument(hiveId, fullName),
    ["getDocument", hiveId, fullName],
    {
      expectedErrorStatusCodes: [NotFound, Unauthorized]
    }
  );
}

export function useCurrentDocumentPageParameters(): DocumentPageParameters {
  const { showExact, ticketId, pzoom, relsection, relgroup, relshowall } =
    useSearchParams<DocumentPageParameters>();

  const location = useLocation();
  const bookmark = location.hash;
  const anonymous = new RegExp("/a/h").test(location.pathname);
  const decodedRelGroup = relgroup ? decodeURI(relgroup) : undefined;

  return {
    showExact,
    ticketId,
    pzoom,
    bookmark,
    relgroup: decodedRelGroup,
    relsection,
    relshowall,
    anonymous
  };
}

export function useGetSupplementalPart(src: string) {
  const [data, setData] = useState<Blob | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const ticketId = useApiTicket();
  useEffect(() => {
    setIsLoading(true);
    fetch(src, {
      method: "GET",
      headers: {
        ticket: ticketId || ""
      }
    })
      .then(r => r.blob())
      .then(text => {
        setData(text);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ticketId, src]);
  return { data, isLoading };
}

// eslint-disable-next-line complexity
export function getDocumentUrl(urlParts: CompleteDocumentPageUrl) {
  const {
    hiveId,
    fullName,
    showExact = true,
    bookmark,
    anonymous,
    ticketId,
    pzoom,
    relgroup,
    relsection,
    relshowall
  } = urlParts;

  const url = new RelativeUrl(`/h/${hiveId}/${fullName}`);
  if (anonymous) url.path = `/a${url.path}`;
  if (showExact) url.searchParams.append("showExact", "true");
  if (ticketId) url.searchParams.append("ticketId", ticketId);
  if (pzoom) url.searchParams.append("pzoom", pzoom);
  if (relgroup) url.searchParams.append("relgroup", encodeURI(relgroup));
  if (relsection) url.searchParams.append("relsection", relsection);
  if (relshowall) url.searchParams.append("relshowall", "true");
  if (bookmark) url.hash = bookmark;
  return url.toString();
}

// Get an url using the existing document page parameters
export function useGetNewRelativeDocumentUrl(
  newUrlParts: Partial<CompleteDocumentPageUrl>
) {
  const { hiveId, fullName } = useDocumentInfo();
  const currentUrlParams = useCurrentDocumentPageParameters();

  const currentUrlParts = { hiveId, fullName, ...currentUrlParams };

  const allParams = getAllUrlParams(currentUrlParts, newUrlParts);

  return getDocumentUrl(allParams);
}

// This function is exported for unit testing purposes
export function getAllUrlParams(
  currentUrlParts: CompleteDocumentPageUrl,
  newUrlParts: Partial<CompleteDocumentPageUrl>
): CompleteDocumentPageUrl {
  // Fixes a bug where showExact was set to true when currentParameters.showExact was undefined (meaning it was not set in the URL)
  // This happens because of the default value of true in getDocumentUrl. Idealy the fix should be moved there, but since that
  // function is used in many places through out the site I decided to fix it here instead.

  // Bug: https://tfs.schultz.dk/tfs/SchultzCollection/Schultz.Rhinestone/_sprints/taskboard/Fundament%20development%20team/Schultz.Rhinestone/Fundament%20release%202.66/Sprint%20205?workitem=179178
  const showExact =
    Boolean(currentUrlParts.showExact) || Boolean(newUrlParts.showExact);

  return {
    ...currentUrlParts,
    ...newUrlParts,
    showExact
  };
}
