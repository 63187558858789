import { AttachmentDto } from "@rhinestone/portal-web-api";
import React from "react";
import AttachmentElementContainer from "./AttachmentElementContainer";
import { useDocumentAttachments } from "./use-document-attachments";
import { Grid } from "@material-ui/core";

const AttachmentContainer: React.FC = () => {
  const attachments = useDocumentAttachments();
  const sortAttachments = (a: AttachmentDto, b: AttachmentDto) =>
    a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;

  if (!attachments || attachments.length === 0) return <></>;

  return (
    <Grid
      container
      item
      direction="column"
      justify="center"
      alignItems="center"
    >
      {attachments.sort(sortAttachments).map((item: AttachmentDto) => (
        <AttachmentElementContainer key={item.identifier} {...item} />
      ))}
    </Grid>
  );
};

export default AttachmentContainer;
