import { Hidden, makeStyles, Paper, Tooltip } from "@material-ui/core";
import * as React from "react";
import { Tab, TabPanel, Tabs, TabsProps } from "../Tabs";
import { PaneItem } from "./ThreePanesLayout";
import { getAriaKey } from "../Tabs/tab-helpers";
import { PaneTitle } from "./PaneTitle";
import clsx from "clsx";

export type TabPaneLayoutProps = {
  useIconOnSmallScreen?: boolean;
  items: PaneItem[];
};

const useStyles = makeStyles(theme => ({
  paper: { height: "100%" },
  tabs: {
    margin: 0,
    "& .MuiTab-wrapper": {
      display: "block"
    }
  },
  tabTitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  title: {
    borderBottom: "1px solid rgb(0 0 0 / 8%)"
  },
  tabPanel: {
    height: "calc(100% - " + theme.spacing(6) + "px) !important"
  },
  tabPanelHeader: {
    transition: "box-shadow 350ms ease",
    "&.scrolled": {
      boxShadow:
        "rgba(0, 0, 0, 0.19) 0px 10px 10px -5px, rgba(0, 0, 0, 0.23) 0px 6px 6px -6px"
    }
  }
}));
type MuiTabProps = Pick<TabsProps, "variant">;

/**  If only one pane is parsed, it uses the normal grid. On 1+ panes the layout is Tabs
 */
export const TabPaneLayout: React.FC<TabPaneLayoutProps & MuiTabProps> = ({
  items,
  variant,
  useIconOnSmallScreen
}): JSX.Element => {
  const classes = useStyles({ tabCount: items.length });
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (e.currentTarget.scrollTop > 10) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const label = (pane: PaneItem) => (
    <span>
      {useIconOnSmallScreen ? (
        <>
          <Hidden mdDown>{pane.title}</Hidden>
          <Hidden lgUp>
            <Tooltip title={pane.title || ""}>
              <>{pane.icon || "?"}</>
            </Tooltip>
          </Hidden>
        </>
      ) : (
        <Tooltip title={pane?.title ?? ""}>
          <div className={classes.tabTitle}>{pane?.title}</div>
        </Tooltip>
      )}
    </span>
  );
  return (
    <Paper elevation={2} className={classes.paper}>
      {items.length > 1 ? (
        <Tabs
          value={currentTabIndex}
          indicatorColor="secondary"
          onChange={(_, newValue) => setCurrentTabIndex(newValue)}
          textColor="primary"
          centered
          variant={variant}
          className={classes.tabs}
        >
          {items.map((pane, index) => (
            <Tab
              key={index}
              tabKey={pane.key}
              label={label(pane)}
              tabIndex={index}
              aria-label={pane.title}
            />
          ))}
        </Tabs>
      ) : (
        items[0]?.title && (
          <header
            className={clsx(
              classes.tabPanelHeader,
              isScrolled ? "scrolled" : ""
            )}
          >
            <PaneTitle id={getAriaKey(items[0].key)}>
              {items[0].title}
            </PaneTitle>
          </header>
        )
      )}
      {items.map((pane, index) => (
        <TabPanel
          onScroll={handleScroll}
          key={index}
          paneKey={pane.key}
          value={currentTabIndex}
          index={index}
          className={classes.tabPanel}
        >
          {pane.content}
        </TabPanel>
      ))}
    </Paper>
  );
};
