import * as React from "react";
import { useDocumentInfo } from "../document-context";
import { useFieldSet } from "../use-fieldset";
import { useGetAboutDocumentFields } from "./about-document-helpers";
import { AboutDocument } from "./AboutDocument";

export const AboutDocumentContainer: React.FC = () => {
  const { hiveId, fullName } = useDocumentInfo();
  const fields = useGetAboutDocumentFields();
  const fieldSet = useFieldSet(hiveId, fullName);

  if (!fieldSet || !fields) return null;

  return <AboutDocument fields={fields} fieldSet={fieldSet} />;
};
