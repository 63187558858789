/* eslint-disable complexity */
import React, { useEffect } from "react";
import { DatePossiblyUndecidedFormatter } from "../../localization/DateFormatter/DateFormatterPossiblyUndecided";
import {
  TimelineItem,
  TimelineSplitItem,
  TimelineMergeItem
} from "../../ui-components/split-timeline";
import { getDocumentUrl } from "../document-page/document-page-helpers";
import { DocumentStatus } from "../document-status";
import {
  TimelineSubItemModel,
  TimelineItemModel
} from "./document-history-timeline-helpers";
import { DocumentHistoryTimelineIcon } from "./DocumentHistoryTimelineIcon";
import _ from "lodash";

interface DocumentHistoryTimelineItemProps {
  item: TimelineItemModel;
  index: number;
  initialActiveItem: TimelineItemModel | undefined;
  items: TimelineItemModel[];
  toolTipComponent: React.ComponentType | undefined;
}
const DocumentHistoryTimelineItem: React.FC<DocumentHistoryTimelineItemProps> =
  ({ item, index, initialActiveItem, items, toolTipComponent }) => {
    const active = initialActiveItem === item;
    const createIcon = (item?: TimelineSubItemModel, active?: boolean) => (
      <DocumentHistoryTimelineIcon
        fontSize="small"
        status={item?.status || DocumentStatus.Unknown}
        alt={_.truncate(item?.name, { length: 128 })}
        active={active}
      />
    );

    useEffect(() => {
      if (active) {
        const element = document.getElementById(item.documentId);
        if (element) {
          element.scrollIntoView({ block: "center" });
        }
      }
    }, [active, item.documentId]);

    const createSplitItem = (item: TimelineItemModel) => ({
      icon: createIcon(item),
      linkTo: getDocumentUrl({ ...item }),
      hasChildren: item.hasMoreConnections,
      hasAncestors: item.hasMoreConnections,
      "icon-aria-label": item.fullName
    });
    return (
      <>
        <TimelineSplitItem
          splits={
            index === 0
              ? item?.additionalAncestors?.slice(1).map(createSplitItem)
              : item?.additionalAncestors?.map(createSplitItem)
          }
          firstItem={
            index === 0
              ? item?.additionalAncestors?.[0] &&
                createSplitItem(item?.additionalAncestors?.[0])
              : index === 1 && items[0].hasMoreConnections
              ? createSplitItem(items[0])
              : undefined
          }
          id={item.documentId}
          useLazyLoading={true}
        />
        {(index === 0 || index === items.length - 1) &&
        item.hasMoreConnections ? null : (
          <TimelineItem
            title={<DatePossiblyUndecidedFormatter value={item.date} />}
            subTitle={item.lbkLabel}
            icon={createIcon(item, active)}
            active={active}
            isLastItem={
              index === items.length - 1 && !item?.additionalChildren?.length
            }
            tooltipComponent={toolTipComponent}
            icon-aria-label={item.fullName}
            linkTo={getDocumentUrl({ ...item })}
            id={item.documentId}
            useLazyLoading={true}
          />
        )}
        <TimelineMergeItem
          merges={
            index === items.length - 1
              ? item?.additionalChildren?.slice(1).map(createSplitItem)
              : item?.additionalChildren?.map(createSplitItem)
          }
          firstItem={
            index === items.length - 1
              ? item?.additionalChildren?.[0] &&
                createSplitItem(item?.additionalChildren?.[0])
              : index === items.length - 2 &&
                items[items.length - 1].hasMoreConnections
              ? createSplitItem(items[items.length - 1])
              : undefined
          }
          id={item.documentId}
          useLazyLoading={true}
        />
      </>
    );
  };

export default DocumentHistoryTimelineItem;
