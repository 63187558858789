import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import { AiCompletionNamedConfigurationDto } from "@rhinestone/portal-web-api";

interface AiConfigurationSelectProps {
  completionConfigurations: AiCompletionNamedConfigurationDto[];
  selectedConfig: AiCompletionNamedConfigurationDto;
  onConfigurationChanged: (config: AiCompletionNamedConfigurationDto) => void;
}

const useStyles = makeStyles(theme => ({
  select: {
    marginBottom: theme.spacing(1)
  }
}));

const AiConfigurationSelect: React.FC<AiConfigurationSelectProps> = ({
  completionConfigurations,
  selectedConfig,
  onConfigurationChanged
}) => {
  const classes = useStyles();

  const handleConfigurationChanged = (value: string) => {
    onConfigurationChanged(
      completionConfigurations.find(x => x.key === value) ??
        completionConfigurations[0]
    );
  };

  return (
    <FormControl fullWidth className={classes.select}>
      <InputLabel id="demo-simple-select-label">Produkt</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedConfig.key}
        label="Konfiguration"
        onChange={event =>
          handleConfigurationChanged(event.target.value as string)
        }
      >
        {completionConfigurations?.map(x => (
          <MenuItem key={x.key} value={x.key}>
            {x.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AiConfigurationSelect;
