import * as React from "react";
import MuiAlert, { AlertProps as MuiAlertProps } from "@material-ui/lab/Alert";
import MuiAlertTitle from "@material-ui/lab/AlertTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";

export type AlertProps = Pick<
  MuiAlertProps,
  "severity" | "variant" | "icon" | "action"
> & {
  title?: JSX.Element;
  className?: string;
};

// Default font Source Sans Pro does not have font-weight 500 (Medium), set to 600 (demi bold)
const useStyles = makeStyles(theme => ({
  alertTitle: {
    fontWeight: 600
  }
}));

export const Alert: React.FC<AlertProps> = ({
  title,
  children,
  action,
  className,
  icon,
  severity,
  variant
}) => {
  const classes = useStyles();

  return (
    <MuiAlert
      action={action}
      className={className}
      icon={icon}
      severity={severity}
      variant={variant}
    >
      {title && (
        <MuiAlertTitle className={classes.alertTitle}>{title}</MuiAlertTitle>
      )}
      {children}
    </MuiAlert>
  );
};
