import { Feature, useUserHasFeature } from "../../../user/features";
import {
  mapToAssetCollection,
  useAssetCollectionContext
} from "../../AssetCollection/asset-collection-context";

export function useHideAddToAssetCollectionAction(): boolean {
  const userHasAssetCollectionFeature = useUserHasFeature(
    Feature.AssetCollection
  );
  const assetCollectionContext = useAssetCollectionContext();
  const assetCollection = mapToAssetCollection(assetCollectionContext);

  return !!assetCollection || !userHasAssetCollectionFeature;
}
