import MaterialUiMenuItem from "@material-ui/core/MenuItem";
import * as React from "react";

export interface MenuItemProps {
  children?: React.ReactNode;
  value?: string | string[] | number;
  onClick?: () => void;
}

/*
 * Material-ui needs to get the underlying DOM-node, in this case to calculate actual width.
 * So to make sure the ref is to the correct element, we use React's forwardRef API.
 * @link ../README.md#wrapping_components | Wrapping components
 */

export const MenuItem = React.forwardRef(
  (props: MenuItemProps, ref: React.Ref<HTMLLIElement> | null | undefined) => {
    return <MaterialUiMenuItem ref={ref} {...props} />;
  }
);
