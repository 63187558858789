import MaterialUiMenu from "@material-ui/core/Menu";
import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useState } from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  iconButton: {
    border: "2px solid " + theme.palette.primary.main
  }
}));

export const Menu: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();

  return (
    <Translate>
      {({ translate }) => (
        <>
          <IconButton
            aria-haspopup="true"
            onClick={event => setAnchorEl(event.currentTarget)}
            title={translate(
              translationKeys.document_page.show_more
            ).toString()}
            size="small"
            className={classes.iconButton}
          >
            <MoreVertIcon />
          </IconButton>
          <MaterialUiMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
          >
            {children}
          </MaterialUiMenu>
        </>
      )}
    </Translate>
  );
};
