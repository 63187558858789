import { SectionPayload } from "./SectionPayload";

export function getInitialSections(targetBookmarks: string[] | undefined) {
  const initialSectionIds: { [key: string]: SectionPayload } = {};
  for (let i = 0; i < (targetBookmarks?.length || 0); i++) {
    const bookmark: string = targetBookmarks?.[i] || "unknown";
    initialSectionIds[bookmark] = {
      sectionIds: [],
      fetchState: "pending",
      hiveId: "",
      fullName: ""
    };
  }
  return initialSectionIds;
}
