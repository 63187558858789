import React from "react";
import { FullScreenModalDialog } from "../../../ui-components/ModalDialog";

export type ParagraphZoomDialogProps = {
  open: boolean;
  onClose: () => void;
  modalTitle: React.ReactNode;
};

export const ParagraphZoomDialog: React.FC<ParagraphZoomDialogProps> = ({
  open,
  onClose,
  modalTitle,
  children
}) => {
  return (
    <FullScreenModalDialog open={open} onClose={onClose} title={modalTitle}>
      {children}
    </FullScreenModalDialog>
  );
};
