import {
  MergeDocumentViewModel,
  RelativeMergeLocation,
  MergeLocationType,
  MergeSectionViewModel
} from "@rhinestone/portal-web-api";

// Takes merge document list as input and returns flat list of notes
// filtered by specified relative merge location and location type
export function getNotesFromMergeDocuments(
  mergeDocuments: ReadonlyArray<MergeDocumentViewModel>,
  mergeTypeFilter: "editorialnote" | "localnote",
  mergeLocationType: MergeLocationType,
  relativeMergeLocation?: RelativeMergeLocation[]
): MergeSectionViewModel[] {
  let result: MergeSectionViewModel[] = [];
  mergeDocuments
    .filter(md => md.mergeType === mergeTypeFilter)
    .forEach(
      x =>
        (result = result.concat(
          x.mergeSections.filter(
            y =>
              (!relativeMergeLocation ||
                relativeMergeLocation.includes(y.relativeMergeLocation)) &&
              y.locationType === mergeLocationType
          )
        ))
    );
  return result;
}
