import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem
} from "../../ui-components/Breadcrumb/Breadcrumb";
import rison from "rison";
import { useSearchParams } from "../../../browser-utils/useSearchParams";
import { ParsedQuery } from "query-string-for-all";
import { makeStyles } from "@material-ui/core";
import { UrlTokens } from "../content/shared/UrlTokens";

interface TaxonLite {
  key: string;
  title: string;
}

interface TaxonUrlParams {
  taxons: TaxonLite[];
  productCode: string;
}

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    margin: "0 20px"
  }
}));

export const BreadcrumbContainer: React.FC = () => {
  const classes = useStyles();
  const searchParams = useSearchParams<{ p: string }>();
  const breadcrumbs = buildBreadcrumbs(searchParams);
  return !breadcrumbs ? null : (
    <Breadcrumb className={classes.breadcrumbs} entries={breadcrumbs} />
  );
};

const buildBreadcrumbs = (searchParams: { p: string }) => {
  const taxonParams = getTaxonParamsFromUrl(searchParams);
  if (!taxonParams) return null;

  const taxonUrlParams = deserializeQueryString(taxonParams);
  const productCode = taxonUrlParams.productCode;

  return taxonUrlParams.taxons.map<BreadcrumbItem>(x => ({
    label: x.title,
    href: `/Product/${productCode}?t=${x.key}`
  }));
};

const deserializeQueryString = (queryString: string) => {
  return rison.decode<TaxonUrlParams>(queryString);
};

const getTaxonParamsFromUrl = (searchParams: { p: string }) => {
  return searchParams[UrlTokens.pathToken];
};
