import React from "react";
import { TocItemData } from "./TocTreeView";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import TocElementList from "./TocElementList";
import { TocExpandIcon } from "./TocExpandIcon";

interface TocElementProps extends TocItemData {
  expanded: boolean;
  selected: boolean;
  expandedNodeIds?: string[];
  selectedNodeIds?: string[];
  onSelectedNodeIdChange?: (nodeId: string) => void;
}
const useStyles = makeStyles(theme => ({
  label: {
    // Toc items use the primary font style to indicate they are intractable
    fontFamily: ["Titillium Web", "sans-serif"].join(","),
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    display: "flex",
    maxWidth: "100%",
    lineHeight: 1.8,
    alignItems: "center",

    // Render a transparent border so when the "selected"-indicator is on the item does not jump right.
    borderLeft: "4px solid transparent",
    paddingLeft: 2
  },
  icon: {
    flex: "0 0 24px",
    height: "24px"
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  selected: {
    borderLeftColor: theme.palette.primary.main,
    color: theme.palette.text.primary
  }
}));
const TocElement: React.FC<TocElementProps> = ({
  nodeId,
  heading,
  children,
  target,
  expandedNodeIds,
  selectedNodeIds,
  expanded,
  selected,
  onSelectedNodeIdChange
}) => {
  const classes = useStyles();

  return (
    <li data-test-id={target}>
      <a
        onClick={() => onSelectedNodeIdChange?.(nodeId)}
        className={clsx(classes.label, {
          [classes.selected]: selected
        })}
        href={`#${target}`}
        data-target={nodeId}
        title={heading}
        data-test-is-selected={selected ? selected : undefined}
      >
        <span className={classes.icon}>
          {children.length > 0 && <TocExpandIcon expanded={expanded} />}
        </span>
        <span className={classes.text}>{heading}</span>
      </a>
      {expanded && (
        <TocElementList
          children={children}
          expandedNodeIds={expandedNodeIds}
          selectedNodeIds={selectedNodeIds}
          onSelectedNodeIdChange={onSelectedNodeIdChange}
        />
      )}
    </li>
  );
};

export default React.memo(
  TocElement,
  // eslint-disable-next-line complexity
  (prevProps, nextProps) =>
    prevProps.nodeId === nextProps.nodeId &&
    prevProps.heading === nextProps.heading &&
    prevProps.children === nextProps.children &&
    prevProps.target === nextProps.target &&
    prevProps.expanded === nextProps.expanded &&
    !nextProps.expanded &&
    prevProps.selected === nextProps.selected &&
    !nextProps.selected
);
