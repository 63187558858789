import * as React from "react";
import { TabPanel } from "../../../ui-components/Tabs";
import { translationKeys } from "../../../../gen/translation-keys";
import { PaneTitle } from "../../../ui-components/layouts/PaneTitle";
import { Translate } from "react-localize-redux";

export const ParagraphZoomRightPane: React.FC = ({ children }) => {
  return (
    <>
      <PaneTitle>
        <Translate id={translationKeys.document_page.about_document} />
      </PaneTitle>
      <TabPanel value={0} index={0} paneKey="pzoom_info">
        {children}
      </TabPanel>
    </>
  );
};
