import { useState, useEffect } from "react";

/*
 * This function is only intended to be used at application start up time.
 * If you need the auth state of the user at any other time, use the useUserContext hook
 */
export function useInitialAuthState(portalIdentifier: string) {
  const [authState, setAuthState] = useState<{
    isAuthenticated: boolean;
    isLoadingAuthState: boolean;
  }>({
    isAuthenticated: false,
    isLoadingAuthState: true
  });

  useEffect(() => {
    (async () => {
      const isAuthenticated = await determineIfUserIsAuthenticated(
        portalIdentifier
      );

      setAuthState({ isAuthenticated, isLoadingAuthState: false });
    })();
  }, [portalIdentifier]);

  return authState;
}

/*
 * This function uses fetch directly because other api infrastructure has not been set up yet.
 */
async function determineIfUserIsAuthenticated(portalIdentifier: string) {
  const userResponse = await fetch(`/api/Portals(${portalIdentifier})/user`);
  return userResponse.status === 200;
}
