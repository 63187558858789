//a material ui context menu component
import React from "react";
import { MenuItem } from "./ContextMenu";
import MouseContextMenu from "./MouseContextMenu";

interface SelectedTextContextMenuProps {
  menuItems?: MenuItem[];
}

const SelectedTextContextMenu: React.FC<SelectedTextContextMenuProps> = ({
  children,
  menuItems
}) => {
  return (
    <MouseContextMenu
      menuItems={menuItems}
      shouldDisplayMenu={() => {
        return !!window.getSelection()?.toString();
      }}
    >
      {children}
    </MouseContextMenu>
  );
};

export default SelectedTextContextMenu;
