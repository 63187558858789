import * as React from "react";
import { DatePicker, DatePickerDate, MAX_DATE, MIN_DATE } from "./DatePicker";

export type DateRangeValue = {
  from: DatePickerDate;
  to: DatePickerDate;
};

export interface DateRangePickerProps {
  dateFromLabel: string;
  dateToLabel: string;
  dateRangeValue: DateRangeValue;
  onChange: (value: DateRangeValue) => void;
  localeCode: string;
}

export const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = ({
  dateFromLabel,
  dateToLabel,
  dateRangeValue,
  onChange,
  localeCode
}) => {
  const [fromPickersMaxDate, setFromPickersMaxDate] = React.useState(MAX_DATE);
  const [toPickersMinDate, setToPickersMinDate] = React.useState(MIN_DATE);

  React.useEffect(() => {
    setToPickersMinDate(dateRangeValue.from);
  }, [dateRangeValue.from]);

  React.useEffect(() => {
    setFromPickersMaxDate(dateRangeValue.to);
  }, [dateRangeValue.to]);

  const handleFromDateChanged = (date: DatePickerDate) => {
    onChange({ ...dateRangeValue, from: date });
  };

  const handleToDateChanged = (date: DatePickerDate) => {
    onChange({ ...dateRangeValue, to: date });
  };

  return (
    <>
      <div>
        <DatePicker
          id="date_from"
          label={dateFromLabel}
          value={dateRangeValue.from}
          localeCode={localeCode}
          onChange={handleFromDateChanged}
          maxDate={fromPickersMaxDate}
        />
      </div>

      <div>
        <DatePicker
          id="date_to"
          label={dateToLabel}
          value={dateRangeValue.to}
          localeCode={localeCode}
          onChange={handleToDateChanged}
          minDate={toPickersMinDate}
        />
      </div>
    </>
  );
};
