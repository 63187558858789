import * as React from "react";
import { noop } from "lodash";
import { useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import { FoldingTimeoutInMs } from "./FoldableSection";
import { RhinestoneTargetLocation } from "../../rhinestone-document-helper-types";

// Normally this would not be necessary, but because our app is bootstrapped multiple times
// as we are working inside angular, global scope is lost between pages etc.
// to make up for this until we have a persistent App, we save state in module-scope here.
// Just move this up to the root of the App and remove the code that uses "saveInModuleScope".
// Depending on requirement, the state could also be stored in session storage.
let saveInModuleScope: FoldableSectionState = {};

type FoldableSectionContextProps = [
  FoldableSectionState,
  React.Dispatch<React.SetStateAction<FoldableSectionState>>
];

const FoldableSectionContext = (() => {
  const newContext = React.createContext<FoldableSectionContextProps>([
    {},
    noop
  ]);
  newContext.displayName = "FoldableSectionContext";
  return newContext;
})();

interface FoldableSectionState {
  [key: string]: boolean | undefined;
}

export interface SectionAncestorMap {
  [key: string]: string[];
}

interface FoldableSectionProviderProps {
  initialState?: FoldableSectionState;
  targetLocation?: RhinestoneTargetLocation;
  sectionIdMap?: SectionAncestorMap;
  onScrollElementIntoView?: (elementId: string) => void;
}

export const FoldableSectionProvider: React.FC<FoldableSectionProviderProps> =
  ({
    children,
    initialState = saveInModuleScope,
    targetLocation,
    sectionIdMap,
    onScrollElementIntoView
  }) => {
    const [state, setState] =
      React.useState<FoldableSectionState>(initialState);

    const pathname = useDocumentPathName();

    // Remove this when no longer run through angular app
    useEffect(() => {
      saveInModuleScope = { ...state };
    }, [state]);

    useEffect(() => {
      if (!sectionIdMap || !targetLocation?.hash) return;

      const ancestorsOrSelf = findNodesToExpandFromHash(
        sectionIdMap,
        targetLocation.hash
      );

      if (!ancestorsOrSelf || ancestorsOrSelf.length === 0) return;

      // we need to request a scroll to current element when we actually unfold
      // and we need to wait with scroll until sections are fully expanded
      // otherwise we get weird scroll behaviour
      // this seems very dependent on what document we are viewing and may not work properly
      // with large documents. Have not figured out a good way for large documents yet.
      // Maybe solution is to disable transition entirely since unfolding and scroll behavior seems difficult to get right
      // for now its set very low, and with small delay
      let scrollRequestTimeout: NodeJS.Timeout;

      setState(prevState => {
        const ancestorsToExpand = calculateNewStateForAncestors(
          ancestorsOrSelf,
          pathname,
          prevState
        );

        if (Object.keys(ancestorsToExpand).length === 0) return prevState;
        scrollRequestTimeout = setTimeout(() => {
          onScrollElementIntoView?.(targetLocation.hash);
        }, FoldingTimeoutInMs + 100);
        return {
          ...prevState,
          ...ancestorsToExpand
        };
      });

      return () => {
        if (scrollRequestTimeout) clearTimeout(scrollRequestTimeout);
      };
    }, [onScrollElementIntoView, pathname, sectionIdMap, targetLocation]);

    return (
      <FoldableSectionContext.Provider value={[state, setState]}>
        {children}
      </FoldableSectionContext.Provider>
    );
  };

function calculateNewStateForAncestors(
  ancestorIds: string[],
  documentPath: string,
  existingFoldedState: FoldableSectionState
) {
  return (
    ancestorIds
      // generate key for each ancestor
      .map(ancestor => `${documentPath}+${ancestor}`)
      // only take ancestors that exists in state and are not expanded
      // using explicit check for false since we dont want get ancestors not defined in state
      .filter(key => existingFoldedState[key] === false)
      // convert array of keys to object with expanded state to true
      .reduce(
        (accumulatedResult, currentAncestor) => ({
          ...accumulatedResult,
          [currentAncestor]: true
        }),
        {}
      )
  );
}

export function findNodesToExpandFromHash(
  map: { [key: string]: string[] },
  hash: string
): string[] {
  const [, tocTargetId] = hash.split("#");
  const ancestors = map[tocTargetId];

  if (ancestors?.length > 0) {
    return ancestors;
  }

  if (tocTargetId in map) {
    return [tocTargetId];
  }

  return [];
}

export function useShouldShowFoldableSectionAction() {
  const { expandedState } = useFoldableSectionState();
  return Object.keys(expandedState).length > 0;
}
export function useFoldableSectionState(
  sectionId?: string,
  defaultExpanded?: boolean
) {
  const [state, setState] = useContext(FoldableSectionContext);

  const documentPath = useDocumentPathName();
  const key = `${documentPath}+${sectionId}`;

  const setExpanded = (expanded: boolean | undefined) => {
    if (sectionId) {
      setState(prev => ({ ...prev, [key]: expanded }));
    }
  };

  const setExpandedAll = (expanded: boolean | undefined) => {
    setState(prev => {
      const newState = { ...prev };
      Object.keys(newState).forEach(key => {
        if (key.startsWith(documentPath)) {
          newState[key] = expanded;
        }
      });
      return newState;
    });
  };
  // Set defaultExpanded state in context only when initializing hook so it can only happens first time (if state is not already set)
  useEffect(() => {
    if (sectionId) {
      setState(prev => {
        // only set default, if section is not already tracked in state!
        // important otherwise module saved state gets overwritten.
        return prev[key] === undefined
          ? { ...prev, [key]: defaultExpanded }
          : prev;
      });
    }
  }, [defaultExpanded, key, sectionId, setState]);
  // return state of foldable section if it already exist, otherwise use the defaultExpanded state
  const expanded = state[key] !== undefined ? state[key] : defaultExpanded;
  return {
    expanded,
    setExpanded,
    setExpandedAll,
    expandedState: state
  };
}

export function useDocumentPathName() {
  return useLocation().pathname;
}
