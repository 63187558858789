import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { TimelineConnector } from "./TimelineConnector";
import { TimelineContent } from "./TimelineContent";
import { TimelineSeparator } from "./TimelineSeparator";
import TimelineActiveLink from "./TimelineActiveLink";
import { TimelineItemWrapper } from "./TimelineItemWrapper";

interface TimelineItemProps {
  id?: string;
  active?: boolean;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  sticky?: boolean;
  linkTo?: string;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  labels?: React.ReactNode[];
  icon: React.ReactNode;
  useLazyLoading?: boolean;
}

export const CenteredTimelineItem: React.FC<TimelineItemProps> = ({
  id,
  sticky,
  useLazyLoading,
  ...rest
}) => {
  return (
    <TimelineItemWrapper
      sticky={sticky}
      id={id}
      useLazyLoading={useLazyLoading}
    >
      <Separator {...rest} />
      <Content {...rest} />
    </TimelineItemWrapper>
  );
};

const Separator: React.FC<TimelineItemProps> = ({
  active,
  icon,
  linkTo,
  isFirstItem,
  isLastItem
}) => (
  <TimelineSeparator>
    {!isFirstItem && <TimelineConnector />}
    <TimelineActiveLink active={active} linkTo={linkTo}>
      {icon}
    </TimelineActiveLink>
    {!isLastItem && <TimelineConnector />}
  </TimelineSeparator>
);

const Content: React.FC<TimelineItemProps> = ({
  active,
  linkTo,
  title,
  subTitle,
  labels,
  isFirstItem,
  isLastItem
}) => {
  const align = isFirstItem ? "start" : isLastItem ? "end" : "center";
  return (
    <TimelineContent align={align}>
      <Box flexBasis="auto">
        <TimelineActiveLink active={active} linkTo={linkTo}>
          <Typography variant="subtitle1" gutterBottom={false}>
            {title}
          </Typography>
        </TimelineActiveLink>
        {subTitle && (
          <Typography variant="subtitle2" color="textSecondary">
            <Box fontWeight="fontWeightMedium" fontStyle="italic">
              {subTitle}
            </Box>
          </Typography>
        )}
      </Box>
      {labels?.map((label, index) =>
        label ? (
          <Box flexBasis="auto" marginLeft={1} key={index}>
            {label}
          </Box>
        ) : null
      )}
    </TimelineContent>
  );
};
