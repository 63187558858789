import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FieldSetTaxonValue } from "../use-fieldset";

export interface ClassificationProps {
  taxons: FieldSetTaxonValue[];
}

const useStyles = makeStyles(theme => ({
  taxonsList: {
    paddingLeft: "20px"
  }
}));

export const Classification: React.FC<ClassificationProps> = ({ taxons }) => {
  const classes = useStyles();

  if (taxons.length === 0) {
    return null;
  }

  if (taxons.length === 1) {
    return <span>{taxons[0].Title}</span>;
  }

  return (
    <ul className={classes.taxonsList}>
      {taxons.map(t => (
        <li key={t.Key}>{t.Title}</li>
      ))}
    </ul>
  );
};
