import React from "react";
import { RelationGroupDto } from "@rhinestone/portal-web-api";
import NoRelationsWarning from "./NoRelationsWarning";
import RelationChips from "./RelationChips";
import RelationChipContainer from "./RelationChipContainer";

interface RelationWrapperProps {
  expanded: boolean;
  setExpanded: (open: boolean) => void;
  sectionId: string;
  relationData?: RelationGroupDto[];
  showAll?: boolean;
}

export const RelationWrapper: React.FC<RelationWrapperProps> = ({
  expanded,
  setExpanded,
  sectionId,
  relationData,
  showAll
}) => {
  return (
    <RelationChips expanded={expanded} onClose={() => setExpanded(false)}>
      {relationData && relationData.length > 0 ? (
        relationData.map((relationDataElement, i) => (
          <RelationChipContainer
            key={relationDataElement.relationGroupName || i}
            relationGroupName={relationDataElement.relationGroupName}
            relationGroupNameTranslationKey={
              relationDataElement.relationGroupNameTranslationKey
            }
            uniqueRelatedDocumentsCount={
              relationDataElement.uniqueRelatedDocumentsCount
            }
            sectionId={sectionId}
            showAll={!!showAll}
          />
        ))
      ) : (
        <NoRelationsWarning
          expanded={expanded}
          setExpanded={() => setExpanded(false)}
        />
      )}
    </RelationChips>
  );
};
