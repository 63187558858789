import React from "react";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import clsx from "clsx";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  icon: {
    paddingBottom: 2.5,
    paddingTop: 2.5,
    filter: "drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))",
    height: 25
  },
  outline: {
    fill: "transparent",
    stroke: "currentColor",
    strokeWidth: 3,
    zoom: 0.8,
    filter: "none",
    width: 25
  }
}));

interface DotIconProps {
  active?: boolean;
  alt?: string;
}
export const DotIcon: React.FC<DotIconProps> = ({ active, alt }) => {
  const classes = useStyles();
  return (
    <CircleIcon
      fontSize={"small"}
      color={"primary"}
      className={clsx(classes.icon, !active && classes.outline)}
      titleAccess={alt}
    />
  );
};
