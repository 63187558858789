import { DocumentRelationNodeDto } from "@rhinestone/portal-web-api";
import { usePortalApi } from "../../api-access/use-portal-api";

export function useGetRelationTreeData(
  hiveId: string,
  fullName: string,
  portalApiEnabled: boolean,
  followsRelationTypeCode: string,
  portalApi = usePortalApi
): {
  data: DocumentRelationNodeDto | undefined;
  isFetching: boolean;
} {
  const { data, isFetching } = portalApi(
    client =>
      client.getRelationTree(
        hiveId,
        fullName,
        [followsRelationTypeCode],
        "DefaultPortalFields"
      ),
    [
      "getRelationTree",
      hiveId,
      fullName,
      [followsRelationTypeCode],
      "DefaultPortalFields"
    ],
    {
      enabled: portalApiEnabled
    }
  );

  return { data, isFetching };
}
