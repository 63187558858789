import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box } from "@material-ui/core";
import DocumentFullWidthComponent from "../../../../ui-components/layouts/DocumentFullWidthComponent";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  }
}));

const TableWrapper: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <DocumentFullWidthComponent>
      <Box className={classes.wrapper}>{children}</Box>
    </DocumentFullWidthComponent>
  );
};

export default TableWrapper;
