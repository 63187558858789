import * as React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../../gen/translation-keys";
import { TextKeys } from "./TextField";
import FormSelectField from "../../../ui-components/forms/controls/FormSelectField";

interface EmailSelectFormFieldProps {
  disabled?: boolean;
  fieldName: TextKeys;
  label?: React.ReactNode;
  options?: string[];
}
const EmailSelectFormField: React.FC<EmailSelectFormFieldProps> = ({
  disabled,
  fieldName,
  label,
  options
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <FormSelectField
          disabled={disabled}
          fieldName={fieldName}
          label={label}
          fullWidth
          fieldOptions={{
            required: translate(
              translationKeys.document_page.send_document_link_to_email_required
            ).toString(),
            maxLength: {
              value: 250,
              message: translate(
                translationKeys.document_page
                  .send_document_link_to_email_too_long
              ).toString()
            }
          }}
        >
          {options &&
            options.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
        </FormSelectField>
      )}
    </Translate>
  );
};

export default EmailSelectFormField;
