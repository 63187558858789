import { useLayoutEffect } from "react";
import Mark, { MarkOptions } from "mark.js";

export function useMarkJs(
  element: HTMLElement | null,
  terms: string | string[] | undefined,
  options: MarkOptions = {}
) {
  useLayoutEffect(() => {
    if (!terms || !terms.length) return;

    const markInstance = new Mark(element);
    markInstance.mark(terms, options);
    return () => markInstance.unmark();
  }, [element, terms, options]);
}

// This constant is the default used to highlight words in document from SOLR
// We should only highlight exact matches (not case-sensitive) as the highlight endpoint
// gives us all synonyms. However, to match SOLR internal highlights we need to add MARK.js
// options to not care about certain special-characters etc.
// Read about mark.js options: https://markjs.io/#mark
export const SEARCH_MARK_OPTIONS: MarkOptions = {
  accuracy: {
    value: "exactly",
    limiters: [",", ".", "(", ")", "-"]
  },
  separateWordSearch: false
};
