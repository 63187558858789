/* eslint-disable complexity */
import { useCallback } from "react";
import {
  AnnotationModel,
  HtmlNewAnnotationDTO,
  PdfNewAnnotationDTO
} from "@rhinestone/portal-web-api";
import {
  AddAnnotationArguments,
  AnnotationTargetType,
  UpdateAnnotationArguments
} from "../../api-access/portal-api-access";
import {
  PortalApiQueryKey,
  usePortalApi,
  usePortalApiMutation
} from "../../api-access/use-portal-api";
import { useSnackbar } from "../../ui-components/Snackbar";
import { Feature, useUserHasFeature } from "../../user/features";
import { Annotation } from "./annotation-state";
import { translationKeys } from "../../../gen/translation-keys";

/**
 * hook to retrieve annotations of all types (pdf|html)
 * and for all context "types" (user|assetCollection)
 *
 * @param contextId either userId or assetCollectionId
 * @param targetId annotationTargetId for document or asset
 * @param targetType {@link AnnotationTargetType} used to type switch which annotation type to fetch
 * @returns
 */
export function useGetAnnotations<T extends AnnotationModel>(
  contextId: string | undefined,
  targetId: string | undefined,
  targetType: AnnotationTargetType
) {
  const hasAnnotationsFeature = useUserHasFeature(Feature.MarkUpAndAnnotations);
  const { data } = usePortalApi<T[]>(
    client =>
      client.getAnnotations(contextId ?? "", targetId ?? "", targetType),
    ["getAnnotations", targetId, contextId],
    {
      enabled: hasAnnotationsFeature && Boolean(contextId) && Boolean(targetId)
    }
  );
  return data;
}

/**
 * hook used to add new annotation of either type (pdf|html)
 *
 * will invalidate annotation queries and any other supplied query keys
 * @param targetId annotationTargetId for document or asset
 * @returns a promise function that can be called to add new annotation
 */
export function useAddAnnotation<
  T extends AnnotationModel,
  V extends HtmlNewAnnotationDTO | PdfNewAnnotationDTO
>(
  targetId: string | undefined,
  extraQueryKeysToInvalidate?: PortalApiQueryKey[]
) {
  const { enqueueSuccessTranslation } = useSnackbar();

  const { mutateAsync } = usePortalApiMutation<T, AddAnnotationArguments>(
    client => client.addAnnotation,
    ({ contextId }) => [
      ["getAnnotations", targetId, contextId],
      ...(extraQueryKeysToInvalidate ?? [])
    ],
    {
      onSuccess: addedAnnotation =>
        enqueueSuccessTranslation(translationKeys.annotations.add_success)
    }
  );

  return useCallback(
    ({ annotation, contextId }: Annotation<V>) => {
      if (!targetId) return;
      return mutateAsync({
        contextId,
        targetId,
        annotation
      });
    },
    [mutateAsync, targetId]
  );
}

/**
 * hook used to save existing annotation of either type (pdf|html)
 *
 * will invalidate annotation queries and any other supplied query keys
 * @param targetId annotationTargetId for document or asset
 * @returns a promise function that can be called to save annotation
 */
export function useSaveAnnotation<T extends AnnotationModel>(
  targetId: string | undefined,
  extraQueryKeysToInvalidate?: PortalApiQueryKey[]
) {
  const { enqueueSuccessTranslation } = useSnackbar();
  return useSaveMutationAsync<T>(targetId, extraQueryKeysToInvalidate, _ =>
    enqueueSuccessTranslation(translationKeys.annotations.save_comment_success)
  );
}

export function useAddAnnotationComment<T extends AnnotationModel>(
  targetId: string | undefined,
  extraQueryKeysToInvalidate?: PortalApiQueryKey[]
) {
  const { enqueueSuccessTranslation } = useSnackbar();
  return useSaveMutationAsync<T>(targetId, extraQueryKeysToInvalidate, _ =>
    enqueueSuccessTranslation(translationKeys.annotations.add_comment_success)
  );
}

export function useRemoveAnnotationComment<T extends AnnotationModel>(
  targetId: string | undefined,
  extraQueryKeysToInvalidate?: PortalApiQueryKey[]
) {
  const { enqueueSuccessTranslation } = useSnackbar();
  return useSaveMutationAsync<T>(targetId, extraQueryKeysToInvalidate, _ =>
    enqueueSuccessTranslation(
      translationKeys.annotations.remove_comment_success
    )
  );
}

function useSaveMutationAsync<T extends AnnotationModel>(
  targetId: string | undefined,
  extraQueryKeysToInvalidate?: PortalApiQueryKey[],
  onSuccess?: (data: T, variables: UpdateAnnotationArguments) => void
) {
  const { mutateAsync } = usePortalApiMutation<T, UpdateAnnotationArguments>(
    client => client.saveAnnotation,
    // this invalidates all queries related to annotations for target independent of "context"
    ({ contextId }) => [
      ["getAnnotations", targetId, contextId],
      ...(extraQueryKeysToInvalidate ?? [])
    ],
    {
      onSuccess
    }
  );

  return mutateAsync;
}

/**
 * hook used to remove existing annotation of either type (pdf|html)
 *
 * will invalidate annotation queries and any other supplied query keys
 * @param targetId annotationTargetId for document or asset
 * @returns a promise function that can be called to save annotation
 */
export function useRemoveAnnotation<T extends AnnotationModel>(
  targetId: string | undefined,
  extraQueryKeysToInvalidate?: PortalApiQueryKey[]
) {
  const { enqueueSuccessTranslation } = useSnackbar();

  const { mutateAsync } = usePortalApiMutation<T, UpdateAnnotationArguments>(
    client => client.removeAnnotation,
    // this invalidates all queries related to annotations for target independent of "context"
    ({ contextId }) => [
      ["getAnnotations", targetId, contextId],
      ...(extraQueryKeysToInvalidate ?? [])
    ],
    {
      onSuccess: updateAnnotation =>
        enqueueSuccessTranslation(translationKeys.annotations.remove_success)
    }
  );

  return mutateAsync;
}
